.accordion {
    &-group-wrap {
        border-top: 1px solid #bbb;
        &.cscenter {
            // margin-left: -2rem;
            // margin-right: -2rem;

            .accordion {
                &__btn {
                    position: relative;
                    z-index: 0;
                    width: 100%;
                    min-height: 5rem;
                    padding: 1.5rem 3rem 1.5rem 0;
                    border-bottom: 1px solid #bbb;
                    background-color: #fff;
                    font-size: 1.8rem;
                    text-align: left;
                    cursor: pointer;
            
                    // &::before {
                    //     content: 'Q';
                    //     display: inline-block;
                    //     color: #21a272;
                    //     font-weight: 700;
                    //     margin-right: .6rem;
                    //     vertical-align: top;
                    // }
            
                    &::after {
                        display: block;
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: 0;
                        width: 2rem;
                        height: 2rem;
                        transform: rotate(180deg);
                        margin-top: -1rem;
                        // transform-origin: center;
                        transition: all .5s;
                        background-image: url(../images/ico/ico_arrow.svg);
                        background-size: cover;
                    }
            
                    &[aria-expanded="true"] {
                        // background-color: #f2f2f2;
                        // font-weight: 700;
            
                        &::after {
                            transform: rotate(0deg);
                        }
                    }
            
                    > span {
                        display: inline-block;
                        width: calc(100% - 3rem);
                        word-break: keep-all;
                    }
                    
                }
            
                &__panel {
                    // height: 0;
                    overflow: hidden;
                    // transition: height .3s;
                    background-color: #f2f2f2;
                }
            
                &__contents {
                    padding: 2rem;
                    font-size: 1.6rem;
                    color: #0a0a0a;
                    line-height: 2.6rem;
                    border-bottom: 1px solid #bbb;
            
                    // &::before {
                    //     content: 'A';
                    //     display: inline-block;
                    //     color: #21a272;
                    //     font-weight: 700;
                    //     margin-right: .6rem;
                    //     vertical-align: top;
                    // }
            
                    .textarea {
                        display: inline-block;
                        // width: calc(100% - 2rem);
                        // word-break: keep-all;
                        height: 100%;
                        border: 0;
                        padding: 0;
                    }
            
                    .text-box {
                        display: inline-block;
                        width: 100%;
                    }
                }
            }
        }

        &.search {
            border-top: 0;

            .accordion__btn {
                padding-top: 3rem;
                padding-bottom: 3rem;
                font-size: 1.8rem;
                font-weight: 500;
                border-bottom: 0;
                border-top: 1px solid #cfcfcf;

                &:first-child {
                    border-top: 0;
                }
            }

            .accordion__contents {
                padding-top: 0;
                padding-bottom: 0;
            }

            .list-box {
                font-size: 0;
                margin: -1.6rem;
                padding-bottom: 1.4rem;
                // padding-bottom: 3rem;

                li {
                    display: inline-block;
                    width: calc(25% - 3.2rem);
                    margin: 1.6rem;
                    text-align: center;
                    vertical-align: top;

                    .thumb {
                        display: block;
                        position: relative;
                        width: 100%;
                        background-position: center;
                        background-size: 52%;
                        background-repeat: no-repeat;
                        background-color: #f2f2f2;
                        border-radius: 50%;
                        
                        &::after {
                            content: "";
                            display: block;
                            padding-bottom: 100%;
                        }
                        
                    }

                    .title {
                        display: block;
                        margin-top: .8rem;
                        font-size: 1.5rem;
                        color: #0a0a0a;
                        font-weight: 500;
                    }

                    &:last-child {
                        .thumb {
                            background-image: url(../images/ico/ico_search_cate_all.svg);
                        }
                    }
                }

                &.effect {

                    li {
                        &:first-child {
                            .thumb {
                                background-image: url(../images/ico/ico_search_cate_effect01.svg);
                            }
                        }
    
                        &:nth-child(2) {
                            .thumb {
                                background-image: url(../images/ico/ico_search_cate_effect02.svg);
                            }
                        }
    
                        &:nth-child(3) {
                            .thumb {
                                background-image: url(../images/ico/ico_search_cate_effect03.svg);
                            }
                        }
    
                        &:nth-child(4) {
                            .thumb {
                                background-image: url(../images/ico/ico_search_cate_effect04.svg);
                            }
                        }
    
                        &:nth-child(5) {
                            .thumb {
                                background-image: url(../images/ico/ico_search_cate_effect05.svg);
                            }
                        }
    
                        &:nth-child(6) {
                            .thumb {
                                background-image: url(../images/ico/ico_search_cate_effect06.svg);
                            }
                        }
    
                        &:nth-child(7) {
                            .thumb {
                                background-image: url(../images/ico/ico_search_cate_effect07.svg);
                            }
                        }
                    }
                }

                &.ingredient {

                    li {
                        &:first-child {
                            .thumb {
                                background-image: url(../images/ico/ico_search_cate_ingredient01.svg);
                            }
                        }
    
                        &:nth-child(2) {
                            .thumb {
                                background-image: url(../images/ico/ico_search_cate_ingredient02.svg);
                            }
                        }
    
                        &:nth-child(3) {
                            .thumb {
                                background-image: url(../images/ico/ico_search_cate_ingredient03.svg);
                            }
                        }
    
                        &:nth-child(4) {
                            .thumb {
                                background-image: url(../images/ico/ico_search_cate_ingredient04.svg);
                            }
                        }
    
                        &:nth-child(5) {
                            .thumb {
                                background-image: url(../images/ico/ico_search_cate_ingredient05.svg);
                            }
                        }
    
                        &:nth-child(6) {
                            .thumb {
                                background-image: url(../images/ico/ico_search_cate_ingredient06.svg);
                            }
                        }
                    }
                }
            }

            
        }
    }

    &__btn {
        position: relative;
        z-index: 0;
        width: 100%;
        min-height: 5rem;
        padding: 1.6rem 0;
        border-bottom: 1px solid #bbb;
        background-color: #fff;
        font-size: 1.6rem;
        text-align: left;
        cursor: pointer;
        color: #0a0a0a;

        &::after {
            display: block;
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            width: 2rem;
            height: 2rem;
            transform: rotate(180deg);
            // margin-top: -.6rem;
            margin-top: -1rem;
            transform-origin: center;
            transition: all .5s;
            background-image: url(../images/ico/ico_arrow.svg);
            background-size: cover;
        }

        &[aria-expanded="true"] {
            // font-weight: 700;

            &::after {
                transform: rotate(0deg);
            }
        }

        > span {
            display: inline-block;
            width: calc(100% - 5rem);
            word-break: keep-all;
        }
        
    }

    &__panel {
        // height: 0;
        overflow: hidden;
        transition: height .2s;
        // background-color: #f2f2f2;

        

        &.is-active {
            height: 100%  !important;
        }

        &.is-none {
            height: 0;
        }
    }

    &__contents {
        padding: 1.6rem 0;
        font-size: 1.4rem;
        color: #636363;
        line-height: 2rem;

        // &::before {
        //     content: 'A';
        //     display: inline-block;
        //     color: #21a272;
        //     font-weight: 700;
        //     margin-right: .6rem;
        //     vertical-align: top;
        // }

        > p {
            display: inline-block;
            width: calc(100% - 2rem);
            // word-break: keep-all;
        }

        .text-box {
            display: inline-block;
            // width: calc(100% - 2rem);

            &.is-nodeite {
                * {
                    background-color: transparent !important;
                }
    
                > p {
                    text-align: left !important;
                    font-size: 1.4rem !important;
                    line-height: 2rem !important;
                }
    
                s {
                    text-decoration: none !important;
                }
    
                sub, sup {
                    font-size: 100% !important;
                    line-height: 2rem !important;
                    position: relative !important;
                    vertical-align: middle !important;
                }
    
                sub {
                    bottom: 0 !important;
                }
    
                sup {
                    top: 0 !important;
                }
    
                em, p, s, u, strong, sup, sub, span {
                    font-family: 'Pretendard', '-apple-system', 'BlinkMacSystemFont', system-ui, sans-serif !important;
                }
    
                a {
                    color: #00a972 !important;
                    text-decoration: underline;
                }
            }
        }

        .profile__list {
            padding: 0 1.6rem;
            background-color: #f2f2f2;

            li + li {
                border-top: 1px solid #bbb;
            }

            li:last-child {
                border-bottom: 0;
            }

            .list-wrap {
                &::after {
                    width: 1.4rem;
                    height: 1.4rem;
                }
            }

            .title {
                display: inline-block;
                width: auto;
                font-size: 1.5rem;
                font-weight: normal;
            }
        }
    }

    &-box {
        
        &.detail__info {
            .accordion {
                &__btn {
                    &::after {
                        width: 1.6rem;
                        height: 1.6rem;
                    }
                }
            }
        }
    }
}

.filed {
    &.--acc {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 2rem;
        padding-bottom: 2rem;

        // label {
        //     white-space: nowrap;
        // }

        .checkbox-large {
            width: 100%;
            max-width: calc(100% - 3rem);
        }

        .accordion__btn {
            width: 2rem;
        }
    }
}