.modal {

	&-box {
		margin: 2.8rem;
	}

	// full
	&-full {
		width: 100%;
		height: 100%;
		border-radius: 0;
		top: 0;
		left: 0;
		transform: none;
		
		overflow: hidden;

		.modal__body .scroll-box .scroll {
			height: 100vh;
			max-height: calc(100vh - 185px);
		}
	}

	&__header {

		.essential {
			color: #949494;
			margin-right: .4rem;
		}

		.date {
			display: block;
			margin-top: .4rem;
			font-size: 1.4rem;
			color: #9a9a9a;
		}

		+ .modal__body {
			margin-top: 2rem;
		}
	}

	&__body {
		.thumb {
			margin-top: 2rem;
			margin-bottom: 2rem;
			text-align: center;

			img {
				max-width: 9rem;
			}
		}

		.desc {
			font-size: 1.6rem;
			color: #0a0a0a;
			line-height: 1.4;
			text-align: center;

			&.cart {
				&::before {
					content: '';
					display: inline-block;
					width: 2rem;
					height: 2rem;
					background-image: url(../images/ico/ico_cart.svg);
					background-position: center;
					background-size: cover;
					background-repeat: no-repeat;
					margin-right: .4rem;
					vertical-align: middle;
				}
			}

			> span {
				display: block;
				
				+ span {
					margin-top: .4rem;
				}
			}
		}

		.scroll-box {
			overflow: hidden;
			margin-top: 2rem;
			border: 1px solid #bbb;
			border-radius: 1rem;
			font-size: 1.4rem;
			line-height: 2rem;
			color: #949494;

			&.bg--gray {
				border: 0;
				color: #0a0a0a;

				.policy-box {
					p, li {
						margin-top: 0;
						color: #0a0a0a;
					}

				}

				.--text {
					p + p, p + .list-box, .list-box + .list-box, .list-box + p  {
						margin-top: 1.6rem;
					}

					.list {
						margin-top: 0;
						padding-left: 0.6rem;

						li {
							position: relative;

							&::before {
								content: "";
								position: absolute;
								top: 0.9rem;
								left: -0.6rem;
								width: 0.2rem;
								height: 0.2rem;
								background-color: #636363;
								border-radius: 50%;
							}
						}
					}
				}
			}

			.scroll {
				overflow-y: auto;
				height: 100%;
				max-height: 30rem;
				padding: 1.4rem;

				&.termspolicy {
					max-height: calc(100vh - 257px);
				}
			}
		}

		.mbr__header {
			margin-top: 0;
			margin-bottom: 1.2rem;
			
			.title {
				font-size: 1.6rem;
				color: #0a0a0a;
			}
		}

		.marketing {
			&__list {
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				
				&::before {
					content: '';
					display: inline-block;
					// background-color: red;
					width: 2.8rem;
					height: 2.8rem;
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;
				}

				dt {
					display: block;
					font-size: 1.6rem;
					color: #0a0a0a;
				}

				dd {
					display: block;
					margin-top: .6rem;
					margin-left: 0;
					font-size: 1.4rem;
					color: #9a9a9a;

				}

				&.mail::before {
					background-image: url(../images/ico/ico_email.svg);
				}

				&.sms::before {
					background-image: url(../images/ico/ico_sms.svg);
				}

				&.push::before {
					background-image: url(../images/ico/ico_noti.svg);
				}

				+ .marketing__list {
					margin-top: 1.8rem;
				}
			}

			&__agree {
				margin-left: 1.6rem;
			}
		}
	}

	&__footer {
		

		&--area {
			display: flex;
			justify-content: center;
			align-items: center;

			.btn {
				flex: 1;

				+ .btn {
					margin-left: .4rem;
				}
			}

			&.is-vertical {
				flex-direction: column;

				.btn {
					width: 100%;
					
					+ .btn {
						margin-left: 0;
						margin-top: .8rem;
					}
				}
			}
		}
	}

	// &__title {
	// 	padding: 0 2rem;
	// 	font-size: 1.9rem;
	// 	font-weight: 700;
	// 	color: #0a0a0a;
	// 	word-break: keep-all;
	// 	text-align: center;

	// 	padding: 50px;
	// }

	&__close {
		position: absolute;
		top: .1rem;
		right: 0;
		width: 2rem;
		height: 2rem;
		background-image: url(../images/ico/ico_close.svg);
		background-size: 1.5rem;
		background-repeat: no-repeat;
		background-position: center;
	}

	&--bottom {
		overflow: hidden;
		max-width: calc(64rem);
		max-height: calc(100vh - 6rem);
		top: auto;	
		transform: translate(-50%, 0);
		border-bottom-left-radius: 0;
    	border-bottom-right-radius: 0;
		transition: bottom .3s;
		bottom: -100%;
		width: 100%;

		&.is-active {
			bottom: 0;
		}
	}
}


@media (max-width: 380px) {
	.modal--toast {
		
		max-width: 28rem;
	}
}

.toast {
	&__content {
		overflow: hidden;
		display: none;
		position: fixed;
		top: 50%;
		left: 50%;
		
		width: 100%;
		max-width: 64rem;
		background-color: #fff;
		border-radius: 2.4rem;
		-webkit-overflow-scrolling: touch;
		outline: 0;
		z-index: 5;
		padding: 2.8rem;
		transform: translate(-50%, -50%);
		box-shadow: 0 .4rem 1.5rem 0 rgba(0, 0, 0, 0.3);

		.desc {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 1.6rem;
			color: #0a0a0a;
			line-height: 1.4;
			text-align: center;

			&.cart {
				&::before {
					content: '';
					display: inline-block;
					width: 2rem;
					height: 2rem;
					background-image: url(../images/ico/ico_cart.svg);
					background-position: center;
					background-size: cover;
					background-repeat: no-repeat;
					margin-right: .4rem;
				}
			}
		}

		&.is-none {
			-webkit-animation: ani-toast .2s both;
			animation: ani-toast .2s both;
		}

		&.is-active {
			-webkit-animation: ani-toast-active .2s both;
			animation: ani-toast-active .2s both;
		}
	}
}

@-webkit-keyframes ani-toast-active {
    0% {
		top: 0;
        opacity: 0;
    }
    100% {
		top: 50%;
        opacity: 1;
    }
}

@keyframes ani-toast-active {
     0% {
		top: 0;
        opacity: 0;
    }
    100% {
		top: 50%;
        opacity: 1;
    }
}


#toast-container.toast-center-center .toast {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transform: translate(12rem, -50%);
}

@media (min-width: 1200px) {
	.view--pc {
		#toast-container.toast-center-center .toast {
			transform: translate(12rem, -50%);
		}
	}
}

#toast-container > div {
	padding: 2.8rem;
	width: 100%;
	max-width: 30rem;
	height: auto;
	box-shadow: 0 0.4rem 1.5rem 0 rgba(0, 0, 0, .3) !important;
	color: #0a0a0a;
	border-radius: 2.4rem;
	text-align: center;
	line-height: 2.3rem;
	font-size: 1.6rem;
	opacity: 1;
}

#toast-container>.toast-success {
	background-image: none !important;
	background-color: #fff;
	border-radius: 2.4rem;
	color: #0a0a0a;

	-webkit-animation: ani-toast-active2 2s both;
	animation: ani-toast-active2 2s both;
}

// @keyframes ani-toast-active2 {
// 	0% {
// 		top: 0;
// 		opacity: 0;
// 	}
// 	25%, 85% {
// 		top: 50%;
// 		opacity: 1;
// 	}
// 	100% {
// 		top: 0;
// 		opacity: 0;
// 	}
// }



//221213 모달 신규 정의
.modal {
	// 기본 스타일
	overflow: hidden;
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
	width: 100%;
    max-width: 64rem;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    z-index: 7;
    word-break: keep-all;
	opacity: 0;
	-webkit-animation: ani-opacity .3s both;
	animation: ani-opacity .3s both;

	// &.is-active {
	// 	display: block;
	// 	opacity: 1;
	// }

	&__con-box {
		padding: .1rem 0;
		background-color: #fff;
		border-radius: 1.6rem;
		width: calc(100% - 4rem);
		margin: 0 auto;
	}

	&-full {
		border-radius: 0;
		width: 100%;
		max-width: 64rem;
	}

	&__header {
		position: relative;
		text-align: center;
		margin-top: 2.7rem;

		padding-bottom: 2rem;
		border-bottom: 1px solid #d4d4d4;

		&--close {
			position: absolute;
			top: 0;
			right: 2.8rem;
			width: 2.3rem;
			height: 2.3rem;
			background-image: url(../images/ico/ico_close.svg);
			background-size: 2rem;
			background-position: center;
			background-repeat: no-repeat;
			z-index: 1;
		}
	}

	&__title {
		padding: 0 2rem;
		font-size: 1.9rem;
		font-weight: 600;
		color: #0a0a0a;
		line-height: 2.3rem;
		word-break: keep-all;
		text-align: center;

		&.is-small {
			font-size: 1.6rem;
			font-weight: 500;
		}
	}

	&__subtitle {
		margin-top: 2rem;
		font-size: 1.6rem;
		line-height: 2rem;
		color: #0a0a0a;
	}

	&__body {
		margin-top: 2rem;
		margin-bottom: 2.7rem;

		.desc {
			// padding: 0 2rem; 23.01.30 제거
			font-size: 1.6rem;
			line-height: 2.3rem;
			color: #0a0a0a;
			text-align: center;
		}
	}

	&__footer {
		padding-bottom: 2.7rem;
		margin-top: 3.2rem;
		text-align: center;

		.btn {
			flex: 1;
			width: 100%;
			padding: 1.2rem 0;
			font-size: 1.4rem;
			color: #fff;
			border-radius: .8rem;
			border: 1px solid #0a0a0a;
			background-color: #0a0a0a;
		}
	}

	&__btn--area {
		display: flex;
		justify-content: space-around;
		align-items: center;
	}

	&__container {
		position: relative;
		padding-left: 2.8rem;
		padding-right: 2.8rem;
	}

	&__btn--area {
		&.is-half {
			.btn {
				color: #0a0a0a;
				border-color: #cfcfcf;
				background-color: #fff;
	
				+ .btn {
					margin-left: .8rem;
					color: #fff;
					border-color: #0a0a0a;
					background-color: #0a0a0a;
				}
			}
		}
		
		&.is-vertical {
			flex-wrap: wrap;

			.btn {
				flex: auto;
				color: #0a0a0a;
				border-color: #cfcfcf;
				background-color: #fff;
	
				+ .btn {
					margin-top: .8rem;
					color: #fff;
					border-color: #0a0a0a;
					background-color: #0a0a0a;
				}

				&.btn--solid--black {
					width: 100%;
					padding: 1.5rem 0;
					font-size: 1.5rem;
					color: #fff;
					border-radius: 0.8rem;
					border: 1px solid #0a0a0a;
					background-color: #0a0a0a;
				}

				&.btn--line--black {
					width: 100%;
					padding: 1.5rem 0;
					font-size: 1.5rem;
					color: #0a0a0a;
					border-radius: 0.8rem;
					border: 1px solid #bbb;
					background-color: #fff;
				}
			}
		}

		&.is-full {
			margin-left: -2.8rem;
			margin-right: -2.8rem;
			margin-bottom: -2.8rem;
			border-top: 1px solid #0a0a0a;
			// box-shadow: 0px -4px 4px 0 #ccc;

			.btn {
				padding: 1.9rem 0;
				border-radius: 0;
				font-size: 1.9rem;
				border: 0;

				&:first-child {
					background-color: #fff;
					color: #0a0a0a
				}

				&.is-black {
					color: #fff;
					background-color: #0a0a0a;
					border-color: #0a0a0a;
				}
			}

			&.no-shadow {
				box-shadow: none;
			}
		}
	}

	&--toast {
		transform: translate(-50%, -50%);
		overflow: visible;
		
		.modal__body {
			margin: 0 auto;
			padding: 2.8rem 0;
			max-width: calc(100% - 4rem);
			background-color: #fff;
			box-shadow: 0 .4rem 1.5rem 0 rgba(0, 0, 0, 0.3);
			text-align: center;
			border-radius: 1.6rem;
		}

		.desc {
			display: flex;
			justify-content: center;
			align-items: center;

			&.cart {
				&::before {
					content: '';
					display: inline-block;
					width: 2rem;
					height: 2rem;
					background-image: url(../images/ico/ico_cart.svg);
					background-position: center;
					background-size: cover;
					background-repeat: no-repeat;
					margin-right: .4rem;
				}
			}
		}

		&.is-active {
			-webkit-animation: ani-toast-active .2s both;
			animation: ani-toast-active .2s both;
		}

		&.is-none {
			-webkit-animation: ani-toast .2s both;
			animation: ani-toast .2s both;
		}
	}

	&.modal--toast {
		z-index: 10;
	}

	&.modal--alert {
		z-index: 9;

		.modal__header--close {
			display: none;

			&.is-block {
				display: block;
			}
		}
	}

	&.modal--confirm {
		z-index: 8;

		.modal__header--close {
			display: none;

			&.is-block {
				display: block;
			}
		}
	}

	&.modal--msgConfirm {
		z-index: 8;
		
		.modal__header--close {
			display: none;

			&.is-block {
				display: block;
			}
		}

		.msg__list {
			text-align: center;
			li {
				display: inline-block;
	
				&::before {
					display: none;
				}
			}	
		}
	}


	&.modal--bottom {
		overflow: hidden;
		max-width: calc(64rem);
		max-height: calc(100vh - 6rem);
		// max-height: calc(100vh - 8.8rem);
		top: auto;
		transform: translate(-50%, 0);
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		transition: all .3s;
		bottom: -100%;
		width: 100%;
		display: block;
		// height: 0;

		.modal__con-box {
			width: 100%;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}

		// &.is-none {
		// 	// display: block;
		// 	// bottom: 0;
		// 	// transform: translateX(calc());
		// 	-webkit-animation: ani-bottom-hide .2s both;
		// 	animation: ani-bottom-hide .2s both;
		// }

		&.is-active {
			// display: block;
			bottom: 0;
			// height: 100%;
			// -webkit-animation: ani-bottom-show .2s both;
			// animation: ani-bottom-show .2s both;
		}
	}

	&.modal-full {
		.modal__con-box {
			width: 100%;
			height: 100%;
			border-radius: 0;
		}	
	}

	&.is-active {
		display: block;
	}
}

@-webkit-keyframes ani-bottom-show {
	0% {
		display: none;
		bottom: -100%;
        opacity: 0;
    }
    100% {
		display: block;
		bottom: 0;
        opacity: 1;
    }
}

@keyframes ani-bottom-show {
	0% {
		display: none;
		bottom: -100%;
        opacity: 0;
    }
    100% {
		display: block;
		bottom: 0;
        opacity: 1;
    }
}

@-webkit-keyframes ani-bottom-show {
	0% {
		display: none;
		bottom: -100%;
        opacity: 0;
    }
    100% {
		display: block;
		bottom: 0;
        opacity: 1;
    }
}

@keyframes ani-bottom-hide {
	0% {
		display: block;
		bottom: 0;
        opacity: 1;
    }
    100% {
		display: none;
		bottom: -100%;
        opacity: 0;
    }
}

@-webkit-keyframes ani-bottom-hide {
    0% {
		display: block;
		bottom: 0;
        opacity: 1;
    }
    100% {
		display: none;
		bottom: -100%;
        opacity: 0;
    }
}


@-webkit-keyframes ani-opacity  {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes ani-opacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}


@-webkit-keyframes ani-toast {
    0% {
		display: block;
		top: 50%;
        opacity: 1;
    }
    100% {
		display: none;
		top: 0;
        opacity: 0;
    }
}

@keyframes ani-toast {
	0% {
		display: block;
		top: 50%;
        opacity: 1;
    }
    100% {
		display: none;
		top: 0;
        opacity: 0;
    }
}


// pc접속시
@media (min-width: 1280px) {
    .view--pc {
		.modal {
			transform: translate(0, -50%);

			&--bottom {
				transform: translate(0, 0);

				&.is-active {
					transform: translate(0, 0);
				}
			}

			&-full {
				// max-width: 64rem;
				transform: translate(0, -50%);
				// left: 50%;
				// transform: translate(-5rem, 0);
				// transform: translate(-5.8rem, 0);
				transform: none;
				top: 0;
				
			}
		}

		.toast__content {
			transform: translate(9.5rem, -50%);
		}
    }
}


#modal-change-deli .modal__body {
	padding-bottom: 4rem;
	margin-bottom: 0;
	max-height: calc(100vh - 20.2rem);
	padding-top: 1rem;
}

.btn-box--fixed.before-shadow {
	box-shadow: none;

	&::before {
		content: '';
		display: block;
		position: absolute;
		top: -3.9rem;
		left: 0;
		width: 100%;
		height: 4rem;
		background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 40%, white 70%);
		content: '';
		z-index: -1;
	}
}

#modal-coupon {
	.modal__body {
		padding-bottom: 4rem;
		margin-bottom: 0;
		max-height: calc(100vh - 13.2rem);
		padding-top: 1rem;
	}

	&.modal--bottom {
		.modal__body {
			padding-bottom: 0rem;
		}
	}
}


.modal--alert,
.modal--imgalert,
.modal--confirm,
.modal--msgConfirm,
.modal--select,
.modal--bottom,
.modal--slide {
	.modal__header {
		padding-bottom: 0;
		border-bottom: 0;
	}
}

#exerciseGuide {
	.img {
		border-radius: 1.2rem;
		border: 1px solid #cfcfcf;
		overflow: hidden;
	}	

	.text-box {
		margin-top: 2.8rem;

		.title {
			margin-bottom: 1.2rem;
			font-size: 1.9rem;
			font-weight: 600;
		}

		.txt {
			position: relative;
			padding-left: 1.1rem;
			font-size: 1.6rem;
			line-height: 1.44;

			&::before {
				content: '-';
				display: inline-block;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
		
		.txt + .txt {
			margin-top: 0.8rem;
		}
	}
}

#modalProfileSetting {
	.section + .section {
		margin-top: 4rem;
	}

	.title {
		display: inline-block;
		margin-bottom: 2rem;
		font-size: 1.6rem;
		font-weight: 600;
	}

	.nickname {
		display: flex;
		align-items: center;

		.thum {
			width: 10.8rem;
			height: 10.8rem;
			position: relative;
			margin-right: 2rem;
			border: 1px solid #bbb;
			border-radius: 50%;
			background-color: #f2f2f2;

			img {
				width: 8rem;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		input {
			width: calc(100% - 12.8rem);
		}
	}

	.character {
		display: flex;
		flex-wrap: wrap;
		margin: -0.6rem;

		li {
			margin: 0.6rem;

			&:last-child {
				margin-right: 0;
			}
		}

		.control {
			display: inline-block;
			margin: 0;
		}

		input[type="radio"] + label {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 10rem;
			height: 10rem;
			padding: 0;
			border-radius: 50%;
			background-color: #f2f2f2;
		}

		.img {
			width: 6rem;

			&.--default {
				width: 6.4rem;
			}
		}

		input[type='radio']:checked + label {
			background-color: #fff;
		}

		input[type='radio']:checked + label::after {
			content: '';
			position: absolute;
			top: -5px;
			right: -8px;
			width: 3.6rem;
			height: 3.6rem;
			background-image: url(/assets/images/ico/check_ps_certification.svg);
			background-size: cover;
		}

		input[type='radio']:checked + label::before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 10rem;
			height: 10rem;
			border: 2px solid #2ccc98;
			border-radius: 50%;
		}

		.is-disabled {
			opacity: 0.5;
		}
	}
}

@media (max-width:380px) {
	#modalProfileSetting {
		.character {
			input[type="radio"] + label {
				width: 8rem;
				height: 8rem;
			}
	
			input[type='radio']:checked + label::after {
				width: 3rem;
				height: 3rem;
			}
	
			input[type='radio']:checked + label::before {
				width: 8rem;
				height: 8rem;
			}
		}
	}
}

.modal--my_point {
	.modal__title {
		padding: 0;
		font-size: 1.6rem;
		font-weight: 400;
		text-align: left;
	}

	.point {
		margin-top: 0.8rem;
		font-size: 2.2rem;
		font-weight: 600;
		text-align: left;
	}

	.scroll-box {
		margin: -0.6rem -1.4rem -1.4rem -1.4rem;
		border: 0;
		border-radius: 0;
	}

	table {
		width: 100%;
		text-align: center;
		border-collapse: collapse;
	}

	tr:nth-child(odd) {
		background-color: #f2f2f2;
	}

	th, td {
		font-weight: normal;
		padding: 1.6rem 1rem;
		border-right: 1px solid #bbb;
	}
	
	tr:first-child {
		border-bottom: 1px solid #bbb;

		td {
			padding: 1.8rem 1rem;
		}
	}

	tr {
		th:last-child, td:last-child {
			border-right: 0;
		}
	}

	.table-point {
		color: #0a0a0a;
		font-weight: 600;
	}
}

.modal--slide {
	overflow: visible;

	.modal__con-box {
		padding: 0;
		border-radius: 0;
		background-color: transparent;
	}

	.modal__title {
		padding: 2rem 0;
		margin-bottom: 0;
		background-color: #fff;
		// margin-bottom: 0;
	}

	.modal__body {
		margin-top: 2.7rem;
		margin-bottom: 0;

		.modal__container {
			margin-left: -2.8rem;
			margin-right: -2.8rem;
		}

		.swiper-container {
			height: 100%;
			
		border-top-left-radius: 1.6rem;
		border-top-right-radius: 1.6rem;
		overflow: hidden
		}

		.swiper-wrapper {
			
		}
	}

	/* .modal__footer {
		padding-bottom: 0;
		margin-top: 0;
	}

	.modal__btn--area.is-full {
		margin-left: 0;
		margin-right: 0;
	} */

	.slide-img {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		overflow: hidden;
		background-color: #fff;

		img {
			width: calc(100% + 1px);
		}
	}

	.swiper-pagination {
		top: -3rem;
		bottom: auto !important;
	}
	.swiper-pagination-bullet {
		width: 0.8rem;
		height: 0.8rem;
		background-color: #cfcfcf;
		border-radius: 0.4rem;
		opacity: 1;
		transition: width .3s;
	}

	.swiper-pagination-bullet-active {
		width: 1.6rem;
		background-color: #fff;
	}
}