.mycoupon {

    // &__list {
    //     padding-top: 2rem;
    // }

    &__item {
        position: relative;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: calc(68% + 1px);
            width: 1.8rem;
            height: 1.8rem;
            background-color: #fff;
            // background-color: #f2f2f2;
            border-radius: 50%;
            z-index: 1;
        }

        &::before {
            left: -10px;
        }

        &::after {
            right: -10px;
        }

        + .mycoupon__item {
            margin-top: 1.6rem;
        }
    }

    &__card {
        overflow: hidden;
        position: relative;
        background-color: #fff;
        border: 1px solid #bbb;
        border-radius: 1.2rem;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 68%;
            width: 1rem;
            height: 2rem;
            background-color: #fff;
            // background-color: #f2f2f2;
            border: 1px solid rgba(0, 0, 0, 0.3);
            z-index: 1;
        }

        &::before {
            left: -1px;
            border-radius: 0 1rem 1rem 0;
        }

        &::after {
            right: -1px;
            border-radius: 1rem 0 0 1rem;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem 2.4rem 0;

        .discount {
            font-size: 2.4rem;
            color: #00a972;
        }

        .dday {
            height: 3.4rem;
            padding: 0 1.2rem;
            background-color: #2ccc98;
            font-size: 1.8rem;
            font-weight: bold;
            color: #fff;
            line-height: 3.4rem;
            border-radius: 1.7rem;
        }
    }

    &__body {
        position: relative;
        padding: 1.2rem 2.4rem 2em;

        .title {
            font-size: 1.9rem;
            color: #0a0a0a;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .subtitle {
            margin-top: .6rem;
            font-size: 1.6rem;
            color: #0a0a0a;
        }

        .period {margin-top: 1rem;
            font-size: 1.3rem;
            color: #9a9a9a;
        }
    }

    &__footer {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            display: inline-block;
            width: calc(100% - 4.8rem);
            border-top: 1px solid #EBEFF4;
        }
    }

    &__link {
        display: flex;
        justify-content:center;
        align-items: center;
        padding: 1.6rem 0;
        font-size: 1.5rem;
        color: #0a0a0a;
        text-align: center;

        &::after {
            content: '';
            display: block;
            width: 1.2rem;
            height: 1.2rem;
            margin-left: .4rem;
            background-image: url(/assets/images/ico/ico_next.svg);
            background-size: cover;
        }
    }

    &__count {
        .title-box {
            // padding-top: 4rem;
        }
        .title {
            font-size: 2.2rem;
            color: #0a0a0a;
            font-weight: 600;

            b {
                color: #00a972;
            }
        }

        .btn-area {
            font-size: 0;
            padding: 0 2rem;
            margin: -.4rem;
            white-space: nowrap;
            overflow: auto;

            li {
                display: inline-block;
                margin: .4rem;

                &.is-active {
                    .btn {
                        color: #fff;
                        border-color: #0a0a0a;
                        background-color: #0a0a0a;
                    }
                }
            }

            .btn {
                padding: 0 1.6rem;
                font-size: 1.6rem;
                color: #636363;
                height: 4.4rem;
                line-height: 4.4rem;
                background-color: #fff;
                border: 1px solid #bbb;
                border-radius: 2.2rem;
            }
        }
    }

    &__tab {
        padding: 2rem 0;
        margin: 0 -1.6rem;
    }

    &__add {
        margin: 0 -2rem;
        padding: 3.2rem 2rem 0;
        // border-bottom: 1px solid #bbb;
        background-color: #fff;
    }

    &__partner {
        .title {
            margin-bottom: 3rem;
            font-size: 2.2rem;
            font-weight: 700;
            text-align: center;
        }

        .period {
            display: block;
            margin-top: 2rem;
            font-size: 1.6rem;
            color: #9a9a9a;
            text-align: center;
        }

        &--barcode {
            .img {
                width: 60%;
                min-width: 18rem;
                max-width: 30rem;
                margin: 0 auto;
            }
        }

        &--num {
            /* display: flex;
            flex-wrap: wrap;
            justify-content: center; */
            text-align: center;

            .text {
                display: block;
                font-size: 2rem;
                font-weight: 500;
            }

            .number {
                display: block;
                margin: 2rem 0 2.6rem 0;
                font-size: 3.4rem;
                font-weight: 700;
                letter-spacing: 0.6rem;
            }
        }

        .btn__coupon-copy {
            padding: 0.8rem 2.4rem;
            font-size: 1.6rem;
            color: #fff;
            border-radius: 10rem;
            background-color: #0a0a0a;
        }
    }
}