.lookup {
    &-wrap {
        display: flex;
        justify-content: space-between;
        font-size: 1.4rem;
    
        .btn {
            padding: 1.4rem 2rem;
            background-color: #0a0a0a;
            border-radius: 0;
            font-size: 1.4rem;
            color: #fff;

            &.is-disabled {
                color: #fff;
                border-color: #9a9a9a;
                background-color: #9a9a9a;
                cursor: auto;
            }
        }
    }

    &-box {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        background-color: #fff;
        border: 1px solid #bbb;
        height: 4.4rem;

    
        .control {
            margin: 0;
        }
    
        input[type="date"] {
            width: 100%;
            max-width: 13rem;
            margin: 0;
            border: 0;
            font-size: 1.4rem;
            color: #0a0a0a;
            line-height: normal;
            outline: 0;
            text-align: center;
            background-color: #fff;

            // &:before {
            //     content: attr(data-date);
            //     width: 100%
            // }

            &::-webkit-calendar-picker-indicator {
                display: block;
                width: 3.4rem;
                height: 3.4rem;
                background-image: url(/assets/images/ico/ico_input_calendar.svg);
                background-size: 2rem;
                background-position: center;
                background-repeat: no-repeat;
                // border-width: thin;
                color: rgba(0, 0, 0, 0);
                opacity: 1;
            }

            &:focus,
            &:focus-visible,
            &:active {
                outline: 0;
                border: 0;
                outline: -webkit-focus-ring-color auto 0
            }
        }
    }
}

@media (max-width: 540px) {
    .lookup-wrap {
        flex-wrap: wrap;

        .btn {
            display: block;
            width: 100%;
            margin-top: .4rem;
        }
    }
}

.cart {

    .section + .section {
        margin-top:0;
    }

    &__pay--section {
        
        border-top: 1px solid #d4d4d4;
        background-color: #fff;
    }

    &__product--section {
        padding:4rem 0;
        background-color: #fff;
    }

    &-box {
        background-color: transparent;

        > ul > li {
            + li {
                margin-top: 2rem;
            }
        }        


        .product__items {
            > li {
                + li {
                    margin-top: 2rem;
                }
            }
        }

        &.--curent {
            .product__items + .product__items {
                margin-top: 2rem;
            }

            .cart__header {
                margin-bottom: 0;
            }

            .cart__card + .cart__card {
                margin-top: 0.4rem;
            }

            .cart__card {
                .btn {
                    margin-top: 2rem;
                }
            }

            .delivery__status {
                margin-bottom: 1.6rem;

                .title {
                    font-size: 1.8rem;
                }
            }

            .product--list li + li {
                padding-top: 2rem;
                margin-top: 2rem;
                border-top: 1px solid #d4d4d4;
            }
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2.4rem;

        .title {
            display: inline-block;
            font-size: 1.8rem;
            font-weight: 700;
            color: #0a0a0a;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .link {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-right: 0.4rem;
            font-size: 1.5rem;
            color: #00a972;
            white-space: nowrap;

            &::after {
                content: '';
                display: block;
                width: 1.2rem;
                height: 1.2rem;
                background-image: url(../images/ico/ico_next_green.svg);
                background-size: cover;
                background-repeat: no-repeat;
                transform: translate(0.4rem, -1px);
            }

            &.--gray {
                color: #636363;

                &::after {
                    background-image: url(../images/ico/ico_next_gray.svg);
                }
            }
        }
    }

    &__no-item {
        padding: 10rem 0;
        text-align: center;
        color: #0a0a0a;

        &::before {
            content: '';
            display: block;
            height: 5.6rem;
            margin-bottom: 1.2rem;
            background-image: url(../images/ico/ico_cart_gray.svg);
            background-size: 5.6rem;
            background-position: center;
            background-repeat: no-repeat;
        }


        .title {
            font-size: 2rem;
            font-weight: 600;
        }

        .desc {
            display: inline-block;
            margin-top: 1.2rem;
            font-size: 1.5rem;
            line-height: 1.53;
        }
    }

    &__login {
        overflow: hidden;
        padding: 1.6rem;
        margin-bottom: 4rem;
        background-color: #fff;
        border: 1px solid #bbb;
        border-radius: .8rem;
        font-size: 1.6rem;
        color: #0a0a0a;

        .link {
            display: inline-block;
            color: #00a972;
            margin-top: .8rem;

            &::after {
                content: '';
                display: block;
                border-bottom: 1px solid #00a972;
            }
        }
    }

    &__choice {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
    }

    &__filter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.6rem;
    
        .control {
            margin-top: 0;
        }
    
        .btn-area {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 0;
        }
        .btn {
            padding: .6rem;
            font-size: 1.3rem;
            border-radius: .6rem;
    
            + .btn {
                margin-left: .8rem;
            }
        }
    
        label {
            height: 2.4rem;
        }
    }

    &__card {
        overflow: hidden;
        position: relative;
        padding: 2rem;
        border-radius: .8rem;
        border: solid 1px #bbb;
        background-color: #fff;
       
        .order-form {
            
            .order__info + .order__info {
                margin-top: 0.8rem;
            }
        }

        .order-form + .order-form {
            padding-top: 1.6rem;
            margin-top: 1.6rem;
            border-top: 1px solid #eee;
        }
    
        .msg {
            margin-top: 2rem;
            color: #ff4646;
            font-size: 1.2rem;
        }
    
        .delivery {
            &__info {
                padding-top: 2rem;
                margin-top: 2rem;
                border-top: 1px solid #d4d4d4;

                .btn {
                    padding: 0.6rem;
                    border: 1px solid #00a972;
                    border-radius: 0.6rem;
                    font-size: 1.3rem;
                    color: #00a972;

                    &.is-danger {
                        border-color: #bbb;
                        color: #0a0a0a;
                    }
                }
            }
    
            &__items {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 1.6rem;
                color: #0a0a0a;
    
                .desc {
                    font-weight: 600;
                }
    
                + .delivery__items {
                    margin-top: 1.6rem;
                }
            }

            &__status {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .title {
                    font-size: 1.6rem;
                    color: #0a0a0a;
                    font-weight: 600;
                    line-height: 2.8rem;
        
                    &.is-success {
                        color: #9a9a9a;
                    }
                }
            }
        }
    
        &.is-soldout {
            .product__thumb {
                &::before {
                    display: none;
                }
            }
    
            img {
                opacity: .6;
            }
            
            .colcart--subtitle {
                color: #9a9a9a
            }
    
            .btn {
                color: #9a9a9a;
    
                &::after {
                    border-color: #9a9a9a;
                }
            }
    
            .product__title {
                color: #9a9a9a;
            }
    
            .delivery__items {
                color: #9a9a9a;
            }
    
            input[type="checkbox"] + label:before {
                background-color: #bbb;
                background-image: url(../images/ico/ico_check_p.svg);
            }

            .cart--subtitle .no-user-change {
                color: #9a9a9a;
            }

            .badge--reserves {
                background-color: #bbbbbb;
            }
        }

        .product__thumb {
            .control {
                position: absolute;
                z-index: 1;
                top: 1.2rem;
                left: 0;
                margin-top: 0;
            }
        }

        .detail__pay-box {
            margin-top: 0.8rem;
        }

        .reg-deli-info {
            margin-top: 2rem;
            border-top: 1px solid #d4d4d4;

            li {
                margin-top: 0;
                padding-top: 2rem;
            }

            .text-wrap {
                display: flex;
                justify-content: space-between;
            }

            .title {
                font-size: 1.6rem;
                white-space: nowrap;
                margin-right: .8rem;
            }

            .cont-box {
                text-align: right;
            }

            .info-txt {
                display: block;
                font-size: 1.6rem;
                font-weight: 600;
                line-height: 1.19;

                em {
                    display: inline-block;
                    margin-top: .4rem;
                    font-weight: 400;
                }
            }

            .btn.btn-link {
                margin: 0rem -1.6rem -1rem -1.6rem;
                font-size: 1.5rem;

                
            }

            // .btn--line--black {
                
            // }
        }

        &.type2 {
            padding-top: 2.4rem;

            .badge-box {
                position: relative;
                margin-bottom: .8rem;
            }

            .control {
                margin-top: 0;
            }

            .price {
                width: 12rem;
            }

            .detail__pay-box .price del {
                margin-right: 0;
            }

            // .price {
            //     display: flex;
            //     justify-content: flex-start;
            //     align-items: center;
            //     flex-wrap: wrap;

            //     del {
            //         margin-left: .4rem;
            //     }
            // }

            .checkbox-large input[type="checkbox"] + label {
                padding-left: 0;
                z-index: 1;
                font-size: 0;
            }

            .product__item-box .checkbox-large input[type="checkbox"] + label::before, .product__item-box .checkbox-large input[type="checkbox"]:checked + label::before {
                z-index: 2;
            }

            .product__item-box .checkbox-large input[type="checkbox"] + label::before, .product__item-box .checkbox-large input[type="checkbox"]:checked + label::after {
                top: 0;
                transform: none;
                z-index: 2;
            }
        }

    }

    &__detail-list {
        li + li {
            padding-top: 2rem;
            border-top: 1px solid #eee;
        }

        .product__info {
            width: 100%;
            padding-left: 0;
        }
    }

    &--subtitle {
        // font-size: 1.4rem;
        font-size: 1.6rem;
        color: #9a9a9a;
        width: calc(100% - 2rem);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: .8rem;
        // display: flex;
        // justify-content: flex-start;
        // align-items: center;

        b {
            color: #00a972;
        }

        .btn {
            padding: 0;
            color: #00a972;
            background-image: url(../images/ico/ico_arrow_down.svg);
            background-size: 1.2rem;
            background-repeat: no-repeat;
            background-position: center right;
            padding-right: 2.6rem;
            font-weight: 600;
            font-size: 1.6rem;

            &::after {
                content: '';
                display: block;
                margin-top: .2rem;
                width: calc(100% + 2.6rem);
                border-bottom: 1px solid #0a0a0a;
            }
        }

        .no-user-change {
            display: inline-block;
            padding: 0;
            color: #00a972;
            background-size: 1.2rem;
            background-repeat: no-repeat;
            background-position: center right;
            font-weight: 600;
            position: relative;
            padding-bottom: .2rem;
            vertical-align: middle;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                display: block;
                margin-top: 0.2rem;
                width: 100%;
                border-bottom: 1px solid #0a0a0a;
            }
        }

        .text {
            display: inline-block;
            // line-height: 1;
            // vertical-align: middle;
        }
    }
}


.user__selet {
    >ul > li {
        + li {
            margin-top: 1.8rem;
        }

        input[type="radio"] + label {
            display: block;
            padding: 1rem 0;
            font-size: 1.5rem;
        }

        input[type="radio"]:checked + label {
            color: #00a972;
        }
    } 
}

// .product__section {
//     overflow: auto;
// }

@media (max-width: 400px) {
    .cart__card.type2 .price {
        width: 10rem;
    }
}