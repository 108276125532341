.card {
    &-box {
        overflow: hidden;
        position: relative;
        padding: 2rem;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.3);

        &.no-shadow {
            box-shadow: none;
            border: 1px solid #bbb;
        }

        &.card--main {
            .card__title {
                margin-bottom: 1.6rem;
                font-size: 2.1rem;
                line-height: 2.8rem;
            }
        }

        &.card--mission {
            .card__title {
                font-size: 1.6rem;
                font-weight: 400;
            }
        }
    }

    &__header {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.2rem;
        z-index: 2;
    }

    &__info {
        overflow: hidden;
        width: calc(100% - 2rem);
        font-size: 1.3rem;
        font-weight: 700;
        color: #636363;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__more {
        width: 2rem;
        height: 2rem;
        background-image: url(../images//ico/ico_more.svg);
        background-size: cover;
        background-repeat: no-repeat;

        &.is-text {
            width: auto;
            height: auto;
            background-image: none;
            color: #0a0a0a;
        }

        &.is-bold {
            font-size: 1.6rem;
            font-weight: 700;
        }
    }

    &__body {
        position: relative;
        // margin-bottom: 1.7rem;
        z-index: 2;

        .calorie-tip__lists {
            margin-top: 1.6rem;
            font-size: 1.4rem;
            color: #0a0a0a;

            li {

                + li {
                    margin-top: .8rem;
                }

                // &::after {
                //     content: '';
                //     display: inline-block;
                //     width: ;
                // }
            }
        }

        + .card__footer {
            margin-top: 1.7rem;
        }
    }

    &__title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        word-break: keep-all;
        overflow: hidden;
        color: #0a0a0a;

        b {
            font-weight: bold;
        }

        &.title--icon {
            &::before {
                content: '';
                display: inline-block;
                width: 2rem;
                height: 2rem;
                margin-right: .4rem;
                background-color: transparent;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                vertical-align: middle;
            }

            &.calorie-tip {
                &::before {
                    background-image: url(../images/ico/ico_chart.svg);
                }
            }
        }
    }

    &__desc {
        overflow: hidden;
        max-height: 6.9rem;
        font-size: 1.6rem;
        color: #0a0a0a;
        line-height: 2.3rem;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        
        b {
            font-weight: bold;
        }
    }

    &__thumb {
        font-size: 0;
    }

    &__footer {

        img {
            position: absolute;
            bottom: 2rem;
            right: 2rem;
            width: auto;
            // height: 100%;
            max-height: 10rem;
        }
    }

    // 타입별
    &--smbti {
        padding-bottom: 5.4rem;
    }

    &--magazine {
        .card__thumb {
            margin: -2rem -2rem 2rem;
        }
    }

    &--banner {
        .card {
            &__thumb {
                margin: -2rem;
            }
        
            &__info {
                color: #fff;
            }

            &__more {
                background-image: url(../images//ico/ico_more2.svg);
            }

            &__title {
                color: #fff;
            }
        }
    }
}

@media (max-width: 379px) {
    .card__footer img {
        max-height: 8.4rem;
    }
    
}

.card__content--absolute {
    position: absolute;
    top: 2rem;
    left: 2rem;
    right: 2rem;
}

.card--reveiw {

    .product-box {

    }

    .product__thumb {
        width: 4.8rem;
    }

    .product__info {
        align-items: center;
        flex-direction: inherit;
        width: calc(100% - 4.8rem);
    }
}