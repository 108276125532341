.title {
    &-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__h2 {
        font-size: var(--h2-font-size);
    }

    &__h3 {
        font-size: var(--h3-font-size);
    }

    &__h4 {
        font-size: var(--h4-font-size);
    }

    &__h5 {
        font-size: var(--h5-font-size);
    }

    &__h6 {
        font-size: var(--h6-font-size);
    }

    &__desc {
        margin: 0;
        margin-top: .5rem;
        font-size: var(--font-size-md);
    }

    &__more {
        display: flex;
    }

    &__link {
        display: flex;
        background-color: #f4f4f4;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        vertical-align: middle;
        align-items: center;
        justify-content: center;

        +.title__link {
            margin-left: .2rem;
        }
    }
}