.mbr {
    &-box {
        // max-width: 46rem; // 네이버 로그인 페이지 기준으로 230418, 회원가입 때문에 디자인 틀어지는 이슈 생김
        // margin-left: auto;
        // margin-right: auto;

        .form-box {
            margin-top: 2.8rem;
            
            &.form__join {
                .field + .field {
                    margin-top: 2.4rem;
                }

                label {
                    font-size: 1.8rem;
                    color: #0a0a0a;
                    font-weight: 600;
                }

                .control {
                    margin-top: .8rem;
                }

                input {
                    &:focus {
                        color: #00a972;
                    }
                }

                .address-box {
                    .control {
                        align-items: inherit;
                    }

                    .btn--solid--black {
                        width: auto;
                        padding-left: 1.6rem;
                        padding-right: 1.6rem;
                        border-radius: .6rem;
                        white-space: nowrap;
                        margin-left: .4rem;
                    }
                }

                .checked__list-box {
                    padding: 2.5rem 2rem;
                    margin-left: -2rem;
                    margin-right: -2rem;
                    background-color: #f2f2f2;
                    border-top: 1px solid #cfcfcf;

                    input[type="checkbox"] + label {
                        padding-left: 3.2rem;
                        line-height: 2.4rem;
                    }

                    input[type="checkbox"] + label:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 2.4rem;
                        height: 2.4rem;
                        text-align: center;
                        background: #fff;
                        border: 1px solid #bbb;
                        box-sizing: border-box;
                        border-radius: 3px;
                        background-image: url(../images/ico/ico_check_d.svg);
                        background-repeat: no-repeat;
                        background-size: 1.4rem;
                        background-position: center;
                    }
            
                    input[type="checkbox"]:checked + label::after {
                        content: '';
                        width: 2.4rem;
                        height: 2.4rem;
                        background-color: #00a972;
                        background-image: url(../images/ico/ico_check_p.svg);
                        background-size: 1.4rem;
                    }

                    .checked__list {

                        input[type="checkbox"] + label {
                            padding-left: 2.8rem;
                            line-height: 1.6rem;
                        }


                        input[type="checkbox"] + label:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 1.6rem;
                            height: 1.6rem;
                            text-align: center;
                            background: transparent;
                            border: 0;
                            box-sizing: border-box;
                            border-radius: 3px;
                            background-image: url(../images/ico/ico_check_d.svg);
                            background-repeat: no-repeat;
                            background-size: 1.4rem;
                            background-position: center;
                        }
                
                        input[type="checkbox"]:checked + label::before {
                            content: '';
                            width: 1.6rem;
                            height: 1.6rem;
                            background-color: transparent;
                            background-image: url(../images/ico/ico_check_nobg.svg);
                            background-size: 2rem;
                        }

                        input[type="checkbox"] + label::after {
                            display: none;
                        }
                    }

                    label {
                        font-size: 1.4rem;
                        font-weight: 400;

                        em {
                            font-weight: 600;
                            color: #949494;
                        }
                    }
                }
            }

            .password {
                color: #0a0a0a;

                &:focus {
                    color: #00a972;
                }
            }

            
            input[type="text"], input[type="email"], input[type="password"], input[type="number"] {
                padding: 1.3rem 0;
                font-size: 1.5rem;
            }
        }
    }

    &__thumb.log {
        overflow: hidden;
        width: 8rem;
        height: 8rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2.8rem;
    }

    &__header {
        margin-top: 3.2rem;
        text-align: center;

        img {
            width: 100%;
        }

        .title {
            font-weight: 500;
        }

        .title {
            display: block;
            font-size: 2.2rem;
            word-break: keep-all;
        }

        .desc {
            display: block;
            font-size: 1.4rem;
        }

        &.--login {
            img {
                max-width: 8.4rem;
            }

            .title {
                margin-top: 1.2rem;
                font-size: 2.4rem
            }
        }

        &.--info01 {
            margin-top: 6rem;

            img {
                max-width: 10.2rem;
            }

            .title {
                margin-top: .8rem;
                font-size: 2.2rem;
                line-height: 1.36;
            }

            .desc {
                margin-top: 2rem;
                font-size: 1.8rem;
                color: #636363;
                line-height: 1.44;
            }
        }

        &.--info02 {
            padding-top: 4rem;
            margin-top: 0;

            .title {
                font-size: 2.2rem;
                font-weight: bold;
                background-color: #f2f2f2;
                border-radius: 1.2rem;
                color: #0a0a0a;
                padding: 4rem 0;
                text-align: center;

            }

            .desc {
                margin-top: 4rem;
                line-height: 3rem;
                font-size: 2.2rem;
            }

            .text {
                display: block;
                padding: 0;
                margin-top: 2rem;
                background-color: transparent;
                font-size: 1.8rem;
                color: #636363;
                line-height: 2.6rem;

            }
        }

        &.--info03 {
            margin-top: 6rem;

            .title {
                font-size: 1.9rem;
                line-height: 2.8rem;
            }
        }

        .text {
            display: block;
            margin-top: 2rem;
            padding: 3.2rem 0;
            background-color: #f2f2f2;
            border-radius: 1.2rem;
            font-size: 1.6rem;
            color: #636363;
            text-align: center;
        }
    }

    &-titie {
        display: block;
        margin-top: 1.2rem;
        font-size: 2.1rem;
    }

    &__login {
        max-width: 31rem;
        margin-top: 2.8rem;
        margin-left: auto;
        margin-right: auto;
    }

    &__util {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 1.6rem;

        .checkbox + .checkbox {
            margin-left: 1.2rem;
        }


        input[type="checkbox"] + label {
            font-size: 1.4rem;
            padding-left: 2.8rem;
            line-height: 2.4rem;
        }

        input[type="checkbox"] + label:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 2.4rem;
            height: 2.4rem;
            text-align: center;
            background: #fff;
            border: 1px solid #bbb;
            box-sizing: border-box;
            border-radius: 3px;
            background-image: url(../images/ico/ico_check_d.svg);
            background-repeat: no-repeat;
            background-size: 1.4rem;
            background-position: center;
        }

        input[type="checkbox"]:checked + label::after {
            content: '';
            width: 2.4rem;
            height: 2.4rem;
            background-color: #00a972;
            background-image: url(../images/ico/ico_check_p.svg);
            background-size: 1.4rem;
        }
    }
    &__btn-area {
        margin-top: 3.2rem;
        text-align: center;

        .btn {
            + .btn {
                margin-top: .8rem;
            }
        }

        .btn.btn-link {
            margin-top: 1.8rem;
            font-size: 1.4rem;
            color: #0a0a0a;
        }
    }

    &__menu {
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;

        li {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                width: 1px;
                background-color: #9a9a9a;
                height: 1rem;
            }

            &:first-child::before {
                display: none;
            }

            a {
                padding: 1rem 1.2rem;
                font-size: 1.5rem;
                color: #636363;
            }
        }
    }

    &__join--btn {
        margin-top: 3.2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 1.3rem 2.2rem; 
        color: #636363;
        background-color: #f2f2f2;
        font-size: 1.5rem;
        border-radius: .8rem;

        > span {
            display: block;
            margin-top: .2rem;
        }

        > b {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: .2rem;
            color: #0a0a0a;
            font-weight: 600;

            &::after {
                content: '';
                display: block;
                margin-left: .8rem;
                width: 1.2rem;
                height: 1.2rem;
                background-image: url(/assets/images/ico/ico_next.svg);
                background-size: cover;
            }
        }
    }
    
    &__sns {
        &-box {
            margin-top: 2rem;
            text-align: center;
        }

        &--title {
            position: relative;
            color: #9a9a9a;
            font-size: 1.9rem;
            white-space: nowrap;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #0a0a0a;
            font-weight: 600;

            &::before,
            &::after {
                content: '';
                width: 100%;
                max-width: 6rem;
                height: 1px;
                background-color: #636363;
            }

            &::before {
                margin-right: 1.6rem;
            }

            &::after {
                margin-left: 1.6rem;
            }
        }

        &--login {
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-top: 2.8rem;
            // max-width: 32rem;
            margin-left: auto;
            margin-right: auto;

            // li {
            //     margin-left: 1.6rem;
            //     margin-right: 1.6rem;
            // }
        }

        &__btn {
            // overflow: hidden;
            display: inline-block;
            // width: 6rem;
            // height: 6rem;
            // background-color: #f4f4f4;
            // border-radius: 50%;
            // background-position: center;
            // background-size: cover;



            // &.kakao {
            //     background-image: url(../images/ico/ico_sns_kakao.svg);
            // }

            // &.naver {
            //     background-image: url(../images/ico/ico_sns_naver.svg);
            // }

            // &.apple {
            //     background-image: url(../images/ico/ico_sns_apple.svg);
            // }

            img {
                width: 6rem;
                height: 6rem;
            }

            .title {
                display: block;
                margin-top: 1.2rem;
                font-size: 1.4rem;
                color: #0a0a0a;
            }
        }
    }

    &__lead {
        margin-top: 5rem;
        text-align: center;

        &--title {
            display: block;
            font-size: 1.4rem;
            color: #0a0a0a;
        }
        
        .btn {
            margin: 0;
            color: #00a972;
            
            &::after {
                content: '';
                background-color: #00a972;
            }
        }
    }
}

// 탭 컨포넌트 없어서 임시로 여기에 저장
// .tab {
    
//     &__btn {
//         flex: 1;
//         padding-top: 1.5rem;
//         padding-bottom: 1.5rem;
//         font-size: 1.9rem;
//         color: #636363;
//         text-align: center;

//         &.is-active {
//             color: #00a972;
//             border-radius: 0;
//             border-bottom: 2px solid #00a972;
//         }
//     }
// }

.join {
    &__benefits {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: 2.4rem 2.8rem;
        border: 1px solid #cfcfcf;
        border-radius: 1.2rem;

        &--wrap {
            margin-top: 2rem;
        }

        &--box {
            overflow: hidden;

            + .join__benefits--box {
                margin-top: 1rem;
            }
        }

        img {
            max-width: 6rem;
        }

        // &::before, 
        // &::after {
        //     content: '';
        //     position: absolute;
        //     top: 50%;
        //     transform: translateY(-50%);
        //     width: 2rem;
        //     height: 2rem;
        //     border-radius: 50%;
        //     background-color: #fff;
        // }

        // &::before {
        //     left: -1rem;
        //     border: 1px solid #bbb;
        // }

        // &::after {
        //     right: -1rem;
        //     border: 1px solid #bbb;
        // }

        &--text {
            margin-right: 1rem;
            min-width: 15rem;
            word-break: keep-all;
        }

        &--title {
            font-size: 2rem;
            font-weight: 600;
            color: #0a0a0a;
        }

        &--desc {
            margin-top: .8rem;
            font-size: 1.8rem;
            color: #636363;
        }
    }

    &__sign {
        padding: 2.8rem 0;
        // margin-top: 3.2rem;
        background-color: #f2f2f2;
        border-radius: .8rem;

        ol {
            font-size: 0;
            text-align: center;

            li {
                display: inline-flex;
                text-align: center;
                padding-left: 8rem;
                vertical-align: middle;
                position: relative;
                transition: .1s;

                &::before {
                    content: '';
                    width: calc(100% - 6.6rem);
                    height: 1px;
                    border-style: dashed solid;
                    border-color: #bbb;
                    border-width: 1px;
                    // border-bottom: dotted 1px #bbb;
                    border-left: 0;
                    border-right: 0;
                    border-top: 0;
                    position: absolute;
                    top: calc(50% - 1.2rem);
                    left: calc(0% + .8rem);
                }

                &:first-child {
                    padding-left: 0;

                     &::before {
                        display: none;
                     }
                }

                &:last-child {
                    margin-right: 0;
                }

                &.dash {
                    flex: 1;
                    border-bottom: 1px dotted #bbb;
                }
            }
        }

        &--step {
            overflow: hidden;
            position: relative;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 3.6rem;
            height: 3.6rem;
            font-size: 1.6rem;
            font-weight: bold; 
            color: #9a9a9a;
            border: 2px solid #bbb;
            border-radius: 50%;
        }

        &--title {
            display: block;
            margin-top: .8rem;
            font-size: 1.4rem;
            color: #9a9a9a;
            text-align: center;
            white-space: nowrap;
        }

        .is-active {
            &::before {
                border-color: #00a972;
            }

            .join__sign {
                &--step {
                    background-color: #00a972;
                    color: #fff;
                    border-color: transparent;
                }

                &--title {
                    color: #00a972;
                    font-weight: 600;
                }
            }
        }

        .is-disabled {
            .join__sign--step {
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    padding: .8rem;
                    background-color: #f2f2f2;
                    background-image: url(../images/ico/ico_step_check.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 1.2rem;
                }
            }
        }
    }
}

@media (max-width: 390px) {
    .join__sign ol li {
        padding-left: 4rem;

        &::before {

        }
    }
}

.checked__list {

    &-box {
        margin-top: 5rem;
    }


    margin-top: 2rem;

    li + li {
        margin-top: 1.7rem;
    }
    .filed {
        display: flex;
        justify-content: space-between;
        align-items:flex-end;
    }

    &--btn {
        padding: 0.4rem;
        margin: -0.4rem;
        font-size: 1.4rem;
        color: #9a9a9a;
        text-decoration: underline;
    }

    input[type="checkbox"] + label {
        padding-left: 2.6rem;
        color: #0a0a0a;

        em {
            color: #21a272;
        }
    }

    &--marketing {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 3.1rem;
        margin-top: 1.5rem;

        li + li {
            margin-top: 0;
            // margin-left: 2.8rem;
            margin-left: 2.2rem;
        }
    }
}

.address {
    &-box {
        margin-top: 2.4rem;

        .field + .field {
            margin-top: .4rem !important;
        }

        input[type="text"].address-user {
            // background-image: url(../images/ico/ico_input_search.svg);
            // background-position: right center;
            // background-size: 2.8rem;
            // background-repeat: no-repeat;
            border-color: #bbb;

            &:focus {
                border-color: #bbb;
            }
        }
    }

    &__btn {
        display: block;

        img {
            max-width: 2.8rem;
        }
    }

    @at-root .is-active {
        input[type="text"].address-user {
            border-color: #00a972;
        }
    }
}


.policy {
    &-box {
        h1, h2, p , ol, ul, li {
            font-size: 1.4rem;
            font-weight: 400;
            color: #949494;
            padding: 0;
        }
    }

    &-contents {
        margin-top: 1.2rem;

        p, ol, ul {
            margin-top: 0.8rem;
        }

        li {

            + li {
                margin-top: .4rem;
            }
        }        

    }
}

.msg-box.mbr {
    // position: fixed;
    padding: 2.8rem 2rem;
    background-color: #f2f2f2;
    width: 100%;
    max-width: 64rem;
 
    .msg__list {
        margin-top: 0;
        font-size: 1.2rem;
        color: #636363;
        line-height: 1.5;

        li + li {
            margin-top: .6rem;
        }
    }
}

.msg.--join {
    margin-top: 3.2rem;
    font-size: 1.8rem;
    color: #0a0a0a;
    text-align: center;
    line-height: 1.44;
}

.required--msg {
    display: block;
    text-align: right;
    font-size: 1.2rem;
    color: #ff4b4b;

    &::before {
        content: '';
        overflow: hidden;
        display: inline-block;
        width: .4rem;
        height: .4rem;
        background-color: #ff4b4b;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: .4rem;
    }
}


.modal.advertisement {

    .modal__header {
        border-bottom: 0;
    }

    .modal__title {
        font-size: 1.9rem;
        color: #0a0a0a;
        line-height: 1.42;
        font-weight: 600;
    }

    .modal__subtitle {
        display: block;
        margin-top: 2rem;
        font-size: 1.6rem;
        line-height: 1.44;
        color: #0a0a0a;
    }

    .date {
        font-size: 1.4rem;
        color: #636363;
        margin-top: 1.6rem;
    }

    .modal__body {
        margin-top: 0;
    }

    .marketing-box {
        margin: 0;
    }

    .marketing__list {
        padding: 1.9rem 2rem;
        border: 1px solid #cfcfcf;
        border-radius: .8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &::before {
            display: none;
        }

        &.is-checked {
            .marketing__agree {
                font-weight: 600;

                &::after {
                    background-image: url(/assets/images/ico/ico_check_marketing_p.svg);
                }
            }
        }

        .marketing__title {
            font-size: 1.5rem;
            color: #0a0a0a;
        }

        .marketing__agree {
            margin-top: 0;
            margin-left: 0;
            font-size: 1.6rem;
            display: flex;
            color: #0a0a0a;
            justify-content: flex-start;
            align-items: center;

            &::after {
                content: '';
                display: inline-block;
                margin-left: .4rem;
                width: 2.4rem;
                height: 2.4rem;
                background-image: url(/assets/images/ico/ico_check_marketing.svg);
                background-size: 2.4rem;
            }

           
        }
    }

    .btn--solid--black {
        padding: 1.4rem 0;
    }
}

.join--complete {
    .control {
        .btn {
            width: auto;
            padding-left: 2rem;
            padding-right: 2rem;
            color: #0a0a0a;
        }
    }

    .tooltip__btn {
        > span {
            font-size: 2rem;
            color: #0a0a0a;
            font-weight: 600;
        }

        &::after {
            background-image: url(../images/ico/ico_tip2_gray.svg);
        }
    }

    .desc {
        display: block;
        margin-top: 1.6rem;
        font-size: 1.8rem;
        line-height: 1.44;
    }
}

.join--recommender {

}

.modal.dormant {
    .modal__header {
        border-bottom: 0;
    }

    .modal__body {
        margin-top: 0;

        .desc {
            margin-top: 3.2rem;
            font-size: 1.6rem;
            color: #0a0a0a;

            > span {                
                display: block;
                text-align: center;
                + span {
                    margin-top: 2rem;
                }
            }
        }
    }

    .mbr__header {
        padding: 3.2rem 0;
        background-color: #f2f2f2;
        border-radius: 1.2rem;
        color: #0a0a0a;
        font-size: 2rem;
    }

    .btn--line--black {
        width: 100%;
        padding: 1.5rem 0;
        font-size: 1.5rem;
        color: #0a0a0a;
        border-radius: 0.8rem;
        border: 1px solid #bbb;
        background-color: #fff;
    }
}