.personal {
    &__card {
        padding: 2.4rem 2.4rem;
        background-color: #fff;
        border-radius: 1.2rem;
        box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.3);

        .title {
            font-size: 2.4rem;
            color: #0a0a0a;
            font-weight: 400;
            text-align: center;
        }

        .desc {
            font-size: 2rem;
            color: #0a0a0a;
            text-align: center;
            word-break: keep-all;

            strong {
                color: #00a972;
                // font-weight: 500;
            }
        }

        .thumb {
            img {
                max-height: 17.2rem;
            }
        }

        .circle {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16rem;
            height: 16rem;
            margin: 3.2rem auto;
            background-color: #f2f2f2;
            border-radius: 50%;

            img {
                width: 7.6rem;
            }
        }
    
        + .personal__card {
            margin-top: 3.2rem;
        }

        &.--info {
            box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.1);
        }

        .date-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            .date {
                font-size: 1.8rem;
            }

            .user {
                width: 100%;
                margin-top: .6rem;
                font-size: 1.4rem;
                .name {
                    color: #00a972;
                }
            }
        }

        .card-title {
            font-size: 2.4rem;
            font-weight: normal;
            word-break: keep-all;

            .btn--refresh {
                transform: translate(0, -9px);
            }
        }

        .card-txt {
            font-size: 2rem;
            word-break: keep-all;
        }

        .section--title-box {
            margin-bottom: 1.6rem;
        }

        .shop__content__more {
            justify-content: flex-end;
            min-width: 6.8rem;

            &.is-bold {
                font-weight: 600;
                color: #00a972;
                font-size: 1.6rem;
            }
        }

        .ico-box {
            display: flex;
            align-items: center;
            margin-bottom: 1.2rem;

            .ico {
                width: 3.2rem;
            }

            .text {
                display: inline-block;
                margin-left: 1rem;
                font-size: 1.7rem;
            }

            .google-img {
                width: 12rem;
            }
        }
    }

    &__point {
        &--title-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1.5rem;
            font-size: 2rem;
            color: #0a0a0a;
            

            .title {
                font-weight: 600;
            }
        }

        &--contents + &--contents {
            margin-top: 0.8rem;
        }

        &--contents {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            padding: .8rem;
            background-color: #fff;
            border: 1px solid #cfcfcf;
            border-radius: .8rem;
            overflow: hidden;

            .thumb {
                overflow: hidden;
                width: 6.8rem;
                height: 6.8rem;
                border-radius: .8rem;
                background-color: #f2f2f2;
                background-repeat: no-repeat;
                background-size: 4rem;
                background-position: 50%;
            }

            .text-box {
                overflow: hidden;
                flex: 1;
                margin-left: 1.6rem;
                font-size: 2rem;
                color: #0a0a0a;

                .title {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    margin-bottom: 0.8rem;
                    text-align: left;
                }

                .desc {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    text-align: left;
                }
            }

            .desc-list {
                width: calc(100% + 1.6rem);
                padding: 1rem 1.8rem;
                margin-left: -0.8rem;
                margin-right: -0.8rem;
                margin-bottom: -0.8rem;
                margin-top: 1.2rem;
                background-color: #f2f2f2;
                font-size: 0;

                li {
                    display: inline-block;
                    margin: 0.3rem;
                }

                .text {
                    padding: 0.6rem 1.2rem;
                    border-radius: 1.4rem;
                    font-size: 1.4rem;
                    background-color: #fff;
                }
            }

            .point {
                padding: .6rem 1.2rem;
                margin-left: 3.6rem;
                background-color: #00a972;
                border-radius: 1.6rem;
                color: #fff;
                font-size: 1.6rem;
                font-weight: 600;
            }

            &.--desc {
                display: block;
                padding: 0;
                overflow: hidden;

                .flex-box {
                    padding: .8rem;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }

                .desc-box { 
                    padding: 1.6rem 2.4rem;
                    background-color: #f2f2f2;

                    .desc-list {
                        margin: -0.3rem;
                        font-size: 0;
                        
                        li {
                            display: inline-block;
                            margin: 0.3rem;
                        }

                        .text {
                            padding: 0.6rem 1.2rem;
                            border-radius: 1.4rem;
                            font-size: 1.4rem;
                            background-color: #fff;
                        }
                    }
                }
            }
        }
    }

    // 인풋스타일 정의
    &__field {
        .control {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: .4rem 0;

            + .control {
                margin-top: 3.2rem;
            }

            + .bmi-box {
                margin-top: 3.2rem;

            }
        }

        .bmi-box + .control {
            margin-top: 3.2rem;
        }

        .personal__label {
            font-size: 1.8rem;
            font-weight: 400;
            white-space: nowrap;
            min-width: 9.4rem;

            &.is-required {
                .txt {
                    position: relative;
                    
                    &::after {
                        content: '';
                        display: inline-block;
                        width: 0.4rem;
                        height: 0.4rem;
                        position: absolute;
                        top: 0;
                        right: -0.6rem;
                        border-radius: 50%;
                        background-color: #ff4b4b;
                    }
                }
            }
        }
    }

    &__input[type="number"] {
        display: inline-block;
        width: 100%;
        padding: 0;
        border: 0;
        font-size: 2rem;
        font-weight: 600;
        text-align: right;

        &:focus {
            border: 0;
            outline: 0;
        }

        &::after {
            content: 'Kg';
        }

        &::-webkit-outer-spin-button, 
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    &__input-box {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 4.5rem;
        border-bottom: 1px solid #bbb;

        &::after {
            content: '';
            margin-left: .8rem;
            display: inline-block;
            // min-width: 2.2rem;
            min-width: 3rem;
            font-size: 1.6rem;
            color: #bbb;
            text-align: right;
            font-weight: 400;
            line-height: 2.4rem;
        }

        &.is-kg {
            &::after {
                content: 'kg';
            }
        }

        &.is-cm {
            &::after {
                content: 'cm';
            }
        }

        &.is-percent {
            &::after {
                content: '%';
            }
        }

        &.is-inch {
            &::after {
                content: 'inch';
            }
        }

    }

    // 프로그레스
    &__progress {
        &.--bar {
            text-align: left;
            .outer {
                overflow: hidden;
                position: relative;
                height: 2.4rem;
                background-color: #e9e9e9;
                border-radius: 1.2rem;
            }

            .inner {
                display: inline-block;
                width: 0;
                height: 100%;
                background-color: #12bf87;
                border-radius: 1.2rem;
                transition: all .5s;
            }

            .text-box {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding: 0 1.2rem;
                font-size: 1.6rem;
                font-weight: 700;
            }

            .current {
                color: #fff;
            }

            .total {

                color: #9a9a9a;
            }
        }

        &.--walk {
            
            position: relative;
            padding: .6rem 0;
            .outer {
                overflow: hidden;
                height: 1.2rem;
                background-color: #e9e9e9;
                border-radius: .6rem;
            }

            .inner {
                display: inline-block;
                width: 0;
                height: 100%;
                background-color: #ffb941;
                border-radius: .6rem;
                transition: all .5s;
            }

            .text-box {
                height: 3.2rem;

                .point {
                    position: absolute;
                    top: 3.2rem;
                    display: block;
                    width: 7rem;
                    font-size: 1.6rem;
                    color: #9a9a9a;
                    text-align: center;

                    &::before {
                        content: '';
                        position: absolute;
                        top: -3.2rem;
                        left: calc(3.5rem - 1.2rem);
                        width: 2.4rem;
                        height: 2.4rem;
                        background-color: #fff;
                        border-radius: 50%;
                        border: 0.6rem solid #e9e9e9;
                    }

                    .num {
                        font-weight: 600;
                    }

                    &:first-child {
                        left: calc(30% - 3.5rem);
                    }

                    &:nth-child(2) {
                        left: calc(80% - 3.5rem);
                    }

                    &.is-active {
                        color: #ffb941;

                        &::before {
                            border-color: #ffb941;
                        }
                    }
                }
            }
        }
    }

    &__intro {
        padding-top: 4.4rem;

        .title-box {
            .title {
                margin-bottom: 1.6rem;
                font-size: 2.4rem;
            }

            .desc {
                font-size: 1.6rem;
                line-height: 1.5;
                word-break: keep-all;
            }
        }  
    }

    &__certify {
        padding-top: 3.2rem;

        .title {
            margin-bottom: 4rem;
            font-size: 2.2rem;
            font-weight: normal;
            line-height: 1.36;
        }

        &--list {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            margin: -2rem;
            text-align: center;

            li {
                margin: 1rem;
                width: calc(33.333% - 2rem);

                .control {
                    margin: 0;
                }
            }
            
            label {
                cursor: pointer;
            }

            input[type='radio'] {

                + label {
                    position: relative;
                    padding: 0;

                    &::after {
                        /* content: ''; */
                        position: absolute;
                        top: -11px;
                        right: -8px;
                        width: 3.6rem;
                        height: 3.6rem;
                        background-image: url(/assets/images/ico/check_ps_certification.svg);
                        background-size: cover;
                    }
                }

                &:checked {
                    + label {
                        &::after {
                            content: '';
                        }
                    }   
                }
            }

            .img {
                width: 8rem;
                height: 8rem;
                border-radius: 1.2rem;
                
                overflow: hidden;

                &.--border {
                    border: 1px solid #bbb;
                }
            }

            .name {
                display: block;
                margin-top: 1.2rem;
                font-size: 1.6rem;
                text-align: center;
            }
        }
    }

    &__checkup {

        &--item {
            + .personal__checkup--item {
                &::before {
                    content: '';
                    display: block;
                    height: 0.8rem;
                    margin-top: 4rem;
                    background-color: #f2f2f2;
                    border-top: 1px solid #d4d4d4;
                    border-bottom: 1px solid #d4d4d4;
                    margin-left: -2rem;
                    margin-right: -2rem;
                }
            }
        }

        &--detail {
            padding-top: 3.2rem;

            .title-box {
                margin-bottom: 2.8rem;
                text-align: center;

                .title {
                    font-size: 2.4rem;
                }

                .desc {
                    margin-top: 1.6rem;
                    font-size: 1.8rem;
                }
            }
        }
    }
}

.gray-box {
    padding: 2.4rem;
    font-size: 1.6rem;
    border-radius: 0.8rem;
    background-color: #f2f2f2;
    line-height: 1.5;
}

.is-nodata {
    background-color: #f2f2f2;
    border-radius: .8rem;
    color: #9a9a9a;
    font-size: 1.5rem;
    text-align: center;
    line-height: 1.5;

    &::before {
        content: '';
        display: block;
        height: 4rem;
        margin-bottom: .8rem;
        background-image: url(../images/ico/ico_nodata.svg);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .text {
        display: block;
    }

    .btn {
        display: inline-block;
        margin-top: .6rem;
        font-size: 1.5rem;
        color: #0a0a0a;

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            border-bottom: 1px solid #0a0a0a;
        }
    }

    
    &.--large {
        padding: 4.7rem 0;
    }

    &.--small {
        padding: .9rem 0;
    }

    &.--white {
        padding: 8.4rem 0;
        background-color: #fff;
        // border: 1px solid #cfcfcf;
        // border-radius: .8rem;
    }

    &.is-nobefore {
        &::before {
            display: none;
        }
    }
}

.bmi {

    &__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__body {
        margin-top: 1.5rem;
    }

    &__footer {
        padding: 1.6rem;
        margin-top: 1.6rem;
        background-color: #fff;
        border-radius: .8rem;
        border: 1px solid #cfcfcf;
        font-size: 0;

        li {
            display: inline-block;
            width: calc(33.3333% - 1.6rem);
            font-size: 1.4rem;
            color: #0a0a0a;
            margin: .8rem;

            &::before {
                content: '';
                display: inline-block;
                width: 1rem;
                height: 1rem;
                margin-right: .4rem;
                background-color: #bbb;
                border-radius: 50%;
            }

            &:first-child {
                &::before {
                    background-color: #00a972;
                }
            }

            &:nth-child(2) {
                &::before {
                    background-color: #5d93ff;
                }
            }
            
            &:nth-child(3) {
                &::before {
                    background-color: #fc9a42;
                }
            }
            
            &:nth-child(4) {
                &::before {
                    background-color: #ff81c4;
                }
            }

            &:last-child {
                &::before {
                    background-color: #ff5d5d;
                }
            }
        }
    }
    
    &-box {
        .btn__tooltip {
            display: inline-block;
            width: 2rem;
            height: 2rem;
            margin-left: .2rem;
            background-image: url(../images/ico/ico_tip2_gray.svg);
            background-size: cover;
        }

        + .personal__field {
            margin-top: 1.6rem;
        }
    }

    &__title {
        font-size: 1.8rem;
        color: #0a0a0a;
        font-weight: 400;
    }
}


// 그래프
.personal--chart-box {
    .chart__unit {
        font-size: 1.2rem;
        color: #bbb;
    }
}

.section-inbody {
    padding-top: 3.2rem;
    padding-bottom: 4rem;

    .personal__card {
        box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.3);
    }

    .personal__card + .personal__card {
        margin-top: 1.6rem;
    }
    
    .inbody-img {
        display: block;
        width: 6.5rem;
    }
    .disc {
        display: -webkit-box;
        max-height: 6rem;
        font-size: 1.4rem;
        color: #636363;
        line-height: 1.3;
        overflow: hidden;
        word-wrap: break-word;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        &.is-active {
            max-height: 100%;
            -webkit-line-clamp: inherit;
        }
    }
    .btn--more {
        display: flex;
        margin: 1.2rem auto 0 auto;
        font-size: 1.5rem;
        text-decoration: underline;
    }
}
.info-essential {
    text-align: right;
    font-size: 1.2rem;
    color: #FF4B4B;
    &::before {
        content: '';
        display: inline-block;
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 50%;
        background-color: #FF4B4B;
        transform: translate(-0.4rem, -0.1rem);
    }
}
.info-essential + .field {
    margin-top: 1.6rem;
}
.bmi-box {
    &.tooltip-wrap {
        display: block;
        .tooltip-box {
            &.is-bottom {
                left: 144px;
                &::before {
                    left: 30px;
                }
            }
        }
    }
}
.inbody {
    .section--sub-title {
        margin: 3rem 0;
    }
    .guide {
        &-box {
            padding: 2.4rem;
        }
        &-txt {
            font-size: 1.4rem;
            line-height: 1.3;
        }
    }
}
.section--sub-title {
    font-size: 2rem;
}
.guide {
    &-box {
        padding: 1rem 1.5rem;
        border-radius: 0.8rem;
        background-color: #F2F2F2;
    }
    &-txt {
        font-size: 1.2rem;
        color: #636363;
    }
}
.font-gray {
    color: #636363;
}
#modal-ms-selex, #modal-ms-exercise {
    .modal__body {
    padding-bottom: 13rem;
    overflow: auto;
   }
}
#modal-ms-exercise {
    .exercise {
        &__box {
            margin: -0.8rem -0.8rem 0 -0.8rem;
            font-size: 0;
            li {
                display: inline-block;
                width: calc(50% - 1.6rem);
                margin: 0.8rem;
                border-radius: 0.8rem;
                border: 1px solid #bbb;
            }
            button {
                width: 100%;
            }
        }
        &__category {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 1.6rem .4rem;
        }
        &__img {
            width: 4.4rem;
            margin-bottom: 1rem;
        }
        &__name {
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            .tit {
                overflow: hidden;
                font-size: 2rem;
                text-align: center;
                text-overflow: ellipsis;
                color: #0a0a0a;
            }
        }
    }
    @media (max-width: 360px) {
        .exercise__name {
            min-height: 4.8rem
        }
    }
}


.weekly__chice {
    position: relative;
    padding-top: 10rem;
    z-index: 2;

    .container {
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
        border: 0;
    }


    .btn__prev, .btn__next {
        min-width: 4rem;
        background-size: 2rem;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 0;
        border: 0;
    }

    .btn__prev {
        background-image: url(../images/ico/ico_pre_d.svg);
    }
 
    .btn__next {
        background-image: url(../images/ico/ico_next_d.svg);

        &:disabled {
            cursor: auto;
        }
    }

    &--lists {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;

        li {
            flex: 1;
            margin-left: 1px;

            &:first-child {
                margin-left: 0;
            }
            
            &.is-active {
                .date__num {
                    color: #fff;
                    background-color: #0a0a0a;
                }

                
            }
            
            &.is-data {
                .date__info {
                    .btn {
                        &::after {
                            background-color: #bbb;
                        }
                    }
                }
            }

            &:nth-child(6) {
                .date__text {
                    color: #296df6;
                }
            }

            &:nth-child(7) {
                .date__text {
                    color: #ff4646;
                }
            }
        }
    }

    .date {
        &__info {
            display: flex;
            justify-content: center;
            align-items: center;

            .btn {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                flex-direction: column;
                padding: 0;
    
                &::after {
                    content: '';
                    display: block;
                    width: .8rem;
                    height: .8rem;
                    margin-top: .6rem;
                    background-color: transparent;
                    border-radius: 50%;
                }

                &:disabled {
                    cursor: auto;
                    .date__text {
                        color: #ddd !important;
                    }
                    .date__num {
                        color: #ddd;
                    }
                }
            }
        }

        &__text {
            font-size: 1.2rem;
            color: #636363;
        }
        
        &__num {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2.8rem;
            height: 2.8rem;
            margin-top: .8rem;
            border-radius: 50%;
            font-size: 1.8rem;
        }
    }

    &.is-selex {
        .weekly__chice--lists {
            .is-data {
                .btn {
                    &::after {
                        background-color: #00a972;
                    }
                }
            }
        }
    }
    &.is-health {
        .weekly__chice--lists {
            .is-data {
                .btn {
                    &::after {
                        background-color: #be85ff;
                    }
                }
            }
        }
    }
    &.is-meat {
        .weekly__chice--lists {
            .is-data {
                .btn {
                    &::after {
                        background-color: #6195ff;
                    }
                }
            }
        }
    }
    &.is-walk {
        .weekly__chice--lists {
            .is-data {
                .btn {
                    &::after {
                        background-color: #ffb941;
                    }
                }
            }
        }
    }
}


/* 박스형태만 사용 하위로 클래스 태우지 말것! */
.line-box {
    padding: 2.4rem;
    border-radius: 1.2rem;
    border: 1px solid #cfcfcf;
    background-color: #fff;

    .title {
        font-size: 1.8rem;
    }

    .desc {
        font-size: 1.6rem;
        color: #636363;
        line-height: 1.5;
    }

    // 다른 형태의 스타일은 중복 클래스로 재정의
    &.aaa {
        .title {
            font-size: 2rem;
        }
    }

    + .line-box {
        margin-top: 1.2rem;
    }

    //제목 + 내용 형태
    &.--text {
        .title {
            display: block;
            width: 100%;
            margin-bottom: 1.2rem;
            font-size: 2rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    
        .desc {
            font-size: 1.8rem;
        }
    }

    //주제에 따른 제목 + 내용 형태
    &.--title {
        .subject-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 1.5rem;
            border-bottom: 1px solid #bbb;
        }

        .subject {
            font-size: 2rem;
            font-weight: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .age-desc {
            font-size: 1.8rem;
            white-space: nowrap;

            .order {
                color: #ff4b4b;
            }
        }

        .text {
            margin-top: 2rem;
        }

        .title {
            display: block;
            margin-bottom: 1.2rem;
            font-weight: 600;
        }
    }

    //썸네일 있는 제목 + 내용 형태
    &.--thum {
        display: flex;
        align-items: center;

        .thum {
            width: 8rem;
            height: 8rem;
            min-width: 8rem;
            border-radius: 0.8rem;
            overflow: hidden;

            img {
                object-fit: cover;
                width: 8rem;
                height: 8rem;
            }
        }

        .text {
            margin-left: 1.2rem;
        }

        .title {
            display: inline-block;
            margin-bottom: 1.2rem;
            font-size: 2rem;
        }

    }
}

.smbti {
    &__result {
        padding-top: 6rem;
        background-color: #fff;
        
        &--title {
            margin-bottom: 2.4rem;
            text-align: center;

           .sub {
                display: inline-block;
                margin-bottom: 0.8rem;
                font-size: 1.4rem;
                color: #9a9a9a;
                font-weight: 500;
           }

           .title {
            font-size: 2.4rem;
            font-weight: normal;
           }
        }
    }

    &__function {
        text-align: center;

        .product__detail--analysis {
            .analysis--keyword {
                .keyword {
                    padding: 0.4rem 0.8rem 0.4rem 2.6rem;
                    margin: 0.4rem 0.25rem;
                    font-size: 1.4rem;
                    background-position: top 50% left 0.6rem;
                }
            }
        }
    }

    &__info {
        &--title {
            padding-top: 4rem;
            padding-bottom: 3.2rem;
            text-align: center;
            border-bottom: 1px solid #bbb;
            
            .title-box {
                display: inline-block;

                /* &::before {
                    content: '';
                    display: inline-block;
                    width: 2.5rem;
                    height: 1.65rem;
                    background-image: url(/assets/images/quotes_L.svg);
                    background-size: contain;
                    background-repeat: no-repeat;
                    transform: translate(-0.5rem, -0.5rem);
                    opacity: 0.1;
                }

                &::after {
                    content: '';
                    display: inline-block;
                    width: 2.5rem;
                    height: 1.65rem;
                    background-image: url(/assets/images/quotes_R.svg);
                    background-size: contain;
                    background-repeat: no-repeat;
                    transform: translate(0.5rem, -0.5rem);
                    opacity: 0.1;
                } */
            }

            .title {
                display: inline-block;
                margin-bottom: 2rem;
                font-size: 2.4rem;   

                &::after {
                    content: '';
                    display: block;
                    height: 0.8rem;
                    margin-top: -0.8rem;
                    background-color: #fff127;
                }
            }

            .desc {
                font-size: 2rem;
                line-height: 1.4;
                word-break: keep-all;
            }
        }
    }

    &__type {
        &--title {
            margin-bottom: 2rem;
            font-size: 2.4rem;
            font-weight: normal;
            text-align: center;
        }
    }

    &__after {
        &--character {
            width: 12rem;
            margin: 0 auto;
        }

        .txt-box {
            margin-top: 0.8rem;
            text-align: center;

            .title {
                display: inline-block;
                font-size: 2.2rem;

                &::after {
                    content: '';
                    display: block;
                    height: 0.8rem;
                    margin-top: -0.8rem;
                    background-color: #fff127;
                }
            }
            .desc {
                margin-top: 1.2rem;
                font-size: 1.6rem;
                color: #636363;
            }
        }

        &--checkup {
            .title-box {
                display: flex;
                align-items: center;
                padding-bottom: 2.4rem;
                border-bottom: 1px solid #bbb;

                .ico {
                    &::after {
                        content: '';
                        display: inline-block;
                        width: 8.4rem;
                        height: 8.4rem;
                        border-radius: 50%;
                        background-color: #f2f2f2;
                        background-size: 4.4rem;
                        background-position: center;
                        background-repeat: no-repeat;
                    }

                    &.--age {
                        &::after {
                           background-image: url(/assets/images/ico/ico_muscle.svg);
                        }
                    }

                    &.--checkup {
                        &::after {
                           background-image: url(/assets/images/ico/ico_survey.svg);
                        }
                    }
                }
            }

            .title {
                margin-left: 2.4rem;
                font-size: 2.2rem;
                font-weight: normal;
                text-align: left;
                word-break: keep-all;
            }

            .txt {
                margin-top: 2.4rem;
                font-size: 1.8rem;
                line-height: 1.44;
                text-align: center;
                word-break: keep-all;
            }
        }

        &--age {
            display: flex;
            // justify-content: center;
            justify-content: space-around;
            align-items: center;
            margin-top: 3.2rem;

            .figure {
                position: relative;
                // display: inline-block;

                &::before {
                    content: '';
                    display: inline-block;
                    border-radius: 50%;
                }
            }

            .txt {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .desc {
                display: block;
                text-align: center;
                margin-top: 0.8rem;
            }

            .healthy-age {
                // background-color: #00d094;
                
                // &::before {
                //     content: '';
                //     padding-top: 100%;
                // }
                // display: inline-block;
                margin-right: .5rem;

                .figure { 
                    &::before {
                        display: block;
                        padding-top: 100%;
                        // width: 14.5rem;
                        // height: 14.5rem;
                        // background-color: #00d094;
                    }
                }

                &.--normalcy {
                    .figure { 
                        &::before {
                            background-color: #00d094;
                        }
                    }
                }

                &.--danger {
                    .figure { 
                        &::before {
                            background-color: #ff5c5c;
                        }
                    }
                }

                &.--great {
                    .figure { 
                        &::before {
                            background-color: #6195ff;
                        }
                    }
                }

                .txt {
                    // top: 40%;
                    // left: 26%;
                    font-size: 2.2rem;
                    color: #fff;
                }

                .desc {
                    font-size: 2rem;
                }
            }

            .my-age {
                // display: inline-block;
                // margin-left: 1rem;
                margin-left: .5rem;

                .figure { 
                    &::before {
                        display: block;
                        padding-top: 100%;
                        // width: 12rem;
                        // height: 12rem;
                        background-color: #f2f2f2;
                    }
                }

                .txt {
                    // top: 42%;
                    // left: 33%;
                    font-size: 1.6rem;
                    font-weight: normal;
                    white-space: nowrap;
                }

                .desc {
                    font-size: 1.6rem;
                }
            }
        }
    }
}

.date-inquiry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .btn {
        padding: 0;
    }

    .btn-date {
        width: calc(100% - 5rem);

        .date {
            font-size: 1.8rem;
            color: #0a0a0a;

            &::after {
                content: '';
                display: inline-block;
                width: 2rem;
                height: 2rem;
                margin-left: 0.8rem;
                background-image: url(/assets/images/ico/ico_input_calendar.svg);
                background-size: cover;
                vertical-align: bottom;
            }
        }
    }
   
    .btn-prev, .btn-next {
        width: 2.4rem;
        height: 2.4rem;
        background-size: cover;
    }

    .btn-prev {
        background-image: url(/assets/images/ico/ico_pre.svg);

        &:disabled {
            background-image: url(/assets/images/ico/ico_pre_d.svg);
        }
    }

    .btn-next {
        background-image: url(/assets/images/ico/ico_next.svg);
        &:disabled {
            background-image: url(/assets/images/ico/ico_next_d.svg);
        }
    }

    .date-choice {
        overflow: hidden;
        display: none;
        position: absolute;
        top: 3.6rem;
        left: 0;
        width: 100%;
        // transform: translateX(-50%);
        border: 1px solid #ccc;
        border-radius: 0.8rem;
        box-shadow: 0 0 0.6rem 0 rgba(0, 0, 0, .3);
        background-color: #fff;
        z-index: 1;

        &.is-active {
            display: block;
        }

        li {
            &:nth-child(odd) {
                background-color: #f2f2f2;
            }
        }

        .btn {
            display: block;
            padding: 1.6rem;
            background-color: transparent;
            border: 0;
            font-size: 1.8rem;
            color: #0a0a0a;
            text-align: center;
            outline: 0;
        }
    }
}

.nutrition-guide {
    .title-box {
        margin-bottom: 2rem;
        text-align: center;

        .ico {
            display: inline-block;
            width: 4.8rem;
            height: 4.8rem;
            margin-bottom: 1.2rem;
            border-radius: 50%;
            background-color: #f2f2f2;
    
            &::before {
                content: '';
                display: inline-block;
                width: 2.8rem;
                height: 2.8rem;
                background-image: url(/assets/images/ico/ico_nutrition.svg);
                background-size: cover;
                transform: translateY(0.8rem);
            }
        }
    
        .title {
            font-size: 2rem;
        }
    }

    &__list {
        .line-box {
            text-align: center;
        }

        .title {
            font-size: 1.8rem;
            font-weight: normal;
        }

        .txt {
            margin-top: 1.2rem;
            font-size: 1.6rem;
            color: #636363;
            word-break: keep-all;
            line-height: 1.5;
        }
    }
}

.sharing {
    display: flex;
    justify-content: center;

    .sharing__ico {
        display: inline-block;
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        overflow: hidden;

        &.--link {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f2f2f2;

            img {
                width: 2rem;
            }
        }
    }

    .sharing__ico + .sharing__ico {
        margin-left: 2rem;
    }
}

.type-list {
    margin-top: 2rem;
    font-size: 0;
    margin-left: -0.8rem;
    margin-right: -0.8rem;

    li {
        display: inline-block;
        // width: calc(25% - 1.6rem);
        width: calc(25% - 1.6rem);
        font-size: 1.4rem;
        color: #0a0a0a;
        margin: 0.8rem;

        white-space: nowrap;

        &::before {
            content: '';
            display: inline-block;
            width: 1rem;
            height: 1rem;
            margin-right: 0.4rem;
            border-radius: 50%;
        }

        &.--yellow {
            &::before {
                background-color: #ffb941;
            }
        }
        &.--purple {
            &::before {
                background-color: #be85ff;
            }
        }
        &.--blue {
            &::before {
                background-color: #6195ff;
            }
        }
        &.--green {
            &::before {
                background-color: #2ccc98;
            }
        }
        &.--pink {
            &::before {
                background-color: #ff77db;
            }
        }
        &.--orange {
            &::before {
                background-color: #ff8b8b;
            }
        }
        &.--lime {
            &::before {
                background-color: #a6c550;
            }
        }
        &.--danger {
            &::before {
                background-color: #ff5c5c;
            }
        }
    }

    &.is-age {
        padding: 1.2rem 1.6rem;
        margin: 2.4rem 0 0;
        border: 1px solid #cfcfcf;
        border-radius: .8rem;

        li {
            width: calc(33.33333% - 1.6rem);

            b {
                margin-right: 1rem;
            }
        }
    }
}


.healthy-age {
    &__method {
        .title-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 2.4rem;

            .title {
               font-size: 2.4rem;
               font-weight: normal;
               text-align: left;
               word-break: keep-all;
            }

            .ico {
                width: 6rem;
                margin-left: 1.2rem;
            }
        }

        &.--checkup {
            .ico {
                width: 10.8rem;
                min-width: 10.8rem;
            }
        }

        .card-txt {
            font-size: 1.8rem;
            color: #636363;
            line-height: 1.44;
        }
    }

    &__intro {
        .join__sign {
            padding: 3.2rem 0 2.8rem;
            margin-top: 0;
        
            &--step {
                width: 3.2rem;
                height: 3.2rem;
                font-size: 1.6rem;
            }
        
            &--title {
                margin-top: 0.8rem;
                font-size: 1.4rem;
            }
        
            li::before {
                border-color: #00a972;
            }
        }

        .text-box {
            padding-top: 6rem;
            text-align: center;
            word-break: keep-all;

            .ico {
                display: inline-block;
                width: 8rem;
                margin-bottom: 0.8rem;
            }

            .certify-ico {
                margin-bottom: 2.8rem;
                border-radius: 1.2rem;
                overflow: hidden;

                &.--border {
                    border: 1px solid #bbb;
                }
            }

            .txt {
                font-size: 2.2rem;
                line-height: 1.36;
            }

            .txt + .txt {
                margin-top: 2rem;
            }

            .desc {
                margin-top: 2rem;
                font-size: 1.8rem;
                color: #636363;
                line-height: 1.44;
            }
        }
    }

    &__tab {
        margin-top: 3.2rem;
        font-size: 0;

        li + li {
            margin-left: 1.2rem;
        }

        li {
            display: inline-block;
            width: calc(50% - 0.6rem);
        }

        .tab-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 2.4rem;
            border-radius: 1.2rem;
            color: #636363;
            background-color: #f2f2f2;

            &.is-active {
                border: 1px solid #00a972;
                color: #0a0a0a;
                background-color: #fff;
                box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
            }
        }

        .title {
            margin-bottom: 1rem;
            font-size: 1.8rem;
            height: 4.6rem;
        }

        .age {
            height: 3.6rem;
            font-size: 1.8rem;

            strong {
                display: inline-block;
                font-size: 2.4rem;
                transform: translateY(2px);
            }
        }

        .ico {
            width: 3.6rem;
        }
    }

    &__list {
        margin-bottom: 4rem;

        ul{
            margin: -0.6rem;
            font-size: 0;
        }

        li {
            display: inline-block;
            width: calc(50% - 1.2rem);
            margin: 0.6rem;
        }

        .list-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 2rem 0.4rem;
            border: 1px solid #cfcfcf;
            border-radius: 1.2rem;
        }

        .ico {
            width: 4rem;
        }

        .category {
            margin-top: 0.4rem;
            margin-bottom: 1.5rem;
            font-size: 1.6rem;

            &::after {
                content: '';
                display: inline-block;
                width: 1.6rem;
                height: 1.6rem;
                background-image: url(/assets/images/ico/ico_next.svg);
                background-size: cover;
                transform: translate(2px, 2px);
            }
        }

        .age {
            margin-bottom: 0.8rem;
            font-size: 1.8rem;

            strong {
                display: inline-block;
                font-size: 2.4rem;
                transform: translateY(2px);
            }
        }

        .state {
            display: inline-block;
            padding:0.5rem 2.6rem;
            font-size: 1.2rem;
            color: #fff;
            border-radius: 1.2rem;

            &.--worst {
                background-color: #ff5c5c;
            }

            &.--normal {
                background-color: #2ccc98;
            }

            &.--best {
                background-color: #6195ff;
            }
        }
    }

    &__legend {
        padding: 2.8rem;
        margin-top: 1.2rem;
        border-radius: 1.2rem;
        background-color: #f2f2f2;
        
        .title {
            margin-bottom: 1.6rem;
            font-size: 1.6rem;
            font-weight: normal;
            text-align: center;
        }

        .graph-box {
            position: relative;
            padding-bottom: 1.6rem;
        }

        .graph {
            font-size: 0;
            color: #fff;

            span {
                display: inline-block;
                padding: 0.5rem 0;
                font-size: 1.2rem;
                text-align: center;
            }
        }

        .best {
            width: 20%;
            border-top-left-radius: 1.2rem;
            border-bottom-left-radius: 1.2rem;
            background-color: #6195ff;
        }

        .normal {
            width: calc(60% - 0.8rem);
            margin-left: 0.4rem;
            margin-right: 0.4rem;
            background-color: #2ccc98;
        }

        .worst {
            width: 20%;
            border-top-right-radius: 1.2rem;
            border-bottom-right-radius: 1.2rem;
            background-color: #ff5c5c;
        }

        .desc {
            margin-top: 0.8rem;
            font-size: 1.4rem;

            span {
                position: absolute;
                bottom: 0;
                /* bottom: -1.6rem; */
            }
        }

        .minus-15 {
            left: 0;
        }

        .minus-5 {
            left: 20%;
        }

        .actual-age {
            left: 50%;
            font-weight: 600;
            transform: translateX(-50%);
        }

        .plus-5 {
            right: 20%;
        }

        .plus-15 {
            right: 0;
        }
    }

    &__change {
        margin-top: 4rem;

        .section-title {
            color: #0a0a0a;
        }

        .btn-arrow {
            font-size: 1.5rem;
            color: rgb(154, 154, 154);
            margin-right: -1.6rem;

            &::after {
                content: '';
                display: inline-block;
                width: 1.2rem;
                height: 1.2rem;
                margin-left: 0.3rem;
                background-image: url(/assets/images/ico/ico_next_gray.svg);
                background-size: cover;
                transform: translateY(1px);
            }

            &.--green {
                
                color: #00a972;
                padding: 0.45rem 1.6rem;
                border: 0;

                &::after {
                    background-image: url(/assets/images/ico/ico_next_green.svg);
                }
            }
        }

        .result {
            padding: 2.4rem;
            font-size: 1.8rem;
            border-radius: 1.2rem;
            background-color: #f2f2f2;
            line-height: 1.44;
            word-break: keep-all;
        }
    }

    &__comparison {
        margin-top: 3.2rem;

        .title {
            margin-bottom: 2rem;
            font-size: 1.9rem;
            font-weight: normal;
        }

        .comparison-list {
            li + li {
                margin-top: 0.8rem;
            }
        }

        .list-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1.5rem 1.6rem;
            border-radius: 0.8rem;
            border: 1px solid #cfcfcf;
        }

        .thum {
            display: flex;
            align-items: center;
        }

        .ico {
            width: 2rem;
        }

        .txt {
            margin-left: 0.4rem;
            margin-right: 0.6rem;
            font-size: 1.6rem;
        }

        .badge {
            display: inline-block;
            width: 6rem;
            padding: 0.5rem 0.4rem;
            font-size: 1.2rem;
            font-weight: 500;
            color: #fff;
            border-radius: 1.6rem;
            text-align: center;

            &.--best {
                background-color: #6195ff;
            }

            &.--worst {
                background-color: #ff5c5c;
            }
        }

        .age {
            font-size: 1.6rem; 

            &::after {
                content: '';
                display: inline-block;
                width: 1.6rem;
                height: 1.6rem;
                background-image: url(/assets/images/ico/ico_next.svg);
                background-size: cover;
                transform: translateY(2px);
            }

            strong {
                display: inline-block;
                font-size: 2rem;
                transform: translateY(1px);
            }
        }
    }

    &__detail {
        .section-title + .tooltip__btn {
            &::after {
                transform: translateY(3px);
                opacity: 0.3;
            }
        }

        .state {
            display: inline-block;
            width: 7.3rem;
            padding: 0.5rem;
            font-size: 1.2rem;
            color: #fff;
            border-radius: 1.2rem;
            text-align: center;

            &.--worst {
                background-color: #ff4b4b;
            }

            &.--best {
                background-color: #6195ff;
            }

            &.--normal {
                background-color: #2ccc98;
            }
        }

        .age-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 1.2rem;
            padding: 2.4rem;
            border-radius: 1.2rem;
            background-color: #fff;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);

            .ico {
                width: 5.2rem;
            }

            .age {
                font-size: 1.8rem;
                text-align: right;

                strong {

                    display: inline-block;
                    font-size: 2.4rem;
                }
            }

            .desc {
                margin-top: 0.4rem;
                font-size: 1.6rem;
                text-align: right;

                .--short {
                    font-weight: 600;
                    color: #296df6;

                    &::before {
                        content: '';
                        display: inline-block;
                        width: 1.6rem;
                        height: 1.6rem;
                        background-image: url(/assets/images/ico/ico_g_down.svg);
                        background-size: cover;
                        transform: translate(-3px, 3px);
                    }
                }

                .--older {
                    font-weight: 600;
                    color: #ff4b4b;

                    &::before {
                        content: '';
                        display: inline-block;
                        width: 1.6rem;
                        height: 1.6rem;
                        background-image: url(/assets/images/ico/ico_g_up.svg);
                        background-size: cover;
                        transform: translate(-3px, 3px);
                    }
                }
            }
                
        }

        &--guide {
            margin-top: 6rem;

            .section-title {
                color: #0a0a0a;
            }
    
            .accordion__btn {
                padding-right: 2rem;
                font-size: 1.8rem;
            }
    
            .detail__info--desc {
                font-size: 1.6rem;
                line-height: 1.63;
            }
        }
    }
}

.section-title {
    margin-bottom: 2rem;
    font-size: 2.2rem;
    font-weight: normal;
}

.exercise-guide {
    .line-box {
        padding: 0;
        border-radius: 0;
        border: 0;
    }

    .list-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 2.4rem;
        border-radius: 1.2rem;
        border: 1px solid #cfcfcf;
    }

    .txt {
        text-align: left;
    }

    .type {
        display: block;
        margin-bottom: 1.2rem;
        font-size: 1.6rem;
        color: #00a972;
    }

    .name {
        font-size: 2rem;
        font-weight: 600;
        color: #0a0a0a;

        &::after {
            content: '';
            display: inline-block;
            width: 1.6rem;
            height: 1.6rem;
            background-image: url(/assets/images/ico/ico_next.svg);
            background-size: cover;
            transform: translate(6px, 2px);
        }
    }

    .ico {
        width: 5.2rem;
    }
}

.caution {
    font-size: 1.2rem;
    color: #636363;

    &::before {
        content: '';
        display: inline-block;
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 0.4rem;
        background-image: url(/assets/images/ico/ico_caution.svg);
        background-size: cover;
        transform: translate(0px, 4px);
    }
}

.nutrient-list {
    margin: -0.4rem;
    font-size: 0;

    li {
        display: inline-block;
        margin: 0.4rem;
    }

    .list-wrap {
        padding: 0.6rem 1.2rem;
        border-radius: 1.6rem;
        background-color: #2ccc98;
    }

    .txt {
        font-size: 1.6rem;
        font-weight: 600;
        color: #fff;
    }

}

.scroll {
    &__view {
        max-width: 100vw;
        margin-left: -2rem;
        margin-right: -2rem;
        overflow: hidden;
    }
}

.deficiency-swiper {
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: -2rem;
    margin-right: -2rem;
}

.deficiency-list {

    .list-wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2.4rem 0.4rem;
        border-radius: 1.2rem;
        border: 1px solid #cfcfcf;
    }

    .title {
        margin-bottom: 1.2rem;
        font-size: 1.8rem;
        font-weight: 600;

        &.--worst {
            color: #ff4b4b;
        }

        &.--good {
            color: #00a972;
        }
    }

    .desc {
        font-size: 1.6rem;
    }

    .ico {
        width: 4.8rem;
        margin-top: 2.4rem;
    }
}

.nutrient {
    &__progress {
        &--list {
            li + li {
                margin-top: 1.6rem;
            }

            .list-box {
                display: flex;
                align-items: center;
            }

            .title {
                width: 6.4rem;
                margin: 0;
                font-size: 1.5rem;
                font-weight: 600;
                text-align: left;
            }

            .progress-outer {
                width: calc(100% - 16.4rem);
                height: 1.2rem;
                position: relative;
                border-radius: 0.6rem;
                background-color: #e9e9e9;
                overflow: hidden;
            }

            .progress-inner {
                height: inherit;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 0.6rem;

                &.is-overflow {
                    background-color: #ff4b4b;
                }

                &.is-adequate {
                    background-color: #2ccc98;
                }

                &.is-shortage {
                    background-color: #ffb941;
                }
            }

            .amount {
                width: 12rem;
                font-size: 1.5rem;
                color: #9a9a9a;
                text-align: right;
            }

            .intake {
                color: #0a0a0a;
            }
        }
    }
}

.bubble__chart--title,
.bubble__chart--point {
    font-size: 22px;
}

@media (max-width: 380px) {
    .bubble__chart--title,
    .bubble__chart--point {
        font-size: 18px;
    }
}

@media (max-width: 340px) {
    .bubble__chart--title,
    .bubble__chart--point {
        font-size: 16px;
    }
}


@media (max-width: 400px) {
    .personal__point--contents .text-box .desc .block{
        display: block;
    }
}


.progress__loading {
    text-align: center;
    
    .circle {
        fill: none;
    }
  
    .circle-back {
        stroke: #f2f2f2;
        stroke-width: 12px;
    }
  
    .circle-front {
        transform: rotate(270deg);
        
        stroke: #00a972;
        stroke-width: 12px;
        stroke-linecap: round;

        /* -webkit-stroke-dasharray: 629px;
        -webkit-stroke-dashoffset: 629px; */
        stroke-dasharray: 629px;
        stroke-dashoffset: 629px;

        -webkit-animation: loading 3s ease;
        animation: loading 3s ease;
        animation-iteration-count: infinite;
        -webkit-animation-iteration-count: infinite;
    }
    
}
  
@keyframes loading {
    50% {
        // stroke: #00a972;
        stroke-dashoffset: 0;
        // -webkit-stroke-dasharray: 0;
        // -webkit-stroke-dashoffset: 0;
        // opacity: 0;
    }
    100% {
        // stroke: #c99;
        stroke-dashoffset: -629px;
        // -webkit-stroke-dasharray: -629px;
        // -webkit-stroke-dashoffset: -629px;
        // opacity: 1;
    }
}