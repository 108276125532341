/*******************************************************************
font - Pretendard
********************************************************************/

// @font-face {
// 	font-family: 'Pretendard';
// 	font-weight: 900;
// 	src: local('Pretendard Black'), url('../fonts/Pretendard-Black.woff2') format('woff2'), url('../fonts/Pretendard-Black.woff') format('woff');
// }

// @font-face {
// 	font-family: 'Pretendard';
// 	font-weight: 800;
// 	src: local('Pretendard ExtraBold'), url('../fonts/Pretendard-ExtraBold.woff2') format('woff2'), url('../fonts/Pretendard-ExtraBold.woff') format('woff');
// }

@font-face {
	font-family: 'Pretendard';
	font-weight: 700;
	src: local('Pretendard Bold'), url('../fonts/Pretendard-Bold.woff2') format('woff2'), url('../fonts/Pretendard-Bold.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 600;
	src: local('Pretendard SemiBold'), url('../fonts/Pretendard-SemiBold.woff2') format('woff2'), url('../fonts/Pretendard-SemiBold.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 500;
	src: local('Pretendard Medium'), url('../fonts/Pretendard-Medium.woff2') format('woff2'), url('../fonts/Pretendard-Medium.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 400;
	src: local('Pretendard Regular'), url('../fonts/Pretendard-Regular.woff2') format('woff2'), url('../fonts/Pretendard-Regular.woff') format('woff');
}

// @font-face {
// 	font-family: 'Pretendard';
// 	font-weight: 300;
// 	src: local('Pretendard Light'), url('../fonts/Pretendard-Light.woff2') format('woff2'), url('../fonts/Pretendard-Light.woff') format('woff');
// }

// @font-face {
// 	font-family: 'Pretendard';
// 	font-weight: 200;
// 	src: local('Pretendard ExtraLight'), url('../fonts/Pretendard-ExtraLight.woff2') format('woff2'), url('../fonts/Pretendard-ExtraLight.woff') format('woff');
// }

// @font-face {
// 	font-family: 'Pretendard';
// 	font-weight: 100;
// 	src: local('Pretendard Thin'), url('../fonts/Pretendard-Thin.woff2') format('woff2'), url('../fonts/Pretendard-Thin.woff') format('woff');
// }