.mission {
    &__date {
        &-box {
            margin-top: 4rem;
            margin-bottom: 2.4rem;

            .container {
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        &--title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 2.1rem;
            font-weight: 700;
            color: #0a0a0a;
        }

        &--btn {
            width: 2.4rem;
            height: 2.4rem;
            background-position: center;
            background-repeat: no-repeat;

            &.prev {
                background-image: url(../images/ico/ico_pre.svg);

                &:disabled  {
                    background-image: url(../images/ico/ico_pre_d.svg);
                    cursor: auto;
                }
            }

            &.next {
                background-image: url(../images/ico/ico_next.svg);

                &:disabled  {
                    background-image: url(../images/ico/ico_next_d.svg);
                    cursor: auto;
                }
            }
        }
    }

    &__category {
        padding-top: 3.2rem;
        color: #0a0a0a;

        .title {
            text-align: center;
            font-weight: normal;
            font-size: 2rem;
        }

        .content {
            width: 20rem;
            margin: 2rem auto 0 auto;
        }
    }

    &__exercise {
        padding-top: 3.2rem;
        color: #0a0a0a;
        text-align: center;

        .bg {
            width: 8.8rem;
            height: 8.8rem;
            display: inline-block;
            position: relative;
            background-color: #f2f2f2;
            border-radius: 50%;
        }
        .ico {
            width: 5.2rem;
            display: inline-block;
            margin: 0;
            transform: translate(0.2rem, 1.8rem);
        }

        .title {
            display: block;
            margin-top: 1.2rem;
            font-size: 2.4rem;
        }

        .mission__record {
            margin-top: 2.4rem;
            padding: 4rem 0.4rem;
            border: 1px solid #bbb;
            border-radius: 0.8rem;

            .title {
                font-size: 1.9rem;
                font-weight: 500;
            }
        }
    }

    &__record {
        color: #0a0a0a;
        text-align: center;

        .title {
            margin-bottom: 2.8rem;
            font-size: 2rem;
        }

        .field {
            width: 20rem;
            display: inline-block;

        }
        .personal__input-box {
            margin-left: 0;
            position: relative;

            &::after {
                content: '분';
                min-width: auto;
                font-size: 2.4rem;
                color: #0a0a0a;
                position: absolute;
                right: 0;
                margin: 0;
            }
        }

        .personal__input[type="number"] {
            padding-bottom: 0.3rem;
            margin-right: -2.2rem;
            font-size: 2.4rem;
            text-align: center;
        }

        .disc {
            margin-top: 1.2rem;
            font-size: 1.6rem;
            line-height: 1.4;
        }

        .count-box {
            width: 20rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 auto;
            border-bottom: 1px solid #bbb;

            input.count {
                width: 100%;
                font-size: 2.4rem;
                font-weight: 600;
                text-align: center;
                border: 0;
            }

            .btn {
                width: 4.4rem;
                height: 4.4rem;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 2.8rem;
            }

            .btn__minus {
                background-image: url(../images/ico/order_minus.svg);
            }

            .btn__plus {
                background-image: url(../images/ico/order_plus.svg);
            }
        }
    }

    &__info {

        &--bg {
            padding: 5.6rem 0.4rem;
            background: linear-gradient(rgba(255,255,255,1) 90%, rgba(255,255,255,0));
        }
            
        .title-h2 {
            display: block;
            text-align: center;
            font-size: 2.4rem;
            line-height: 1.4;
            font-weight: normal;
        }

        .title {
            margin-bottom: 1.8rem;
            font-size: 2.4rem;
            line-height: 1.25;
            font-weight: 500;
        }

        .sub-title {
            margin-bottom: 2.4rem;
            font-size: 1.9rem;
            line-height: 1.32;
        }

        &--txt {
            padding-top: 2.8rem;
            margin-top: 2.8rem;
            font-size: 2rem;
            border-top: 1px solid #cfcfcf;
            line-height: 1.4;
        }

        .guide-box {
            margin: 2rem 0;
        }

        .txt-box {
            padding: 2.2rem 1.5rem;
            border-radius: 0.8rem;
            background-color: #f1f6fa;

            .txt {
                font-size: 1.8rem;
                line-height: 1.33;
                color: #878fa1;
            }
        }

        &--list {
            margin-top: 3.7rem;
        }
    }

    &__exam {
        margin: -1rem;
        font-size: 0;

        li{
            display: inline-block;
            width: calc(50% - 2rem);
            margin: 1rem;

            &:first-child {
                .mission__exam-box {
                    border: 4px solid #00a972;
                    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
                }
            }
        }

        &-box {
            padding: 2.4rem 0.4rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px solid #bbb;
            border-radius: 0.8rem;
        }

        .ico {
            width: 4.8rem;
        }

        .txt {
            display: block;
            margin-top: 1.8rem;
            font-size: 1.8rem;
        }
    }
}

.mission-list {
    li + li {
        margin-top: 2.5rem;
    }

    .list-wrap {
        display: flex;
        align-items: center;

        .thum {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 6rem;
            height: 6rem;
            margin-right: 1.2rem;
            border-radius: 50%;
            background-color: #f2f2f2;

            img {
                width: 3.7rem;
            }
        }

        .txt-area {
            flex: 1;

            .title {
                font-size: 1.9rem;
                font-weight: 600;
            }

            .desc {
                margin-top: 0.4rem;
                font-size: 1.8rem;
                color: #868b96;
            }
        }
    }
}

.swiper-ms-info {
    margin-top: -1.2rem;

    .swiper-container {
        padding: 2rem 0 2.6rem;
    }

    .card {
        overflow: hidden;
        background-color: #fff;
        border-radius: 1.2rem;
        box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.1);
    }
    
}

.swiper-report {
    .swiper-container {
        padding: 2rem 0 2.6rem;
    }

    .personal__card + .personal__card {
        margin-top: 0;
    }

    .product__items {
        margin-top: 2.4rem;
    }
}

.font-green {
    color: var(--selex--green);
}

.my-mission {
    &-swiper {

        .swiper-pagination-outer {
            position: absolute;
            bottom: 0;
            width: 100%;
        }

        .swiper-pagination {
            overflow: hidden;
            position: relative;
            bottom: -.8rem;
            height: .4rem;
            background-color: #dfdfdf;
            border-radius: .4rem;
            
        }
        
        .swiper-pagination-progressbar-fill {
            // border-bottom-right-radius: .2rem;
            // border-top-right-radius: .2rem;
            background-color: #9a9a9a;
        }
        
        .card-box {
            margin-top: 2rem;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.6rem;
    }

    &__current {
        font-weight: 700;
    }

    &--detail {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: .8rem;
        font-size: 1.4rem;
    }
    
    &__current2 {
        font-weight: 700;
        color: #0a0a0a;
    }

    &__total {
        color: #9a9a9a;
    }

    &__footer {
        margin-top: 2rem;

        > ul {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &--li {
            flex: 1;

            + li {
                    margin-left: .8rem;
            }

            &.is-active {
                .my-mission__certify {
                    background-color: #5d93ff;
                }

                .my-mission__certify--title {
                    color: #fff;
                }

                .my-mission__certify--point {

                    .point {
                        display: block;
                    }

                    img {
                        display: none;
                    }
                }
            }
        }
    }

    &__certify {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: #e9e9e9;
        border-radius: .8rem;
        font-size: 1.4rem;


        &::after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }

        &--text {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        &--title {
            color: #636363;
        }

        &--point {
            margin-top: .4rem;
            
            .point {
                display: none;
                font-weight: 700;
                color: #ffde00;
                line-height: 2.4rem;
            }

            img {
                width: 2.4rem;
            }
        }

        &.more {
            background-color: #fff;
            // border: 1px dotted bbb;
            background-image: url(../images/ico/btn_more1.svg);
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    // 프로그래스
    &__progressbar {
        display: inline-block;
        width: 100%;
        height: 1.2rem;
        margin-top: 1.2rem;
        border-top-right-radius: .6rem;
        border-bottom-right-radius: .6rem;
        background-color: #e3e3e3;
    }

    &__gauge {
        display: inline-block;
        height: 100%;
        background-color: #5d93ff;
        border-top-right-radius: .6rem;
        border-bottom-right-radius: .6rem;
    }

    &__point-box {

        background-image: url(../images/ico/ico_point.svg);
        background-position: top 2rem right 2rem;
        background-repeat: no-repeat;
        background-size: 6rem;

        .card__body {
            padding-bottom: 1.3rem;
            margin-bottom: 1.6rem;
            border-bottom: 1px solid #bbb;
        }

        .point {
            font-size: 2.4rem;
            font-weight: 700;
        }

        .info {
            font-size: 1.3rem;
        }
    }

    &__point-wrap {
        margin-top: 3.2rem;
        margin-bottom: 4rem;
        text-align: center;

        .title {
            font-size: 2.2rem;
            font-weight: 600;
        }

        .my-mission__point {
            display: inline-block;
            margin: 2.7rem 0 4rem 0;
        }
    }

    &__point {
        font-size: 3rem;

        &::after {
            content: '';
            display: inline-block;
            width: 2.7rem;
            height: 2.7rem;
            background-image: url(../images/ico/ico_point_blk.svg);
            background-repeat: no-repeat;
            background-size: 2.7rem;
            transform: translateY(2px);
        }
    }
    
    &__state {
        padding-top: 3.2rem;
        margin-bottom: 2rem;
        border-top: 1px solid #cfcfcf;
        text-align: center;

        .ico {
            font-size: 4.8rem;
        }

        .txt{
            display: inline-block;
            margin-top: 0.8rem;
            font-size: 1.8rem;
            word-break: keep-all;
        }
    }

    &__snack-msg {
        padding: 2.4rem;
        border-radius: 1.2rem;
        background-color: #fff;

        .title-box {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1.6rem;
        }

        .title {
            // width: 17rem;
            font-size: 2.2rem;
            line-height: 1.3;
            word-break: keep-all;
        }

        .ico {
            width: 5.6rem;
        }

        .txt {
            font-size: 1.6rem;
            line-height: 1.3;
            color: #636363;
        }
    }

    &__habit {
        margin: 0 -1rem;
        font-size: 0;
        
        li {
            display: inline-block;
            width: calc(50% - 2rem);
            margin: 0 1rem;
        }

        .personal__card {
            text-align: center;
            padding-left: .5rem;
            padding-right: .5rem;

            &.is-full {
                display: block;
                width: 100%;
            }

            &:disabled {
                color: inherit;
                cursor: default;
            }
        }

        .section--title-box {
            justify-content: center;
        }

        .ico {
            width: 6rem;
            margin: 1.6rem auto 0 auto;
        }
    }

    &__meal {
        margin-top: 2rem;

        .personal__point--contents {
            // .thumb {
            //     background-image: url(../images/ico/ico_no_meal.svg);
            // }

            &.uncertified {
                .text-box {
                    color: #b8b8b8;
                }
            }
        }

        .state {
            img {
                width: 4.4rem;
                margin-right: 1.2rem;
            }

            .btn--arrow {
                margin-right: 0.8rem;
            }
        }
    }

    &__selex {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.2rem;

        .card-txt {
            width: calc(100% - 6.8rem);
        }

        .ico {
            width: 4.8rem;
        }
    }

    &__walk {
        margin-top: 2.8rem;

        .btn--line--black {
            background-image: url(../images//ico/ico_next.svg);
            background-size: 1.2rem;
            background-repeat: no-repeat;
            background-position: center right 1.6rem;
            text-align: center;
            padding: 1.2rem 0;
            font-size: 1.4rem;
            
            &:disabled {
                color: #9a9a9a;
                border-color: #e9e9e9;
                background-color: #e9e9e9;
                cursor: auto;
            }

            &.is-disabled {
                background-image: none;
            }

            b, .txt {
                display: inline-block;
            }
        }
    }

    &__weight {
        .date {
            font-size: 1.6rem;
            color: #636363;
        }

        .weight {
            margin-top: 2.5rem;
            text-align: center;

            .txt {
                display: block;
                margin-bottom: 0.6rem;
                font-size: 2.2rem;
    
                span {
                    font-size: 1.5rem;
                }
            }

            img {
                max-width: 26rem;
            }
        }

        .weight-change {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 3.7rem;

            .current {
                margin-right: 1.3rem;
                font-size: 2.9rem;

                span {
                    font-size: 2rem;
                }
            }
            
            img {
                max-width: 13rem;
                min-width: 8rem;
            }

            .contrast {
                position: relative;
                font-size: 2.2rem;
                margin-left: 2.4rem;

                span {
                    font-size: 1.5rem;
                }

                &::before {
                    content: '';
                    position: absolute;
                    left: -2.2rem;
                    top: 1px;
                    width: 2.4rem;
                    height: 2.4rem;
                    background-size: 2.4rem;
                }
            }

            &.--gain {
                .contrast {
                    color: #ff4b4b;

                    &::before{
                        background-image: url(../images/ico/ico_g_up.svg);
                    }
                }
            }

            &.--loss {
                .contrast {
                    color: #296df6;

                    &::before{
                        background-image: url(../images/ico/ico_g_down.svg);
                    }
                }
            }

            &.--same {
                .contrast {
                    margin-left: 1.3rem;
                    color: #ffb941;
                }
            }
        }
    }

    &__history {
        position: relative;

        .title {
            margin: 3.2rem 0;
            font-size: 2.4rem;
            font-weight: normal;
            text-align: center;

            .btn--refresh {
                transform: translate(0, -9px);
            }
        }

        .disc {
            display: block;
            margin-top: 1.2rem;
            font-size: 1.6rem;
            color: #636363;
        }

        .btn-nodata {
            position: absolute;
            bottom: 0;
        }
    }
}

.report-rank {

    &__list {
        margin-top: 2rem;

        li + li {
            margin-top: 1.6rem;
        }
    }

    &__box {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .thum {
            width: 9rem;
            height: 9rem;
            min-width: 9rem;
            min-height: 9rem;
            position: relative;
            overflow: hidden;
            background-color: #fff5fb;
        }

        .badge {
            padding: 0.4rem 0.6rem;
            position: absolute;
            top: 0;
            left: 0;
            font-size: 1.1rem;
            color: #fff;
            background-color: #636363;

            &.--black {
                background-color: #0a0a0a;
            }

            &.--red {
                background-color: #ff4646;
            }
        }

        .txt {

            span:first-child {
                display: block;
                margin-bottom: 0.8rem;
            }

            margin-left: 1.6rem;
            font-size: 2rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .title {
            font-weight: 600;;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .disc {
            font-size: 1.6rem;
            font-weight: normal;
            color: #9a9a9a;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.exercise-rank {
    margin-top: 2rem;

    li + li {
        margin-top: 1.2rem;
    }

    &__box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .thum {
        width: 6rem;
        height: 6rem;
        background-color: #f2f2f2;
        border-radius: 1.6rem;
    }

    .ico {
        width: 4rem;
        display: inline-block;
        transform: translate(1rem, 1rem);
    }

    .category {
        flex: 1;
        display: block;
        margin-left: 1.6rem;
        font-size: 2rem;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .kcal {
        font-size: 2rem;
    }

}

.text-emphasis {
    display: inline-block;
    &::after {
        content: '';
        display: block;
        height: 0.8rem;
        margin-top: -0.8rem;
        background-color: #fff127;
    }
}

.font-red {
    color: #FF4B4B;
}

.font-blue {
    color: #296df6;
}

.graph-footer  {
    margin-top: 2.4rem;

    .category {
        margin: 0 -0.8rem;
        font-size: 0;
        text-align: center;

        li {
            display: inline-block;
            margin: 0 0.8rem;
        }
        .title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 0;
            font-size: 1.6rem;
            font-weight: normal;

            &::before {
                content: '';
                display: inline-block;
                width: 1.6rem;
                height: 1.6rem;
                border-radius: 50%;
                margin-right: 0.4rem;
            }
        }
    }

    &.--meal {
        .title {
            &.is-shortage {
                &::before {
                    background-color: #FFB941;
                }
            }

            &.is-overflow {
                &::before {
                    background-color: #FF4B4B;
                }
            }

            &.is-adequate {
                &::before {
                    background-color: #2CCC98;
                }
            }
        }
    }

    &.--exercise {
        .title {
            &.--base-kcal {
                &::before {
                    background-color: #FFDE00;
                }
            }

            &.--burn-kcal {
                &::before {
                    background-color: #BE85FF;
                }
            }
        }
    }

    &.--mission {
        .title {
            &.--meal {
                &::before {
                    background-color: #6195ff;
                }
            }
            &.--selex {
                &::before {
                    background-color: #2ccc98;
                }
            }
            &.--exercise {
                &::before {
                    background-color: #be85ff;
                }
            }
            &.--walk {
                &::before {
                    background-color: #ffb941;
                }
            }
        }
        
    }

    .info {
        padding: 0.8rem;
        margin-top: 2rem;
        background-color: #fff;
        border-radius: 0.8rem;
        border: 1px solid #cfcfcf;
        font-size: 0;

        li {
            display: inline-block;
            width: calc(33.3333% - 1.6rem);
            margin: 0.8rem;
            text-align: center;

            .title {
                font-size: 1.4rem;
                color: #0a0a0a;

                &::before {
                    content: '';
                    display: inline-block;
                    width: 1rem;
                    height: 1rem;
                    border-radius: 50%;
                    transform: translateX(-4px);
                }

                &.is-shortage {
                    &::before {
                        background-color: #FFB941;
                    }
                }
    
                &.is-overflow {
                    &::before {
                        background-color: #FF4B4B;
                    }
                }
    
                &.is-adequate {
                    &::before {
                        background-color: #2CCC98;
                    }
                }
            }
        }
    }
}

@media all and (max-width:340px){
    
    .graph-footer{
        .category {
            margin: 0 -2rem;
        }
    }
}

.history-list {
    li + li {
        margin-top: 3.2rem;
    }
}

.selex-info {
    margin: 0 -0.4rem;
    font-size: 0;

    li {
        display: inline-block;
        width: calc(33% - 0.8rem);
        margin: 0 0.4rem;
        border: 1px solid #cfcfcf;
        border-radius: 0.8rem;
    }

    &__box {
        padding: 1.6rem 0.4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .ico {
        display: block;
        width: 2rem;
    }

    .capacity {
        margin: 0.4rem 0 1rem 0;
        font-size: 2.4rem;
    }

    .cate-txt {
        display: block;
        font-size: 1.3rem;
        color: #9a9a9a;
    }
}

.exercise-list {
    margin-top: 3.2rem;

    li + li {
        margin-top: 0.8rem;
    }

    &__box {
        padding: 1.6rem;
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        border: 1px solid #cfcfcf;
        border-radius: 0.8rem;
    }

    &__info {
        .category {
            font-size: 2rem;
            font-weight: 600;
        }

        .time {
            display: block;
            margin-top: 0.8rem;
            margin-bottom: 1.6rem;
            font-size: 1.6rem;
            color: #636363;
        }

        .kcal {
            font-size: 2rem;
            font-weight: 600;
        }
    }

    &__point {
        .ico {
            width: 4.4rem;
            margin: 0 auto;
        }

        .point {
            display: block;
            padding: 0.6rem 1.2rem;
            margin-top: 1.5rem;
            background-color: #00a972;
            border-radius: 1.6rem;
            color: #fff;
            font-size: 1.6rem;
            font-weight: 600;
        }
    }
}

.body-composition {
    .user--choice {
        margin-bottom: 2.4rem;
        width: auto;
        white-space: nowrap;
        overflow-x: auto;
        margin-left: -2rem;
        padding-left: 2rem;
        margin-right: -2rem;
        padding-right: 2rem;
        padding-bottom: .4rem;

        &--panner {
            // height: 32rem;
            position: relative;

            &::after {
                content: '';
                display: block;
                padding-bottom: calc(50% + 1.4rem);
            }
        }

        &--item {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #fff;

            &.is-active {
                display: block;
            }
        }
    }
}

.weight-list {
    li + li {
        margin-top: 0.8rem;
    }

    &__box {
        padding: 1.6rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: #fff;
        border: 1px solid #cfcfcf;
        border-radius: 0.8rem;
    }

    .ico {
        width: 4rem;
    }

    .info {
        flex: 1;
        margin-left: 0.8rem;
        color: #0a0a0a;

        .way {
            display: block;
            margin-bottom: 0.4rem;
            font-size: 1.6rem;
        }
        .date {
            font-size: 1.4rem;
            color: #636363;
        }
    }

    .weight {
        font-size: 2rem;
    }
}

.walk-graph {
    .walk-txt {
        margin-bottom: 2.8rem;
        font-size: 1.8rem;
        text-align: center;
        
        strong {
            font-size: 2.4rem;
        }
    }
}

.walk-info {
    margin-top: 3.2rem;

    li + li {
        margin-top: 0.8rem;
    }

    &__box {
        padding: 1.6rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid #cfcfcf;
        border-radius: 0.8rem;
    }

    .ico {
        width: 2rem;
        display: block
    }

    .txt {
        flex: 1;
        display: block;
        margin-left: 0.4rem;
        font-size: 1.6rem;
    }

    .figure {
        font-size: 2rem;
    }
}

.walk-detail {
    li {
        padding: 2rem 0;
        border-bottom: 1px solid #cfcfcf;

        &:first-child {
            border-top: 1px solid #cfcfcf;
        }
    }

    &__box {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .subject {
        font-size: 1.8rem;
    }

    .check {
        font-size: 1.8rem;

        &.--uncheck {
            color: #9a9a9a;
        }
    }

    .contrast {
        margin-top: 1.2rem;

        .subject {
            font-size: 1.6rem;
            font-weight: 600;
            color: #636363;
        }

        .figure {
            font-size: 1.6rem;
            font-weight: 600;

            &::before {
                content: '';
                display: inline-block;
                width: 1.6rem;
                height: 1.6rem;
                background-size: 1.6rem;
                transform: translate(0, 2px);
            }

            &.--decrease {
                color: #296df6;

                &::before {
                    background-image: url(../images/ico/ico_g_down.svg);
                }
            }

            &.--increase {
                color: #ff4b4b;

                &::before{
                    background-image: url(../images/ico/ico_g_up.svg);
                }
            }

            &.--zero {
                color: #00a972;

                &::before{
                    background-image: none;
                    width: 1.4rem;
                    height: 0.2rem;
                    background-color: #00a972;
                    vertical-align: middle;
                    transform: none;
                    margin-right: 0.2rem;
                    margin-top: -0.1rem;
                }
            }
        }
    }
}

@media (max-width: 420px) {
    .my-mission__walk {
        .btn-area.is-half {
            display: block;
            .btn {
                + .btn {
                    margin-left: 0;
                    margin-top: .6rem;
                }
            }
        }
    }
}

.balance {
    &--body {
        width: 50%;
        min-width: 28rem;
        margin: auto;

        &-dl {
            display: flex;
            justify-content: space-between;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            margin: -.6rem;
            margin-top: 3.2rem;
        }

        .balance-detail {
            &:nth-child(even) {
                justify-content: flex-end;
            }
        }
    }

    &-detail {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: calc(50% - 1.2rem);
        font-size: 1.4rem;
        color: #0a0a0a;
        margin: .6rem;

        dt {

            &::before {
                content: '';
                overflow: hidden;
                display: inline-block;
                width: 1.6rem;
                height: 1.6rem;
                margin-right: .8rem;
                border-radius: 50%;
                background-color: #bbb;
                vertical-align: middle;
            }
        }

        dd {
            margin-left: 2rem;
        }

        &.is-lack {
            dt {
                &::before {
                    background-color: #ffc746;
                }
            }
        }

        &.is-adequate {
            dt {
                &::before {
                    background-color: #53c5f8;
                }
            }
        }

        &.is-excess {
            dt {
                &::before {
                    background-color: #ff81c4;
                }
            }
        }
    }

    &-target {
        display: block;
        margin-top: .8rem;
        text-align: center;
        font-size: 1.4rem;
        color: #636363;
    }

    &--footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: .8rem 1.2rem;
        margin-top: 1.4rem;
        background-color: #f2f2f2;
        border-radius: .4rem;
        font-size: 1.1rem;
        color: #0a0a0a;

        &.is-weekly {
            justify-content: flex-end;

            .is-target,
            .is-average {
                &::before {
                    content: '';
                    display: inline-block;
                    width: 0.8rem;
                    border-top: 2px dotted #ddd;
                    vertical-align: middle;
                    margin-right: 0.4rem;
                    background-color: transparent;
                    border-radius: initial;
                    height: auto;
                    overflow: visible;
                }
            }
            .is-target {
                &::before {
                    border-color: #09c272;
                }
            }
            .is-average {
                &::before {
                    border-color: #bbb;
                }
            }
            .is-intake {
                &::before {
                    background-color: #ff8e81;
                }
            }
            .is-consumption {
                &::before {
                    background-color: #8b90ff;
                }
            }
        }
    }

    &--info {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &__btn {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #0a0a0a;
            line-height: 1;

            &::after {
                content: '';
                display: inline-block;
                width: 1.6rem;
                height: 1.6rem;
                margin-left: .4rem;
                background-image: url(../images/ico/ico_mag.svg);
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                vertical-align: middle;
            }
        }

        &__list {
            &::before {
                content: '';
                overflow: hidden;
                display: inline-block;
                width: .8rem;
                height: .8rem;
                margin-right: .4rem;
                border-radius: 50%;
                background-color: #bbb;
                vertical-align: middle;
            }

            + .balance--info__list {
                margin-left: 1.2rem;
            }

            &.is-lack {
                &::before {
                    background-color: #ffc746;
                }
            }

            &.is-adequate {
                &::before {
                    background-color: #53c5f8;
                }
            }

            &.is-excess {
                &::before {
                    background-color: #ff81c4;
                }
            }
        }
    }
}

@media (max-width: 360px) {
    .balance--body {
        width: 100%;
        max-width: 100%;
    }
}

.timeline {
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        font-size: 1.6rem;
        color: #0a0a0a;
    }

    &__date {
        font-size: 1.3rem;
        color: #636363;
    }

    &__body {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1.2rem;
        margin-top: .8rem;
        background-color: #fff;
        border-radius: .8rem;
        border: 1px solid #bbb;
        font-size: 1.4rem;
        color: #0a0a0a;
    }

    &__thumb {
        overflow: hidden;
        width: 8rem;
        height: 8rem;
    }

    &__box {
        position: relative;
        padding-left: 30px;
        // padding-bottom: 4rem;
        padding-bottom: 2rem;

        &.is-hide {
            display: none;
            animation: timelineBoxHide .2s both;
        }

        &.is-active {
            display: block;
            animation: timelineBoxBlock .2s both;
        }

        &::before {
            content: '';
            display: inline-block;
            // border-right: 2px dotted #bbb;
            height: calc(100% - .6rem);
            overflow: hidden;
            position: absolute;
            left: .6rem;
            top: .6rem;
            background-image: url(../images/dot_line.png);
            width: 1px;
            background-size: contain;
        }

        &::after {
            content: '';
            overflow: hidden;
            display: inline-block;
            width: 1.4rem;
            height: 1.4rem;
            background-color: #bbb;
            border-radius: 50%;
            position: absolute;
            top: 0.2rem;
            left: 0;
            border: 2px solid #fff;
            box-shadow: 0 0 0 1px #bbb;
        }

        &.is-selex {
            &::after {
                background-color: #00a972;
                box-shadow: 0 0 0 1px #00a972;
            }

            .timeline__body {
                border-color: #00a972;
            }
        }
        &.is-health {
            &::after {
                background-color: #aa72fe;
                box-shadow: 0 0 0 1px #aa72fe;
            }

            .timeline__body {
                border-color: #aa72fe;
            }
        }
        &.is-meal {
            &::after {
                background-color: #5d93ff;
                box-shadow: 0 0 0 1px #5d93ff;
            }

            .timeline__body {
                border-color: #5d93ff;
            }
        }
        &.is-etc {
            &::after {
                background-color: #636363;
                box-shadow: 0 0 0 1px #636363;
            }

            .timeline__body {
                border-color: #636363;
            }
        }
        &.is-walking {
            &::after {
                background-color: #ff973b;
                box-shadow: 0 0 0 1px #ff973b;
            }

            .timeline__body {
                border-color: #ff973b;
            }
        }
    }

    &__lists {
        margin-top: 2rem;
    }

    &__card {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &.--text {
            .desc {
                margin-left: 2rem;
            }
        }

        &.--thumb {
            .timeline__text {
                
                margin-left: 1.6rem;

                .title,
                .desc {
                    display: block;
                }

                .desc {
                    margin-top: .8rem;
                }
            }
        }
    }

    &__btn-box {
        overflow: auto;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-left: -2rem;
        margin-right: -2rem;
        font-size: 0;
        white-space: nowrap;

        .inner {

            &.is-active {
                position: fixed;
                top: 76px;
                width: 100%;
                max-width: 64rem;
                padding: 2rem;
                margin-left: -2rem;
                background: #f2f2f2;
                z-index: 4;
            }
        }

        .btn {
            padding: .9rem 1.2rem;
            font-size: 1.2rem;
            background-color: #fff;
            border: 1px solid transparent;
            border-radius: 1.6rem;

            + .btn {
                margin-left: .8rem;
            }

            &.is-all {
                border-color: #0a0a0a;
                color: #0a0a0a;

                &.is-active {
                    background-color: #0a0a0a;
                    color: #fff;
                }
            }

            &.is-meal {
                border-color: #5d93ff;
                color: #5d93ff;

                &.is-active {
                    background-color: #5d93ff;
                    color: #fff;
                }
            }
            &.is-selex {
                border-color: #00a972;
                color: #00a972;

                &.is-active {
                    background-color: #00a972;
                    color: #fff;
                }
            }
            &.is-health {
                border-color: #aa72fe;
                color: #aa72fe;

                &.is-active {
                    background-color: #aa72fe;
                    color: #fff;
                }
            }
            &.is-walking {
                border-color: #ff973b;
                color: #ff973b;

                &.is-active {
                    background-color: #ff973b;
                    color: #fff;
                }
            }
            &.is-etc {
                border-color: #636363;
                color: #636363;

                &.is-active {
                    background-color: #636363;
                    color: #fff;
                }
            }
        }
    }

    &__inner {
        top: 0;
        z-index: 2;
        background: #f2f2f2;
        padding-bottom: 1rem;

        .is-active {
            position: fixed;
            top: 74px;
            width: 100%;
            max-width: 64rem;
            padding-top: 2.1rem;
            padding-left: 2rem;
            margin-left: -2rem;
            padding-right: 2rem;
            margin-right: -2rem;
        }
    }
}


// 220817 미확정분
.my-mission--succese {
    &--lists {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        color: #0a0a0a;
    }

    &--detail {
        
        .day {
            font-size: .9rem;
        }

        .graph {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 6rem;
            
            margin-top: .6rem;
            // border: 4px solid #bbb;
            border-radius: 50%;
            // width: 3.7rem;
            // border: 5px solid #bbb;

            position: relative;
            box-shadow: inset 0 0 0 10px #e9e9e9;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                display: block;
                background-image: url(../images/bg/bg_weekly_none.svg);
                background-size: cover;
                background-repeat: no-repeat;
            }

            // &::after {
            //     content: '';
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     right: 0;
            //     bottom: 0;
            //     width: 100%;
            //     height: 100%;
            //     display: block;
            //     border: 10px solid transparent;
            //     transform: rotate(-45deg);
            //     border-radius: 50%;
            // }

            &::before {
                content: '';
                display: block;
                padding-top: 100%;
            }

            .date {
                font-size: 1.1rem;
                font-weight: 700;
            }
        }

        &.is-sun {
            color: #ff6161;
        }

        &.is-sat {
            color: #327ef2;
        }

        // &.step00 {
        //     .graph {
        //         &::after {
        //             border-color: transparent;
        //         }
        //     }
        // }
        // &.step01 {
        //     .graph {
        //         &::after {
        //             border-right-color: #41dfac;
        //         }
        //     }
        // }
        // &.step02 {
        //     .graph {
        //         &::after {
        //             border-right-color: #41dfac;
        //             border-bottom-color: #bc8fff;
        //         }
        //     }
        // }
        // &.step03 {
        //     .graph {
        //         &::after {
        //             border-right-color: #41dfac;
        //             border-bottom-color: #bc8fff;
        //             border-left-color: #ffa555;
        //         }
        //     }
        // }
        // &.step04 {
        //     .graph {
        //         &::after {
        //             border-right-color: #41dfac;
        //             border-bottom-color: #bc8fff;
        //             border-left-color: #ffa555;
        //             border-top-color: #6b9cff;
        //         }
        //     }
        // }

        .succese {
            display: block;
            margin-top: .8rem;
            font-size: 1.2rem;
            color: #0a0a0a;

            b {
                // font-size: 1.4rem;
                // color: #6b9cff;
                color: #636363;
            }
        }

        &.completion {
            &--one {
                .graph {
                    &::after {
                        background-image: url(../images/bg/bg_weekly_one.svg);
                    }
                }
            }

            &--two {
                .graph {
                    &::after {
                        background-image: url(../images/bg/bg_weekly_two.svg);
                    }
                }
            }

            &--three {
                .graph {
                    &::after {
                        background-image: url(../images/bg/bg_weekly_three.svg);
                    }
                }
            }

            &--four {
                .graph {
                    &::after {
                        background-image: url(../images/bg/bg_weekly_four.svg);
                    }
                }
            }
                                       
            &--one--two {
                .graph {
                    &::after {
                        background-image: url(../images/bg/bg_weekly_one_two.svg);
                    }
                }
            }

            &--one--three {
                .graph {
                    &::after {
                        background-image: url(../images/bg/bg_weekly_one_three.svg);
                    }
                }
            }

            &--one--four {
                .graph {
                    &::after {
                        background-image: url(../images/bg/bg_weekly_one_four.svg);
                    }
                }
            }
                                        
            &--two--three {
                .graph {
                    &::after {
                        background-image: url(../images/bg/bg_weekly_two_three.svg);
                    }
                }
            }

            &--two--four {
                .graph {
                    &::after {
                        background-image: url(../images/bg/bg_weekly_two_four.svg);
                    }
                }
            }
                                        
            &--three--four {
                .graph {
                    &::after {
                        background-image: url(../images/bg/bg_weekly_three_four.svg);
                    }
                }
            }
                                        
            &--one--two--three {
                .graph {
                    &::after {
                        background-image: url(../images/bg/bg_weekly_one_two_three.svg);
                    }
                }
            }

            &--one--two--four {
                .graph {
                    &::after {
                        background-image: url(../images/bg/bg_weekly_one_two_four.svg);
                    }
                }
            }

            &--one--three--four {
                .graph {
                    &::after {
                        background-image: url(../images/bg/bg_weekly_one_three_four.svg);
                    }
                }
            }

            &--two--three--four {
                .graph {
                    &::after {
                        background-image: url(../images/bg/bg_weekly_two_three_four.svg);
                    }
                }
            }

            &--all {
                .graph {
                    &::after {
                        background-image: url(../images/bg/bg_weekly_all.svg);
                    }
                }
            }
        }
    }

    .card__header {
        border-bottom: 1px solid #bbb;
        padding-bottom: 1.2rem;
    }
    
    .my-mission__current {
        font-size: 1.6rem;
    }
}

@media (max-width: 500px) {
    .my-mission--succese--detail .graph {
        width: 5rem;
    }
}

.donut-progrees {
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &__frame {
        stroke: #f2f2f2;
        stroke-width: 12px;
    }

    &.--meal {
        height: 20rem;

        .donut-progrees {
            &__con {
                width: 20rem;
                height: 20rem;
            }
        
            &__circle {
                stroke: #6195ff;
                stroke-linecap: round;
                stroke-dashoffset: 591;
                stroke-dasharray: 591;
            }
        }
    }

    &.--exercise {
        height: 20rem;

        .donut-progrees {
            &__con {
                width: 20rem;
                height: 20rem;
            }
        
            &__circle {
                

                &:nth-child(2) {
                    // stroke: #be85ff;
                    // stroke-dashoffset: -591;
                    // stroke-dashoffset: 591;
                    stroke: #be85ff;
                stroke-linecap: round;
                stroke-dashoffset: 591;
                stroke-dasharray: 591;
                }

                &:nth-child(3) {
                    // stroke: #ffde00;
                    // stroke-dashoffset: -591;
                    // stroke-dashoffset: 591;
                    
                    stroke: #ffde00;
                stroke-linecap: round;
                stroke-dashoffset: 591;
                stroke-dasharray: 591;
                }
            }
        }
    }

    &.--exercise-top {
        height: 20rem;

        .donut-progrees {
            &__con {
                width: 20rem;
                height: 20rem;
            }
        
            &__circle {
                &:nth-child(2) {
                    stroke-dashoffset: 591;
                    stroke-linecap: round;
                }

                &:nth-child(3) {
                    stroke-dashoffset: 591;
                    stroke-linecap: round;
                }

                &:nth-child(4) {
                    stroke-dashoffset: 591;
                    stroke-linecap: round;
                }
            }
        }
    }

    &.--achieve {
        height: 24rem;

        .donut-progrees {
            &__svg {
                &:first-child {
                    width: 24rem;
                    height: 24rem;

                    .donut-progrees__circle {
                        stroke: #ffb941;
                        stroke-dashoffset: 717;
                        stroke-dasharray: 717;
                        stroke-linecap: round;
                    }
                }

                &:nth-child(2) {
                    width: 20rem;
                    height: 20rem;

                    .donut-progrees__circle {
                        stroke: #be85ff;
                        stroke-dashoffset: 591;
                        stroke-dasharray: 591;
                        stroke-linecap: round;
                    }
                }
                &:nth-child(3) {
                    width: 16rem;
                    height: 16rem;

                    .donut-progrees__circle {
                        stroke: #2ccc98;
                        stroke-dashoffset: 465;
                        stroke-dasharray: 465;
                        stroke-linecap: round;
                    }
                }
                
                &:nth-child(4) {
                    width: 12rem;
                    height: 12rem;

                    .donut-progrees__circle {
                        stroke: #6195ff;
                        stroke-dashoffset: 340;
                        stroke-dasharray: 340;
                        stroke-linecap: round;
                    }
                    
                }
            }
            
        }
    }

    &.--walk  {
        height: 20rem;

        .donut-progrees {
            &__con {
                width: 20rem;
                height: 20rem;
            }

            &__block {
                height: 20rem;
            }
        
            &__circle {
                stroke: #ffb941;
                stroke-linecap: round;
                stroke-dashoffset: 591;
                stroke-dasharray: 591;
                stroke-linecap: round;
            }
        }
    }

    &__con {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &--hr {
        width: 12rem;
    }

    &__box {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 12px solid #e9e9e9;
        }
    }

    &__number {
        font-size: 2.4rem;
        // text-align: center;
    }

    &__title {
        margin-bottom: .4rem;
        font-size: 1.6rem;
        color: #0a0a0a;
        // text-align: center;
    }

    &__sub {
        font-size: 1.6rem;
    }

    &__num {
        font-weight: 700;
    }

    &__svg {
        position: absolute;
        width: 100%;
        height: 100%;
        fill: none;
        transform: rotate(-90deg);
    }

    &__circle {
        // stroke: #ff8e81;
        stroke-width: 12px;
        // stroke-dasharray: 640;
        // stroke-dashoffset: 639;
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
        transition: 2s stroke-dashoffset;
    }

    &--target {
        padding: .2rem 1.2rem;
        margin-top: 1rem;
        background-color: #bbb;
        border-radius: 1.3rem;
        font-size: 1.6rem;
        color: #fff;
    }

    .text-box {
        text-align: center;
        line-height: 2.3rem;

        .img {
            width: 5.6rem;
            // height: 5.6rem;
        }

        &.percent {
            font-size: 2.4rem;
            line-height: 1;

            .num {
                font-size: 3rem;
            }
        }
    }
}

@keyframes timelineBoxHide {
    0% {
        opacity: 1;
        display: block;
        
    }

    100% {
        opacity: 0;
        display: none;
    }
}

@keyframes timelineBoxBlock {
    0% {
        opacity: 0;
        display: none;
    }

    100% {
        opacity: 1;
        display: block;
    }
}

.horizontal-progress {
    position: relative;

    &__lists {
        padding-top: 4.6rem;
        font-size: 0;

        li {
            display: inline-block;
            height: 1.2rem;
            color: #fff;
            text-align: center;
            vertical-align: middle;
            border-left: 3px solid #fff;


            &:first-child {
                width: 13%;
                background-color: #00a972;
                border-top-left-radius: .6rem;
                border-bottom-left-radius: .6rem;
                border-left: 0;
            }

            &:nth-child(2) {
                width: 24%;
                background-color: #5d93ff;
            }
            
            &:nth-child(3) {
                width: 10%;
                background-color: #fc9a42;
            }
            
            &:nth-child(4) {
                width: 27%;
                background-color: #ff81c4;
            }

            &:last-child {
                width: 26%;
                background-color: #ff5d5d;
                border-top-right-radius: .6rem;
                border-bottom-right-radius: .6rem;
            }
        }
    }

    &__text {
        display: inline-block;
        position: absolute;
        top:0;
        // width: 3.6rem;
        // height: 3.6rem;
        padding: .5rem .8rem;
        border-radius: .3rem;
        background-color: #bbb;
        font-size: 1.6rem;
        color: #fff;
        text-align: center;
        // margin-bottom: .4rem;
        transform: translateX(-50%);

        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-bottom: 2px solid transparent;
            border-top: 8px solid #00a972;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
        }

        &.is-step01 {
            background-color: #00a972;

            &::before {
                border-top-color: #00a972;
            }
        }

        &.is-step02 {
            background-color: #5d93ff;

            &::before {
                border-top-color: #5d93ff;
            }
        }

        &.is-step03 {
            background-color: #fc9a42;

            &::before {
                border-top-color: #fc9a42;
            }
        }

        &.is-step04 {
            background-color: #ff81c4;

            &::before {
                border-top-color: #ff81c4;
            }
        }

        &.is-step05 {
            background-color: #ff5d5d;

            &::before {
                border-top-color: #ff5d5d;
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn {
            flex: 1;
        }
    }

    &__btn {
        width: 4rem;
        height: 4rem;
        margin-left: .8rem;
        background-image: url(../images/ico/btn_more2.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.dash-info {
    font-size: 1.1rem;
    color: #0a0a0a;

    &::before {
        content: '';
        display: inline-block;
        width: .8rem;
        border-top: 2px dotted #bbb;
        vertical-align: middle;
        margin-right: .4rem;
    }

    + .dash-info {
        margin-left: 1.2rem;
    }

    &.is-target {
        &::before {
            border-color: #09c272;
        }
    }

    &.is-average {
        &::before {
            border-color: #bbb;
        }
    }
}

.is-fixedfoot {
    position: relative;
    width: 100%;
    z-index: 3;

    .btn-area {
        position: fixed;
        bottom: 10.8rem;
        width: calc(100% - 4rem);
        max-width: calc(64rem - 4rem);
    }
}

.date__select {
    max-height: 50vh;
    overflow: auto;
    &--item {
        position: relative;
        + .date__select--item {
            margin-top: 1.8rem;
        }
    }

    &--input {
        + .date__select--label {
            display: block;
            padding: 1rem 0;
            font-size: 1.5rem;
        }

        &:checked {
            + .date__select--label {
                color: #00a972;
            }
        }
    }
}

// .filter__selet {
//     >ul > li {
//         + li {
            
//         }

//         input[type="radio"] + label {
//             display: block;
//             padding: 1rem 0;
//             font-size: 1.5rem;
//         }

//         input[type="radio"]:checked + label {
//             color: #00a972;
//         }
//     } 
// }

.date__change {
    .btn {
        display: block;
        font-size: 1.8rem;
        font-weight: 600;
        width: 100%;
        text-align: left;
        // &::after {
        //     content: '';
        //     display: block;
        //     width: 2.4rem;
        //     height: 2.4rem;
        //     background-image: url(../images/ico/ico_input_calendar.svg);
        //     background-size: cover;
        //     background-position: center;
        //     background-repeat: no-repeat;
        // }
    }
}

.category-box__swiper {
    .user--choice {
        .swiper-slide {
            width: auto;
            margin: 0;
        }
    }
}


.view--pc {

    .body-composition .user--choice {
       
        /* width */
        &::-webkit-scrollbar {
            // width: 4px;
            height: 6px;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            background: #fff; 
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #eee; 
            overflow: hidden;
            border-radius: 3px;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #00a972; 
        }
    }
}

.view--mo {
    .body-composition .user--choice {
        overflow: auto;
    }
}

.walk-detail__box {
    .point {
        padding: 0.6rem 1.2rem;
        margin-left: 3.6rem;
        background-color: #00a972;
        border-radius: 1.6rem;
        color: #fff;
        font-size: 1.6rem;
        font-weight: 600;
        margin-right: 1rem;
    }
}

.my-mission__eat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: -.4rem;
    margin-right: -.4rem;
    margin-top: 2rem;

    .item {
        flex: 1;
        margin: .4rem;

        &.is-success {
            .box {
                background-color: #e9e9e9;
                border-color: #e9e9e9;
            }

            .thumb {
                background-color: #fff;
            }

            .btn > span::after {
                display: none;
            }

            .state {
                display: block;
            }
        }
    }

    .thumb {
        font-size: 0;
        background-color: #f2f2f2;;
        overflow: hidden;
        border-radius: .4rem;
    }

    .box {
        padding: 0.8rem;
        background-color: #fff;
        border: 1px solid #cfcfcf;
        border-radius: 0.8rem;
        overflow: hidden;
        position: relative;
    }

    .state {
        position: absolute;
        top: 1.2rem;
        right: 1.2rem;
        width: 30%;
        height: 30%;
        display: none;
    }

    .btnarea {
        display: block;
        margin-top: .8rem;
        text-align: center;
    }

    .btn {
        width: 100%;
        padding: 0;
        
        &:disabled {
            color: #9a9a9a;
            cursor: auto;
        }

        > span {
            position: relative;
    
            &::after {
                content: '';
                display: inline-block;
                position: absolute;
                width: .4rem;
                height: .4rem;
                top: -.1rem;
                right: -.4rem;
                border-radius: 50%;
                background-color: #ff4b4b;
            }
    
        }
    }
} 

.fixed__btn-area--top {
    padding-top: 7rem;


    .container {
        position: fixed;
        top: 105px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-self: center;
        background-color: #fff;
        transition: .3s;
        z-index: 3;
        border-bottom: 1px solid #d4d4d4;
        background: #fff;
    }

    .btn-area {
        padding: 1.2rem 0;
        margin: 0 -0.4rem;
        font-size: 0;

        li {
            display: inline-block;
            margin: 0 0.4rem;

            &.is-active {
                .btn {
                    background-color: #0a0a0a;
                    font-weight: 600;
                    color: #fff;
                }
            }
        }
        

        
    }

    .btn {
        height: 4.4rem;
        padding: 0 1.6rem;
        background-color: #e9e9e9;
        color: #636363;
        font-size: 1.6rem;
        border-radius: 2.2rem;
    }
}

.donut-progrees .text-box {
    .top {
        display: block;
        margin-top: 0.4rem;
        font-size: 2rem;
        font-weight: 700;
    }
}