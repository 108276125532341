.floating {
    &-box {
        position: fixed;
        bottom: 0;
        right: 0.8rem;
        z-index: 2;
        transform: translateY(-1rem);
        transition: transform 0.5s cubic-bezier(0.17, 0.04, 0.03, 0.94);
        max-width: 5.6rem;

        &.is-active {
            transform: translateY(-8.8rem);
        }

        &.no-chat {
            .floating__menu {
                display: none;
            }
            .floating__btn.open {
                display: none;
            }
        }
    }

    &__btn {
        display: block;
        z-index: 1;
        width: 5.6rem;
        height: 5.6rem;
        padding: 1.2rem;
        background-color: #0a0a0a;
        border-radius: 50%;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);

        + .floating__btn {
            margin-top: 1.2rem;
        }

        &.open {
            position: relative;
            z-index: 2;
            transition: all .2s;
            background-color: rgba(10,10,10,.8);

            &.is-active {
                transform: rotate(135deg);
                background-color: rgba(10,10,10,1);
            }
        }

        &.scroll-top {
            background-color: rgba(255,255,255,.8);

            img {
                transform: rotate(180deg);
            }
        }
    }

    &__menu {
		
		position: relative;
		width: 100%;
		height: 100%;
		bottom: -5.6rem;
		margin: 0 auto;

        .floating__btn {
            display: flex;
            position: absolute;
            opacity: 0;
            bottom: 0;
            border: none;
            outline: none;
            color: #fff;
            justify-content: flex-end;
            transform: scale(0.9);
            transition: all 0.3s cubic-bezier(0.17, 0.04, 0.03, 0.94);
        }

        &.is-active {
            bottom: -.6rem;

            .floating__btn {
                transform: scale(1);
                opacity: 1;

                &:nth-child(1) {
                    bottom: 16rem;
                }

                &:nth-child(2) {
                    bottom: 9rem;
                }

                &:nth-child(3) {
                    bottom: 2rem;
                }
            }
        }
	}
}

// pc접속시
// .view--pc {
//     .floating-box.is-active {
//         transform: translateY(-1rem);
//     }
// }

// @media (min-width: 640px) {
//     .view--pc {
//         .floating-box {
//             display: block;
//             -webkit-animation: display-block-transition .5s both;
//             animation: display-block-transition .5s both;
//             // right: calc(50% - 38.2rem);
//             right: calc(50% - 31.2rem);
//         }
//     }
// }

// @media (max-width: 640px) {
//     .view--pc {
//         .floating-box {
//             right: 0.8rem;
//         }
//     }
// }

// @media (min-width: 1230px) {
//     .floating-box {
//         display: none !important;
//         opacity: 0;
//     }
// }

// @keyframes display-block-transition {
//     0% {
//         opacity: 0;
//     }

//     75% {
//         opacity: 0;
//     }

//     100% {
//         opacity: 1;
//     }
// }