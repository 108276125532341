
.toggle {
	&-box {
		position: relative;
		margin-top: 4px;
		font-size: 0;

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			border: 1px solid #dcdce4;
			border-radius: 4px;
		}

		&.is-false {
			.toggle__btn:first-child {
				background-color: #fcecea;
				color: #dc3545;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;

				&::after {
					border-color: red;
					border-top-left-radius: 4px;
					border-bottom-left-radius: 4px;
					z-index: 1;
				}
			}
			
		}

		&.is-true {
			.toggle__btn:last-child {
				background-color: #eafbe7;
				color: #35a336;

				&::after {
					border-color: #35a336;
					border-top-right-radius: 4px;
					border-bottom-right-radius: 4px;
				}
			}
		}
	}

	&__btn {
		width: 90px;
		height: 38px;
		position: relative;
		overflow: hidden;
		color: #5e5e66;
		font-size: 12px;
		font-weight: bold;
		transition: all .2s ease-in-out;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left :0;
			bottom: 0;
			right:0;
			border: 1px solid transparent;
			transition: all .2s ease-in-out;
		}

		+ .toggle__btn {
			margin-left: -1px;
		}
	}
}
