// 가이드 레이아웃용 스타일 입니다.

.example {

    &__body {
        display: block;
        background-color: #fff;
    }

    &_wrap {
        margin-left: 240px;
        margin-top: 50px;
    }

    &__header {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        max-height: 50px;
        z-index: 2;
        background-color: var(--primary-700);

        &--nav {
            li {
                display: inline-block;

                a {
                    display: inline-block;
                    color: #fff;
                    line-height: 30px;
                    font-size: 1.6rem;
                    padding: 0 15px;
                }

                + li {
                    margin-left: 10px;
                }
            }
        }

        &--container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px;
        }
    }

    &__logo {
        font-size: 25px;

        a {
            color: #fff;
        }
    }

    &__aside {
        position: fixed;
        top: 50px;
        left: 0;
        bottom: 0;
        z-index: 2;
        width: 240px;
        overflow: hidden;
        background: #fff;
        border-right: 1px solid #ddd;
        padding: 50px 0;

        &--title {
            display: block;
            position: relative;
            overflow: hidden;
            font-size: 18px;
            color: #222;
            font-weight: 400;
            padding: 0 40px;
        }
    }

    &__lnb {
        margin-top: 20px;
        overflow: auto;
        height: calc(100vh - 190px);

        &::-webkit-scrollbar {
            width: 0;
            transition: width .2s ease-in-out;
            -webkit-transition: width .2s ease-in-out;
            margin-right: -20px;
        }

        &:hover {
            &::-webkit-scrollbar {
                width: 8px;
                transition: width .2s ease-in-out;
                -webkit-transition: width .2s ease-in-out;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
                border-radius: 4px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: rgba(173, 181, 189, .5);
                border-radius: 4px;
            }
        }

        &--link {
            position: relative;
            display: block;
            overflow: hidden;
            padding: 6px 40px;
            font-size: 14px;
            color: #777;
            line-height: 1.8;
        }
    }

    &__main {
        padding-bottom: 4rem;

        &--title {
            font-size: 30px;
            font-weight: normal;
            padding: 50px 30px 10px;
        }
    }

    &__container {
        padding: 0 30px;
        overflow: hidden;

        + .example__container {
            margin-top: 40px;
            &::before {
                content: '';
                display: block;
                height: 8px;
                background-color: var(--neutral-100);
                margin-left: -30px;
                margin-right: -30px;
            }
        }
        
        &--header {
            // padding-bottom: 15px;
            border-bottom: 1px solid #eee;
        }

        &--title {
            font-size: 18px;
            font-weight: normal;
            padding: 30px 0 10px;
        }

        &--desc {
            font-size: 14px;
        }

        &--body {
            position: relative;
            padding: 10px 0;
            margin-top: 15px;
        }
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
    }

    &__col {
        flex: 1 0 0%;
    }

    &__gnb {
        ul {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__chart-box {
        .title {
            margin-top: 2rem;
            font-size: 2rem;
            text-align: center;
        }
    }
}

.example--h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.guide__color-box {
    width: 100%;

    + .guide__color-box {
        margin-top: 6rem;
    }
}

.guide__color-list {
    margin-top: 20px;
    display: flex;

    li {
        width: 20rem;
        display: flex;
        align-items: center;
        
        // + li {
        //     margin-top: 10px;
        // }
    }

    &__thumb {

        &.color-swich {
            width: 50px;
            height: 50px;
            border-radius: 4px;

            &.bg-primary--grenen {
                background-color: var(--selex--green);
            }
            &.bg-primary--yellow {
                background-color: var(--selex--yellow);
            }
            &.bg-primary--blue {
                background-color: var(--selex--blue);
            }

            &.bg-gray--600 {
                background-color: var(--selex--gray-600);
            }

            &.bg-gray--500 {
                background-color: var(--selex--gray-500);
            }

            &.bg-gray--400 {
                background-color: var(--selex--gray-400);
            }

            &.bg-gray--300 {
                background-color: var(--selex--gray-300);
            }

            &.bg-gray--200 {
                background-color: var(--selex--gray-200);
            }

            &.bg-gray--100 {
                background-color: var(--selex--gray-100);
            }

            &.bg-danger {
                background-color: var(--selex--danger);
            }
            &.bg-sucesse {
                background-color: var(--selex--sucesse);
            }
            

            // &.neutral--900 {
            //     background-color: var(--neutral-900);
            // }
    
            // &.neutral--800 {
            //     background-color: var(--neutral-800);
            // }
    
            // &.neutral--700 {
            //     background-color: var(--neutral-700);
            // }
    
            // &.neutral--600 {
            //     background-color: var(--neutral-600);
            // }
    
            // &.neutral--500 {
            //     background-color: var(--neutral-500);
            // }
    
            // &.neutral--400 {
            //     background-color: var(--neutral-400);
            // }
    
            // &.neutral--300 {
            //     background-color: var(--neutral-300);
            // }
    
            // &.neutral--200 {
            //     background-color: var(--neutral-200);
            // }
    
            // &.neutral--150 {
            //     background-color: var(--neutral-150);
            // }
    
            // &.neutral--100 {
            //     background-color: var(--neutral-100);
            // }
    
            // &.neutral--0 {
            //     background-color: var(--neutral-0);
    
            //     &.border {
            //         border: 1px solid #dcdce4;
            //     }
            // }
    
            // &.primary--700 {
            //     background-color: var(--primary-700);
            // }
            // &.primary--600 {
            //     background-color: var(--primary-600);
            // }
            // &.primary--500 {
            //     background-color: var(--primary-500);
            // }
            // &.primary--200 {
            //     background-color: var(--primary-200);
            // }
            // &.primary--100 {
            //     background-color: var(--primary-100);
            // }
    
            // &.success--700 {
            //     background-color: var(--success-700);
            // }
            // &.success--600 {
            //     background-color: var(--success-600);
            // }
            // &.success--500 {
            //     background-color: var(--success-500);
            // }
            // &.success--200 {
            //     background-color: var(--success-200);
            // }
            // &.success--100 {
            //     background-color: var(--success-100);
            // }
    
            // &.danger--700 {
            //     background-color: var(--danger-700);
            // }
            // &.danger--600 {
            //     background-color: var(--danger-600);
            // }
            // &.danger--500 {
            //     background-color: var(--danger-500);
            // }
            // &.danger--200 {
            //     background-color: var(--danger-200);
            // }
            // &.danger--100 {
            //     background-color: var(--danger-100);
            // }
    
            // &.warning--700 {
            //     background-color: var(--warning-700);
            // }
            // &.warning--600 {
            //     background-color: var(--warning-600);
            // }
            // &.warning--500 {
            //     background-color: var(--warning-500);
            // }
            // &.warning--200 {
            //     background-color: var(--warning-200);
            // }
            // &.warning--100 {
            //     background-color: var(--warning-100);
            // }
    
            // &.secondary--700 {
            //     background-color: var(--secondary-700);
            // }
            // &.secondary--600 {
            //     background-color: var(--secondary-600);
            // }
            // &.secondary--500 {
            //     background-color: var(--secondary-500);
            // }
            // &.secondary--200 {
            //     background-color: var(--secondary-200);
            // }
            // &.secondary--100 {
            //     background-color: var(--secondary-100);
            // }
    
            // &.alternative--700 {
            //     background-color: var(--alternative-700);
            // }
            // &.alternative--600 {
            //     background-color: var(--alternative-600);
            // }
            // &.alternative--500 {
            //     background-color: var(--alternative-500);
            // }
            // &.alternative--200 {
            //     background-color: var(--alternative-200);
            // }
            // &.alternative--100 {
            //     background-color: var(--alternative-100);
            // }
        }

        

    }

    &__desc {
        margin-left: 12px;

        .title {
            color: #212134;
            font-size: 1.6rem
        }

        span {
            display: block;
            margin-top: 4px;
            font-size: 1.4rem;
            color: #666687;
            line-height: 16px;
        }
    }

    &.btn-swich-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        li {
            +li {
                margin-top: 0;
                margin-left: 6px;
            }
        }
    }

    
}

.font-size-xxxl {
    font-size: var(--selex-xxxl)
}
.font-size-xxl {
    font-size: var(--selex-xxl)
}
.font-size-xl {
    font-size: var(--selex-xl)
}
.font-size-lg {
    font-size: var(--selex-lg)
}
.font-size-md {
    font-size: var(--selex-md)
}
.font-size-sm {
    font-size: var(--selex-sm)
}
.font-size-xs {
    font-size: var(--selex-xs)
}
.font-size-xxs {
    font-size: var(--selex-xxs)
}
.font-size-xxxs {
    font-size: var(--selex-xxxs)
}

// @mixin headingStyle {
//     border: 1px solid #0a0a0a;
//     padding: .4rem .8rem;
//     color: #000;
//     margin: .4rem;
//     cursor: auto;
// }



// @mixin font_size($size, $color) {
//     @if $size == 'xxxs' {
//         font-size: var(--selex-xxxs);
//         // color: var(--selex--green);
//     }

//     @else if $size == 'xxs' {
//         font-size: var(--selex-xxs);
//     }
    
//     @else if $size == 'xs' {
//         font-size: var(--selex-xs);
//     }

//     @else if $size == 'sm' {
//         font-size: var(--selex-sm);
//     }

//     @else if $size == 'lg' {
//         font-size: var(--selex-lg);
//     }

//     @else if $size == 'xl' {
//         font-size: var(--selex-xl);
//     }

//     @else if $size == 'xxl' {
//         font-size: var(--selex-xxl);
//     }

//     @else if $size == 'xxxl' {
//         font-size: var(--selex-xxxl);
//     }

//     @else {
//         font-size: var(--selex-md);
//     }
// }


// .title-h1 {
//     @include headingStyle;
//     @include font_size('xxxl');
// }

// .title-h2 {
//     @include headingStyle;
//     @include font_size('xxl');
// }

// .title-h3 {
//     @include headingStyle;
//     @include font_size('lg');
// }

// .title-h4 {
//     @include headingStyle;
//     @include font_size('');
// }

// .title-h5 {
//     @include headingStyle;
//     @include font_size('sm');
// }

// .title-h6 {
//     @include headingStyle;
//     @include font_size('xs');
// }

// .aco_re {

//     &--panner {
//         overflow: hidden;
//         height: 0;
//         transition: height .2s;
//     }

//     &--contents{
//         padding: 2rem 1rem;
//     }
// }

// .accordion {
//     position: relative;
//     z-index: 1;

//     &__btn {
//         position: relative;
//         z-index: 0;
//         width: 100%;
//         min-height: 5rem;
//         padding: 1.6rem 4rem 1.6rem 2rem;
//         border-bottom: 1px solid #bbb;
//         background-color: #fff;
//         font-size: 1.6rem;
//         text-align: left;
//         cursor: pointer;

//         &::before {
//             content: 'Q';
//             display: inline-block;
//             color: #21a272;
//             font-weight: 700;
//             margin-right: .6rem;
//             vertical-align: top;
//         }

//         &::after {
//             display: block;
//             content: '';
//             position: absolute;
//             top: 50%;
//             right: 2rem;
//             width: 1.2rem;
//             height: 1.2rem;
//             transform: rotate(180deg);
//             margin-top: -.6rem;
//             transform-origin: center;
//             transition: all .5s;
//             background-image: url(../images/ico/ico_arrow.svg);
//             background-size: cover;
//         }

//         &[aria-expanded="true"] {
//             background-color: #f2f2f2;
//             font-weight: 700;

//             &::after {
//                 transform: rotate(0deg);
//             }
//         }

//         > span {
//             display: inline-block;
//             width: calc(100% - 5rem);
//             word-break: keep-all;
//         }
        
//     }
// }

// .accordion-header {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
// }

// .accordion__panel {
//     overflow: hidden;
//     height: 0;
//     transition: height .2s;
// }
