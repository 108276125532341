
// Primary Color
$middle-green: #35a336 !default;
$green: #005729 !default;

// Secondary Color
$light-green: #cdec6f !default;
$light-blue: #8ae3fe !default;
$light-orange: #ffba31 !default;

// Universal Color
$red: #dc3545 !default;
$white: #fff !default;
$black: #222 !default;

// Gray Color
$light: #f3f3f3 !default;
$light-gray: #f3f3f3 !default;
$line-gray: #e8e8e8 !default;
$light-gray: #b5b5b5 !default;
$middle-gray: #888 !default;
$gray: #555 !default;

// Spacing
$spacing-xs: -2px !default;
$spacing-sm: -1px !default;
$spacing-md: 0 !default;
$spacing-lg: 1px !default;
$spacing-xl: 2px !default;
$spacing-xxl: 3px !default;

// line-height
$line-heigth-xs: 1 !default;
$line-heigth-sm: 1.2 !default;
$line-heigth-md: 1.4 !default;
$line-heigth-lg: 1.6 !default;
$line-heigth-xl: 1.8 !default;
$line-heigth-xxl: 2 !default;

// border-color
$border-color-primary: rgb(33, 0, 122);
$border-color-danger: rgb(196, 0, 0);
$border-color-warning: rgb(173, 55, 0);
$border-color-dark: rgb(0, 0, 0);
$border-color-info: rgb(0, 131, 148);
$border-color-success: rgb(0, 146, 44);
$border-color-secondaty: rgb(129, 0, 101);

// border-radius
$border-round-xs: 4px !default;
$border-round-sm: 5px !default;
$border-round-md: 6px !default;
$border-round-lg: 7px !default;
$border-round-xl: 8px !default;
$border-round-xxl: 10px !default;

// box-shadow
$box-shadow-xs: 0px 0px 2px $gray !default;
$box-shadow-sm: 2px 2px 4px $gray !default;
$box-shadow-md: 3px 3px 6px $gray !default;
$box-shadow-lg: 4px 4px 8px $gray !default;
$box-shadow-xl: 5px 5px 10px $gray !default;
$box-shadow-xxl: 6px 6px 12px $gray !default;

:root {
    --gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --body-font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, sans-serif;
    --body-font-size: 62.5%;
    --body-font-weight: 400;
    --body-line-height: $line-heigth-md;
    --body-color: $black;
    --body-bg: #fff;

    // 컬러 정의
    --neutral-900: #212121;
    --neutral-800: #353535;
    --neutral-700: #444447;
    --neutral-600: #5e5e66;
    --neutral-500: #7e7e8f;
    --neutral-400: #a5a5ba;
    --neutral-300: #c0c0cf;
    --neutral-200: #dcdce4;
    --neutral-150: #eaeaef;
    --neutral-100: #f6f6f9;
    --neutral-0: #ffffff;
    --neutral-boxshadow: #eaeaef;

    // --primary-700: #08800a;
    --primary-700: #00aa6c;
   
    --primary-600: #35a336;
    --primary-500: #5cb176;
    --primary-200: #c6f0c2;
    --primary-100: #eafbe7;
    --primary-boxshadow: #aecc53;

    --success-700: #005729;
    --success-600: #008551;
    --success-500: #5cb176;
    --success-200: #c6f0c2;
    --success-100: #eafbe7;
    --success-boxshadow: #5cb176;

    --danger-700: #d02b20;
    --danger-600: #dc3545;
    --danger-500: #ee5e52;
    --danger-200: #f5c0b8;
    --danger-100: #fcecea;
    --danger-boxshadow: #ee5e52;

    --warning-700: #f69220;
    --warning-600: #ffaa00;
    --warning-500: #ffba31;
    --warning-200: #fae7b9;
    --warning-100: #fdf4dc;
    --warning-boxshadow: #ffba31;

    --secondary-700: #028bb6;
    --secondary-600: #06aadd;
    --secondary-500: #4bc6ec;
    --secondary-200: #b8e1ff;
    --secondary-100: #eaf5ff;
    --secondary-boxshadow: #4bc6ec;

    --alternative-700: #7ea012;
    --alternative-600: #9cc125;
    --alternative-500: #c2e35a;
    --alternative-200: #d3ee7d;
    --alternative-100: #ebfeaf;
    --alternative-boxshadow: #c2e35a;


    --boxshadow-primaty: #aecc53;
    --boxshadow-gray: rgba(0,0,0,.15);

    // container, row
    --gutter-x: 1rem ;
    --gutter-y: 1.5rem ;
    --container-padding-x: --gutter-x * .5 ;
    --row-margin-x: --gutter-x * -1 ;
    // $row-margin-x: $gutter-x * -.5 !default;


    // 크기 정의
    --selex-zero: 0;
    --selex-xxxl: 2.4rem;
    --selex-xxl: 2rem;
    --selex-xl: 1.9rem;
    --selex-lg: 1.8rem;
    --selex-md: 1.6rem;
    --selex-sm: 1.5rem;
    --selex-xs: 1.4rem;
    --selex-xxs: 1.3rem;
    --selex-xxxs: 1.2rem;
    --body-font-size: 1.4rem;


    // 221219 컬러 신규 정의
    --selex--green: #00a972;
    --selex--yellow: #ffde00;
    --selex--blue: #296df6;
    --selex--gray-100: #f2f2f2;
    --selex--gray-200: #e9e9e9;
    --selex--gray-300: #bbb;
    --selex--gray-400: #9a9a9a;
    --selex--gray-500: #636363;
    --selex--gray-600: #0a0a0a;
    --selex--danger: #ff6161;
    --selex--sucesse: #4194f3;
}