.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;

    &-box {
        display: flex;
        padding-left: 0;
        list-style: none;
    }

    &-item {

        // S : 텍스트 형태가 들어올 경우 활성화
        // &:first-child {
        //     .page-link {
        //         width: 100px;
        //         text-align: left;
        //         display: flex;
        //         justify-content: flex-start;
        //         align-items: center;
        //     }
        // }
    
        // &:last-child {
        //     .page-link {
        //         width: 80px;
        //         display: flex;
        //         justify-content: flex-end;
        //         align-items: center;
        //     }
        // }
        // E : 텍스트 형태가 들어올 경우 활성화
    
        + .pagination-item {
            margin-left: 2px;
        }
    }

    &-link {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        // padding: 0.375rem 0.75rem;
        color: #353535;
        text-decoration: none;
        background-color: #fff;
        border: 1px solid transparent;
        border-radius: 4px;
        transition: color .15s ease-in-out,
        background-color .15s ease-in-out,
        box-shadow .15s ease-in-out;
        font-size: 1.2rem;

        i {
            width: 32px;
            vertical-align: middle;
            text-align: center;
            font-weight: inherit;
        }
        
        &:hover,
        &:focus {
            z-index: 2;
            color: #08800a;
            text-decoration: underline;
            box-shadow: 0 1px 4px 0 rgba(26, 26, 67, 0.1);
            font-weight: bold;

            i {
                color: #08800a;
            }
        }
    }
}
