.section__search {
    &--form {
        // padding-top: 1.2rem;
        padding-top: 3.2rem;

        .title {
            font-size: 2.4rem;
            line-height: 3.2rem;
            font-weight: 400;
            color: #0a0a0a;
        }

        .field {
            margin-top: 3rem;

            &.is-active {
                .btn--clear {
                    display: block;
                }
            }
        }
        
        .control {
            margin-top: 0;
        }

        input[type="text"] {
            padding: 1.3rem 0;
            font-size: 1.5rem;
        }
        .btn {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 2.8rem;
            height: 2.8rem;
            padding: 0;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 0;
        }

        .btn--search {
            background-size: cover;
            background-image: url(../images/ico/ico_search.svg);
        }

        .btn--clear {
            display: none;
            background-size: 1.6rem;
            background-image: url(../images/ico/ico_input_del.svg);
            right: 2.8rem;
        }

        .tag__btn {
            width: 100%;
            height: auto;
            position: relative;
            margin-top: 1.6rem;
            padding: 1.4rem 2.2rem;
            text-align: left;
            border-radius: 4rem;
            transform: translateY(0);
            background-color: #fff;
        }
    }


    &--banner {

        .container {
            padding-left: 0;
            padding-right: 0;
    
            li {
                font-size: 0;
                + li {
                    margin-top: 1rem;
                }
            }
        }
    }

    &--title {

        display: block;

        .title {
            font-size: 2.4rem;
            font-weight: 700;
        }

        .desc {
            margin-top: 1.2rem;
            font-size: 1.5rem;
            color: #636363;
        }
    }

   
}

.search__popular {
    margin-top: 3rem;

    li {
        border-bottom: 1px solid #cfcfcf;

        a {
            display: block;
            padding: 1rem 0;
            font-size: 0;

            &::before {
                content: '';
                display: inline-block;
                font-size: 1.9rem;
                font-weight: 700;
                color: #00a972;
                margin-right: 1.2rem;
                vertical-align: middle;
                min-width: 1.3rem;
            }

            span {
                font-size: 1.6rem;
                font-weight: 600;
                color: #0a0a0a;
                vertical-align: middle;
                line-height: 2rem;
            }
        }
        

        &:nth-child(1) {
            a {
                &::before {
                    content: '1';
                }
            }
        }

        &:nth-child(2) {
            a {
                &::before {
                    content: '2';
                }
            }
        }

        &:nth-child(3) {
            a {
                &::before {
                    content: '3';
                }
            }
        }

        &:nth-child(4) {
            a {
                &::before {
                    content: '4';
                }
            }
        }

        &:nth-child(5) {
            a {
                &::before {
                    content: '5';
                }
            }
        }
    }
}


.accordion__btn .count {
    width: auto;
    color: #ff4646;
}

.count.color-red {
    width: auto;
    color: #ff4646;
}

.accordion__search {
    &--result {
        border-top: 0;

        .accordion__btn {
            // padding: 2rem 0 0 0;
            margin-top: 1rem;
            border-bottom: 0;
            font-size: 2rem;
            font-weight: 700;
        }
    
        .accordion__contents {
            padding-top: 1rem;
        }

        .thumb {
            overflow: hidden;
            position: relative;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: #ddd;
            font-size: 0;
            width: 100%;

            // &::before {
            //     content: '';
            //     position: absolute;
            //     top: 50%;
            //     left: 50%;
            //     min-width: 40px;
            //     min-height: 40px;
            //     margin-top: -20px;
            //     margin-left: -20px;
            //     border: 5px solid rgba(255, 255, 255, 0.5);
            //     border-right: 5px solid #00aa6c;
            //     border-radius: 50%;
            //     -webkit-animation: spinner 1s linear infinite;
            //     animation: spinner 1s linear infinite;
            //     z-index: 0;
            // }

            &::after {
                content: '';
                display: block;
                padding-top: 100%;
            }

            img {
                // position: relative;
                position: absolute;
                width: 100%;
                height: auto;
                z-index: 1;
                font-size: 0;
            }

            
            &.is-wide {
                &::after {
                    content: '';
                    display: block;
                    padding-top: 50%;
                }
            }
        }
    }
}

.accordion__search--product {
}

.accordion__search--tip {
    .more__list {
        > li {
            + li {
                margin-top: 1.4rem;
            }
        }
    }

    .text-box {
        display: block;
        padding: 1.6rem 0;

        .title {
            font-size: 1.7rem;
            color: #0a0a0a;
            font-weight: 700;
        }

        .desc {
            display: block;
            margin-top: .8rem;
            font-size: 1.5rem;
            color: #636363;

        }
    }
}

.accordion__search--banner {
}

.result-title {
    margin-top: 6rem;
    font-size: 1.8rem;
}

.is-nodata {
    &.--box {
        padding: 4rem 0;
    }
}