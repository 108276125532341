// 검색바
.field.cscenter--search {
    padding-top: 2.6rem;
    padding-bottom: 2.6rem;

     &.is-clear {
        .btn {
            &.btn-search {
                display: none;
            }

            &.btn-clear {
                display: block;
            }
        }
    }

    .control {
        overflow: hidden;
        padding-left: .8rem;
        padding-right: .8rem;
        background-color: #f2f2f2;
        border: 1px solid #bbb;
        border-radius: .4rem;
    }

    input {
        height: 4rem;
        background-color: transparent;
        border: 0;
        font-size: 1.6rem;
        color: #0a0a0a;

        &::placeholder {
            // font-size: 1.3rem;
            font-size: inherit;
            color: #9a9a9a;
        }

        &:focus {
            border: 0;
        }
    }

    .btn {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 4rem;
        height: 4rem;
        padding: 0;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 0;

        &.btn-search {
            background-size: 2.4rem;
            background-image: url(../images/ico/ico_search.svg);
        }

        &.btn-clear {
            display: none;
            background-size: 1.4rem;
            background-image: url(../images/ico/ico_input_del.svg);
        }
    }
}

// 카테고리
.tab--block-box {
    position: relative;
    z-index: 1;

    ul {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        background-color: #f2f2f2;
        border-top: 1px solid #bbb;
        border-bottom: 1px solid #bbb;
    }

    li {
        position: relative;
        border-left: 1px solid #bbb;
        box-sizing: border-box;
        width: calc((100% / 3) + 1px);
        margin-left: -1px;
        margin-bottom: -1px;
        border-top: 1px solid #bbb;

        &:first-child,
        &:nth-child(4) {
            border-left: 0;
            margin-left: 0;
            width: calc((100% / 3));
        }

        &:nth-child(-n+3) {
            border-top: 0;
            margin-top: 0;
        }

        &:last-child,
        &:nth-child(3) {
            border-right: 0;
        }

        &.is-active {
            z-index: 2;

            button {
                color: #636363;
                font-weight: 700;
            }
        }
    }

    button {
        width: 100%;
        height: 3.9rem;
        font-size: 1.3rem;
        color: #9a9a9a;
        word-break: keep-all;
        word-wrap: break-word;
    }
}

.board--accodian {
    .section--title-box {
        margin-top: 2.6rem;
    }
    .title {
        font-size: 1.6rem;
        font-weight: 700;
        color: #0a0a0a;
    }
}

.one-to-one {
    .radio-list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 1.7rem !important;
        margin-left: -.4rem;
        margin-right: -.4rem;

        .radio {
            margin-left: .4rem;
            margin-right: .4rem;

            // + .radio {
            //     margin-left: .8rem;
            // }

            input[type="radio"] + label {
                display: flex;
                justify-content: center;
                align-items: center;
                // width: 100%;
                height: 3rem;
                padding: 0 1.2rem;
                border: 1px solid #b8b8b8;
                border-radius: 1.5rem;
                font-size: 1.7rem;
                color: #0a0a0a;
                line-height: 3rem;
                // font-weight: 400;
                // text-align: center;
                cursor: pointer;
                text-align: center;
            
            }

            input[type="radio"]:checked + label {
                color: #fff;
                background-color: #0a0a0a;
                border-color: #0a0a0a;
            }

            
        }
    }

    .file--photo {
        margin-top: .8rem;
        
        &-box {
            background-color: #f2f2f2;
            border-radius: .8rem;
            border: 1px solid transparent;
            background-image: url(/assets/images/ico/ico_plus_gray_large.svg);
            background-size: 2.8rem;

            &.is-active {
                border-color: #bbb;
            }

            img {
                object-fit: cover;
            }
        }

        &__thumb {
            // border-radius: .8rem;
            border: 0;
            border-color: transparent;
        }

        &--msg {
            margin-top: 1.6rem;
            font-size: 1.2rem;
            color: #636363;
        }

        .btn--remove {
            width: 2rem;
            height: 2rem;
            top: 0.4rem;
            right: 0.4rem;
            border-radius: 50%;
            opacity: .7;
            background-size: 1rem;
        }
    }


    .textarea {
        padding:1.6rem;
        font-size: 1.5rem;
    }

    .btn-ordernum {
        width: auto;
        padding-left: 1.6rem;
        padding-right: 1.6rem; 
    }

    .title {
        // display: ;
        // margin-bottom: 1.7rem;
        font-size: 1.8rem;
    }

    .result__filter {
        margin-top: .8rem;
        border-bottom: 1px solid #bbb;

        .btn {
            position: relative;
            width: 100%;
            font-size: 1.5rem;
            color: #9a9a9a;
            text-align: left;
            padding: 1.3rem 0;
            line-height: 1.9rem;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                width: 2rem;
                height: 2rem;
                margin-top: -1rem;
                background-image: url(/assets/images/ico/btn_arr_up.svg);
                background-size: cover;
                transform: rotate(-180deg);
            }

            &.is-active {
                color: #0a0a0a;
            }
        }
    }

    .control {
        margin-top: .8rem;
    }

    input[type="text"] {
        padding: 1.3rem 0;
        font-size: 1.5rem;
    }

    .field + .field {
        margin-top: 3.2rem;
    }
}


// 알림 설정
.alarm__setting {

    &.is-section-contour {
        &::after {
            margin-top: 2rem;
        }
    }

    &.is-nobefore {
        &::before {
            display: none;
        }
    }

    .section--title-box {
        display: block;
        padding-top: 1.6rem;

        .title {
            font-size: 2rem;
            color: #0a0a0a;
            font-weight: 600;
            // display: block;
            padding: 1.6rem 0;
            border-bottom: 1px solid #bbb;
        }

        .desc {
            display: block;
            font-size: 1.5rem;
            color: #636363;
            padding: 1.6rem 0 0;
        }
    }

    &--body {

		.desc {
			font-size: 1.4rem;
			color: #636363;
		}

        .accordion-group-wrap {
            margin-top: -2rem;
            border-top: 0;
            // border-bottom: 1px solid #f2f2f2;
            // padding-bottom: 1.6rem;
        }

        .accordion__btn {
            padding-right: 5rem;
            word-break: keep-all;
            font-weight: 400;
            line-height: 2rem;
            color: #636363;
            border-bottom: 0;
            padding-bottom: 0;
            // margin-bottom: 2rem;

            &::before {
                display: none;
            }

            &::after {
                top: 2rem;
                margin-top: 0;
            }

            &[aria-expanded="true"] {
                background-color: transparent;
            }
        }

        .accordion__panel {
            margin-left: -2rem;
            margin-right: -2rem;
            background-color: transparent;
        }

        .accordion__contents {
            margin: 2rem;
            margin-top: 2rem;
            margin-bottom: 0;
            padding: 1.5rem;
            background-color: #f2f2f2;

            &::before {
                display: none;
            }
        }

        .board--dl{
            border: 1px solid #bbb;
            border-bottom: 0;
        }

        .board--dl__div dt, .board--dl__div dd {
            font-size: 1.4rem;
            // border-bottom: 0;
        }

        // .board--dl__div dd {
        //     border-bottom: 0;
        // }

        .msg-box {
            margin-top: 2rem;
            padding: 0;
            font-size: 1.4rem;

            li {
                &::before {
                    content: '*';
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: .4rem;
                }
            }
        }
        
    }

    &--link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.5rem;
        color: #636363;
        line-height: 2.3rem;
        word-break: keep-all;

        &:hover {
            color: #636363;
        }

        &::after {
            content: '';
            display: inline-block;
            width: 2rem;
            height: 2rem;
            background-image: url(../images/ico/ico_arrow_r.svg);
            background-size: cover;
        }
    }

    // + .alarm__setting {
    //     margin-top: 0;
    // }

    &--link-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        .title {
            font-size: 1.4rem;
            color: #0a0a0a;
        }

        .sub-title {
            margin-top: .4rem;
			font-size: 1.2rem;
			color: #9a9a9a;
        }

        .alarm__setting--link {
            &::after {
                content: '';
                display: inline-block;
                width: 2.4rem;
                height: 2.4rem;
                background-image: url(../images/ico/ico_setting.svg);
                background-size: contain;
            }
        }
    }
}


.apppush--service {
    // padding: 2rem;
    // margin-top: 2.4rem;
    // border: 1px solid #bbb;

    // hr {
    //     margin: 1.2rem 0;
    //     border-color: #bbb;
    // }

    .switch--small-box {
        padding: 2.4rem;
        background-color: #f2f2f2;
    }
}

.switch--small-box {
    + .alarm__setting--link-box {
        margin-top: 2.7rem;
    }
}

select + .textarea-box {
      margin-top: 0.4rem;  
}

.mypage {
    .date__choice {
        margin-top: 1.2rem;
    }

    .field{
        label {
            font-weight: normal;
        }
    }

    .control {
        margin-top: 2rem;
    }

    &__order-filter {
        padding-top: 5rem;
        background-color: #fff;

        .container {
            position: fixed;
            top: 0;
            transform: translateY(4.8rem);
            transition: .3s;
            width: 100%;
            max-width: 64rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
            background-color: #ffff;
            z-index: 3;
        }

        &.no-fixed {
            padding-top: 0;

            .container {
                position: relative;
                top: 0;
                transform: none;
                transition:none;
                width: 100%;
                max-width: 64rem;
                padding-top: 1rem;
                padding-bottom: 1rem;
                background-color: #ffff;
                z-index: 3;
            }
        }

        .msg {
            margin-top: 0.4rem;
        }
    }

    &__deli-address {
        li {
            padding: 2rem;
            box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.3);;
            border-radius: 0.8rem;
            background-color: #fff;
        }

        li + li {
            margin-top: 2rem;
        }

        .label-box {
            display: flex;
            align-items: center;
        }

        .top-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .name {
            margin-right: 0.8rem;
            font-size: 1.8rem;
            font-weight: 600;
        }

        .label {
            padding: 0.4rem 0.8rem;
            font-size: 1.3rem;
            border: 1px solid #bbb;
            border-radius: 1.6rem;
        }

        .default-deli {
            padding: 0.4rem 0.8rem;
            font-size: 1.1rem;
            color: #fff;
            border-radius: 1.6rem;
            background-color: #636363;
        }

        .address-txt {
            margin-top: 1.6rem;
            font-size: 1.5rem;
            line-height: 1.53;
        }

        .phone {
            margin-top: 1.2rem;
            font-size: 1.5rem;
            color: #9a9a9a;
        }

        .edit-btn {
            margin-top: 1.6rem;
            padding-top: 1.6rem;
            text-align: center;
            border-top: 1px solid #bbb;

            .btn {
                width: 49%;
                padding: 0;
            }

            span {
                display: inline-block;
                border-bottom: 1px solid #090909;
            }

            .btn-del {
                color: #636363;

                span {
                    border-color: #636363;
                }
            }
        }
    }

    .field.is-active input[type="text"] {
        border-color: #bbb;
    }

    .control__btn {
        margin-right: 0.8rem;
    }

    &__small-title {
        font-size: 1.6rem;
        font-weight: normal;

        .bold {
            font-weight: 600;
        }
    }

    &__inquiry {
        &--content {
            margin-top: 2rem;
        }

        .answer-label {
            display: inline-block;
            padding: 0.7rem 1rem;
            font-size: 1.4rem;
            color: #fff;
            border-radius: 1.8rem;
            background-color: #636363;

            &.is-complete {
                background-color: #00a972;
            }
        }

        .product-name {
            display: inline-block;
            margin-top: 1.2rem;
            font-size: 1.7rem;
            font-weight: normal;
        }

        .date {
            display: block;
            margin-top: 1rem;
            font-size: 1.5rem;
            font-weight: normal;
            color: #9a9a9a;
        }

        .inquiry-text {
            margin-bottom: 2.4rem;
        }

        .txt {
            margin-top: 2rem;
            font-size: 1.6rem;
            line-height: 1.63;
        }

        &--answer {
            padding-top: 2.4rem;
            border-top: 1px solid #bbb;

            img {
                width: 100% !important;
                height: auto !important;
            }
        }

        .answer-title {
            font-size: 1.8rem;
            font-weight: 600;
            color: #00a972;

            &::before {
                content: 'A.';
                display: inline-block;
                margin-right: 0.4rem;
            }
        }

        .btn-evaluate {
            > .btn {
                padding: 0;
            }

            input[type='radio'] + label {
                border-radius: 0.8rem;
                font-size: 1.4rem;
                border: 1px solid #bbb;
                background-color: #fff;

                &::before {
                    content: '';
                    display: inline-block;
                    width: 2.4rem;
                    height: 2.4rem;
                    margin-right: 0.6rem;
                    background-size: cover;
                    vertical-align: middle;
                }

                &.btn-satisfy::before {
                    background-image: url(/assets/images/ico/ico_smile_black.svg);
                } 

                &.btn-dissatisfy::before {
                    background-image: url(/assets/images/ico/ico_nodata_black.svg);
                } 
            }

            input[type='radio']:checked + label {
                border-color: #0a0a0a;
                color: #fff;
                background-color: #0a0a0a;

                &.btn-satisfy::before {
                    background-image: url(/assets/images/ico/ico_smile_white.svg);
                } 

                &.btn-dissatisfy::before {
                    background-image: url(/assets/images/ico/ico_nodata_white.svg);
                } 

            }
        }

        .accordion__btn {
            padding: 2rem 0 0 0;
            border-bottom: 0;

            &::after {
                top: 3.3rem;
            }
        }

        .accordion__btn[aria-expanded="true"], .accordion__btn[aria-expanded="false"] {
            .inquiry-title {
                width: calc(100% - 3rem);
                font-size: 1.8rem;
                font-weight: 600;
                line-height: 1.44;
    
                &::before {
                    content: 'Q.';
                    display: inline-block;
                    margin-right: 0.4rem;
                }
            }
    
            .date {
                display: block;
                margin-top: 1rem;
                font-size: 1.5rem;
                font-weight: normal;
                color: #9a9a9a;
            }
        }
    }

    &__select {
        flex: 1;
        position: relative;
        margin-left: 4.5rem;
        border-bottom: 1px solid #bbb;

        .select-input {
            width: 100%;
            // padding: 1.2rem 0;
            padding-bottom: 2px;
            font-size: 2rem;
            font-weight: 600;
            text-align: right;
            color: #0a0a0a;

            &::after {
                content: '';
                display: inline-block;
                min-width: 3rem;
                width: 2rem;
                height: 2rem;
                margin-left: 0.8rem;
                background-image: url(/assets/images/ico/ico_arrow_down.svg);
                background-size: 2rem;
                background-repeat: no-repeat;
                background-position: 100% 0;
                vertical-align: top;
            }
        }

        .value {
            font-size: 1.9rem;
            text-align: right;
        }

        &--box {
            display: none;
            position: absolute;
            z-index: 2;
            top: 5.8rem;
            left: 0;
            width: 100%;
            border-radius: 0.8rem;
            box-shadow: 0 0 0.6rem 0 rgba(0, 0, 0, 0.3);
            background-color: #fff;
            overflow: hidden;

            &.is-active {
                display: block;
            }

            li + li {
                border-top: 1px solid #bbb;
            }

            .label {
                display: block;
                width: 100%;
                padding: 1.6rem 0;
                font-size: 1.8rem;
                text-align: center;
            }

            input[type='radio']:checked + label {
                background-color: #f2f2f2;
            }
        }
    }
}

.product__check {

    li {
        padding: 2rem 0;
    }

    li + li {
        border-top: 1px solid #cfcfcf;
    }

    .product__thumb {
        width: 8rem;
    }

    .product__info {
        width: calc(100% - 8rem);
    }

    .--check {
        display: flex;
        align-items: center;
    }

    .detail__pay-box {
        width: calc(100% - 3.2rem);
        margin-top: 1.2rem;
        margin-left: auto;
    }
}

//리뷰작성
.review {
  &__create {

    .field {
        margin-top:4rem;
    }
    
    &--title {
        position: relative;
        display: inline-block;
        font-size: 1.8rem;
        font-weight: normal;
    }

    &--title.is-required::after {
        position: absolute;
        top: 0;
        right: -1.2rem;
        display: inline-block;
        content: '';
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        background-color: #ff4646;
    }
  }
}

//별점 등록
.star-point {

    display: flex;
    align-items: center;

    &--radio {

        display: inline-block;
        direction: rtl;

        input[type="radio"] + label, input[type="checkbox"] + label {
            padding-left:0;
        }
        input[type=radio] + label {
            width: 3.3rem;
            height: 3.3rem;
            background-image: url(../images/ico/ico_stats_rate_one_gray.svg);
            background-repeat: no-repeat;
        }

        input[type=radio]:checked ~ label {
            background-image: url(../images/ico/ico_stats_rate_one.svg);
        }
    }

    &--txt {
        margin-left: 1.2rem;
        font-size: 1.7rem;
        color:#9a9a9a;
        white-space: nowrap;
    }
}

.msg-bg {
    padding: 2.8rem 2rem;
    background-color: #f2f2f2;

    .msg__list {
        margin-top:0;
    }
}

.btn--detail-inquiry {
        padding: 0.6rem 0.6rem 0.6rem 2rem;
        border-radius: 0.6rem;
        background-image: url(../images/ico/ico_check_d.svg);
        background-color: #fff;
        background-size: 1.2rem;
        background-repeat: no-repeat;
        background-position: 8% 50%;
        font-size: 1.3rem;

        &.is-active {
            border:1px solid #00a972;
            background-image: url(../images/ico/ico_check.svg);
        }
}

.gift {
    &__delivery {
        margin-top: 3.2rem;

        .btn-link {
            margin: 0;
            font-size: 1.5rem;
        }
    }

    &__destination {
        margin-top: 0.4rem;
    }

    &__msg {
        padding-bottom: 2rem;
        border-bottom: 1px solid #d4d4d4;

        &--header {
            margin-bottom: 0.8rem;
        }
        .ico {
            width: 2.8rem;
            display: inline-block;
            margin-right: 0.8rem;
            vertical-align: middle;
        }
        .title {
            font-size: 1.8rem;
            font-weight: 600;
        }

        &--content {
            font-size: 1.6rem;
            line-height: 1.5;
        }

        &--box {
            padding: 2rem;
            border: 1px solid #bbb;
            border-radius: 0.8rem;
        }
    }

    &__product {
        margin-top: 2rem;

        .product__quantity {
            // margin-left: 0;

            &::before {
                display: none;
            }
        }

        // &.is-price {
        //     .product__quantity {
        //         margin-left: 0.8rem;

        //         &::before {
        //             display: inline-block;
        //         }
        //     }
        // }
    }

    &__info {
        margin-top: 2rem;
        li {
            display: flex;
            justify-content: space-between;
            font-size: 1.8rem;
        }

        li + li {
             margin-top: 1.6rem;
        }

        .cancel {
            display: block;
            text-align: right;
            
            .btn {
                font-size: 1.2rem;
                color: #9a9a9a;    
            }
        }
    }

    &__nodata {
        .is-nodata {
            background-color: transparent;
            border-radius: 0;

            &.--small {
                padding: 0;
            }
        }
    }
}


.mypage__order-filter {
    .user--choice {
        overflow: auto;
        white-space: nowrap;
    }

    // padding-left: 2rem;
    // padding-right: 2rem;

    .swiper-slide {
        width: auto !important;
    }
}


@media (min-height: 600px) {
    .view--mo {
        .modal__body {
            .date__choice {
                max-height: 40rem;
                overflow: auto;
            }
        }    
    }
}

.profile {
    &__area {
        .basic-info {
            display: flex;
            align-items: center;
            margin-bottom: 2rem;
        }
    }

    &__thum {
        width: 6.2rem;
        height: 6.2rem;
        position: relative;
        margin-right: 2rem;
        border: 1px solid #bbb;
        border-radius: 50%;
        background-color: #f2f2f2;
        overflow: hidden;

        img {
            width: 4.8rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__info {
        width: calc(100% - 8.2rem);
        
        &--rate {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;

            .btn-link {
                font-size: 1.5rem;
                color: #636363;
            }
        }

        .user-name {
            font-size: 2.2rem;

            .is-block {
                display: inline-block;
            }
            
            .link {
                &::after {
                    content: '';
                    display: inline-block;
                    width: 1.6rem;
                    height: 1.6rem;
                    margin-left: 0.4rem;
                    background-image: url(/assets/images/ico/ico_arrow_r.svg);
                    background-size: cover;
                }
            }
        }
    }

    &__box {
        display: flex;
        border: 1px solid #bbb;
        border-radius: 0.8rem;
        box-shadow: 0 0 6px rgba(0,0,0,0.2);

        li {
            width: 33.333%;
            display: inline-block;
            position: relative;
            box-sizing: border-box;
        }

        li + li {
            &::after {
                content: '';
                display: inline-block;
                position: absolute;
                top: 50%;
                width: 1px;
                height: 2rem;
                background-color: #bbb;
                transform: translateY(-50%);
            }
        }

        .box-wrap {
            padding: 1.6rem 0;
            text-align: center;
        }

        .title {
            display: block;
            margin-bottom: 1.1rem;
            font-size: 1.3rem;
        }

        .content {
            font-size: 1.9rem;
        }
    }

    &__list {

        &--title {
            margin-bottom: 1.6rem;
            font-size: 2.2rem;
            font-weight: 400;
        }

        &.--account {
            margin: 0.2rem 0;

            li:last-child {
                border-bottom: 0;
            }

            .title {
                font-size: 1.7rem;
            }
        }

        li + li {
            border-top: 1px solid #bbb;
        }

        li:last-child {
            border-bottom: 1px solid #bbb;
        }

        &.no-borderbottom {
            li:last-child {
                border-bottom: 0;
            }
        }

        .list-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 2rem 0;

            &::after {
                content: '';
                display: inline-block;
                width: 1.6rem;
                height: 1.6rem;
                margin-left: 0.4rem;
                background-image: url(/assets/images/ico/ico_arrow_r.svg);
                background-size: cover;
            }
        }

        .title {
            display: block;
            width: calc(100% - 5.6rem);
            font-size: 1.6rem;
            font-weight: 600;

            &.--employee {
                &::after {
                    content: '';
                    display: inline-block;
                    width: 4rem;
                    height: 1.8rem;
                    margin-left: 0.4rem;
                    background-image: url(/assets/images/CI_maeil.svg);
                    background-size: cover;
                    vertical-align: text-top;
                }
            }
        }

        .accordion-group-wrap {
            border-top: 0;
        }

        .accordion__btn {
            font-weight: 600;

            &::after {
                margin-top: auto;
                width: 1.5rem;
                height: 1px;
                position: absolute;
                top: 50%;
                right: 0.8rem;
                background-color: #0a0a0a;
                transition: all .3s;
                /* transform: rotate(0deg) translate(50%, -50%); */
                /* transform-origin: center; */
                margin: auto;
                display: block;
                content: '';
                transform: rotate(-90deg);
            }

            &::before {
                content: '';
                display: block;
                width: 1.5rem;
                height: 1px;
                position: absolute;
                top: 50%;
                right: 0.8rem;
                background-color: #0a0a0a;
                /* transform: rotate(0deg) translate(-50%, 0); */
                /* transition: all .5s; */
                /* transform-origin: center; */
                margin: auto;
            }
        }

        .accordion__btn[aria-expanded="true"] {
            &::after {
                // right: 0;
                transform: rotate(0deg);
                // transform-origin: top;
                background-color: transparent;
            }
        }

        .accordion__contents {
            padding: 0;
        }
    }

    &__number {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 2.8rem;
        height: 2.8rem;
        border-radius: 50%;
        font-size: 1.4rem;
        color: #fff;
        background-color: #0a0a0a;
    }

    &__setting {
        .basic-info {
            padding-bottom: 2.4rem;
            border-bottom: 1px solid #bbb;
            text-align: center
        }

        &--thum {
            display: inline-block;
            position: relative;
            margin-bottom: 1.7rem;

            .thum {
                width: 9.2rem;
                height: 9.2rem;
                position: relative;
                border: 1px solid #bbb;
                border-radius: 50%;
                background-color: #f2f2f2;
                overflow: hidden;

                img {
                    width: 5.6rem;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .btn--setting {
                width: 2.8rem;
                height: 2.8rem;
                position: absolute;
                bottom: 0;
                right: 0;
                padding: 0;
                border-radius: 50%;
                background-color: #636363;
    
                &::after {
                    content: '';
                    display: inline-block;
                    width: 2rem;
                    height: 2rem;
                    background-image: url(/assets/images/ico/ico_setting_white.svg);
                    background-size: cover;
                    transform: translateY(1px);
                }
            }
        }

        &--name {
            font-size: 2.2rem;
        }
    }

    &__tab {
        .tab-box {
            .title-22 {
                margin-bottom: 1.2rem;
                margin-left: 2rem;
            }
        }
    }

    &__card {
        &--list {
            li {
                border-radius: 1.2rem;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);;
                background-color: #fff;
            }

            li + li {
                margin-top: 1.6rem;
            }

            .list-wrap {
                display: flex;
                align-items: center;
                min-height: 8.4rem;
                padding: 0.6rem 2rem;
            }

            .benefit-type {
                display: inline-block;
                border-radius: 1.6rem;

                &.--coupon {
                    background-color: #2ccc98;
                }
                &.--benefit {
                    background-color: #6195ff;
                }
                &.--save {
                    background-color: #be85ff;
                }
                &.--member {
                    background-color: #ff9216;
                }
                &.--notice {
                    background-color: #f651c5;
                }

                .txt {
                    display: inline-block;
                    padding: 0.4rem 0.8rem;
                    font-size: 1.2rem;
                    font-weight: 600;
                    color: #fff;
                    white-space: nowrap;
                }
            }

            .title-area {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }

            .title-box {
                flex: 1;
                width: calc(100% - 5rem);
                // margin-left: 1.2rem;
                overflow: hidden;
                
            }

            .title {
                font-size: 1.8rem;
                font-weight: 600;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                color: #0a0a0a;
            }

            .desc {
                margin-top: 0.6rem;
                font-size: 1.5rem;
                color: #9a9a9a;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            
            &.--benefit {
                .list-wrap {
                    min-height: 8.4rem;
                    padding: 0.6rem 2rem;
                    display: flex;
                    align-items: center;
                }

                .benefit-type {
                    display: inline-block;
                    margin-right: 1.2rem;
                    border-radius: 1.6rem;
    
                    &.--coupon {
                        background-color: #2ccc98;
                    }
                    &.--benefit {
                        background-color: #6195ff;
                    }
                    &.--save {
                        background-color: #be85ff;
                    }
    
                    .txt {
                        display: inline-block;
                        padding: 0.4rem 0.8rem;
                        font-size: 1.2rem;
                        font-weight: 600;
                        color: #fff;
                        white-space: nowrap;
                    }
                }
            }

            &.--point {
                .list-wrap {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .ico {
                    width: 3.6rem;
                    height: 3.6rem;
                    margin-right: 1.2rem;
                    background-size: cover;
                }
    
                .save {
                    .ico {
                        background-image: url(/assets/images/ico/ico_save.svg);
                    }
    
                    .point, .point-desc {
                        color: #00a972;
                    }
                }
    
                .use {
                    .ico {
                        background-image: url(/assets/images/ico/ico_use.svg);
                    }
    
                    .point, .point-desc {
                        color: #636363;
                    }
                }

                .desc {
                    font-size: 1.3rem;
                }
    
                .point-area {
                    text-align: right;
                    
                    .point {
                        display: block;
                        font-size: 1.9rem;
                        font-weight: 600;
                    }
    
                    .point-desc {
                        display: block;
                        margin-top: 0.6rem;
                        font-size: 1.3rem;
                    }
                }
            }
        }
    }
}

.btn-lougout {
    font-size: 1.2rem;
    color: #9a9a9a;
}

.user-rate {
    display: inline-block;
    border-radius: 1.6rem;

    .txt {
        display: inline-block;
        padding: 0.4rem 0.8rem;
        font-size: 1.2rem;
        font-weight: 500;
        color: #fff;
    }

    &.--green {
        background-color: #00a972;
    }

    &.--family {
        background-color: #00a972;
    }

    &.--gold {
        background-color: #ffb941;
    }

    &.--vip {
        background-color: #6195FF;
    }
    &.--vvip {
        background-color: #BE85FF;
    }
}

.header-msg {
    margin: 0 2rem;
    padding: 3.2rem 0;
    border-bottom: 1px solid #bbb;

    .title {
        font-size: 2.2rem;
        font-weight: 400;
        line-height: 1.44;
        word-break: keep-all;

        &.--gray {
            color: #636363;
        }
    }

    .desc {
        margin-top: 2rem;
        font-size: 1.8rem;
        color: #636363;
        line-height: 1.44;
    }

    &.--bg-gray {
        margin: 0;
        padding: 3.2rem 2rem;
        border-bottom: 1px solid #bbb;
        background-color: #f2f2f2;
    }

    &.--notice {
        .title {
            font-weight: 600;
        }

        .msg__list {
            font-size: 1.6rem;
            line-height: 1.5;

            li {
                margin-left: 0.8rem;
            }

            li::before {
                top: 1rem;
                left: -0.8rem;
                width: 0.3rem;
                height: 0.3rem;
            }
        }
    }
}

.form__line {

    &.--fam {
        .title-box {
            padding-top: 0;
            margin-bottom: 1.6rem;
        }

        input:disabled + .label {
            background-color: red;
        }

        .label {
            display: block;

            &.birth {
                padding: 1.2rem 0 1.2rem 1.2rem;
                color: #9a9a9a;
                // color: #0a0a0a;
                font-size: 1.5rem;
                font-weight: 500;
                border-bottom: 1px solid #bbb;
                margin-bottom: 0;

                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 0;
                    width: 2rem;
                    height: 2rem;
                    margin-top: -1rem;
                    background-image: url(/assets/images/ico/ico_arrow_down.svg);
                    background-size: cover;
                }

                &.is-active {
                    color: #0a0a0a;
                }
            }
        }

        .btn-year {
            position: relative;
            width: 100%;

            &::after {
                content: '년';
                position: absolute;
                top: 1.2rem;
                right: 0;
                font-size: 1.6rem;
                color: #bbb;
            }
        }

        .control {
            &.--half {
                display: flex;
                justify-content: space-between;
            }

            &.year {
                position: relative;
                width: 100%;

                &::after {
                    content: '년';
                    position: absolute;
                    top: 1.2rem;
                    right: 0;
                    font-size: 1.6rem;
                    color: #bbb;
                }
            }

            input[type="text"] {
                color: #0a0a0a;
            }
        }

        .mypage__select {
            width: 50%;
            border-bottom: 0;
            margin-left: 0;

            .select-input {
                position: relative;
                padding: 0;
                color: #0a0a0a;

                &::after {
                    position: absolute;
                    top: 1.2rem;
                    right: 0; 
                    min-width: inherit;
                    margin-left: 0;
                }
            }
        }
    }

    &.--fam + &.--fam {
        margin-top: 6rem;
    }

    .field {
        &.--rec {
            .control {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
    
            .txt {
                display: inline-block;
                font-size: 1.5rem;
            }
        }
    }

    .field + .field {
        margin-top: 3.2rem;
    }

    .control {
        margin: 0;
    }

    .control + .control {
        margin-top: 0.8rem;
    }

    input[type="text"], input[type="number"], input[type="password"] {
        padding: 1.2rem 0;
        font-size: 1.5rem;

        &:focus {
            border-color: #0a0a0a;
        }

        &:disabled, &:read-only, &:read-only:focus {
            color: #9a9a9a;
            border-color: #bbb;
        }
    }

    .is-grouped .btn {
        margin-left: 0.4rem;
    }

    .label {
        display: inline-block;
        margin-bottom: 0.8rem;
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 1.44;
        word-break: keep-all;
    }

    .btn {
        font-size: 1.5rem;
    }

    .btn-input {
        width: 8.4rem;
        height: 4.4rem;
        padding: 0;
        color: #fff;
        border-radius: 0.6rem;
        background-color: #0a0a0a;

        &:disabled {
            cursor: default;
            background-color: #bbb;
        }
    }

    .is-pw-change {
        display: none;

        &.is-active {
            .control__btn {
                display: block;
            }
        }   
    }

    .btn-change-pw {
        margin-top: 0.4rem;
        font-size: 1.4rem;
    }

    .msg {
        color: #ff4b4b;

        .error {
            color: #ff4b4b;
        }

        .success {
            color: #00a972;
        }
    }

    .select--line--black {
        padding: 1.2rem 3rem 1.2rem 0;
        font-size: 1.5rem;
        border: 0;
        border-bottom: 1px solid #bbb;
        background-position: top 50% right 0;
    }
}

@media (max-width: 340px) {
    .form__line .survey--card input[type="radio"] + label {
        padding: 2rem;
    }

    .form__line .survey--card p {
        font-size: 1.8rem;
        width: inherit;
    }
}

.guidance {
    .title {
        font-size: 1.8rem;
        font-weight: 600;
        text-align: center;
    }

    .desc {
        display: block;
        font-size: 1.5rem;
        text-align: center;
    }

    .title + .desc {
        margin-top: 0.8rem;
    }

    .content {
        margin-top: 2rem;

        .title {
            display: block;
            margin-top: 1rem;
            font-size: 1.5rem;
            font-weight: 600;
            text-align: left;
        }

        .txt + .title, .txt-list + .title {
            margin-top: 2.4rem;
        }

        .txt + .point-list {
            margin-top: 1.6rem;
        }

        .title + .txt, .title + .txt-list {
            margin-top: 1rem;
        }
    }

    .txt-box {
        padding: 2rem;
        font-size: 1.4rem;
        border-radius: 1rem;
        background-color: #f2f2f2;
        line-height: 1.43;
    }

    .txt + .txt, .txt + .txt-list, .txt-list + .txt, .txt-list + .txt-list {
        margin-top: 2.4rem;
    }

    .point-list {
        li + li {
            margin-top: 1rem;
        }
    }

    .txt-list {
        counter-reset: number;
    }

    .number-list {
        margin-left: 1rem;
        padding-left: 0.3rem;
        counter-increment: number;

        &::marker {
            content: counter(number)')';
        }
    }
}

.agree-box {
    .checkbox-large input[type="checkbox"] + label {
        font-size: 1.8rem;
        font-weight: 600;
    }

    .checkbox-large input[type="checkbox"] + label::before, .checkbox-large input[type="checkbox"] + label::after {
        top: 0;
    }

    .msg {
        display: inline-block;
        margin-top: 1.6rem;
        font-size: 1.5rem;
        line-height: 1.53;
    }
}

.scroll-box{
    .scroll.is-border {
        border-top: 1px solid #bbb;
        border-bottom: 1px solid #bbb;
        padding: 0;

        .modal--my_point table {
            border: 0
        }
    }
}

.form__line .field.--rec .txt.is-keepall {
    word-break: keep-all;
}

.header-profile {
    .title-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .title {
        margin-bottom: 0.8rem;
        font-size: 2.2rem;
        font-weight: 600;
    }

    .rate {
        display: inline-block;
        border-radius: 1.6rem;

        &.--family {
            background-color: #00a972;
        }

        &.--vip {
            background-color: #6195FF;
        }

        &.--vvip {
            background-color: #BE85FF;
        }

        // &.--gold {
        //     background-color: #ffb941;
        // }

        // &.--vip {
        //     background-color: #0a0a0a;
        // }

        .txt {
            display: inline-block;
            width: 7.2rem;
            text-align: center; 
            padding: 0.6rem 1rem;
            font-size: 1.6rem;
            font-weight: 600;
            color: #fff;
        }
    }

    .point{
        .txt {
            font-size: 2.2rem;
            font-weight: 600;
            color: #00a972;
        }
    }

    .txt {
        display: inline-block;
        padding: 0.6rem 1rem;
        font-size: 1.6rem;
        color: #fff;
    }

    .desc {
        font-size: 1.2rem;
        color: #636363;
    }

    
    .btn-area {
        margin-top: 0;
    }

    .btn-point {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 1.2rem;
        color: #636363;

        &::after {
            content: '';
            display: inline-block;
            width: 1.2rem;
            height: 1.2rem;
            margin-left: 0.2rem;
            background-image: url(/assets/images/ico/ico_next_gray.svg);
            background-size: cover;
            background-color: transparent;
            vertical-align: text-top;
        }
    }
}

.accrue {
    &__progress {
        padding-top: 2rem;
        border-top: 1px solid #bbb;

        .progress-outer {
            width: 100%;
            height: 1.2rem;
            position: relative;
            border-radius: 0.6rem;
            background-color: #e9e9e9;
            overflow: hidden;
        }

        .progress-inner {
            width: 0;
            height: inherit;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 0.6rem;
            background-color: #2ccc98;
            // transition: all 10s;
        }
    }

    &__txt {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.2rem;
        font-size: 1.6rem;
        color: #0a0a0a;

        .number {
            font-weight: 600;
        }

        &.family  {
            .number {
                color: #00a972;
            }
        }

        &.vip  {
            .number {
                color: #6195FF;
            }
        }

        &.vvip  {
            .number {
                color: #BE85FF;
            }
        }
    }

    // &__amount {
    //     display: flex;
    //     justify-content: space-between;
    //     margin-top: 1.2rem;

    //     .amount {
    //         font-size: 1.8rem;
    //         font-weight: 600;
    //     }

    //     .goal {
    //         font-size: 1.8rem;
    //         color: #00a972;

    //         .desc {
    //             display: inline-block;
    //             margin-right: 0.5rem;
    //             font-size: 1.3rem;
    //             font-weight: 400;
    //             color: #636363;
    //         }
    //     }
    // }
}

.title-22 {
    font-size: 2.2rem;
    font-weight: 600;
}

.white-textbox {
    padding: 2rem;
    border-radius: 1.5rem;
    background-color: #fff;

    .txt.msg {
        font-size: 1.6rem;
        line-height: 1.25;
        margin-top: 0;

        > li {
            list-style: inside;
            padding: .4rem 0;
        }
    }
}

.consent {

    &__info {
        .title {
            font-size: 2.4rem;
            color: #0a0a0a;
            text-align: center;
            font-weight: 500;

            span {
                color: #00a972;
            }
        }
        
        .desc {
            display: block;
            margin-top: 2.5rem;
            color: #636363;
            font-size: 2rem;
            text-align: center;
            word-break: keep-all;
        }
    }

    &__desc {
        font-size: 1.5rem;
        color: #0a0a0a;
        margin-top: 1.6rem;
        margin-bottom: 3.2rem;
        line-height: 2.3rem;
        text-align: right;

        > b {
            font-weight: 600;
            color: #6195ff;
        }
    }

    .info {
        padding: 2rem;
        background-color: #f2f2f2;
        color: #0a0a0a;
        font-size: 1.5rem;
        word-break: keep-all;
        line-height: 2.3rem;
    }

    .title-box {
        padding-top: 2rem;
        margin-bottom: 2rem;

        .title {
            font-size: 2rem;
            font-weight: 600;
        }

        .desc {
            display: inline-block;
            font-size: 1.6rem;
            word-break: keep-all;
        }
    }

    &__control {
        + .consent__control {
            margin-top: .8rem;
        }
    }

    &__radio {
        + .consent__label {
            border: 2px solid transparent;
            box-shadow: 0 0 0 1px #cfcfcf;
        }

        &:checked {
            + .consent__label {
                border-color:#00a972;
                box-shadow: 0 0 .5rem 0 rgba(0, 0, 0, 0.16);
            }
        }
    }

    input[type="radio"].consent__radio + .consent__label {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1.6rem 2.4rem;
        border-radius: .8rem;

        .thumb {
            width: 6rem;
            height: 6rem;
            border-radius: 50%;
            background-color: #f2f2f2;
            padding: 1.2rem;
            margin-right: 2rem;
        }

        .txt-box {
            flex: 1;
        }

        .title {
            display: block;
            font-size: 1.6rem;
            font-weight: 600;
            color: #0a0a0a;
        }

        .desc {
            display: block;
            margin-top: .8rem;
            color: #0a0a0a;
            font-size: 1.4rem;
        }
    }
}

.switch__alarm {
    &--wrap {
        padding-top: 2rem;

        .title {
            font-size: 1.8rem;
            color: #0a0a0a;
            font-weight: 600;

            &.no-bold {
                font-weight: 500;
            }
        }

        .right {
            display: flex;
            justify-content: right;
            align-items: center;
        }
    }
 
    &--list {
        background-color: #f2f2f2;
        padding: 2.4rem 2rem;
    }

    &--item {
        + .switch__alarm--item {
            margin-top: 1.6rem;
        }
    }

    &--detail {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &--box {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.selex_point_info, .selex_point_list {
    display: none;

    &.is-visible {
        display: block;
    }
}


.rolldate-container {
    color: #0a0a0a !important;

    .rolldate-content {
        top: 0 !important;
    }

    #rolldate-hour {
        position: relative;
        &::after {
            content: ':';
            display: inline-block;
            position: absolute;
            top: 50%;
            font-size: 1.6rem;
            right: 0;
            transform: translateY(-50%);
        }
    }

    .mask-top,
    .mask-bottom {
        left: 2.8rem !important;
        border-color: #bbb !important;
        width: calc(100% - 5.6rem) !important;
    }

    header.rolldate-header {
        position: relative;
        border-bottom: none;
        padding-top: 2.7rem;
        margin-top: 2.7rem;

        .rolldate-cancel {
            position: absolute;
            top: 0;
            left: auto;
            right: 2.8rem;
            width: 2.3rem;
            height: 2.3rem;
            background-image: url(../images/ico/ico_close.svg);
            background-size: 2rem;
            background-position: center;
            background-repeat: no-repeat;
            z-index: 1;
        }
    }

    .rolldate-panel {
        overflow: hidden;
        border-top-left-radius: 1.6rem;
        border-top-right-radius: 1.6rem;
    }

    .rolldate-wrapper {
        ul {
            cursor: pointer;
        }
    }

    .rolldate-mask {
        background-color: rgba(0,0,0,.7) !important;
    }


    .rolldate-footer {
        padding-bottom: 2.7rem;
        padding-top: 3.2rem;
        text-align: center;

        .rolldate-btn {
            position: relative;
            left: inherit;
            right: inherit;
            color: #fff;
            background-color: #0a0a0a;
            display: block;
            -webkit-tap-highlight-color: #0a0a0a;
            padding: 1.2rem 0;
            font-size: 1.4rem;
            border-radius: 0.8rem;
        }

    }

    .rolldate__cotainer {
        padding-left: 2.8rem;
        padding-right: 2.8rem;
    }

}

.view--pc {
    .rolldate-container .rolldate-panel {
        left: 50%;
    }

    .rolldate-panel {
        max-width: 64rem;
    }

    .mask-top,
    .mask-bottom {
        max-width: 58.4rem;
        left: 2.8rem !important;
    }
}

@media (max-width: 1280px) {

    .view--pc {
        .rolldate-container .rolldate-panel {
            // left: calc(50% + 2rem);
            transform: translate3d(-50%,326px,0);
        }

        .rolldate-container .rolldate-panel.fadeIn {
            transform: translate3d(-50%,0,0);
        }
    }
}

input[type="text"].time__btn {
    font-size: 1.6rem;
    color: #0a0a0a;
    width: auto;
    // max-width: 4.4rem;
    max-width: 5rem;
    margin-right: 1.6rem;
    background-color: transparent;
    border: none !important;
    outline: 0;
    padding: 0 !important;
    cursor: pointer;

    &.is-blur {
        color: #9a9a9a;
        cursor: auto;
    }

    &.is-large {
        font-size: 1.8rem;
    }
}

.box-sd {
    border-radius: 1.2rem;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.3);
    background-color: #fff;

    .content-center {
        padding: 6rem 2rem;
        text-align: center;
    }

    .ico {
        display: inline-block;
        width: 7.6rem;
        margin-bottom: 1.3rem;
    }

    .title {
        font-size: 2.2rem;
        font-weight: 600;
        line-height: 1.36;
        word-break: keep-all;
    }

    .desc {
        margin-top: 1.6rem;
        font-size: 1.8rem;
        line-height: 1.44;
        color: #636363;
        word-break: keep-all;
    }
}

.fam-account {
    &__list {
        li {
            padding: 2rem;
            border-radius: 1.2rem;
            box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.3);
            background-color: #fff;
        }

        li + li {
            margin-top: 2rem;
        }

        &--info {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .info {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .thum {
                width: 7.2rem;
                height: 7.2rem;
                position: relative;
                margin-right: 1.6rem;
                border: 1px solid #bbb;
                border-radius: 4rem;
                background-color: #f2f2f2;
                overflow: hidden;

                img {
                    width: 6rem;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .name {
                font-size: 1.9rem;
                font-weight: 600;
            }

            .desc {
                margin-top: 0.6rem;
                font-size: 1.3rem;
                color: #9a9a9a;

                span {
                    display: inline-block
                }

                .sex {
                    margin-right: 0.8rem;
                }

                .age {
                    margin-left: 0.8rem;

                    &::before {
                        content: '';
                        display: inline-block;
                        width: 1px;
                        height: 1rem;
                        background-color: #9a9a9a;
                        transform: translateX(-0.8rem);
                    }
                }
            }

            .info-btn {
                display: inline-grid;

                .btn {
                    display: inline-block;
                    padding: 0.5rem 1.2rem;
                    font-size: 1.5rem;
                    border: 1px solid #bbb;
                    border-radius: 1.8rem;
                }

                .edit-btn {
                    color: #00a972;
                    border-color: #00a972;
                }

                .btn + .btn {
                    margin-top: 0.8rem;
                }
            }
        }

        .inqury {
            margin-top: 1.6rem;
            text-align: center;
            border-top: 1px solid #bbb;

            .btn {
                display: block;
                padding: 1.6rem 0 0 0;
            }
        }
    }
}

.white-wrap {
    .user--choice {
        &.--gray {
            margin: 0 -0.3rem;

            li {
                margin: 0 0.3rem;

                .btn {
                    width: 10.5rem;
                    height: 4.2rem;
                    padding: 0;
                    text-align: center;
                    border: 1px solid #bbb;
                    background-color: #fff;
                }

                &.is-active {
                    border-color: #0a0a0a;
                }
            }
        }
    }   
}

.card-list {
    li + li {
        margin-top: 2rem;
    }

    .cart__card {
        .delivery__status {
           .btn {
                padding: 0.6rem 0.8rem;
                font-size: 1.3rem;
                color: #00a972;
                border: 1px solid #00a972;
                border-radius: 0.6rem;
           } 
        }
    }

    .card-num {
        margin-top: 2.8rem;
        font-size: 1.7rem;
    }

    .more__list {
        display: none;
    }
}

.health-progress {
    padding: 2.4rem;
    border-radius: 0.8rem;
    border: 1px solid #cfcfcf;
    background-color: #fff;
    
    .box-wrap {
        display: flex;
        align-items: center;
    }

    .box-wrap + .box-wrap {
        margin-top: 2rem;
    }

    .ico {
        width: 6rem;
        height: 6rem;
        position: relative;
        margin-right: 1.4rem;
        border-radius: 50%;
        background-color: #f2f2f2;
        overflow: hidden;

        img {
            width: 3.6rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .kcal-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.3rem;
    }

    .title {
        flex: 1;
        font-size: 1.6rem;
        font-weight: 600;
    }

    .kcal {
        font-size: 1.8rem;
        font-weight: 600;
        color: #00a972;
    }

    .content {
        flex: 1;
    }

    .progress-outer {
        height: 1.2rem;
    }

    .progress-inner {
        display: inline-block;
        height: inherit;
        border-radius: 8px;
        background-color: #2ccc98;
    }

    &.--point {
        padding: 2rem 2.4rem;

        > li {
            position: relative;

            + li {
                padding-top: 2.4rem;

                &::after {
                    content: '';
                    position: absolute;
                    top: 3px;
                    left: 3.2rem;
                    height: 1.8rem;
                    width: 0;
                    border-top-width: 0;
                    border-bottom-width: 0;
                    border-right-width: 0;
                    border-left-width: 4px;
                    border-style: solid dotted;
                    border-color: #bbb;
                }
            }
        }

        .box-wrap + .box-wrap {
            margin-top: 0;
        }

        .ico {
            width: 6.8rem;
            height: 6.8rem;
            margin-right: 1.6rem;
            flex: none;
        }

        .step {
            display: inline-block;
            margin-bottom: .4rem;
            padding: 0.5rem 0.8rem;
            font-size: 1.2rem;
            font-weight: 600;
            color: #fff;
            border-radius: 1.2rem;
            background-color: #00a972;
        }

        .text {
            display: block;
            font-size: 1.6rem;
            // line-height: 1.44;
        }

        &.--info {
            padding: 2.8rem;

            li::after {
                display: none;
            }

            .ico {
                width: 7.2rem;
                height: 7.2rem;

                img {
                    width: 4rem;
                }
            }

            .title {
                display: block;
                margin-bottom: 0.8rem;
                font-size: 1.8rem;
                font-weight: 600;
            }

            .text {
                font-size: 1.5rem;
            }
        }
    }
}

@media (max-width: 380px) {
    .health-progress .kcal-box {
        flex-wrap: wrap;
    }

    .health-progress .title, .health-progress .kcal {
        width: 100%;
    }
}

.modal.myorder {
    .modal__body {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 3.2rem;
        background-color: #f2f2f2;

        .product__title {
            font-size: 1.7rem;
            line-height: 2.5rem;
            color: #0a0a0a;
        }

        .product__thumb {
            width: 9rem;
            height: 9rem;
        }

        .product__order--price {
            margin-top: 1.5rem;
            color: #0a0a0a;
            font-size: 1.7rem;

            .volume {
                position: relative;
                margin-left: 0.6rem;
                &::before {
                    content: '';
                    display: inline-block;
                    vertical-align: middle;
                    width: 1px;
                    height: 1.4rem;
                    background-color: #b8b8b8;
                    /* margin-left: 1.3rem; */
                    /* padding-left: 0.6rem; */
                    /* margin-right: 0.6rem; */
                    position: absolute;
                    top: 50%;
                    left: -0.6rem;
                    transform: translateY(-50%);
                }
            }
        }

        .desc  {
            font-size: 2.2rem;
            color: #0a0a0a;
            line-height: 3rem;
        }

        .product-box {
            background-color: transparent;
        }

        .product__link {
            padding: 2rem;
            background-color: #fff;
            
        }

        .product__item {
            position: relative;
        }

        input[type="radio"].myorder__radio {
            + .product__item {
                display: flex;
                align-items: center;
                padding-left: 0;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 2.4rem;
                    height: 2.4rem;
                    // background-color: #000;
                    background-image: none;
                    background-size: cover;
                    z-index: 2;
                }
            }

            &:checked {
                + .product__item {
                    &::before {
                        background-image: url(/assets//images/ico/ico_bg_check.svg);
                    }
                }
            }
        }

        .product__order--date {
            font-size: 1.8rem;
            color: #0a0a0a;
            font-weight: 600;
            margin-bottom: 2.5rem;
        }
    }
    
    .modal__footer {
        margin-top: 0;
    }
}

@media (max-width: 420px) {
    .modal.myorder .modal__body {
        .product__thumb {
            width: 8rem;
            height: 8rem;
        }

        .product__title {
            -webkit-line-clamp: 1;
        }

        .product__order--price {
            .volume {
                display: block;
                margin-left: 0;

                &::before {
                    display: none;
                }
            }
        }
    }
}

.charct-swiper {
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: -2rem;
    margin-right: -2rem;

}

.select-charct {
    padding: -0.6rem;

    .swiper-slide {
        width: auto;

        .char__item {
            .thumb {
                overflow: hidden;
                padding: 1rem;
                border-radius: 50%;
                border: 2px solid #f2f2f2;
                background-color: #f2f2f2;
            }
        
            .name {
                display: block;
                margin-top: 1.2rem;
                text-align: center;
                font-size: 1.6rem;
                color: #0a0a0a;
            }
        }

        &.is-active {
            .char__item {
                .thumb {
                    border-color: #2ccc98;
                    background-color: #fff;

                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 3.6rem;
                        height: 3.6rem;                    
                        background-image: url(/assets/images/ico/check_ps_certification.svg);
                        background-size: cover;
                    }
                }
            }
        }

    }

    .control {
        text-align: center;
    }

    input[type="radio"] + label {
        padding: 0;
        font-size: 0;
        border-radius: 50%;
        background-color: #f2f2f2;
    }

    .name {
        display: block;
        margin-top: 1.2rem;
        font-size: 1.6rem;
    }

    input[type='radio']:checked + label::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 2px solid #2ccc98;
        border-radius: 50%;
    }

    input[type='radio']:checked + label::after {
        content: '';
        position: absolute;
        top: -5px;
        right: -8px;
        width: 3.6rem;
        height: 3.6rem;
        background-image: url(/assets/images/ico/check_ps_certification.svg);
        background-size: cover;
    }
}

.push__list {
    margin-left: -2rem;
    margin-right: -2rem;

    

    .push__item {
        padding-left: 2rem;
        padding-right: 2rem;
        border-bottom: 1px solid #bbb;

        &:last-child {
            border: 0;
        }

        .desc {
            display: block;
            margin-top: 1rem;
            font-size: 1.5rem;
            color: #636363;
            line-height: 1.53;

            .alert-icon {
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-top: 0.4rem;
    
                &::before {
                    display: inline-block;
                    content: '!';
                    width: 0;
                    height: 0;
                    margin-right: 0.4rem;
                    /* border-bottom: 2rem solid #fad017; */
                    /* border-left: 1rem solid transparent; */
                    /* border-right: 1rem solid transparent; */
                    vertical-align: middle;
                    background-color: #fad017;
                    width: 1.8rem;
                    height: 1.8rem;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.4rem;
                    line-height: 1.8rem;
                    color: #fff;
                }
    
                // &::after {
                //     display: inline-block;
                //     content: '!';
                //     position: absolute;
                //     top: 2.7px;
                //     left: 8.5px;
                //     font-size: 1.2rem;
                //     font-weight: bold;
                // }
            }
        }
    }

    .switch--small-box {
        padding-top: 2.2rem;
        padding-bottom: 2.2rem;

        &.is-type2 {
            display: block;
            padding: .9rem 0;
        }
    }

    .switch--small-box .title {
        font-size: 1.9rem;
        color: #0a0a0a;
        font-weight: 600;
    }

    .switch--small {
        margin-top: .5rem;
    }
}

.apppush--service {
    padding: 2.4rem;
    background-color: #f2f2f2;

    .switch__alarm--list {
        width: 100%;
        padding: 0;
    }
}

.translates {
    &__title {
        padding-top: 3.2rem;
        font-size: 2rem;
        color: #0a0a0a;
        font-weight: 600;
    }

    &__card {
        padding: 2.8rem 2.4rem;
        margin-top: 2rem;
        background-color: #fff;
        border: 1px solid #cfcfcf;
        border-radius: .8rem;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 2rem;

        .title {
            font-size: 1.9rem;
            color: #0a0a0a;
            font-weight: 600;
        }
    }

    &__link {
        margin: -.8rem;
        margin-right: 0;
        width: 2rem;
        height: 2rem;
        background-image: url(/assets/images/ico/ico_arrow.svg);
        background-size: cover;
        transform: rotate(90deg);
    }

    &__list {
        li + li {
            margin-top: 1.2rem;
        }

        .translates__header {
            padding: 2.6rem 2rem;
            border-radius: 0.8rem;
            background-color: #f2f2f2;
        }

        .translates__link {
            width: 1.6rem;
            height: 1.6rem;
        }

        .title {
            display: block;
            font-size: 1.6rem;
            font-weight: normal;
        }

        .desc {
            display: block;
            margin-top: 0.6rem;
            font-size: 1.4rem;
            color: #9a9a9a;
        }
    }
}


// common.scss로 옮기기
.notified {

    &__wrap {
        padding: 3.2rem 2rem;
        margin-left: -2rem;
        margin-right: -2rem;
        background-color: #fff;
        border-bottom: 1px solid #bbb;

        &.--border-none {
            border-bottom: 0;
        }

        &.--code {
            padding: 0;
            margin: 0;

            .notified__info {
                padding: 2.4rem;
            }

            .notified__desc {
                font-size: 1.6rem;
            }
        }
    }

    &__info {
        padding: 2rem;
        background-color: #f2f2f2;
        border-radius: .8rem;
    }

    &__desc {
        font-size: 1.5rem;
        color: #0a0a0a;
        line-height: 2.3rem;
    }

    &__link {
        display: flex;
        align-items: center;
        position: relative;
        margin-top: 1.6rem;
        font-size: 1.5rem;
        color: #1c1c1c;

        &::after {
            content: '';
            display: block;
            width: 1.2rem;
            height: 1.2rem;
            margin-left: .4rem;
            background-image: url(/assets/images/ico/ico_next.svg);
            background-size: cover;
        }
    }

    &__code-img {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 17rem;
        }
    }
}

.tab__swiper {
    box-shadow: 0 0 2px 0 #bbb;
    // padding-bottom: 4px;
    // border-bottom: 1px solid #bbb;

    // &::after {
    //     content: '';
    //     display: block;
    //     height: 4px;
    //     width: 100%;
    //     background-image: url(/assets/images/sd_4px.png);
    //     position: absolute;
    //     bottom: 0;

    // }
    .swiper-slide {
        width: auto;
    }

    &.tabs {
        .tablist {
            overflow: visible;
            display: flex;
            flex-grow: inherit;
            flex-shrink: inherit;
            justify-content: flex-start;
            align-items: normal;
        }

        .tab__btn {
            border: 0;
        }
    }
}

.profile__card--list {
    &.--notified {

        li {
            overflow: hidden;
        }
        
        .list-wrap {
            height: auto;
            padding: 1.5rem 2rem;

            &.is-new {
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    top: 1rem;
                    right: 1rem;
                    width: .8rem;
                    height: .8rem;
                    border-radius: 50%;
                    background-color: #ff4b4b;
                }
            }
        }

        .title {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
            line-height: 2.4rem;
            font-size: 1.6rem;
            font-weight: 600;
        }

        .desc {
            margin-top: .8rem;
            font-size: 1.3rem;
            font-weight: 500;
        }

        .title-box {
            margin-left: 1.2rem;
        }

        .benefit-type {
            .txt {
                width: 4.8rem;
                padding: 0.4rem 0;
                text-align: center;
                font-weight: bold;
            }
        }
    }
}

.personal__progress.--level {
    position: relative;
    padding: 0.6rem 0;

    .outer {
        overflow: hidden;
        height: 1.2rem;
        background-color: #e9e9e9;
        border-radius: 0.6rem;
    }

    .inner {
        display: inline-block;
        width: 0;
        height: 100%;
        
        border-radius: 0.6rem;
        transition: none;
    }
    
    &.family {
        .inner {
            background-color: #00a972;
        }

        // .point {
        //     &::before {
        //         border-color: #00a972;
        //     }
        // }
    }

    &.vip {
        .inner {
            background-color: #6195FF;
        }

        .point:first-child {
            color: #6195ff;
            
            &::before {
                border-color: #6195FF;
            }
        }
    }

    &.vvip {
        .inner {
            background-color: #BE85FF;
        }

        .point {
            &::before {
                border-color: #BE85FF;
            }

            &:first-child {
                color: #9a9a9a;
            }

            &:nth-child(2) {
                color: #BE85FF;
            }
        }
    }

    .text-box {
        height: 3.2rem;
    }


    .point {
        position: absolute;
        top: 3.2rem;
        display: block;
        width: 7rem;
        font-size: 1.6rem;
        color: #0a0a0a;
        text-align: center;
        
        &::before {
            content: '';
            position: absolute;
            top: -3.2rem;
            left: calc(3.5rem - 1.2rem);
            width: 2.4rem;
            height: 2.4rem;
            background-color: #fff;
            border-radius: 50%;
            border: 0.6rem solid #e9e9e9;
        }

        &:first-child {
            left: calc(50% - 3.5rem);
            // color: #00a972;
            // color: #6195FF;

            // &.is-active {
            //     color: #6195FF;
            // }
        }

        &:nth-child(2) {
            left: calc(83.333% - 3.5rem);
            // color: #BE85FF;

            // &.is-active {
            //     color: #BE85FF;
            // }
        }

       
    }

    .num {
        font-weight: 600;
    }
}

input[type="text"].datepickerInput {
    text-align: center;
    text-decoration: none;
    border-bottom: 0;
    /* width: auto; */
    max-width: 13rem;
    z-index: 2;
    // position: relative;
    padding-right: 3rem;
    background-color: transparent;
}

.lookup-box .control {
    position: relative;
    height: 3rem;
}

.lookup-box .control::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 3rem;
    height: 3rem;
    background-image: url(/assets/images/ico/ico_input_calendar.svg);
    background-size: 2rem;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    transform: translateY(-50%);
}

.appver {
    font-size: 1.3rem;
    color: #636363;
}