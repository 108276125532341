.order {
    
    color:#0a0a0a;

    label {
        font-size:1.8rem;
    }

    .control {
        position: relative;
        margin-top:1.2rem;
    }

    .field.is-grouped .control {
        align-items: normal;
    }

    input[type=text], input[type=password], input[type=number] {
        padding:1.2rem 0.2rem 1.2rem 1.6rem;
        border:1px solid #bbb;
        background:#fff;
    }

    input[type=text]:focus, input[type=password], input[type=number]:focus {
        // border:1px solid #0a0a0a;
        outline: 0;
    }

    input[type=text].input-del {
        padding-right: 4.2rem;
    }

    .btn-payment {
        width: 100%;
    }

    .accordion-group-wrap {
        border:0;
    }
    .accordion__btn {
        min-height: auto;
        border:0;
        background-color: transparent;
    }

    .accordion__btn[aria-expanded="true"] {
        font-weight: normal;
    }

    .is-section-contour::after {
        margin-top:0;
    }

    &__title-box {
        height: 4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;
        margin-bottom: 2rem;

        .button-box {
            display: flex;
            align-items: center;
            position: relative;
            z-index: 1;

            &__accordion{
                width:2rem;
            }

            .checkbox {
                margin-right:2.6rem;
            }
        }
    }

    &__title {
        font-size:2rem;
        color: #0a0a0a;

        .order__info {
            font-size: 2rem;
        }

        + .msg {
            display: inline-block;
            margin-top: 0.8rem;
            font-size: 1.4rem;
            color: #636363;
        }
    }
    

    &__field {
        .msg {
            display: block;
            margin-top:0.4rem;
            font-size: 1.2rem;
            color: #636363;
        }
    }

    &__field + &__field {
        margin-top:2rem;
    }


    &__field.is-grouped {
        .control {
            display: flex;
        }
    }

    .address-box {

        margin-top:1.6rem;

        .order__field + .order__field {
            margin-top:0.4rem;
        }

        .order__field + .order__field .control {
            margin-top:0;
        }
    }

    .deli-input {
        display: none;

        .control {
            margin-top:0.4rem;
        }
    }
    &__content {
        // padding-bottom: 4rem;
        &::after {
            content: '';
            display: block;
            height: 4rem;
        }

        // .final-pament {

        //     padding-top:2rem;

        //     &--box {
        //         display: flex;
        //         justify-content: space-between;
        //         font-size: 2rem;
        //     }

        //     &--point {
        //         display: block;
        //         margin-top:0.6rem;
        //         text-align: right;
        //         font-size: 1.2rem;
        //         color:#00a972;
        //     }
        // }
    }

    .msg {
        &-box {
            padding:0 2rem;
            background-color: transparent;
        }
    }

    &__subtitle {
        font-size:1.8rem;
        .count {
            color:#ff4646;
        }
    }

    &__payment-info {
        padding:2rem 0;
        border-bottom:1px solid #cfcfcf;

        li + li {
            margin-top:2rem;
        }
        &--box {
            display: flex;
            justify-content: space-between;
            font-size: 1.6rem;
        }

        .sale-price {
            color:#ff4646;
        }
    }

    &__payment {
        .container {
            padding-left:0;
            padding-right:0;
        }

        .order__title-box {
            padding:0 2rem 0 2rem;
        }

        .accordion__btn::after {
            right:2rem;
        }

        &--regular {
            .order__payment-method > li:first-child {
                padding:0 2rem;
            }

            .order__selex-pay--wrap .swiper-slide {
                padding-top:0;
            }

            .order__selex-pay {
                padding-top:0;
            }
        }
    }

    &__payment-method {

        & > li:first-child {
            padding:0 2rem 2rem 2rem;
        }
        
        & > li + li  {
            padding:2rem 2rem 0 2rem;
            border-top:1px solid #cfcfcf;
        }

        label {
            width:100%;

            .radio-box > span {
                font-size: 2rem;
            }
        }

        input[type="radio"]:checked + span {
            font-weight: bold;
        }

        .card-info {
            padding-top: 2rem;
            margin-top: 2rem;
            margin-bottom: -2rem;
            border-top: 1px solid #cfcfcf;
        }
    }

    &__selex-pay {

        padding-top:1rem;

        .swiper-container {
            padding-left:2rem;
            padding-right:2rem;
            margin-left:-2rem;
            margin-right:-2rem;
        }

        &--wrap {
            .swiper-slide {
                padding-top: 10px;
                padding-bottom: 10px;
            }

            .card-disc {  
                margin-top:0.8rem;
                padding:0.8rem 1.2rem;
                border-radius: 0.8rem;
                border:1px solid #cfcfcf;
                font-size: 1.3rem;

                &__title{
                    color:#00a972;
                }
            }
        }

        .selex-card {
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
            overflow: hidden;

            &::before {
                content: '';
                width: 100%;
                height: 1rem;
                position: absolute;
                bottom: 0;
                display: block;
                border-bottom-left-radius: 0.9rem;
                border-bottom-right-radius: 0.9rem;
                background: #18c48c;
                border-top: 2px solid #fff;
                box-shadow: 0 0 0 2px #18c48c;
            }
        }


        &--logo {
            overflow: hidden;
            width: 100%;
            max-width: 6rem;
            position: absolute;
            top: 1.5rem;
            left: 2rem;
        }

        &--amount {
            width:100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            position: absolute;
            bottom:2.8rem;
            padding:0 2rem;
            font-size: 2rem;

            & > span {
                font-size: 1.3rem;
                font-weight: bold;
            }
        }

        .add-payment {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            // border:1px solid #bbb;
            border: 2px dashed #bbb;
            background-image: none;
            background-color: #f2f2f2;

            &::before {
                display: none;
            }

            &--ico {
                img {
                    width:3.8rem;
                }
            }

            &--btn {
                width: 100%;
            }

            &--txt {
                display: block;
                margin-top:1.2rem;
                font-size:1.5rem;
                color: #0a0a0a;
            }
        }
    }

    &__general-pay {
      
        padding-top:2rem;
        margin-right:-1.5rem;
        font-size: 0;

        li {
            width:calc(33% - 1.5rem);
            height:6.5rem;
            display:inline-block;
            margin:0.5rem;
            vertical-align: top;
        }

        &--btn {
            width:100%;
            height:100%;
            border:1px solid #cfcfcf;

            span {
                font-size: 1.5rem;
                color:#0a0a0a;
            }
        }

        &--btn.is-active {
            border:1px solid #0a0a0a;
            background:#0a0a0a;

            span {
                color:#fff;
            }
        }

        .samsung-pay {
            background: url(../images//ico/order_samsungPay.svg) no-repeat 50% 50%;
            background-size:6.9rem;
        }
        .samsung-pay.is-active {
            background-image: url(../images//ico/order_samsungPay_wh.svg);
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-color: #0a0a0a;
        }
        .naver-pay {
            background: url(../images//ico/order_naverPay.svg) no-repeat 50% 50%;
            background-size:5.7rem;
        }
        .naver-pay.is-active {
            background-image: url(../images//ico/order_naverPay_wh.svg);
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-color: #0a0a0a;
        }
        .kakao-pay {
            background: url(../images//ico/order_kakaoPay.svg) no-repeat 50% 50%;
            background-size:6rem;
        }
        .kakao-pay.is-active {
            background-image: url(../images//ico/order_kakaoPay_wh.svg);
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-color: #0a0a0a;
        }
        .payco {
            background: url(../images//ico/order_payco.svg) no-repeat 50% 50%;
            background-size:6.7rem;
        }
        .payco.is-active {
            background-image: url(../images//ico/order_payco_wh.svg);
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-color: #0a0a0a;
        }
        .toss-pay {
            background: url(../images//ico/order_tossPay.svg) no-repeat 50% 50%;
            background-size:10rem;
        }

        @media (max-width:395px){
            .toss-pay {
                background-size:7.5rem;
            }
        }
        .toss-pay.is-active {
            background-image: url(../images//ico/order_tossPay_wh.svg);
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-color: #0a0a0a;
        }
    }

    &__policy {

        input[type="checkbox"] + label {
            padding-left: 3.2rem;
            font-size: 1.4rem;
        }

        input[type="checkbox"] + label:before {
            top: -2px;
            width: 2.4rem;
            height: 2.4rem;
            background-size: 1.6rem;
        }

        input[type="checkbox"]:checked + label:after {
            top: -2px;
            width: 2.4rem;
            height: 2.4rem;
            background-size: 1.6rem;
        }

        .checked__list--btn {
            font-size: 1.4rem;
        }

        &--wrap {
            padding:0 2rem 4rem 2rem;
            background-color: #f2f2f2;
        }

        &--title {
            input[type="checkbox"] + label {
                font-weight: bold;
            }
            
        }

        &--content {

            font-size: 1.3rem;

            input[type="checkbox"] + label:before {
                border: 0;
                background-color: transparent;
            }
    
            input[type="checkbox"]:checked + label:after {
                background-color: transparent;
                background-image: url(../images/ico/ico_check.svg);
            }

            .msg-box {
                padding:0;

                &--gift {
                    padding:0;
                }
            }

            .msg__list {
                font-size: 1.3rem;
            }

        }

        &--detail {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top:1.6rem;
        }

        &--view {
            color:#9A9A9A;
            border-bottom:1px solid #9A9A9A;
        }

        &--disc {
            padding-top:1.6rem;
            color:#636363;
            line-height: 1.8rem;

            .underline {
                font-weight: 500;
                text-decoration: underline;
            }
        }

        .essential {
            font-weight: 600;
            color: #949494;
        }
    }

    &__product-box {

        /* li {
            margin-top:1.6rem;
        } */

        .product--list li + li {
            margin-top:2rem;
        }

        .product__item-box {
            .radio-box {
                height: 100%;
            }
        }
    }

    &__product {

        &--subtitle {
            margin-bottom:0.8rem;
            font-size:1.6rem;
        }

        &--recipient {
            color:#00a972;
        }

        &-list {
            .product__item {
                width: calc(100% - 2rem);
                padding-left:1rem;
            }
        }
    }

    &__coupon {
        &--box {
            width: 100%;
            position: relative;
            padding:1.2rem 1.6rem;
            border:1px solid #bbb;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .control__btn {
                display: block;

                &.btn--clear {
                    display: none;

                    &.is-active {
                        display: block;
                    }
                }

                &.btn--point-clear {
                    display: none;

                    &.is-active {
                        display: block;
                    }
                }
            }
            
        }

        &--txt {
            display: block;
            width: calc(100% - 1.5rem);
            font-size: 1.6rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &--point {
            display: flex;
            justify-content: space-between;
            width: calc(100% - 1.5rem);
            font-size: 1.6rem;

            strong {
                text-align: right;
            }

            // .control {
            //     margin: 0;
            //     flex: 1;
            //     margin-left: 2rem;
                
                input[type=number] {
                    padding: 0;
                    margin-left: 1rem;
                    border: 0;
                    text-align: right;
                    font-weight: 600;

                    &:focus {
                        outline: 0;
                        border: 0;
                    }
                    &:active {
                        outline: 0;
                        border: 0;
                    }

                    &::-webkit-outer-spin-button, 
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }


                }
            // }
        }
    }

    &__add-discount {
        &--title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 1.8rem;

            &-point {
                font-size: 1.6rem;
            }
        }
    }

    &__add-discount + &__add-discount {
        padding-top:4rem;
    }

    &__regular-deli {

        li {
            display: flex;
            justify-content: space-between;
            font-size: 1.6rem;
        }

        li + li {
            margin-top: 1.6rem;
        }

        strong {
            text-align: right;
        }
    }

    &__regular-deli-card {
        // background-image: url(../images/@dummy/pay_card_img@3x.png);
        background-size: cover;
    }

    .gift {
        &__msg-box {
            padding:0; 
        }
    }

    &__modal-full {

        .modal__body {
            height: 100%;
            padding-bottom:17rem;
            overflow-y: auto;
        }

        .content {
            padding-top:1rem;
            margin:0 2.8rem;
        }

        .select-box + .select-box {
            margin-top:1.2rem;
        }

        .select-box.is-active {
            border-color: transparent;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
        }

        .select {

            &-box {
                width:100%;
                padding:2rem;
                border-radius: 0.8rem;
                border:1px solid #cfcfcf;
            }

            &-name {
                font-size: 2rem;
            }

            &-wrap {
                margin: 1.6rem 0 0 3rem;
            }

            &--txt {
                display: block;
                font-size: 1.6rem;
            }

            &--contact {
                display: block;
                margin-top: 1.3rem;
                font-size: 1.6rem;
                color:#9a9a9a;
                word-break: break-all;
            }

            &--disc {
                display: block;
                margin-top: 0.8rem;
                font-size: 1.3rem;
                color:#636363;
            }
        }

        .btn-area {
            margin-top:6rem;
        }

        .btn-modal {
            width:100%;
        }

        .btn-add-deli {
            width: calc(100% - 4rem);
            position: relative;
            /* z-index: 1; */
            margin-bottom:2rem;
        }
        .btn-box--fixed-bg {
            display: block;
            position: absolute;
            bottom: 10.5rem;
            left: 0;
            width: 100%;
            height: 8rem;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 40%, white 70%);
            content: '';
            z-index: -1;
        }
    }

    &__event {

        .section + .section {
            margin-top:2.2rem;
        }

        .banner-img {
            margin-left:-2rem;
            margin-right:-2rem;
        }

        &-btn {
            margin-top:1.6rem;
        }

        .comment--box {
            margin-top:4rem;
            .review-box {
                margin-top:1.2rem;
            }
        }

        .order__policy {
            padding-top:4rem;
            margin-left:-2rem;
            margin-right:-2rem;

            &--wrap{
                padding-top:1.6rem;
            }
        }

        .e-request-btn {
            margin-top:4rem;
        }

        &--noti {
            padding-left:2rem;
            padding-right:2rem;
            margin-left:-2rem;
            margin-right:-2rem;

            &.board__view--header {
                padding-top: 3.2rem;
                padding-bottom: 2rem;
            }

        }
    }

    .product__result-box {
        .container {
            padding-left:0;
            padding-right:0;
        }
    }

    &__info {

        display: flex;
        font-size: 1.6rem;
        .title {
            width: auto;
            min-width: 8rem;
            display: inline-block;
            font-weight: normal;
            white-space: nowrap;
        }

        .content {
            font-weight: 600;
        }
    }

    &__info + &__info {
        margin-top: 1.6rem;
    }
}


.review {

    .no-search {
        padding-top:6rem;
        margin-top:0;

        img {
            width: 10.2rem;
        }
        span {
            display: block;    
        }
    }
    
    .checkbox {
        input[type="checkbox"] + label:before, input[type="checkbox"] + label:after {
            width:2rem;
            height:2rem;
            background-size: 1.2rem;
        }
        input[type="checkbox"] + label {
            padding-left: 2.6rem;
            font-size: 1.5rem;
        }
    }

    &-box {

        border-top:1px solid #d4d4d4;

        li {
            width:100%;
            padding:1.2rem 0;
            border-bottom:1px solid #d4d4d4;
        }

        .btn--more {
            margin-top: 1.2rem;
            font-size: 1.2rem;
            color:#00a972;
            text-decoration: underline;
        }

        .comment-view {
            margin-top: 1.2rem;
            font-size: 1.2rem;
            color:#0a0a0a;
            text-decoration: underline;
        }

        // &.review--detail {
        //     border-top: 0;
        // }
    }

    &__unit {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.8rem;

        .product__detail--stars {
            margin-top:0;
        }

        .btn--report {
            font-size: 1.2rem;
            color:#9a9a9a;
            text-decoration: underline;
        }

        .btn--comment {
            font-size: 1.2rem;
            color:#00a972;
            text-decoration: underline;
        }

        .badge--comment {
            padding: 0.6rem 0.8rem;
            font-size: 1.1rem;
            font-weight: normal;
            background-color: #0a0a0a;
        }
    }


    &__write {
        font-size: 1.2rem;
        .user::after {
            content: '';
            display: inline-block;
            position: relative;
            top:2px;
            width:1px;
            height:1.2rem;
            margin:0 0.8rem;
            background-color: #9a9a9a;
        }
        .date {
            color:#636363;
        }
    }

    &__write + &__content {
        margin-top:1.2rem;
    }

    &__content {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .thumb {
            display: flex;
            align-items: center;
            width:9rem;
            height:9rem;
            margin-right: 1.6rem;
            overflow: hidden;
        }

        .thumb + .text-box {
            max-width:calc(100% - 10.6rem);
            margin-left:1.6rem;
        }

        .text-box {
            display: -webkit-box;
            max-height: 6rem;
            font-size: 1.6rem;
            overflow: hidden;
            word-wrap: break-word;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;

            &.is-active {
                max-height:100%;
                -webkit-line-clamp: inherit;
            }
        }
    }

    &__report {

        &--box {
            .desc {
                margin-bottom:2rem;
            }
        }
        .desc {
            text-align: left;
        }

        &--list {

            margin:-1rem -1.5rem;

            li {
                display: inline-block;
                min-width: calc(45% - 1.5em);
                margin:1rem 1.5rem;

                &:last-child {
                    margin-bottom:0;
                }

                &.is-photo {
                    display: block;
                }
            }

            .radio-box > span {
                font-size: 1.4rem;
            }
            .radio-box input[type="radio"] + span {
                margin-left:0.7rem;
            }
        }

        &--txtarea {

            margin-top:3rem;

            .textarea {
                padding:1.2rem 1.6rem;
                margin-top:1.2rem;
            }
        }
    }

    

    &__comment {
        display: none;
        margin-top: 1.6rem;


        &.is-active {
            display: block;
        }

        .comment-box {
            padding: 2rem 3.5rem 2rem 3.5rem;
            background-color: #f2f2f2;
        }

        .comment-box + .comment-box {
            margin-top: 1.6rem;
        }

        .review__write {
            &::before {
                display: inline-block;
                content: '';
                width: 1.2rem;
                height: 1.2rem;
                margin-left: -1.9rem;
                margin-right: 0.3rem;
                background-image: url(/assets/images/ico/ico_re_arrow.svg);
                background-size: cover;
            }
        }
    }
   
}

.total-sum {
    .order__payment-info--box {
        font-size:1.8rem;
    }
}

@media (max-width:377px){

    .review__report--list {

        height: 120px;
        margin:-1rem -1.5rem 0 -1.5rem;
        overflow-y: scroll;

        li {
            display: block;
            margin:0.1rem 1.5rem 2rem 1.5rem;
        }
    }
}

@media (min-width:601px){
    .review__report--list {

        height: 150px;
        margin:0 -1.5rem;
        overflow-y: scroll;

        li {
            display: block;
            margin:0.1rem 1.5rem 2rem 1.5rem;
        }
    }
}

.order-complete {

    padding: 4rem 2rem;

    &__top {
        padding-bottom: 4rem;

        .top-img {
            width: 13.6rem;
            margin: 0 auto 3rem auto;
        }

        .top-txt {
            text-align: center;
            &--title {
                font-size: 2rem;

                &.bold-regular {
                    font-weight: 400;
                }
            }

            &--content {
                display: inline-block;
                margin-top:2rem;
                font-size: 1.5rem;
            }
        }
    }

    &__info {
        li {
            padding: 2rem 0;
            border-top: 1px solid #CFCFCF;
        }

        li:last-child {
            border-bottom: 1px solid #CFCFCF;
        }

        span {
            display: block;
            font-size: 1.5rem;
        }

        .msg {
            font-size: 1.2rem;
        }
    }

    &__destination {
        .title {
            font-size: 1.8rem;
            margin-bottom: 2rem;
        }

        .name {
            strong::after {
                content: '';
                display: inline-block;
                width:1px;
                height: 1.2rem;
                margin: 0 0.8rem;
                background-color: #b8b8b8;
            }
        }

        .txt-box {
            span + span {
                margin-top:1.2rem;
            }
        }

    }

    &__amount {

        > div {
            display: flex;
            justify-content: space-between;
        }

        div + div {
            margin-top:1.4rem;
        }
        
        .amount {
            font-size: 1.8rem;
            color:#ff4646;
        }

        .account-num {

            &-txt {
                display: inline-block;
                border-bottom:1px solid #0a0a0a;
            }

            img {
                width:1.6rem;
                height:1.6rem;
                vertical-align: middle;
            }
        }
    }

    &__benefit {
        display: flex;
        justify-content: space-between;

        .point {
            text-align: right;
            font-size: 1.8rem;
            color:#00a972;
        }

        .msg {
            margin-top:0.6rem;
        }
    }

    .order__regular-deli {

        margin-bottom: 2rem;

        li {
            font-size: 1.5rem;
        }
    }
}

.destination {

    &--title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .btn--line--gray {
        width: auto;
        padding: 0.6rem;
        border-radius: 0.6rem;
        font-size: 1.3rem;
    }

    &--address {
        line-height: 2.2rem;
        font-size:1.6rem;
    }

    .recipient-contact {
        margin-top: 0.8rem;
        color:#9a9a9a;
        span {
            margin-left: 0.8rem;
            color:#0a0a0a;
        }
    }
}

.label-default-deli {
    display: inline-block;
    padding:0.4rem 0.8rem;
    margin-left:0.8rem;
    vertical-align: middle;
    font-size: 1.1rem;
    color:#666;
    border-radius: 10rem;
    background-color: #e9e9e9;
}

.gift-process {
    &--box {
        padding-bottom: 4rem;
        margin: 0;

        .img-category__panel{
            display: block;
            margin: 0;

            li {
                display: inline-block;
                width: calc(25% - 2.8rem);
                margin: 0 1.4rem;
                text-align: center;
                vertical-align: top;
            }

            li:first-child .thumb {
                background-image: url(../images/ico/ico_noti_talk.svg);
            }
            li:nth-child(2) .thumb {
                background-image: url(../images/ico/ico_selex.svg);
            }
            li:nth-child(3) .thumb {
                background-image: url(../images/ico/ico_deli_dest.svg);
            }
            li:last-child .thumb {
                background-image: url(../images/ico/ico_gift.svg);
            }

            .title {
                font-size: 1.3rem;
                word-break: keep-all;
            }
        }
    }
}

.btn--solid--black.btn-add-deli {
    .text::after {
        content: '';
        display: inline-block;
        width: 2rem;
        height: 2rem;
        margin-left: 0.8rem;
        background-image: url(../images/ico/ico_plus_white.svg);
        background-size: contain;
        vertical-align: middle;
    }
}

.btn-add-deli {
    .text::after {
        content: '';
        display: inline-block;
        width: 2rem;
        height: 2rem;
        margin-left: 0.8rem;
        background-image: url(../images/ico/ico_plus.svg);
        background-size: contain;
        vertical-align: middle;
    }
}

.btn-add-deli.type2 {
    .text {
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1.6rem;
        &::after {
            content: '';
            display: inline-block;
            width: 1.6rem;
            height: 1.6rem;
            margin-left: 0.8rem;
            background-image: url(../images/ico/plus.svg);
            background-size: contain;
            vertical-align: middle;
        }
    }
}

.product-inquiry {
    margin-top:2rem;
    select {
        margin-bottom:0.8rem;
    }
}

.final-pament {

    padding-top:2rem;

    &--box {
        display: flex;
        justify-content: space-between;
        font-size: 2rem;
    }

    &--point {
        display: block;
        margin-top:0.6rem;
        text-align: right;
        font-size: 1.2rem;
        color:#00a972;
    }
}

.order__coupon--point input[type=number],
.order__coupon--point input.count,
.order__coupon--point input {
    padding: 0;
    margin-left: 1rem;
    border: 0;
    text-align: right;
    font-weight: 600;

    &:focus {
        border: 0;
        outline: 0;
    }
}

.order-form--addbtn .control {
    display: flex;
}

@media (max-width: 500px) {
    .order-form--addbtn .control {
        flex-direction: column;

        .btn-input {
            margin-top: 0.4rem;
            margin-left: 0;
            height: 4.5rem;

        }
    }
}

.order__title--type02 {
    margin-bottom: 1.6rem;

    .title {
        font-size: 1.8rem;
        color: #0a0a0a;
        font-weight: 600;
        line-height: 2.8rem
    }
}

.refund {
    display: none;

    &.is-active {
        display: block;
    }
}




.pay__card {
    &--info {
        overflow: hidden;
        position: relative;
        width: 100%;
        background-color: #00a972;
        background-image: url(../images/bg/bg_card_default.svg);
        background-size: cover;
        border-radius: 1.6rem;
        margin-top: .6rem;
        &::after {
            content: '';
            display: block;
            padding-bottom: 63%;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(255, 255, 255, .85);
            z-index: 3;
            transform: scale(1.1);
        }
    }

    &--text {
        position: absolute;
        left: 2rem;
        bottom: 2.4rem;
        width: calc(100% - 4rem);
    }

    &--name {
        display: block;
        color: #fff;
        font-size: 1.8rem;
    }

    // &--add {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     text-align: center;
    //     border: 2px dashed #bbb;
    //     background-color: #f9f9f9;
    //     background-image: none;

    //     &::before {
    //         display: none;
    //     }

    //     &-btn {
    //         width: 100%;
    //     }
    // }

    &--radio {
        position: absolute;
        top: 1.3rem;
        right: 1.4rem;
        background-color: red;
        width: 3.2rem !important;
        height: 3.2rem !important;
        border-radius: 50%;
        z-index: 2;
        margin: 0 !important;
        clip: auto !important;
    }
    
    &--num {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: .8rem;
        color: #fff;
        font-size: 0;
        
        max-width: 24rem;
        width: 100%;

        .text {
            display: flex;
            justify-content: flex-start;
            // flex: 1;
        }

        .num  {
            font-size: 1.6rem;
            letter-spacing: 2px;
        }
    }
    
    &--dot {
        display: inline-block;
        width: .5rem;
        height: .5rem;
        background-color: #fff;
        border-radius: 50%;

        margin-left: .5rem;

        &:first-child {
            margin-left: 0;
        }

        &-box {
            display: flex;
            justify-content: center;
            align-items: center;

            &.--two {
                margin-left: .4rem;
            }
        }
    }
}

.order__selex-pay--wrap {
    .swiper-slide-active {
        .pay__card--info {
            background-image: url(../images/bg/bg_card_check.svg);
            box-shadow: 0 0 8px rgba(0,0,0,.4);

            &::before {
                display: none;
            }

            &.add-payment {
                background-image: none;
                box-shadow: none;
            }
        }
    }
}

.order .control.credit-number {
    display: flex;
    align-items: center;

    input[type=text], 
    input[type=password] {
        // padding: 1.2rem 1.6rem;
        padding: 1.2rem 0;
        text-align: center;
    }

    
}

.order .control.credit-date {
    display: flex;
    align-items: center;
}