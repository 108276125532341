.footer {
    position: relative;
    padding-top: 2.8rem;
    background-color: #e9e9e9;
    color: #0a0a0a;
    line-height: 1.6rem;

    // &::before {
    //     content: '';
    //     display: block;
    //     width: 100%;
    //     height: 1rem;
    //     background-color: #e9e9e9;   
    // }

    .container {
        padding-bottom: 9.8rem;
    }

    &__call {
        font-size: 0;
        font-weight: 600;
        line-height: 1;

        span {
            
        font-size: 1.4rem;
        }

        a {
            margin-left: 1.2rem;
            font-size: 1.4rem;
            // font-weight: bold;
        }
    }

    &__info {
        margin-top: .75rem;
        font-size: 1.3rem;
        // font-weight: 500;
        color: #636363;
        line-height: 1.8rem;
    }

    &__sns {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 1rem;
        font-size: 0;


        li {
            + li {
                // margin-left: 1rem;
                margin-left: 1.6rem;
            }
        }

        .thumb {
            overflow: hidden;
            width: 2.4rem;
            height: 2.4rem;
            background-color: #9a9a9a;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.5rem;

            // img {
            //     width: 100%;
            // }
        }
    }

    &__company {
        margin-top: 2.8rem;

        .btn {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: -1rem;
            margin-left: -1.6rem;
            margin-right: -1.6rem;
            font-size: 1.4rem;
            font-weight: 600;
            color: #0a0a0a;

            &::after {
                content: '';
                display: inline-block;
                width: 1.6rem;
                height: 1.6rem;
                margin-left: .8rem;
                background-image: url(../images/ico/btn_arr_up.svg);
                background-size: cover;
                transform: rotate(180deg);
            }
        }

        &.is-active {
            .footer__address {
                display: block;
            }

            .btn {
                &::after {
                    content: '';
                    transform: rotate(0);
                }
            }
        }
    }

    &__address {
        display: none;
        margin-top: .3rem;
        font-size: 1.3rem;
        font-style: normal;
        color: #636363;
        line-height: 2rem;
        padding-bottom: 2rem;
        
        li {
            display: block;
        }

        &--btn {
            font-weight: 700;
        }
    }

    // &__util {
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;
    //     margin-top: 1.8rem;
    // }

    &__nav {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // padding-top: 2rem;
        // margin-top: 2rem;
        // border-top: 1px solid #707070;

        li {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                width: 1px;
                height: 1.2rem;
                background-color: #9a9a9a;
            }
            
            a {
                font-size: 1.3rem;
                padding: .8rem;
                color: #636363;
            }

            &:first-child {
                margin-left: -.8rem;
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }

            &:nth-child(4) {
                a {
                    font-weight: 700;
                }
            }
        }
    }
}