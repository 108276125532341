// 샵 메인 스위치 버튼
.switch {
	// display: flex;
	// justify-content: center;

	&__box {
		position: relative;
		display: block;
		width: 10rem;
		height: 2.4rem;
		background: #e9e9e9;
		border-radius: 1.2rem;

		input {

			&:checked {
				+ .switch__slider:before {
					transform: translate(4.6rem, -50%);
				}

				~ .switch__title {
					&::after {
						font-weight: 700;
						color: #fff;
						transition: color 0.3s ease 0s;
					}
					
					&::before {
						font-weight: 500;
						color: #0a0a0a;
						transition: color 0.3s ease 0s;
					}
				}
			}
		}
	}

	&__title {

		display: flex;
		justify-content: space-around;
		max-width: calc(100% - 0.4rem);
		align-items: center;
		margin-left: 0.2rem;
		height: 100%;


		&::before {
			content: "기능별";
			position: relative;
			z-index: 5;
			font-weight: 700;
			font-size: 1.1rem;
			color: #fff;
			cursor: pointer;
			transition: color 0.3s ease 0s;
		}

		&::after {
			content: "성분별";
			position: relative;
			z-index: 5;
			font-weight: 500;
			font-size: 1.1rem;
			color: #0a0a0a;
			cursor: pointer;
			transition: color 0.3s ease 0s;
		}
	}

	&__slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transition: 0.3s;

		&::before {
			position: absolute;
			content: "";
			width: 5rem;
			height: 2rem;
			left: .2rem;
			top: 50%;
			background-color: #000;
			transition: all 0.4s ease 0s;
			border-radius: 1rem;
			transform: translate(0, -50%);
		}
	}
}

// 공통 스위치 버튼
.switch--small {
	position: relative;
    font-size: 0;
    margin-top: 3px;

	&-box {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		font-size: 0;
		
		.title {
			font-size: 1.4rem;
			color: #0a0a0a;
		}

		+ .switch--small-box {
			margin-top: 2.7rem;
		}

		.sub-title {
			margin-top: .4rem;
			font-size: 1.2rem;
			color: #9a9a9a;
		}
	}

	&__input {
		position: absolute;
		/* hidden */
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: none;

		 + .switch--small__label {
			position: relative;
			cursor: pointer;
			display: inline-block;
			width: 4.6rem;
			height: 1.2rem;
			background-color: #bbb;
			border-radius: 0.6rem;
			transition: 0.2s;
			padding-left: 0;
			font-size: 0;
			line-height: 1;
			vertical-align: middle;
		
			&::before {
				display: none;
			}
		}

		&:checked {
			+.switch--small__label {
				background-color: #00a972;

				&::after {
					display: none;
				}

				.switch--small__btn {
					left: 22px;
					
				}
			}

		}

	}

	&__label {
		

		// &:hover {
		// 	background: #efefef;
		// }
	}

	&__btn {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		display: inline-block;
		width: 2.4rem;
		height: 2.4rem;
		border-radius: 20px;
		// background: #bbb;
		transition: 0.2s;
		background-color: #fff;
		border: solid 1px #f2f2f2;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.4);
	}
}