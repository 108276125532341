/**
* SassFlexbox
* Manage Flexbox in Sass easily.
*
* @author     Samuel Marchal (zessx)
* @version    0.1
*/


/*
    Display
 */
%display-flex {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flexbox;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
%display-inline-flex {
  display: -webkit-inline-flex;
  display: inline-flex;
}
@mixin display-flex() {
  @extend %display-flex;
}
@mixin display-inline-flex() {
  @extend %display-inline-flex;
}

/*
    Flex direction
 */
%flex-direction-row {
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
%flex-direction-row-reverse {
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -moz-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -moz-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
%flex-direction-column {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
%flex-direction-column-reverse {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-direction: reverse;
  -moz-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -moz-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
%flex-direction-inherit {
  -webkit-box-orient: inherit;
  -moz-box-orient: inherit;
  -webkit-flex-direction: inherit;
  -moz-flex-direction: inherit;
  -ms-flex-direction: inherit;
  flex-direction: inherit;
}
@mixin flex-direction($direction: row) {
  @if not index((row, row-reverse, column, column-reverse, inherit), $direction) {
    $direction: row;
  }
  @extend %flex-direction-#{$direction};
}

/*
    Flex wrap
 */
%flex-wrap-nowrap {
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: none;
  flex-wrap: nowrap;
}
%flex-wrap-wrap {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
%flex-wrap-wrap-reverse {
  -webkit-flex-wrap: wrap-reverse;
  -moz-flex-wrap: wrap-reverse;
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}
%flex-wrap-inherit {
  -webkit-flex-wrap: inherit;
  -moz-flex-wrap: inherit;
  -ms-flex-wrap: inherit;
  flex-wrap: inherit;
}
@mixin flex-wrap($wrap: nowrap) {
  @if not index((nowrap, wrap, wrap-reverse, inherit), $wrap) {
    $wrap: nowrap;
  }
  @extend %flex-wrap-#{$wrap};
}

/*
    Flex flow
 */
@mixin flex-flow($direction, $wrap) {
  @include flex-direction($direction);
  @include flex-wrap($wrap);
}

/*
    Order
 */
@mixin order($order: 0) {
  -webkit-box-ordinal-group: ($order + 1);
  -moz-box-ordinal-group: $order;
  -ms-flex-order: $order;
  -webkit-order: $order;
  -moz-order: $order;
  order: $order;
}

/*
    Flex grow
 */
@mixin flex-grow($grow: 0) {
  -webkit-flex-grow: $grow;
  -webkit-box-flex: $grow;
  -moz-flex-grow: $grow;
  -ms-flex-positive: $grow;
  flex-grow: $grow;
}

/*
    Flex shrink
 */
@mixin flex-shrink($shrink: 1) {
  -webkit-flex-shrink: $shrink;
  -webkit-box-flex: $shrink;
  -moz-flex-shrink: $shrink;
  -ms-flex-negative: $shrink;
  flex-shrink: $shrink;
}

/*
    Flex basis
 */
@mixin flex-basis($basis: auto) {
  -webkit-flex-basis: $basis;
  -moz-flex-basis: $basis;
  -ms-flex-preferred-size: $basis;
  flex-basis: $basis;
}

/*
    Flex
 */
@mixin flex($grow: 0, $shrink: 1, $basis: auto) {
  @include flex-grow($grow);
  @include flex-shrink($shrink);
  @include flex-basis($basis);
}

/*
    Justify content
 */
%justify-content-flex-start {
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -webkit-flex-pack: start;
  -ms-flex-pack: start;
  -moz-justify-content: flex-start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
%justify-content-flex-end {
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -webkit-flex-pack: end;
  -ms-flex-pack: end;
  -moz-justify-content: flex-end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
%justify-content-center {
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -webkit-flex-pack: center;
  -ms-flex-pack: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  justify-content: center;
}
%justify-content-space-between {
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -webkit-flex-pack: justify;
  -ms-flex-pack: justify;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
%justify-content-space-around {
  -moz-justify-content: space-around;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}
%justify-content-inherit {
  -webkit-box-pack: inherit;
  -moz-box-pack: inherit;
  -webkit-flex-pack: inherit;
  -ms-flex-pack: inherit;
  -moz-justify-content: inherit;
  -webkit-justify-content: inherit;
  justify-content: inherit;
}
@mixin justify-content($justify: flex-start) {
  @if not index((flex-start, flex-end, center, space-between, space-around, inherit), $justify) {
    $justify: flex-start;
  }
  @extend %justify-content-#{$justify};
}

/*
    Align items
 */
%align-items-flex-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  align-items: flex-start;
}
%align-items-flex-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  align-items: flex-end;
}
%align-items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
}
%align-items-baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  -moz-align-items: baseline;
  align-items: baseline;
}
%align-items-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  -moz-align-items: stretch;
  align-items: stretch;
}
%align-items-inherit {
  -webkit-box-align: inherit;
  -ms-flex-align: inherit;
  -webkit-align-items: inherit;
  -moz-align-items: inherit;
  align-items: inherit;
}
@mixin align-items($align: stretch) {
  @if not index((flex-start, flex-end, center, baseline, stretch, inherit), $align) {
    $align: stretch;
  }
  @extend %align-items-#{$align};
}

/*
    Align self
 */
%align-self-auto {
  -ms-flex-item-align: auto;
  -webkit-align-self: auto;
  -moz-align-self: auto;
  align-self: auto;
}
%align-self-flex-start {
  -ms-flex-item-align: start;
  -webkit-align-self: flex-start;
  -moz-align-self: flex-start;
  align-self: flex-start;
}
%align-self-flex-end {
  -ms-flex-item-align: end;
  -webkit-align-self: flex-end;
  -moz-align-self: flex-end;
  align-self: flex-end;
}
%align-self-center {
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  -moz-align-self: center;
  align-self: center;
}
%align-self-baseline {
  -ms-flex-item-align: baseline;
  -webkit-align-self: baseline;
  -moz-align-self: baseline;
  align-self: baseline;
}
%align-self-stretch {
  -ms-flex-item-align: stretch;
  -webkit-align-self: stretch;
  -moz-align-self: stretch;
  align-self: stretch;
}
%align-self-inherit {
  -ms-flex-item-align: inherit;
  -webkit-align-self: inherit;
  -moz-align-self: inherit;
  align-self: inherit;
}
@mixin align-self($align: auto) {
  @if not index((auto, flex-start, flex-end, center, baseline, stretch, inherit), $align) {
    $align: auto;
  }
  @extend %align-self-#{$align};
}

/*
    Align content
 */
%align-content-flex-start {
  -ms-flex-line-pack: start;
  -webkit-align-content: flex-start;
  -moz-align-content: flex-start;
  align-content: flex-start;
}
%align-content-flex-end {
  -ms-flex-line-pack: end;
  -webkit-align-content: flex-end;
  -moz-align-content: flex-end;
  align-content: flex-end;
}
%align-content-center {
  -ms-flex-line-pack: center;
  -webkit-align-content: center;
  -moz-align-content: center;
  align-content: center;
}
%align-content-space-between {
  -ms-flex-line-pack: space-between;
  -webkit-align-content: space-between;
  -moz-align-content: space-between;
  align-content: space-between;
}
%align-content-space-around {
  -ms-flex-line-pack: space-around;
  -webkit-align-content: space-around;
  -moz-align-content: space-around;
  align-content: space-around;
}
%align-content-stretch {
  -ms-flex-line-pack: stretch;
  -webkit-align-content: stretch;
  -moz-align-content: stretch;
  align-content: stretch;
}
%align-content-inherit {
  -ms-flex-line-pack: inherit;
  -webkit-align-content: inherit;
  -moz-align-content: inherit;
  align-content: inherit;
}
@mixin align-content($align: stretch) {
  @if not index((flex-start, flex-end, center, space-between, space-around, stretch, inherit), $align) {
    $align: stretch;
  }
  @extend %align-content-#{$align};
}