.app-lead {
    border-top: 0;
    z-index: 5;
    padding-top: 6.8rem;
    position: relative;

    &__title {
        display: block;
        color: #00a972;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
    }

    &__text {
        display: block;
        color: #616161;
        font-weight: 400;
        margin-top: 2px;
        font-size: 14px;
    }

    &__link {
        display: block;
        position: relative;
        max-width: 768px;
        margin: 0 auto;
        padding: 15px 0;
    }

    &__btn {
        position: absolute;
        top: 50%;
        right: 16px;
        padding: 0 8px;
        margin-top: -14px;
        border-radius: 6px;
        background-color: #00a972;
        color: #fff;
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 28px;
    }

    .container {
        position: fixed;
        top: 0;
        // left: 0;
        // right: 0;
        z-index: 4;
        width: 100%;
        background-color: #fff;
        transition: .3s;
        transform: translateY(0);

        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background-color: #dcdcdc;
        }
    }

    &.is-up {
        .container {
            transform: translateY(-6.8rem);

        }
    }
}

// pc접속시
.view--pc {
    .app-lead {
        max-width: 640px;
    }
}

// @media (min-width: 1230px) {
//     .view--pc {
//         .app-lead {
//             padding-top: 6.8rem; .container {
//                 transform: translateY(0);
//             }
//         }
//     }
// }

// @media (min-width: 1230px) {
//     .view--pc {
//         .app-lead {
//             padding-top: 0;

//             .container {
//                 transform: translateY(-6.8rem);
//             }
//         }
//     }
// }