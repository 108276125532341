
// 배너
.container {
    &.banner--main {
        padding: 0;
    }
}

.banner--full {
    margin-left: -2rem;
    margin-right: -2rem;

    + .product-box {
        padding: 1.25rem;
        margin-top: -4rem;
    }
}

.dashboard {
    padding-top: 2rem;
    padding-bottom: 2rem;

    &__header {
        color: #0a0a0a;

        .name {
            display: block;
            font-size: 2.1rem;
            font-weight: bold;
            line-height: 1.4;
        }

        .msg {
            margin-top: .6rem;
            font-size: 1.8rem;
            line-height: 1.5;
        }
    }

    &__link {
        display: block;
        overflow: hidden;
        padding: 2px;
        background-image: linear-gradient(139deg, #4bd2a6 16%, #4bd2a6 21%, #11b187 32%, #81bcff 70%, #164f90 83%);
        border-radius: 50%;
    }

    &__simple {
        position: relative;
        margin-top: 1.6rem;

        &--list {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: calc(100% - 2.6rem);

            li {
                flex: 1;
                + li {
                    margin-left: 1.2rem;
                }

                .thumb {
                    display: block;
                    width: 100%;
                    background-position: center;
                    background-size: 54%;
                    background-repeat: no-repeat;
                    background-color: #f2f2f2;
                    border: 2px solid #fff;
                    border-radius: 50%;

                    &:after {
                        content: "";
                        display: block;
                        padding-bottom: 100%;
                    }
                }

                &:first-child {
                    .thumb {
                        background-image: url(../images/ico/ico_meal.svg);
                    }
                }

                &:nth-child(2) {
                    .thumb {
                        background-image: url(../images/ico/ico_selex.svg);
                    }
                }

                &:nth-child(3) {
                    .thumb {
                        background-image: url(../images/ico/ico_exercise.svg);
                    }
                }

                &:nth-child(4) {
                    .thumb {
                        background-image: url(../images/ico/ico_walk.svg);
                    }
                }

                &.has-success {
                    .dashboard__link {
                        padding: 0;
                        background-image: none;
                    }

                    .thumb {
                        background-color: #ebebeb;
                        border: solid 2px #bbb;
                    }

                    &:first-child {
                        .thumb {
                            background-image: url(../images/ico/ico_meal_d.svg);
                        }
                    }

                    &:nth-child(2) {
                        .thumb {
                            background-image: url(../images/ico/ico_selex_d.svg);
                        }
                    }

                    &:nth-child(3) {
                        .thumb {
                            background-image: url(../images/ico/ico_exercise_d.svg);
                        }
                    }

                    &:nth-child(4) {
                        .thumb {
                            background-image: url(../images/ico/ico_walk_d.svg);
                        }
                    }
                }
            }
        }
        
    }

    &__btn {
        display: inline-block;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        margin-top: -8px;
        right: 0;
        transform: rotate(180deg);
        background-image: url(../images/ico/ico_arr_v.svg);
        background-size: contain;
        background-repeat: no-repeat;
        transition: all .3s;

        &.is-active {
            transform: rotate(0);
        }
    }

    &__detail {
        overflow: hidden;
        position: relative;
        max-height: 0;
        transition: all .3s ease-in-out;
        z-index: 0;

        &--list {
            font-size: 0;
            margin: -.4rem;
            margin-top: .8rem;

            li {
                display: inline-block;
                margin: .4rem;
                width: calc(50% - .8rem);
                
                .dashboard__card  {
                    background-position: top 12px right 12px;
                    background-repeat: no-repeat;
                }

                &:first-child {
                    .dashboard__card {
                        background-image: url(../images/ico/ico_meal.svg);
                    }
                }

                &:nth-child(2) {
                    .dashboard__card {
                        background-image: url(../images/ico/ico_selex.svg);
                    }
                }

                &:nth-child(3) {
                    .dashboard__card {
                        background-image: url(../images/ico/ico_exercise.svg);
                    }
                }

                &:nth-child(4) {
                    .dashboard__card {
                        background-image: url(../images/ico/ico_walk.svg);
                    }
                }

                &.is-success {
                    .dashboard__card--title {
                        color: #9a9a9a;
                    }

                    .dashboard__card--desc {
                        font-size: 1.1rem;
                        font-weight: 400;
                        color: #9a9a9a;
                        vertical-align: bottom;
                    }

                    &:first-child {
                        .dashboard__card {
                            background-image: url(../images/ico/ico_meal_d.svg);
                        }
                    }

                    &:nth-child(2) {
                        .dashboard__card {
                            background-image: url(../images/ico/ico_selex_d.svg);
                        }
                    }

                    &:nth-child(3) {
                        .dashboard__card {
                            background-image: url(../images/ico/ico_exercise_d.svg);
                        }
                    }

                    &:nth-child(4) {
                        .dashboard__card {
                            background-image: url(../images/ico/ico_walk_d.svg);
                        }
                    }
                }
            }
        }
    }

    &__card {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        height: 6.8rem;
        padding: 1.2rem;;
        border-radius: 8px;
        border: solid 1px #bbb;
        background-color: #fff;
        color: #0a0a0a;

        &--title {
            font-size: 1.4rem;
            font-weight: 400;
        }

        &--desc {
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: .8rem;
            font-size: 1.6rem;
            font-weight: bold;

            small {
                margin-left: .4rem;
                font-size: 1.1rem;
                font-weight: normal;
            }
        }
    }
}

.best-product-swiper {
    margin-left: -2rem;
    margin-right: -2rem;
    padding-left: 2rem;
    padding-right: 2rem;

    .product__thumb {
        width: 100%;
    }

    &.--shop {
        margin-top: 2rem;
        margin-bottom: 5rem;
    }
}

.mainVisual {
    .profile {
        align-items: center;

        .txt-box {
            padding: 1.5rem 2.2rem;
            border-radius: 1.2rem;
            background-color: #fff;
            box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.3);;
        }

        .img {
            width: 8rem;
            margin-right: 1.6rem;
        }

        .txt {
            font-size: 1.5rem;
            line-height: 1.4;
            // word-break: keep-all;
        }
    }

    .benefit {
        margin-top: 2.3rem;

        .benefit-list {
            display: flex;
            justify-content: space-between;
            flex-direction: row;

            li {
                display: inline-block;
            }

            .list-wrap {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 100%;
                padding: 1rem;
                border-radius: 1.2rem;
                box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.3);
                background-color: #fff;
            }

            .ico {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 4.8rem;
                height: 4.8rem;
                border-radius: 1.6rem;
                background-color: #f2f2f2;

                img {
                    width: 3.6rem;
                }
            }

            .txt {
                margin-top: 0.4rem;
                font-size: 1.4rem;
                text-align: center;
            }
        }            
    }

    .main__top {
        padding-top: 1.6rem;

        &.--logout {
            .benefit-list {
                li {
                    width: 33%;
                }

                li + li {
                    margin-left: 1rem;
                }
            }
        }

        &.--login {
            .benefit-list {
                li {
                    width: calc(25% - 0.8rem);
                }

                li + li {
                    margin-left: 0.8rem;
                }

                .ico {
                    position: relative;

                    &::after {
                        position: absolute;
                        top: 0;
                        right: 0;
                        display: inline-block;
                        content: '';
                        width: 1rem;
                        height: 1rem;
                        border-radius: 50%;
                        border: 2px solid #fff;
                        background-color: #ff4646;
                    }
                }

                .sucess {
                    .ico {
                        &::after {
                            display: none;
                        }

                        &::before {
                            position: absolute;
                            top: -5px;
                            right: -4px;
                            display: inline-block;
                            content: '';
                            width: 2.9rem;
                            height: 2.9rem;
                            background-image: url(/assets/images/ico/ico_mission_ok.svg);
                            background-size: cover;
                        }
                    }
                }
            }
        }
    }

    .btn {
        margin-top: 0.8rem;
    }
}

@media (max-width: 400px) {
    .mainVisual .benefit-list .list-wrap {
        .block {
            display: block;

            &.one {
                line-height: 3.2rem;
            }
        }
    }
}

.main {
    &__card {
        padding: 2.4rem;
        border-radius: 1.2rem;
        background-color: #fff;
        box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.3);
        overflow: hidden;

        img {
            width: 100% !important;
            height: auto !important;
        }

        .link {
            display: block;
            margin: -2.4rem;
            padding: 2.4rem;
        }
        
        .title-box {
            margin-bottom: 2rem;

            &.--bottom {
                margin-top: 2rem;
                margin-bottom: 0;
            }

            .title {
                font-size: 1.9rem;
                font-weight: normal;
                line-height: 1.36;
            }

            .desc {
                margin-top: 0.8rem;
                font-size: 1.6rem;
                color: #636363;
                line-height: 1.41;
                word-break: keep-all;
            }
        }

        .content-img {
            width: 100%;
            // position: relative;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            // background-color: #f7f7f7;
            // overflow: hidden;

            // &::after {
            //     content: '';
            //     display: block;
            //     padding-bottom: 50%;
            // }

            // img {
            //     position: absolute;
            // }
        }

        &--txt {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .txt {
                flex: 1;
                font-size: 1.6rem;
                line-height: 1.44;
                word-break: keep-all;
            }

            .ico {
                width: 4.8rem;
                margin-left: 1rem;
            }
        }

        &--top {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            .title {
                margin-bottom: 1rem;
                margin-right: 1rem;
                font-size: 1.7rem;
                font-weight: normal;
                line-height: 1.41
            }
        }

        .btn {
            margin-top: 2rem;
            font-size: 1.4rem;
        }

        .btn-link {
            font-size: 1.5rem;
        }

        &--subject {
            display: flex;
            align-items: center;
            margin-bottom: 0.8rem;

            .ico {
                display: inline-block;
                width: 2rem;
                margin-right: 0.4rem;
            }

            .title {
                font-size: 1.7rem;
                font-weight: normal;
            }
        }
    }

    &__demography {
        margin-bottom: 1rem;

        .txt {
            display: inline-block;
            padding: 0.4rem 0.8rem;
            font-size: 1.6rem;
            border: 1px solid #0a0a0a;
            border-radius: 1.4rem;
        }

        .txt + .txt {
            margin-left: 0.8rem;
        }
    }

    &__product {
        .product--list {
            .product__title {
                font-size: 1.4rem;
                line-height: 1.3;
                -webkit-line-clamp: 3;
            }

            .product__price {
                .num, .unit {
                    font-size: 1.5rem;
                }
            }

            .product__del {
                font-size: 1.1rem;
            }
        }

        .product__title {
            font-size: 1.4rem;
            -webkit-line-clamp: 3;
        }

        .product__del {
            font-size: 1.1rem;
        }

        .product__price {
            .num, .unit {
                font-size: 1.4rem;
            }
        } 

        .product__subtitle {
            margin-bottom: 0.6rem;

            &.is-small {
                font-size: 1.2rem;
            }
        }

        .product--gallery {
            .product__title {
                line-height: inherit;
            }
            
            .product__info {
                margin-top: 1.4rem;
            }
            .product__subtitle {
                margin-bottom: 0.6rem;
                font-size: 1.2rem;
            }
        }
    }
}

.mainCardkeyVisual04 {
    .main__card--top {
        margin-bottom: 1rem;
    }
}

.mainCardkeyVisual05 {

    .title-box {
        margin-bottom: 1.2rem;
    }

    .point {
        &__total {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            padding: 1.6rem;
            border-radius: 0.8rem;
            background-color: #f2f2f2;

            .title {
                display: inline-block;
                font-size: 1.6rem;
                font-weight: normal;
    
                &::before {
                    display: inline-block;
                    content: '';
                    width: 2.4rem;
                    height: 2.4rem;
                    margin-right: 0.4rem;
                    vertical-align: middle;
                    background-image: url(/assets/images/ico/ico_point_join.svg);
                    background-size: cover;
                }
            }
    
            .total-point {
                font-size: 1.9rem;
                font-weight: bold;
    
                &::after {
                    display: inline-block;
                    content: '';
                    width: 2rem;
                    height: 2rem;
                    vertical-align: bottom;
                    background-image: url(/assets/images/ico/ico_point_blk.svg);
                    background-size: cover;
                }
            }
        }

        &__save {
            margin-top: 2rem;

            &--title {
                margin-bottom: 1rem;
                font-size: 1.6rem;
                font-weight: 600;
            }

            &--list {
                margin-bottom: 1rem;

                li + li {
                    margin-top: 1.2rem;
                }

                .title-box {
                    margin-bottom: 0;
                    display: flex;
                    align-items: center;
                }

                .list-wrap {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .ico {
                    display: inline-block;
                    width: 2rem;
                    margin-right: 0.4rem;
                }

                .title {
                    font-size: 1.4rem;
                    font-weight: normal;
                }

                .point {
                    font-size: 1.4rem;
                    font-weight: normal;
                }

                .criterion {
                    color: #9a9a9a;
                }
            }

            &--total {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 1rem;
                border-top: 1px solid #bbb;

                .title {
                    font-size: 1.7rem;
                    font-weight: 600;
                }

                .point {
                    font-size: 1.9rem;
                    font-weight: 600;

                    &::after {
                        display: inline-block;
                        content: '';
                        width: 2.4rem;
                        height: 2.4rem;
                        vertical-align: sub;
                        background-image: url(/assets/images/ico/ico_point_blk.svg);
                        background-size: cover;
                    }
                }
            }
        }

        &__disappear {
            margin-top: 2rem;
            padding: 0.7rem;
            font-size: 1.4rem;
            color: #fff;
            text-align: center;
            border-radius: 1.6rem;
            background-color: #00a972;
            word-break: keep-all;

            &::before {
                display: inline-block;
                content: '';
                width: 1.6rem;
                height: 1.6rem;
                margin-right: 0.2rem;
                vertical-align: bottom;
                background-image: url(/assets/images/ico/ico_tip1_white.svg);
                background-size: cover;
            }
        }
    }
}

.mainCardkeyVisual07 {
    .product-box--shop {
        padding-left: 2.4rem;
        padding-right: 2.4rem;
        margin-left: -2.4rem;
        margin-right: -2.4rem;
    }
}

.mainCardkeyVisual07, .mainCardkeyVisual08 {
    .main__demography {
        margin-bottom: 0.9rem;
    }

    .product-box--shop, .swiper-container {
        padding-left: 2.4rem;
        padding-right: 2.4rem;
        margin-left: -2.4rem;
        margin-right: -2.4rem;
    }
}

// .mainCardkeyVisual08 {
//     .main__demography {
//         text-align: right;
//     }
// }


.mainCardkeyVisual09 {
    .graph-desc {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 2rem;
        padding: 1rem;
        border-radius: 0.8rem;
        background-color: #f2f2f2;

        li {
            margin: 0.6rem;
            font-size: 1.2rem;
            color: #636363;

            &::before {
                display: inline-block;
                content: '';
                width: 1rem;
                height: 1rem;
                margin-right: 0.4rem;
                border-radius: 50%;
                vertical-align: middle;
            }

            &.--blue {
                &::before {
                    background-color: #6195ff;
                }
            }

            &.--green {
                &::before {
                    background-color: #2ccc98;
                }
            }

            &.--red {
                &::before {
                    background-color: #ff5c5c;
                }
            }
        }
    }
}

@media (max-width:382px) {
    .mainCardkeyVisual09 {
        .graph-desc {
            justify-content: flex-start;
        }
    }
}

.mainCardkeyVisual06, .mainCardkeyVisual07, .mainCardkeyVisual08 {
    .main__card .title-box {
        margin-bottom: 2rem;
    }
}

.mainCardkeyVisual11 {
    .smbti-title {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 2rem;

        .sub-title {
            display: block;
            margin-bottom: 0.8rem;
            font-size: 1.7rem;
            color: #636363;
        }

        .title {
            font-size: 2.2rem;
            font-weight: normal;
            line-height: 1.36;
        }
    }

    .title-area {
        flex: 1;
    }

    .smbti-character {
        width: 9rem;
        margin-left: 1.2rem;
    }

    .product--gallery {
        .product__title {
            line-height: inherit;
        }

        .product__info {
            margin-top: 0.8rem;
        }
    }

    .product__info--body {
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
    }

    .product__discount {
        margin-right: 0.4rem;
        font-size: 1.4rem;
    }
}

.mainCardkeyVisual12 {
    .main__card .title-box {
        margin-bottom: 0.4rem;
    }

    .chart-desc {
        font-size: 1.3rem;
        color: #9a9a9a;
    }

    .walk-graph {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin: 1.7rem -1.5rem -1.5rem -1.5rem;

        &--wrap {
            margin: 1.5rem;
        }
    }

    .donut-progrees.--walk {
        width: 15rem;
        height: 15rem;

        .donut-progrees__con {
            width: 15rem;
            height: 15rem;
        }
    }
    
    .kcal-consume {
        text-align: right;
    }

    .kcal-area {
        .img {
            width: 10rem;
        }
    }

    .txt-box {
        margin-top: 0.4rem;
        text-align: center;

        .food-name {
            display: block;
            font-size: 1.7rem;
        }

        .kcal {
            font-size: 2rem;
            font-weight: bold;
        }
    }

    .btn--tooltip {
        width: 2rem;
        height: 2rem;
        padding: 1rem;
        margin-top: 0;
        background-image: url(/assets/images/ico/ico_tip1_gray.svg);
        background-size: cover;
    }
}

#kcalTooltipModal {
    .scroll-box {
        margin-top: 0;
        border: 0;
        border-radius: 0;
        font-size: 0;
        line-height: inherit;
        color: inherit;
    }

    .tooltip-content {
        text-align: center;

        .title {
            margin-bottom: 0.8rem;
            font-size: 1.9rem;
            font-weight: 600;
        }

        .txt {
            font-size: 1.6rem;
            line-height: 2.3rem;
            word-break: break-all;

            .link {
                color: #6195ff;
            }
        }
    }

    .tooltip-content + .tooltip-content {
        margin-top: 2rem;
    }
}

// 헤더 bg 변경
.header.header--transition {
    .container {
        background-color: #f2f2f2;
        // transition: 0s;
    }

    &.is-up {
        .container {
            background-color: #fff;
        }
    }
}