.board {
    &--title-box {
        margin-top: 2.6rem;
        margin-bottom: 2rem;

        .title {
            font-size: 1.6rem;
            color: #0a0a0a;
        }
    }

    &-box {
        margin-left: -2rem;
        margin-right: -2rem;
    }

    &__lists {
        &.notice {
            margin-top: 2.4rem; 
            border-top: 1px solid #bbb;
        }

        li {
            // height: 6.2rem;
            // overflow: hidden;
            border-bottom: 1px solid #bbb;
            // vertical-align: middle;
        }

        &--title {
            font-size: 2.2rem;
            font-weight: 600;
            color: #0a0a0a;
            padding: 3.2rem 2rem 2rem;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 2rem;
        // width: 100%;
        // height: 100%;

        .badge {
            width: 4rem;
            height: 2.5rem;
            padding: 0;
            background-color: #00a972;
            border-radius: 1.25rem;
            text-align: center;
            font-size: 1.4rem;
            font-weight: bold;
            line-height: 2.5rem;
            color: #fff;

            + .title {
                margin-left: .8rem;
                width: calc(100% - 4.8rem);

                + .date {
                    margin-left: 4.8rem;
                }
            }
        }

        .title {
            // flex: 1;
            font-size: 1.8rem;
            color: #0a0a0a;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            font-weight: 600;
        }

        .date {
            display: inline-block;
            width: 100%;
            margin-left: 0;
            margin-top: .9rem;
            font-size: 1.5rem;
            color: #9a9a9a;
        }

        // &.is-important {
        //     .title {
                
        //     }
        // }
    }

    &__more {
        width: 100%;
        padding: 2.2rem 0;
        text-align: center;
        color: #0a0a0a;
        font-size: 1.5em;
        // display: flex;
        // justify-content: center;
        // align-items: center;

        // &::before {
        //     content: '';
        //     display: inline-block;
        //     width: 1.2rem;
        //     height: 1.2rem;
        //     background-image: url(../images/ico/ico_plus_gray.svg);
        //     background-size: cover;
        //     background-position: center;
        //     margin-right: .4rem;
        //     vertical-align: middle;
        // }

        > span {
            display: inline-block;

            &::after {
                content: '';
                display: block;
                height: 1px;
                background-color: #0a0a0a;
            }
        }
    }

    &__view {
        &--header {
            padding: 3.2rem 2rem 2rem;
            border-bottom: 1px solid #bbb;
            margin-left: -2rem;
            margin-right: -2rem;
            
            // &::after {
            //     content: '';
            //     display: block;
            //     margin-top: 2rem;
            //     margin-left: -2rem;
            //     margin-right: -2rem;
            //     border-bottom: 1px solid #bbb;
            // }

            // .date {
            //     display: inline-block;
            //     width: 100%;
            //     // font-size: 1.5rem;
            //     color: #9a9a9a;
            //     margin-top: .8rem;
            // }

            .title {
                display: inline-block;
                width: 100%;
                font-size: 1.9rem;
                font-weight: 600;
                color: #0a0a0a;
                // line-height: 2.6rem;
            }
            
            // .order-num {
            //     display: inline-block;
            //     width: 100%;
            //     // margin-top: 1.2rem;
            //     // font-size: 1.2rem;
            //     color: #9a9a9a;
            // }

            // .attachment {
            //     display: inline-block;
            //     width: 100%;
            //     // margin-top: .4rem;
            //     // font-size: 1.2rem;
            //     color: #9a9a9a;
            // }
            .date {
                margin-top: .8rem;
                display: block;
                font-size: 1.5rem;
                color: #9a9a9a;
            }

        }

        &--create {
            
            
            color: #9a9a9a;
            margin: 0;
            margin-top: 2rem;

            .box {
                font-size: 0;
                + .box {
                    margin-top: 1.1rem;
                }

                &.order-num,
                &.attachment {
                    .txt {
                        color: #0a0a0a;
                        word-break: break-all;
                        // text-decoration: underline;
                    }
                }
            }

            dt {
                display: inline-block;
                width: 6rem;
                margin-right: 1.6rem;
                vertical-align: top;
                font-size: 1.5rem;
            }

            dd {
                display: inline-block;
                width: calc(100% - 7.6rem);
                margin: 0; 
                vertical-align: top;
                font-size: 1.5rem;
            }
        }

        &--body {
            padding-top: 2.1rem;

            img {
                width: 100% !important;
                height: auto !important;
            }
        }

        &--contents {
            padding: 2.4rem 2rem 4rem;
            background-color: #f2f2f2;
            font-size: 1.6rem;
            color: #0a0a0a;
            line-height: 2.4rem;

            .inquiry {
                display: block;
                margin-bottom: 1rem;
                font-size: 1.2rem;
                color: #9a9a9a;
            }
        }

        &--answer {
            padding: 1.2rem 0 10rem;
            // margin-top: 2.2rem;
            

            &--header {
                display: flex;
                justify-content:space-between;
                align-items: center;
                font-size: 1.5rem;

                .con {
                    color: #636363
                }

                .date {
                    color: #9a9a9a;
                }
            }

            &--body {
                padding: 2rem;
                margin-top: 1rem;
                font-size: 1.6rem;
                color: #636363;
                line-height: 2.4rem;
                background-color: #f2f2f2;

                img {
                    width: 100% !important;
                    height: auto !important;
                }
            }
        }
    }

    &__ask {
        // padding-top: 2.4rem;
        
        &--body {
            // padding-top: 2.4rem;
            margin-left: -2rem;
            margin-right: -2rem;
            padding-bottom: 2rem;
        }

        &--footer {
            // margin-top: 4rem;
        }

        &--lists {
            li {
                border-bottom: 1px solid #bbb;

                &.is-active {
                    .board__ask--current {
                        background-color: #00a972;
                    }
                }
            }
        }

        &--link {
            display: block;
            padding: 2rem;
        }

        &--contents {
            // display: flex;
            // justify-content: space-between;
            // align-items: center;

            .title {
                display: block;
                flex: 1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 1.8rem;
                color: #0a0a0a;
                font-weight: 600;
                margin-bottom: 1rem;
            }

            .date {
                // margin-left: 2rem;
                font-size: 1.5rem;
                color: #9a9a9a;
            }
        }

        &--current {
            display: inline-block;
            padding: .8rem 1rem;
            // margin-top: 1.3rem;
            margin-bottom: 1.2rem;
            background-color: #636363;
            font-size: 1.4rem;
            font-weight: 600;
            color: #fff;
            border-radius: 1.6rem;
        }
    }

    &__write {
        &-box {
            padding-top: 2.6rem;
        }

        &--footer {
            margin-top: 4rem;
        }
    }

    &--dl {
        margin: 0;

        &__div {
            // font-size: 0;
            display: flex;

            dt, dd {
                margin: 0;
                font-size: 1.2rem;
                // display: inline-block;
                border-bottom: 1px solid #bbb;
                padding: .7rem;
                vertical-align: top;
                word-break: keep-all;
            }

            dt {
                width: 24%;
                border-right: 1px solid #bbb;
            }

            dd {
                width: 76%;
            }
        }
    }
}

.no-search {
    margin: 6rem auto;
    text-align: center;
    font-size: 1.4rem;
    color: #9a9a9a;
}