.splash {
    &-box {
        // width: 100vw;
        height: 100vh;
        background-image: url(../images/@dummy/@splash.jpg);
        background-size: cover;
        // background-position: right center;
        animation: splashBg 15s ease-in-out infinite alternate;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-title {
        font-size: 40px;
        font-weight: 700;
        color: #fff;
    }

    &-loading {
        margin-top: 10px;
    }
}


@keyframes splashBg {
    from {
        background-position: 40% center;
    }
    to {
        background-position: right center;
    }
}


.progress {

    &-box {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // max-width: 78px;
        height: 8px;

        .cancel {
            margin-top: 8px;
            font-size: 12px;
            color: #dcdce4;
            text-align: center;
    
            i {
                margin-left: 8px;
                color:#212134;
            }
        }

        &.small {
            height: 4px;
        }
    }

    &-outer {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;
        background: #eaeaef;
        border-radius: 8px;
    }

    &-bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background: #5e5e66;
        border-radius: 8px;
    }
}