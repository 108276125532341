.view--pc .side-nav--pc .container {
    height: 100%;
    // padding-right: 22.8rem;
    padding-right: 0;
    padding-left: 0;
    max-width: 41.2rem;
}

.sidebar {
    &__body {
        margin-top: 20rem;
    }

    &__title-area {
        margin-bottom: 5.6rem;
    }
    
    &__title {
        font-size: 3.2rem;
        font-weight: 600;

        img {
            display: inline-block;
            width: 3.2rem;
            margin-left: 0.6rem;
        }
    }

    &__desc {
        margin-top: 0.8rem;
        font-size: 1.8rem;
        color: #636363;
    }

    &__content {
        .tab-list {
            font-size: 0;
            margin: -0.6rem;

            li {
                display: inline-block;
                width: calc(50% - 1.2rem);
                margin: 0.6rem;
                text-align: center;

                &.is-active {
                    .list-wrap {
                        color: #fff;
                        border-color: #00a972;
                        background-color: #00a972;
                    }
                }
            }

            .list-wrap {
                display: block;
                padding: 1rem;
                font-size: 1.6rem;
                border: 1px solid #9a9a9a;
                border-radius: 2rem;
                background-color: #fff;

                &.--red {
                    border-color: #ff4b4b;
                    color: #ff4b4b;
                }
            }
        }
    }

    &__footer {
        position: absolute;
        bottom: 2rem;
        width: 100%;
        // max-width: 60rem;

        &--wrap {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }

        .down {
            width: calc(100% - 11rem);
            padding-bottom: .8rem;
        }

        .qr-code {
            width: 6.4rem;
        }

        .text-area {
            margin-top: 0.8rem;

            .title {
                font-size: 1.6rem;
                font-weight: 600;
            }

            .desc {
                margin-top: 0.8rem;
                font-size: 1.4rem;
                color: #636363;
                line-height: 1.43;
            }
        }

        .smbti {
            width: 11rem;
        }
    }
}