.event--list {

    > li {
        + li {
            margin-top: 4rem;
        }
    }
    .badge {
        padding: .6rem .8rem;
        font-weight: 500;
        font-size: 1.1rem;
    }

    .badge--event {
        background-color: #0a0a0a;
    }

    .product__title {
        font-weight: 600;
    }

    .product__period {
        margin-top: .8rem;
        font-size: 1.2rem;
        color: #9a9a9a;
    }
    .more__item {
        display: none;
    }

    &.-end {
        .badge--event {
            background-color: #9a9a9a;
        }

        .product__title {
            color: #636363
        }
    }

    &.event--board {
        padding: 0 2rem;
        
        li {
            border-bottom: 1px solid #e9e9e9;
            + li {
                margin-top: 0;
            }

            &:first-child {
                border-top: 1px solid #e9e9e9;
            }

            .board__link {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .product__thumb::after {
        display: none;

        img {
            position: relative;
        }
    }

    img {
        position: relative;
    }
}