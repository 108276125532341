
// 상품 레이아웃
.product {

    &-box {
        position: relative;
        background-color: #fff;
        z-index: 1;
    }

    &__items {
        &.is-square {
            .product__thumb {
                width: 100%;

                &::after {
                    content: '';
                    display: block;
                    padding-top: 100%;
                }
            }
        }

        &.is-round {
            .product__thumb {
                border-radius: 1rem;
            }
        }
    }

    &__item {
        display: flex;
        justify-content: space-between;

        &-box {
            position: relative;

            .soldout {
                display: none;
            }

            &.is-soldout {

                .soldout {
                    display: block;
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    padding: 0.8rem 0;
                    background-color: rgba(0,0,0,.7);
                    color: #fff;
                    font-size: 1.4rem;
                    text-align: center;
                    z-index: 2;
                }
            }

            .checkbox-large input[type="checkbox"] + label::before,
            .checkbox-large input[type="checkbox"]:checked + label::after {
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    &__info {
        display: flex;
        // justify-content: space-between;
        justify-content: flex-start;
        flex-direction: column;
        width: calc(100% - 12rem);
        padding-left: 1.6rem;

        &--body {
            margin-top: 1.2rem;
            font-size: 0;
        }
    }

    &__link {
        display: block;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    // 리스트형, 갤러리형, 와이드형, 정사각형
    &--list {

        li + li {
            margin-top: .8rem;
        }

        .product__thumb {
            width: 12rem;
        }

        .product__info {
            justify-content: center;
        }

        .product__info--body {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        .product__price {
            .num {
                font-size: 1.7rem;
            }

            .unit {
                margin-left: 0;
                font-size: 1.7rem;
            }
        }

        .product__del {
            margin-left: .4rem;
            margin-bottom: 0;
            font-size: 1.3rem;
            color: #b8b8b8;
        }

        .product__title {
            font-size: 1.7rem;

            &.is-ellipsis-two {
                height: auto;
            }

            &.is-line-two {
                height: 4rem;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                white-space: normal;
            }
        }

        .product__kcal {
            .num {
                font-size: 1.7rem;
            }

            .unit {
                margin-left: 0;
                font-size: 1.7rem;
            }

            &::after {
                content: '';
                display: inline-block;
                width: 1px;
                height: 1.4rem;
                margin: 0 0.6rem;
                background-color: #b8b8b8;
                transform: translateY(1px);
            }
        }

        .product__cap {
            .num {
                font-size: 1.7rem;
            }

            .unit {
                margin-left: 0;
                font-size: 1.7rem;
            }
        }

        .product__arrow {
            width: 2.8rem;
            display: flex;
            align-items: center;
        }

        &.--regular {
            > li + li {
                border-top: 1px solid #ccc;
                padding-top: 4rem;
            }
        }

        &.--desc {
            li + li {
                margin-top: 4rem;
            }
        }
    }

    &--gallery {
        .product__info {
            // display: block;
            width: 100%;
            // height: 16rem;
            padding-left: 0;
            // margin-top: .8rem;
            margin-top: 1.6rem;
        }
        .product__item {
            flex-direction: column;
        }

        .product__thumb {
            width: 100%;

            &.is-wide {
                &::after {
                    content: '';
                    display: block;
                    padding-top: 50%;
                }
            }
        }

        .product__title {
            line-height: 2.6rem;

            &.is-ellipsis-two {
                height: 5.2rem;
            }
        }

        .product__subtitle {
            margin-bottom: .4rem;
            font-size: 1.3rem;
        }

        .product__price .unit {
            margin-left: 0;
        }

        &.--col-2 {
            margin: -2rem -.8rem;
            font-size: 0;

            > li {
                display: inline-block;
                width: calc(50% - 1.6rem);
                margin: 2rem .8rem;
            } 
        }
    }

    &__order {

        > li {
            + li {
                margin-top: 1.2rem;
            }
        }

        .product__link {
            border: 1px solid #bbb;
            border-radius: .8rem;
            padding: 1.4rem 1.6rem;
        }

        .product__thumb {
            width: 8rem;
        }

        .product__info {
            width: calc(100% - 8rem);
        }

        .product__title {
            font-weight: 400;
        }

        &--date {
            display: block;
            font-size: 1.3rem;
            color: #636363;
            margin-bottom: .8rem;
        }

        &--price {
            margin-top: .6rem;
            font-size: 1.4rem;
            color: #0a0a0a;
        }
    }

    &__desc {
        margin-top: 1.2rem;
        padding: 1.2rem;
        border-radius: 1.2rem;
        background-color: #f2f2f2;

        .title-box {
            display: inline-block;
            padding: 0.4rem 1rem;
            margin-bottom: 0.8rem;
            border-radius: 1.4rem;
            background-color: #fff;

            .ico {
                display: inline-block;
                width: 2rem;
                margin-right: 0.4rem;
            }
    
            .title {
                display: inline-block;
                margin-bottom: 0;
                font-size: 1.3rem;
                vertical-align: super;
            }
        }

        .txt {
            font-size: 1.3rem;
            line-height: 1.38;
        }

    }

    &__delete {
        width: 2.8rem;
        height: 2.8rem;
        // background-color: red;
        position: absolute;
        top: 2rem;
        right: 2rem;
        border-radius: 50%;
        background-image: url(/assets/images/ico/ico_input_del.svg);
        background-size: cover;
    }
}


// 상품 썸네일 정의
.product {
    &__thumb {
        overflow: hidden;
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        // width: 12rem;
        // background-color: #ddd;
        font-size: 0;

        img {
            // position: relative;
            position: absolute;
            width: 100%;
            height: auto;
            z-index: 1;
            // object-fit: fill;
        }

        // &::before {
        //     content: '';
        //     position: absolute;
        //     top: 50%;
        //     left: 50%;
        //     min-width: 40px;
        //     min-height: 40px;
        //     margin-top: -20px;
        //     margin-left: -20px;
        //     border: 5px solid rgba(255,255,255,.5);
        //     border-right: 5px solid #00aa6c;
        //     border-radius: 50%;
        //     animation: spinner 1s linear infinite;
        //     z-index: 0;
            
        // }
        &::after {
            content: '';
            display: block;
            padding-top: 100%;
        }

        &.is-wide {
            &::after {
                content: '';
                display: block;
                padding-top: 75%;
            }
        }

        &.is-noafter {
            &::after {
                display: none;
            }
        }

        &.is-thumb70 {
            height: 100%;
            width: 100%;

            &::after {
                content: '';
                display: block;
                padding-top: 70%;
            }
        }
    }
}


// 상품 썸네일 스피너
@keyframes spinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

// 상품 정보
.product {
    &__subtitle {
        overflow: hidden;
        display: block;
        width: 100%;
        margin-bottom: .8rem;
        font-size: 1.2rem;
        // color: #0a0a0a;
        color: #00a972;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        white-space: normal;

        &.is-ellipsis-two {
            -webkit-line-clamp: 2;
        }

        &.is-ellipsis-one {
            -webkit-line-clamp: 1;
        }

        &.is-small {
            font-size: 1.3rem;
        }
    }

    &__title {
        overflow: hidden;
        width: 100%;
        max-height: 6rem;
        font-size: 1.4rem;
        // font-weight: 700;
        color: #0a0a0a;
        text-overflow: ellipsis;
        word-wrap: break-word;
        // word-break: keep-all;
        display: -webkit-box;
        // -webkit-line-clamp: 3;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 2rem;

        &.is-ellipsis-two {
            height: 4rem;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
        }

        &.is-ellipsis-one {
            // height: 4rem;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            white-space: normal;
        }

        &.is-large {
            font-size: 1.8rem;
        }
    }

    &__del {
        // display: block;
        display: inline-block;
        font-size: 1.3rem;
        color: #b8b8b8;
        margin-left: .4rem;

        

        &.is-block {
            display: block;
            margin-left: 0;
            margin-bottom: .4rem; 
        }
    }

    &__discount {
        margin-right: .8rem;
        font-size: 1.8rem;
        font-weight: 700;
        color: #fe4a4a;
    }

    &__review {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: .8rem;
    }

    &__star {
        width: 1.6rem;
    }

    &__grade {
        margin-left: 1px;
        font-size: 1.2rem;
        color: #0a0a0a;
    }

    &__cart {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 2.4rem;
    }

    &__price {
        font-size: 0;
        color: #0a0a0a;

        .num {
            font-size: 1.8rem;
        }

        .unit {
            margin-left: .4rem;
            font-size: 1.8rem;
        }
    }

    &__period {
        margin-top: .9rem;
        // font-size: 1.1rem;
        font-size: 1.3rem;
        // font-weight: 700;
        // color: #0a0a0a;
        color: #636363;
    }

    &__quantity {
        margin-left:0.8rem;
        font-size:1.5rem;
        // color:#b8b8b8;
    }

    &__quantity::before {
        content: '';
        display:inline-block;
        width:1px;
        height:1.2rem;
        margin-right:0.8rem;
        background: #b8b8b8;
    }

    &__quantity {
        margin-left: 0;

        &.is-nobefore::before {
            display: none;
        }
    }
}

// 뱃지 스타일 정의
.badge {
    display: inline-block;
    padding: .4rem .6rem; 
    font-size: 1.2rem;
    font-weight: 700;
    color: #fff;

    + .badge {
        margin-left: 1px;
    }

    &-box {
        position: absolute;
        top:0 ;
        left: 0;
        font-size: 0;
        z-index: 1;

        &.--count {
            top: .9rem;
            left: auto;
            right: 2rem;

            .badge {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 6rem;
                height: 6rem;
                background-color: rgba(10,10,10,.65);
                border-radius: 50%;
                font-size: 1.3rem;
                font-weight: 700;
                line-height: 1.9rem;
                color: #fff;
                text-align: center;
            }
        }

        &.--prd {
            .badge {
                padding: 0;
            }

            img {
                position: relative;
                height: 2.4rem;
                width: auto;
            }
        }
    }

    &--free {
        background-color: #a06bef;
    }

    &--regularly {
        background-color: #618ffb;
    }

    &--coupon {
        background-color: #f66262;
    }

    &--event {
        background-color: #d373ef;
    }

    &--reserves {
        background-color: #00a972;
    }

    &--count {
        background-color: #ff4646;
        font-size: 1.1rem;
        // color: #fff;
        font-weight: 500;

        b {
            font-size: 1.2rem;
        }
    }

    &--kit {
        background-color: #296df6;
    }
}

// 상품 상세페이지

.product {
    &__detail {
        &--thumb {
            margin-left: -2rem;
            margin-right: -2rem;

            .thumb {
                overflow: hidden;
                position: relative;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                background-color: #ddd;
                font-size: 0;
                width: 100%;

                // &::before {
                //     content: '';
                //     position: absolute;
                //     top: 50%;
                //     left: 50%;
                //     min-width: 40px;
                //     min-height: 40px;
                //     margin-top: -20px;
                //     margin-left: -20px;
                //     border: 5px solid rgba(255, 255, 255, 0.5);
                //     border-right: 5px solid #00aa6c;
                //     border-radius: 50%;
                //     -webkit-animation: spinner 1s linear infinite;
                //     animation: spinner 1s linear infinite;
                //     z-index: 0;
                // }
    
                &::after {
                    content: '';
                    display: block;
                    padding-top: 100%;
                }

                img {
                    position: relative;
                    width: 100%;
                    height: auto;
                    z-index: 1;
                }
            }

            .swiper-pagination {
                bottom: 2rem;
            }
           

            .swiper-pagination-bullet {
                width: .8rem;
                height: .8rem;
                background-color: #cfcfcf;
                border-radius: .4rem;
                opacity: 1;
                transition: width .3s;
            }

            .swiper-pagination-bullet-active {
                width: 1.6rem;
                background-color: #0a0a0a;
            }
        }

        &--top {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .badge-box {
                position: relative;
            }

            .badge {
                padding: 0;
                // padding: .6rem .8rem;
                // background-color: #f2f2f2;
                // font-size: 1.1rem;
                // font-weight: 400;
                font-size: 0;

                &--coupon {
                    background-color: #ff4646;
                }

                &--event {
                    background-color: #0a0a0a;
                }

                img {
                    width: auto;
                    height: 100%s;
                    max-height: 2.6rem;
                }
            }

            .btn-share {
                width: 3.5rem;
                height: 3.5rem;
                color: #fff;
                background-image: url(../images/ico/ico_sharing.svg);
                background-size: contain;
            }
        }

        &--tag {
            margin-top: 2.1rem;
            font-size: 1.6rem;
            color: #0a0a0a;

            .tag {
            }
        }

        &--title {
            overflow: hidden;
            margin-top: 1.2rem;
            font-size: 2.1rem;
            color: #0a0a0a;
            line-height: 2.9rem;
        }

        &--stars {

            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 1.2rem;

            .stats-rate {
                &--bg {
                    display: inline-block;
                    overflow: hidden;
                    position: relative;
                    white-space: nowrap;
                    vertical-align: middle;
                    background-image: url(../images/ico/ico_stats_rate.svg);
                    background-size: cover;
                    background-position: 0 0;
                    background-repeat: no-repeat;
                    width: 88px;
                    height: 16px;
                }

                &__star {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    background-image: url(../images/ico/ico_stats_rate_over.svg);
                    background-size: cover;
    
                    &--00 {
                        width: 0% !important;
                    }
                    &--05 {
                        width: 9% !important;
                    }
                    &--10 {
                        width: 19% !important;
                    }
                    &--15 {
                        width: 29% !important;
                    }
                    &--20 {
                        width: 40% !important;
                    }
                    &--25 {
                        width: 50% !important;
                    }
                    &--30 {
                        width: 61% !important;
                    }
                    &--35 {
                        width: 71% !important;
                    }
                    &--40 {
                        width: 80% !important;
                    }
                    &--45 {
                        width: 92% !important;
                    }
                    &--50 {
                        width: 100% !important;
                    }
                }

                &--text {
                    margin-left: .2rem;
                    font-size: 1.3rem;
                    color: #0a0a0a;
                }
            }

            .btn-review {
                padding: 0;
                margin-left: .8rem;
                font-size: 1.3rem;
                font-weight: 500;
                color: #9a9a9a;
                // text-decoration: underline;
                cursor: auto;
            }
        }

        &--discount {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 2rem;
            font-size: 1.5rem;
            color: #ff4646;

            em {
                margin-right: .8rem;
                font-size: 2.1rem;

                b {
                    
                    font-weight: 700;
                }
            }
        }

        &--price {
            margin-top: .8rem;
            color: #0a0a0a;
            font-size: 0;
            
            em {
                font-size: 2.1rem;
            }

            del {
                margin-left: .8rem;
                font-size: 1.5rem;
                color: #b8b8b8;
            }
        }

        &--btn-box {
            margin-top: 2rem;

            .btn {
                width: 100%;
                // padding: 1.3rem 0;
                // font-size: 1.5rem;
                // border-radius: 1.2rem;
                // border: 1px solid #00a972;
                border-color: #0a0a0a;

                .text {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    line-height: 2rem;

                    &::after {
                        content: '';
                        display: inline-block;
                        width: 2rem;
                        height: 2rem;
                        margin-left: .8rem;
                        background-image: url(../images/ico/ico_down_black.svg);
                        background-size: contain;
                        vertical-align: middle;
                    }
                }
            }
        }

        &--banner {
            margin-top: 2rem;

            .swiper-pagination {
                left: auto;
                right: 2rem;
                top: auto;
                bottom: .8rem;
                width: auto;

                .swiper-pagination-bullet {
                    background-color: #fff;
                    opacity: .6;
                    margin: 0 .2rem;
                }
    
                .swiper-pagination-bullet-active {
                    opacity: 1;
                }
            }
        }

        &--analysis {
            // margin-top: 2rem;

            .title {
                display: block;
                margin-bottom: 1.2rem;
                font-size: 1.6rem;
                font-weight: 400;
                color: #0a0a0a;

                + .title {
                    margin-top: -.6rem;
                    margin-bottom: 0;
                }

                strong {
                    display: inline-block;

                    &::after {
                        content: '';
                        display: block;
                        height: .8rem;
                        margin-top: -.8rem;
                        background-color: #fff127;
                    }
                }
            }

            .analysis--keyword {
                font-size: 0;
                margin: -.4rem;
                .keyword {
                    display: inline-block;
                    padding: .5rem 1.2rem .5rem 3.2rem;
                    margin: .4rem;
                    border: 1px solid #b8b8b8;
                    border-radius: 1.5rem;
                    font-size: 1.7rem;
                    background-image: url(../images/ico/ico_check.svg);
                    background-size: 1.6rem;
                    background-repeat: no-repeat;
                    background-position: top 50% left 1.2rem;
                }
            }

            + .product__detail--analysis {
                margin-top: 4rem;
            }

            .analysis--ico-list {
                font-size: 0;
                margin: -.6rem 0;

                li {
                    display: inline-block;
                    width: 50%;
                    margin: .6rem 0;
                }
    
                .analysis {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    &::before {
                        content: '';
                        display: inline-block;
                        width: 6rem;
                        min-width: 6rem;
                        height: 6rem;
                        min-height: 6rem;
                        border-radius: 50%;
                        background-color: #f2f2f2;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 3.2rem;
                    }

                    &.analysis--alarm {
                        &::before {
                            background-image: url(../images/ico/ico_alarm.svg);
                        }
                    }
                    
                    &.analysis--collagen {
                        &::before {
                            background-image: url(../images/ico/ico_ingre.svg);
                        }
                    }

                    &.analysis--effect {
                        &::before {
                            background-image: url(../images/ico/ico_effect.svg);
                        }
                    }

                    &.analysis--intake {
                        &::before {
                            background-image: url(../images/ico/ico_ingest.svg);
                        }
                    }

                    &.analysis--point {
                        &::before {
                            background-image: url(../images/ico/ico_point_s.svg);
                        }
                    }
                }

                .text-box {
                    margin-left: .8rem;
                }

                span {
                    display: block;
                }
    
                .sub-title {
                    font-size: 1.2rem;
                    font-weight: 500;
                    color: #9a9a9a;
                }
    
                .title {
                    margin-top: .6rem;
                    margin-bottom: 0;
                    font-size: 1.6rem;
                    color: #0a0a0a;
                }
            }

            .text-box--gifts {
                .subtitle {
                    display: block;
                    margin-bottom: 2rem;
                    font-size: 1.2rem;
                    color: #9a9a9a;
                    font-weight: 600;
                }
            }
        }

        &--tabs {
            background-color: #fff;
            border-bottom: 1px solid #d4d4d4;
            position: sticky;
            top: 4rem;
            // height: 55px;
            z-index: 2;
            ul {
                display: flex;
                justify-content: space-around;
                align-items: center;
            }

            li {
                flex: 1;
                text-align: center;

                &.is-active {
                    span {
                        &::after {
                            content: '';
                            display: block;
                            width: 100%;
                            height: 2px;
                            background-color: #0a0a0a;
                            position: absolute;
                            bottom: 0;
                        }
                    }
                }
            }

            a {
                font-size: 1.6rem;
                color: #0a0a0a;
            }

            span {
                display: block;
                padding: 1.7rem 0;
                position: relative;
            }

            &.is-fixed {
                
                // z-index: 1;
                width: 100%;
                max-width: 640px;
            }
        }

        &--item {
            padding: 2rem 0;

            .img-box {
                overflow: hidden;
                position: relative;
                height: 130rem;

                img {
                    width: 100% !important;
                }

                &::before {
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 3.2rem;
                    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .6) 40%, rgba(255, 255, 255, 1) 70%);
                    content: '';
                    z-index: 1;
                }

                &.is-nobefore {
                    &::before {
                        display: none;
                    }
                }
            }

            // .btn {
            //     width: 100%;
            //     padding: 1.5rem 0;
            //     font-size: 1.5rem;
            //     border-radius: 1.2rem;
            //     border: 1px solid #cfcfcf;
            // }

            .ico-arr {
                display: inline-block;
                width: 1.2rem;
                height: 1.2rem;
                background-image: url(../images/ico/ico_arrow.svg);
                background-size: contain;
                background-repeat: no-repeat;
                transform: rotate(180deg);
                margin-left: 1.2rem;
                vertical-align: middle;
            }
        }

        &--title-box {
            margin-bottom: 1.8rem;

            .title {
                font-size: 1.6rem;
                font-weight: 400;
            }

            .satisfaction {
                font-size: 1.6rem;
            }
        }
    }

    &__review {
        &--header {}
        &--body {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 2rem;

            .stats-rate {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-right: 3rem;
                font-size: 1.6rem;
                color: #0a0a0a;

                &::before {
                    content: '';
                    display: inline-block;
                    width: 1.7rem;
                    height: 1.7rem;
                    margin-right: .6rem;
                    background-image: url(../images/ico/ico_stats_rate_one.svg);
                    background-size: contain;
                }
            }
        }

        &--list {
            flex: 1;

            li {
                + li {
                    margin-top: .5rem;
                }

                // &:first-child {
                    .prograss__inner {
                        // 221118, 상세페이지 총 리뷰 프로그레스 색상 변경
                        // background-color: #0a0a0a;
                        background-color: #00a972;
                        
                    }
                // }
            }
        }

        &--content {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
                width: 6rem;
                font-size: .9rem;
                color: #9a9a9a;
            }

            .prograss {
                width: calc(100% - 9rem);
                padding: 0 .8rem 0 1.2rem;

                &__outer {
                    overflow: hidden;
                    display: block;
                    position: relative;
                    width: 100%;
                    height: .4rem;
                    background-color: #f2f2f2;
                    border-radius: .2rem;
                }

                &__inner {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    background-color: #cfcfcf;
                    border-radius: .2rem;
                }
            }

            .total {
                width: 3rem;
                font-size: .9rem;
                font-weight: 500;
                color: #9a9a9a;
                text-align: right;
            }
        }

        // &--btn {
        //     width: 100%;
        //     padding: 1.5rem 0;
        //     margin-top: 2rem;
        //     font-size: 1.5rem;
        //     color: #0a0a0a;
        //     border-radius: 1.2rem;
        //     border: 1px solid #cfcfcf;
        // }
    }
}


.poroduct--recommend {
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: -2rem;
    margin-right: -2rem;
}

// 상품삳세페에지 섹션 구분선
.is-section-contour {

    &::after {
        content: '';
        display: block;
        height: .8rem;
        margin-top: 4rem;
        background-color: #f2f2f2;

        // 221118
        border-top: 1px solid #d4d4d4;
        border-bottom: 1px solid #d4d4d4;
    }

    &.contour--nomargin {
        &::after {
            margin-top: 0;
        }
    }
}

// 하단 고정 버튼
.btn-box--fixed {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 640px;
    background-color: #fff;
    box-shadow: 0px -4px 4px 0 #ccc;
    z-index: 2;
    text-align: center;

    .btn {
        border-radius: 0;
        padding: 0;
        height: 6rem;
        font-size: 1.9rem;
    }

    .btn-gift {
        width: 6rem;
        border-right: 1px solid #cfcfcf;
        background-image: url(../images/ico/ico_gift.svg);
        background-position: center;
        background-size: 3.6rem;
        background-repeat: no-repeat;
    }

    .btn-periodic {
        flex: 1;
        font-size: 1.9rem;
        color: #0a0a0a;
    }

    .btn-payment {
        flex: 1;
        background-color: #0a0a0a;
        font-size: 1.9rem;
        color: #fff;
    }

    .btn-area {
        margin-top: 0;

        &.is-half {
            .btn + .btn {
                margin-left: 0;
            }
        }
    }
}

.is-flex--around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

@media (max-width: 400px) {
    .product--gallery {
        .product__price {
            display: block;
        }
    }

    .product--list .product__thumb {
        width: 10rem;
        height: 10rem;
    }

    .product--list .product__info {
        width: calc(100% - 10rem);
    }

    .product--list {
        .product__price,
        .product__del,
        .product__quantity {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }

        .product__quantity {
            &::before {
                display: none;
            }
        }
    } 

    .badge {
        &-box {
            &.--prd {
                img {
                    height: 2rem;
                }
            }
        }
    }
}

.shop__main--swiepr {
    .product__thumb {
        display: block;
        background-color: rgba(0, 155, 93, .2);

        &::after {
            content: '';
            display: block;
            padding-top: 70%;
        }
    }
}

.shop__main--swiepr-wrap {
    margin-left: -2rem;
    margin-right: -2rem;
    &::after {
        content: '';
        display: block;
        padding-top: 70%;
    }

    .shop__main--swiepr {
        position: absolute;
        width: 100%;
        height: 100%;
        margin-left: 0;
        margin-right: 0;
    }
}

.product__detail--top .badge {
    > span {
        padding: .6rem .8rem;
        
        font-size: 1.1rem;
        color: #fff;
        font-weight: 500;
    }
    
    .badge--regular {//정기배송
        background-color: #ff9216;
    }

    .badge--event {//이벤트
        background-color: #be85ff;
    }

    .badge--reserves {//적립금전용
        background-color: #00a972;
    }

    .badge--delivery {//무료배송
        background-color: #296df6;
    }

    .badge--gifts {//사은품
        background-color: #0a0a0a;
    }

    .badge--coupon {//쿠폰
        background-color: #ff4646;
    }

    .badge--special {//특가제품
        background-color: #f651c5;
    }
    
}