.breadcrumb {
        position: relative;
        display: inline-block;
        margin: 0 -10px;
        line-height: 1;
        font-size: 0;
        
    &__item {
        position: relative;
        padding: 0 0 0 10px;
        vertical-align: middle;
        font-size: 14px;

        &::before {
            content: '>';
            display: inline-block;
            margin-right: 10px;
            color: #000;
        }

        &:first-child::before {
            display: none;
        }

        &.is-active {
            color: var(--primary-700);
        }
    }
}