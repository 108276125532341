.table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    background-color: #fff;
    color: #333;
    border-bottom: 1px solid #eee;

    thead, tbody {
        background-color: transparent;
    }

    th, td {
        padding: 10px;
        font-size: 1.6rem;
        vertical-align: middle;
        text-align: center;
        border: 1px solid #eee;
        border-width: 0 0 1px;

        &.table__title {
            overflow: hidden;
            text-align: left;
            word-wrap: normal;
            word-break: break-all;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    th {
        font-weight: 700;
    }

    &-box {
        border-top: 2px solid #ccc;
        border-bottom: 1px solid #ccc;
    }

    &--alarm {

        border: 0;
        
        .table {
            background-color: transparent;
            border: 1px solid #bbb;

            th, td {
                font-size: 1.2rem;
                color: #636363;
                padding: 0.4rem 0.8rem;
                text-align: left;;
                border: 1px solid #bbb;
                word-break: keep-all;
            }

            th {
                font-weight: 500;
                background-color: #ddd;
                border-bottom: 0;
            }

            // td {
            //     border: 1px solid #bbb;
            // }
        }
    }
}