.renewal {
    .tooltip__btn {
        &::after{
            width: 2.2rem;
            height: 2.2rem;
            background-image: url(../images/ico/icon_info.svg);
        }
    }

    .btn--refresh {
        width: 2.4rem;
        height: 2.4rem;

        &.is-active {
            transform: rotate(360deg);
            transition: all 0.3s;
        }
    }

    .btn--line--gray {
        width: 100%;
        padding: 1rem 0;
        font-size: 1.6rem;
        color: #0a0a0a;
        border-radius: 2rem;
        border: 0.5px solid #999 !important;
        background-color: #fff;

        &.is-disabled {
            color: #a7a7a7;
            border: 0.5px solid #f0f0f0 !important;
            background-color: #f0f0f0;
        }
    }

    .btn {
        &.--point {
            &::before {
                content: '';
                display: inline-block;
                width: 1.8rem;
                height: 1.8rem;
                margin-right: 0.6rem;
                background-image: url(../images/ico/icon_point_coin.svg);
                background-size: cover;
                vertical-align: bottom;
            }
        }
    }

    .btn__date {
        &.--select {
            &::after {
                content: '';
                display: inline-block;
                width: 1.4rem;
                height: 1.4rem;
                margin-left: 0.4rem;
                background-image: url(../images/ico/ico_arrow_down_gray.svg);
                background-size: cover;
                vertical-align: inherit;
            }
        }
    }
}