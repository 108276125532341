.tooltip {
    &-wrap {
        display: inline-block;
        position: relative;

        &.is-active {
            .tooltip-box {
                display: flex;
            }
        }
    }

    &__btn {
        display: inline-flex;
        color: #888;
        align-items: center;
        padding: 0;
        margin: 0;
        line-height: 1;
        font-size: 1.4rem;

        &.is-underline {
            
            > span {
                border-bottom: 1px solid #242424;
                color: #242424;
                font-size: 1.6rem;
            }
        }

        &::after {
            content: '';
            width: 2rem;
            height: 2rem;
            margin-left: .4rem;
            background-image: url(../images/ico/ico_tip2.svg);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        &.--gray {
            &::after {
                opacity: 0.3;
                transform: translate(-4px, 3px);
            }
        }
    }

    &-box {
        display: none;
        position: absolute;
        left: 0;
        z-index: 10;
        width: 25rem;
        // max-width: 25rem;
        padding: 1.4rem;
        background-color: #2480e9;
        box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.63);
        border-radius: .8rem;
        color: #fff;
        line-height: 1.8rem;
        font-size: 1.2rem;
        text-align: left;
        word-break: keep-all;

        .title {
            margin-bottom: 1.2rem;
            font-size: 1.4rem;
            font-weight: 600;
            color: #fff;
        }

        &.is-top {

        }

        &.is-bottom {
            top: 3.6rem;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
            
            &::before {
                content: '';
                width: 0;
                height: 0;
                border-bottom: 1.2rem solid #2480e9;
                // border-top: 0.7rem solid transparent;
                border-left: 0.7rem solid transparent;
                border-right: 0.7rem solid transparent;
                position: absolute;
                top: -1.2rem;
                left: 50%;
                transform: translateX(-50%);
                z-index: 2;
            }
        }

        &.is-left {

        }

        &.is-right {

        }

        &.is-white {
            background-color: #fff;

            .title {
                color: #0a0a0a;
            }

            .tooltip__body {
                color: #636363;
            }
        }

        &.is-fullwidth {
            flex: 1;
            width: 100%;
        }
    }

    &.is-blue {

    }
}

//2308 리뉴얼
.renewal {
    .tooltip-wrap {
        &.is-active {
           .tooltip-box {
                display: block;
           } 
        }
    }

    .tooltip-box {
        padding: 2rem 2.4rem;
        border: 1px solid var(--selex--green);
        background-color: #FAFFFD;
        box-shadow: 0px 0px 20px 0px rgba(61, 139, 114, 0.20);

        &.is-bottom {
            &::before {
                top: -0.8rem;
                border-bottom: 1rem solid #FAFFFD;
                left: auto;
            }

            &::after {
                content: '';
                width: 0;
                height: 0;
                border-bottom: 1rem solid var(--selex--green);
                border-left: 0.7rem solid transparent;
                border-right: 0.7rem solid transparent;
                position: absolute;
                top: -1rem;
                transform: translateX(-50%);
                z-index: 1;
            }
        }

        table {
            width: 100%;
            font-size: 1.4rem;
            color: #666;
            text-align: center;
            border-top: 1px solid #CFE9DB;
            border-left: 1px solid #CFE9DB;
            
            td {
                padding: 1rem 0.5rem;
                border-bottom: 1px solid #CFE9DB;
                border-right: 1px solid #CFE9DB;
            }
        }
    }
}