.dropdown {
	display: inline-flex;
	position: relative;
	vertical-align: top;

	.dropdown-trigger {
		min-width: inherit;

		button {
			justify-content: space-between;
			min-width: inherit;
			border-color: #ccc;
		}
	}

	.dropdown-menu {
		min-width: inherit;
		margin-top: -1px;
		padding-top: 0;
	}

	&.is-active,
	&.is-hoverable:hover {
		.dropdown-menu {
			display: block;
		}
	}

	&.is-right {
		.dropdown-menu {
			left: auto;
			right: 0;
		}
	}

	&.is-up {
		.dropdown-menu {
			bottom: 100%;
			padding-bottom: 10px;
			padding-top: initial;
			top: auto;
		}
	}
}

.dropdown-menu {
	display: none;
	left: 0;
	min-width: inherit;
	padding-top: 10px;
	position: absolute;
	top: 100%;
	z-index: 2;
}

.dropdown-content {
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 2px 2px 4px #000;
	padding-bottom: 1rem;
	padding-top: 1rem;
	border: 1px solid #333;
}

.dropdown-item {
	color: #000;
	display: block;
	// font-size: 9px;
	line-height: 1.5;
	padding: 4px 10px;
	position: relative;
}

a.dropdown-item,
button.dropdown-item {
	padding-right: 30px;
	text-align: left;
	white-space: nowrap;
	width: 100%;
	font-size: 14px;

	&:hover,
	&:focus {
		// color: green;
		background-color: #f2f2f2;
    	border-color: #00a972;
	}

	&.is-active {
		// color: gold;
		background-color: #f2f2f2;
    	border-color: #00a972;
	}
}

.dropdown-divider {
	background-color: pink;
	border: none;
	display: block;
	height: 1px;
	margin: 5px 0;
}
