.header--draft {
    position: fixed;
    background-color: #fff;
    width: 100%;
    height: 120px;
    overflow: hidden;


    .info {
        margin-bottom: 16px;
        a {
            text-decoration: underline;
        }

        padding-left: 2rem;
        padding-right: 2rem;

        span {
            display: block;
            font-size: 1.1rem;

            + span {
                margin-top: .2rem;
            }
        }

        em {
            display: inline-block;
            min-width: 4rem;
        }
    }
}

.body--draft {
    padding-top: 120px;
}

.wrap.draft {
    position: relative;
    // max-width: 100%;
    // overflow: hidden;
    // max-height: 100vh;
    .header {
        z-index: 4;
    }
    .header--shop {
        padding-top: 0;
    }

    .header--shop{
        // .container {
        //     // top: 40px;
        //     background-color: #f2f2f2;
        // }

        &.is-detail {
            
            .container {
                background-color: #fff;
            }
        }
        
    }

    .header .btn--barcode {
        background-color: #0a0a0a;
    }

    .floating-box {
        display: flex;
        flex-wrap: wrap;
        max-width: 5.6rem;
        right: 1.6rem;

        // .floating__btn {
        //     width: 5.6rem;
        //     height: 5.6rem;
        //     background-color: rgba(255,255,255,.8);
        // }

        // .floating__btn:first-child {
        //     background-color: rgba(10,10,10,.8);
        // }
    }

    // .floating__btn + .floating__btn {
    //     margin-top: 1.2rem;
    // }

    .tabs__btn {
        font-size: 1.6rem;
        color: #636363;
    }
    
    .tabs__btn.is-active {
        color: #0a0a0a;
        border-color: #0a0a0a;
    }

    .tabs--top .container {
        // top: 10rem;
    }

    .tabs.tabs--top.is-active {
        z-index: 4;

        .container {
            // top: 8rem;
        }
    }

    .table-box {
        max-height: calc(100vh - 60px);
        overflow-y: auto;
        border-top: 2px solid #000;
        border-bottom: 1px solid #000;
        // padding-top: 35px;
        margin-top: -2px;

        &.is-fixed {
            border-bottom: 0;
            // position: fixed;
            padding-top: 0;
            margin-top: 0;
        }
    }

    .table th {
        background-color: #F2F2F2;
    }

    .table th, .table td {
        padding: 10px 2%;
        font-size: 1.2rem;
        vertical-align: middle;
        text-align: center;
        // border: 1px solid #eee;
        // border-width: 0 0 1px;
    }

    // .table th {
    //     border-top: 2px solid #000;
    // }

    .table .is-border th,
    .table .is-border td {
        border-bottom: 1px solid #0a0a0a;
    }

    .table .is-bg th,
    .table .is-bg td {
        background-color: #eafbe7
    }
    
    
    .tableTitle {
        font-size: 1.4rem;
        font-weight: 700;
        padding: 6px 20px;
        margin-top: 10px;
        color: #00a972;

    }

    .complete {
        color: #0788da;
        text-decoration: underline;
        font-weight: 500;
    }

    .plan {
        color: #ff5722;
    }

    .table {
        .dev__title-box {
            background-color: #00a972;
            color: #fff;
            font-weight: 600;
            text-align: left;
            font-size: 1.4rem;
        }
    }
}

.development {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #444;
    z-index: 999;
    text-align: right;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.btn--development {
    position: relative;
    /* display: block; */
    /* width: 100%; */
    padding: 6px 30px 6px 20px;
    color: #fff;
    color: #444;
    font-size: 1.6rem;
    text-align: right;
    /* height: 40px; */
    background: #fff;
    border-radius: 10px;
    vertical-align: middle;
    margin-right: 4px;

    &::after {
        position: absolute;
        right: 20px;
        top: calc(50% - 2px);
        content: '';
        width: 8px;
        height: 8px;
        border-top: 2px solid #444;
        border-right: 2px solid #444;
        transform: rotate(45deg) translateY(-50%);
    }
}

.main.draft {
    padding-bottom: 7rem !important;
    background-color: #f2f2f2;
}

.main.draft--shop {
    padding-bottom: 6rem !important;
}

.historyBack {
    position: fixed;
    top: 10px;
    right: 10px;
    display: inline-block;
    width: 20px;
    height: 20px;
    z-index: 9999;
}

