.btn {
    display: inline-block;
    padding: 1rem 1.6rem;
    margin-bottom: 0;
    font-size: 14px;
    // font-weight: 700;
    line-height: 1;
    // line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 2rem;
    text-decoration: none;
    outline: 0;
    transition: all .1s ease-in-out;
    color: #0a0a0a;

    &:hover {}

    &:focus {}

    &:active {}

    // &:disabled {
    //     background-color: #9a9a9a !important;
    // }

    &-small {
        padding-top: .75rem;
        padding-bottom: .6rem;
        font-size: 1.5rem;

        &--radius {
            border-radius: .6rem;
        }
    }

    &-xlarge {
            padding-top: 1.4rem;
            padding-bottom: 1.3rem;
            font-size: 1.9rem;
            border-top-left-radius: 2.4rem;
            border-top-right-radius: 2.4rem;
            border-bottom-left-radius: 2.4rem;
            border-bottom-right-radius: 2.4rem;
    }

    &-large {
        padding: 1.2rem 2rem;
        font-size: 1.4rem;
    }

    &-primary {
        background-color: #00a972;
        color: #fff;

        &.btn-outline {
            color: #00a972;
            border-color: #00a972;  
            background-color: #fff;
        }

        &:hover {
            color: #fff;
        }
    }

    &-danger {}

    &-success {}

    &-warning {}

    &-secondary {}

    &-text {}

    &-link {
        margin: -1rem -1.6rem;
        font-size: 1.4rem;
        color:#0a0a0a;
 
        &::after {
            content: '';
            display: block;
            height: 1px;
            background-color: #0a0a0a;
        }

        &.is-bold {
            font-size: 1.3rem;
            font-weight: 700;
        }
    }

    &-outline-primary {
        // width: 100%;
        // height: 4rem;
        // padding: 1.2rem 0;
        background-color: #fff;
        // border-radius: 2rem;
        border: solid 1px #00a972;
        // font-size: 1.4rem;
        font-weight: normal;
        color: #00a972;
        // line-height: 1;
        &:hover,
        &:active,
        &:focus {
            color: #00a972;
        }
    }

    &.is-fullwidth {
        width: 100%;
    }

    &.font-large {
        font-size: 1.9rem
    }
}

.btn--line--black {
    width: 100%;
    padding: 1.5rem 0;
    // margin-top: 2rem;
    font-size: 1.5rem;
    color: #0a0a0a;
    border-radius: 0.8rem;
    border: 1px solid #bbb;
    background-color: #fff;

    &.is-small {
        padding: 1.2rem 0;
        font-size: 1.4rem;
    }

    &:disabled {
        background-color: #e9e9e9;
        color: #9a9a9a;
        border-color: #e9e9e9;
        cursor: auto;
    }
}

.btn--solid--black {
    width: 100%;
    padding: 1.5rem 0;
    // margin-top: 2rem;
    font-size: 1.5rem;
    color: #fff !important;
    border-radius: 0.8rem;
    border: 1px solid #0a0a0a;
    background-color: #0a0a0a;

    &.is-small {
        padding: 1.2rem 0;
        font-size: 1.4rem;
    }

    &:hover {
        color: #fff;
    }

    &.is-order {
        width: auto;
        border-radius: 0;
        padding: 0 1.6rem;
        white-space: nowrap;
    }

    &:disabled {
        color: #fff;
        border-color: #9a9a9a;
        background-color: #9a9a9a;
        cursor: auto;
    }

    &.is-disabled {
        color: #fff;
        border-color: #9a9a9a;
        background-color: #9a9a9a;
        cursor: auto;
    }

    &.--type2 {
        &:disabled {
            color: #9a9a9a !important;
            border-color: #f2f2f2;
            background-color: #f2f2f2;
        }
    }
}

//221117 회색 솔리드 버튼 추가, 인풋 옆 버튼 마진 추가
.btn--solid--gray {
    width: 100%;
    padding: 1.5rem 0;
    font-size: 1.5rem;
    color: #fff;
    border-radius: 0.8rem;
    background-color: #9a9a9a;

    &.is-order {
        width: auto;
        border-radius: 0;
        padding: 0 1.6rem;
        white-space: nowrap;
    }

    &.font-large {
        font-size: 1.9rem
    }
}

.btn--solid--green {
    width: 100%;
    padding: 1.5rem 0;
    font-size: 1.6rem;
    color: #fff !important;
    border-radius: 0.8rem;
    background-color: var(--selex--green);
}

a.btn--solid--gray:hover, button.btn--solid--gray:hover {
    color:#fff;
}

.btn-input {
    margin-left:0.4rem;
}


.btn--line--gray {
    width: 100%;
    padding: 1.5rem 0;
    // margin-top: 2rem;
    font-size: 1.5rem;
    color: #0a0a0a;
    border-radius: 0.8rem;
    border:1px solid #bbb !important;
    background-color: #fff;
}

//221116 버튼 박스 추가
.btn-area {
    margin-top:4rem;

    &.is-vertical {
        .btn + .btn {
            margin-top:0.8rem;
        }
    }

    &.is-half {
        display: flex;
        justify-content: space-between;

        .btn {
            width: 100%;
        }

        .btn + .btn {
            margin-left:0.8rem;
        }
    }

    &.is-right {
        text-align: right;
    }

    &.is-center {
        text-align: center;
    }
}

//221205 .btn-payment 노란색 하이라이트 추가
.btn {
    .yellow {
        color:#ffde00;
    }
}

.btn--edit {
    width: 2.2rem;
    height: 2.2rem;
    position: relative;
    top: -0.3rem;
    background-image: url(../images/ico/ico_edit.svg);
    background-size: 2.2rem;
    background-repeat: no-repeat;

    &.is-disabled {
        background-image: url(../images/ico/ico_edit_disabled.svg);
    }
}

.btn--solid--light-gray {
    width: 100%;
    padding: 1.5rem 0;
    font-size: 1.5rem;
    color: #0a0a0a;
    border-radius: 0.8rem;
    background-color: #f2f2f2;
}

.btn--solid--white {
    width: 100%;
    padding: 1.5rem 0;
    font-size: 1.5rem;
    color: #0a0a0a;
    border-radius: 0.8rem;
    background-color: #fff;
}

.btn--arrow {
    font-size: 1.5rem;
    color: #0a0a0a;

    &::after {
        content: '';
        display: inline-block;
        width: 1.2rem;
        height: 1.2rem;
        background-image: url(../images/ico/ico_arrow_r.svg);
        background-size: 1.2rem;
        background-repeat: no-repeat;
        transform: translate(0.4rem, 0.1rem);
    }
}

.btn--refresh {
    width: 2.8rem;
    height: 2.8rem;
    background-image: url(../images/ico/ico_refresh.svg);
    background-position: 50%;
    background-size: 2.8rem;
}

.btn--line--green {
    width: 100%;
    padding: 1.5rem 0;
    font-size: 1.5rem;
    color: var(--selex--green);
    border-radius: 0.8rem;
    border: 1px solid var(--selex--green);
    background-color: #fff;

    &:hover {
        color: var(--selex--green);;
    }
}

// .btn {
//     border: 1px solid;

//     &--solid {
//         background-color: #0a0a0a;
//         border-color: transparent;
//     }

//     &--line {
//         background-color: transparent;
//         border-color: #0a0a0a;
//     }
// }

// .btn--primary {
//     &.--solid {
//         background-color: #0a0a0a;
//         border-color: transparent;
//     }

//     &.--line {
//         background-color: transparent;
//         border-color: #0a0a0a;
//     }
// }

// .btn--secondary {
//     background-color: transparent;
//     border-color: #bbb;
// }

.btn-maeil {
    .maeil {
        display: inline-block;
        width: 3.2rem;
        margin-right: 0.2rem;
        vertical-align: text-bottom;
    }
}