.section.--shop {
    + .section.--shop {
        margin-top: 5rem;
    }
} 

.category {
    &-box {
        padding-top: 5.2rem;
        position: relative;
        z-index: 4;

        .swiper-container {
            border-bottom: 1px solid #bbb;
            margin-left: -2rem;
            margin-right: -2rem;
        }

        &.--btn {
            .view {
                padding: 1.2rem 2rem;
                overflow: hidden;
            }
        }

        .container {
            // padding-left: 0;
            // padding-right: 0;

            position: fixed;
            top: 0;
            transform: translateY(4.8rem);
            transition: .3s;        
            width: 100%;
            // max-width: 64rem;
            background-color: #ffff;
            z-index: 3;
        }
    }

    &__list {
        white-space: nowrap;
        // overflow-x: auto;
        text-align: center;
        // font-size: 0;     
        
        // display: flex;
        // justify-content: space-between;
        // align-items: center;

        // &::before {
        //     content: '';
        //     display: block;
        //     width: 100%;
        //     position: absolute;
        //     bottom: 0;
        //     border-bottom: 1px solid #ccc;
        // }

        li {
            display: inline-block;
            text-align: center;

            &.is-active {
                .category__btn {
                    position: relative;
                    // font-weight: 700;
                    color: #0a0a0a;

                    &::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        left: 0;
                        bottom: 0;
                        border-bottom: 3px solid #0a0a0a;
                    }
                }
            }
        }
    }
    
    &__btn {
        // display: flex;
        // justify-content: center;
        // align-items: center;        
        // // height: 5rem;
        // height: 5.2rem;
        // padding: 0 2rem;
        // // padding: 1rem 2rem 1.5rem;
        // font-size: 1.6rem;
        // color: #636363;

        // &:hover {
        //     color: #636363;
        // }

            /* display: flex; */
        /* justify-content: center; */
        /* align-items: center; */
        height: 5.2rem;
        /* padding: 0 2rem; */
        font-size: 1.6rem;
        color: #636363;
        // width: 100%;
        // min-width: 8rem;
        display: block;
        text-align: center;
        border: 0;
        line-height: 5.2rem;
        cursor: pointer;
    }
    
}

// pc접속시
.view--pc {

    .category-box .container {
        max-width: 64rem;
    }

    .category-box.--btn .view {
        overflow: auto;

        /* width */
        &::-webkit-scrollbar {
            // width: 4px;
            height: 6px;
            
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            background: #fff;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #eee; 
            overflow: hidden;
            border-radius: 3px;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #00a972; 
        }
    }

    // .category__list {
    //     &::-webkit-scrollbar {
    //         width: 4px;
    //         height: 4px;
    //     }

    //     /* Track */
    //     &::-webkit-scrollbar-track {
    //         // box-shadow: inset 0 0 5px grey; 
    //         border-radius: 2px;
    //         background: #f2f2f2;
    //     }
        
    //     /* Handle */
    //     &::-webkit-scrollbar-thumb {
    //         background: #ddd; 
    //         border-radius: 2px;
    //     }
    // }
}

.view--mo {
    .category-box.--btn .view {
        overflow: auto;
    }
}

.shop__main--swiepr {
    margin-left: -2rem;
    margin-right: -2rem;

    .logo {
        position: absolute;
        top: 4.5rem;
        left: 2rem;
    }

    .text {
        position: absolute;
        bottom: 3.6rem;
        // transform: translateY(-50%);
        // font-size: 2.4rem;
        display: block;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
        // margin-top: -1.8rem;
        z-index: 1;
        width: 100%;
        

        .title {
            display: block;
            font-size: 2.4rem;
            // line-height: 2.8rem;
            font-weight: 600;
            text-align: center;
        }

        .desc {
            display: block;
            margin-top: .7rem;
            font-size: 1.7rem;
            text-align: center;
        }
    }

    .swiper-control-wrapper {
        width: 100%;
        display: block;
        position: absolute;
        // bottom: 1.2rem;
        bottom: 1.5rem;
        z-index: 1;

        .container {
            padding-left: 1.2rem;
            padding-right: 1.2rem;
        }
    }

    .paging-box {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .swiper-pagination-outer {
        width: 100%;
        overflow: hidden;
        position: relative;
        height: 2px;
    }

    .swiper-pagination-count {
        margin-left: 1.1rem;
        color: #efefef;
        font-size: 1.3rem;
        white-space: nowrap;

        background-color: rgba(10, 10, 10, .6);
        line-height: 1;
        padding: 0.3rem 0.8rem;
        border-radius: 0.8rem;
        font-size: .9rem;
        color: #fff;
    }

    .swiper-pagination-progressbar {
        background-color: #efefef;
    }

    .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
        background-color: #0a0a0a;
    }

    .swiper-control-box {
        .swiper-button-pause {
            background-size: 2rem;
            background-position: center;
            background-repeat: no-repeat;
        }

        .__state-swiper-play {
            background-image: url(../images/ico/btn_play.svg);
        }

        .__state-swiper-pause {
            background-image: url(../images/ico/btn_pause.svg);
        }
    }

    
}

.category--switch {
    position: relative;
    z-index: 1;

    &__panel {
        &-box {
            margin-top: 2rem;
            // padding-bottom: 3.5rem;

            .panel {
                margin: -.375rem -.5rem;
                font-size: 0;

                li {
                    display: inline-block;
                    margin: .375rem .5rem;
                    
                    .btn {
                        padding: .9rem 1.2rem;
                        border: 1px solid #bbb;
                        border-radius: 1.6rem;
                        color: #0a0a0a;
                    }

                    &:first-child {
                        .btn {
                            background-color: #0a0a0a;
                            color: #fff;
                            border-color: #0a0a0a;
                        }
                    }
                }
            }

            &.is-switch {
                .panel--type01 {
                    display: none;
                }

                .panel--type02 {
                    display: block;
                }
            }

            .panel--type01 {
                display: block;
            }

            .panel--type02 {
                display: none;
            }
        }
    }
}

.product-box--shop {
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: -2rem;
    margin-right: -2rem;
    // margin-bottom: 5rem;

    .product__thumb {
        width: 100%;
    }
}

.shop {
    &__content {
        &--header {
            // margin-bottom: 1.5rem;
            margin-bottom: 2rem;
        }

        &--info {
            margin-bottom: 1rem;
            font-size: 1.3rem;
            font-weight: 700;
            color: #636363;
        }

        &__more {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 1.2rem; 
            color: #9a9a9a;

            &:hover {
                color: #9a9a9a;
            }
        }
    }
}

.shop-swiepr {
    
    .swiper-container {
        padding-left: 2rem;
        padding-right: 2rem;
        margin-left: -2rem;
        margin-right: -2rem;
        
    }
}


.banner {
    &--launching {
        border-top: 0;
        z-index: 6;
        padding-top: 4rem;
        position: relative;

        .container {
            position: fixed;
            top: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            z-index: 4;
            width: 100%;
            height: 4rem;
            background-image: linear-gradient(110deg, #5890ff -19%, #2770ef 37%, #04a380 80%, #06cf8e 107%);
            // background-color: #fff;
            transition: .3s;
            transform: translateY(0);
        }

        a {
            background: transparent;
            font-size: 1.5rem;
            color: #fff;
        }

        button {
            width: 1.2rem;
            height: 1.2rem;
            background-image: url(../images/ico/ico_close_w.svg);
            background-size: cover;
        }

        &.is-none {
            .container {
                transform: translateY(-4rem);
            }
        }
    }
}

.header--shop {
    &.--main {
        transition: .3s;

        .container {
            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: -2rem;
                display: block;
                width: 100%;
                // height: 2rem;
                background: #fff;
            }
        } 
    }

    &.--sub {
        padding-top: 4.8rem;
        transition: .3s;
    }

}

// .header {
//     .container {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         height: 4.8rem;
//         transition: .3s;
//         width: 100%;
//         background-color: #fff;
//         position: fixed;
//         top: 0;
//         transform: translateY(0);
//     }

//     &--main {
//         .container {
//             top: 6.8rem;
//         }
//     }
// }


.img-category {
    &__header {
        margin-top: 2rem;
        font-size: 0;
    
        .btn {
            padding: .8rem 1.2rem;
            font-size: 1.9rem;
            color: #9a9a9a;
            border: 1px solid #9a9a9a;
            border-radius: 1.8rem;
            line-height: 1;
            background-color: #fff;
    
            &.is-active {
                font-weight: 600;
                color: #fff;
                background-color: #0a0a0a;
                border-color: #0a0a0a;
            }
    
            + .btn {
                margin-left: 1.2rem;
            }
        }
    }

    &__body {
        margin-top: 1.6rem;
    }

    &__panel {
        font-size: 0;
        margin: -1.6rem;
        // padding-bottom: 1.4rem;
        display: none;

        &.is-active {
            display: block;
        }
    
        li {
            display: inline-block;
            width: calc(25% - 3.6rem);
            margin: 1.8rem;
            text-align: center;
            vertical-align: top;
    
            .thumb {
                display: block;
                position: relative;
                width: 100%;
                background-position: center;
                background-size: 52%;
                background-repeat: no-repeat;
                background-color: #f2f2f2;
                border-radius: 50%;
                border: 1px solid transparent;
                
                &::after {
                    content: "";
                    display: block;
                    padding-bottom: 100%;
                }
            }
    
            .title {
                display: block;
                margin-top: .8rem;
                font-size: 1.5rem;
                color: #0a0a0a;
            }
    
            &:last-child {
                .thumb {
                    background-image: url(../images/ico/ico_search_cate_all.svg);
                }
            }
    
            &.is-active {
                .thumb {
                    border-color: #0a0a0a;
                    background-color: #fff;
                }
            }
        }
    
        &.effect {
    
            li {
                &:first-child {
                    .thumb {
                        background-image: url(../images/ico/ico_search_cate_effect01.svg);
                    }
                }
    
                &:nth-child(2) {
                    .thumb {
                        background-image: url(../images/ico/ico_search_cate_effect02.svg);
                    }
                }
    
                &:nth-child(3) {
                    .thumb {
                        background-image: url(../images/ico/ico_search_cate_effect03.svg);
                    }
                }
    
                &:nth-child(4) {
                    .thumb {
                        background-image: url(../images/ico/ico_search_cate_effect04.svg);
                    }
                }
    
                &:nth-child(5) {
                    .thumb {
                        background-image: url(../images/ico/ico_search_cate_effect05.svg);
                    }
                }
    
                &:nth-child(6) {
                    .thumb {
                        background-image: url(../images/ico/ico_search_cate_effect06.svg);
                    }
                }
    
                &:nth-child(7) {
                    .thumb {
                        background-image: url(../images/ico/ico_search_cate_effect07.svg);
                    }
                }
            }
        }
    
        &.ingredient {
    
            li {
                &:first-child {
                    .thumb {
                        background-image: url(../images/ico/ico_search_cate_ingredient01.svg);
                    }
                }
    
                &:nth-child(2) {
                    .thumb {
                        background-image: url(../images/ico/ico_search_cate_ingredient02.svg);
                    }
                }
    
                &:nth-child(3) {
                    .thumb {
                        background-image: url(../images/ico/ico_search_cate_ingredient03.svg);
                    }
                }
    
                &:nth-child(4) {
                    .thumb {
                        background-image: url(../images/ico/ico_search_cate_ingredient04.svg);
                    }
                }
    
                &:nth-child(5) {
                    .thumb {
                        background-image: url(../images/ico/ico_search_cate_ingredient05.svg);
                    }
                }
    
                &:nth-child(6) {
                    .thumb {
                        background-image: url(../images/ico/ico_search_cate_ingredient06.svg);
                    }
                }

                &:nth-child(7) {
                    .thumb {
                        background-image: url(../images/ico/ico_search_cate_ingredient07.svg);
                    }
                }
            }
        }
    }
}
.shop__best {
    &--header {
        margin-top: 2.8rem;
        margin-bottom: 2rem;
        font-size: 0;

        li {
            display: inline-block;

            + li {
                &::before {
                    content: '';
                    display: inline-block;
                    vertical-align: middle;
                    width: 1px;
                    height: 1.2rem;
                    background-color: #cfcfcf;
                    margin-left: 1.2rem;
                    margin-right: 1.2rem;
                }  
            }

            &.is-active {
                .btn {
                    color: #0a0a0a;
                }
            }
        }

        .btn {
            padding: 0;
            font-size: 2rem;
            font-weight: 600;
            color: #9a9a9a;
        }
    }

    &--info {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &--product {
        .thumb {
            overflow: hidden;
            width: 12rem;
            height: 12rem;

            &::after {
                content: '';
                display: block;
                padding-top: 100%;
            }
        }

        .title {
            margin-bottom: 1rem;
            font-size: 1.9rem;
            font-weight: 500;
            color: #0a0a0a;
            display: flex;
            justify-content: flex-start;
            align-items: center;


            b {
                margin-right: 1.5rem;
                font-size: 2.2rem;
                vertical-align: middle;
            }

            span {
                overflow: hidden;
                width: 100%;
                text-overflow: ellipsis;
                word-wrap: break-word;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }

        .text-box {
            width: calc(100% - 14rem);
            margin-left: 1.6rem;
        }

        .tag-box {
            font-size: 0;
            color: #0a0a0a;
            // overflow: hidden;
            // width: 100%;
            // text-overflow: ellipsis;
            // word-wrap: break-word;
            // display: -webkit-box;
            // -webkit-line-clamp: 2;
            // -webkit-box-orient: vertical;
            
            .tag {
                font-size: 1.7rem;
                
                + .tag {
                    margin-left: .8rem;
                }
            }
        }

        .price {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            margin-top: .8rem;

            .num {
                font-size: 1.7rem;
                font-weight: 700;
            }

            .del {
                display: inline-block;
                margin-left: .4rem;
                font-size: 1.3rem;
                color: #b8b8b8;
            }
        }
        

        li {
            margin-top: 2rem;

            
            &:first-child {
                .title {
                    font-weight: 600;
                    b {
                        color: #0a0a0a;
                    }
                }
            }

            &:nth-child(2) {
                .title {
                    b {
                        color: #9a9a9a;
                    }
                }
            }

            &:nth-child(3) {
                .title {
                    b {
                        color: #cfcfcf;
                    }
                }
            }
        }
    }
}


.shop__recommend {
    background-size: 100%;
    background-repeat: no-repeat;

    // &.--login {
    //     padding-top: 4rem;
    //     background-image: url(../images/@dummy/bg_recommend_in.jpg);
    // }

    // &.--logout {
    //     position: relative;
    //     // padding-bottom: 3.2rem;
    //     background-image: url(../images/@dummy/bg_recommend_out.jpg);
    //     overflow: hidden;
    //     position: relative;
    //     display: inline-flex;
    //     justify-content: center;
    //     align-items: center;
    //     background-color: #ddd;
    //     font-size: 0;
    //     width: 100%;

    //     &::after {
    //         content: '';
    //         display: block;
    //         padding-top: 100%;
    //     }

    //     .container {
    //         &::after {
    //             content: '';
    //             display: block;
    //             padding-top: 100%;
    //         }

    //         .tag-box {
    //             position: absolute;
    //             top: 6rem;
    //             margin-top: 0;
    //        } 
    
    //     }

    //     .section--title-box {
    //         position: absolute;
    //         top: 2rem;
    //     }

       
    //     .btn {
    //         position: absolute;
    //         bottom: 1rem;
    //         width: calc(100% - 4rem);
    //         height: 4.7rem;
    //         background-color: #0a0a0a;
    //         color: #fff;
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //     }
    // }

    // .title-h2 {
    //     color: #fff;
    // }

    // .container {
    //     > .tag-box {
    //         margin-top: 1.2rem;
    
    //         .tag {
    //             font-size: 2rem;
    //             font-weight: 600; 
    //             color: #fff;
    
    //             +.tag {

    //             }
    //         }
    //     }

    // }

    &--product {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;

        &.product-box {
            background-color: transparent;
        }

        .product__items {
            margin-left: -.8rem;
            margin-right: -.8rem;
            font-size: 0;

            > li {
                flex: 1;
                display: inline-block;
                width: calc(50% - 1.6rem);
                margin: 0 .8rem;
            }
        }

        .product__thumb {
            width: 100%;
        }
        
        .product__link {
            overflow: hidden;
            display: block;
            background-color: #fff;
            border-radius: .8rem;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
        }
        .product__info {
            padding: 1.6rem;
            margin-top: 0;
        }

        .product__subtitle {
            overflow: hidden;
            display: block;
            text-overflow: ellipsis;
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            height: 3.2rem;
            margin-bottom: .4rem;
            font-size: 1.3rem;
            line-height: 1.6rem;
            width: 100%;
            white-space: normal;
        }

        .product__title {
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            height: 5.6rem;
            margin-bottom: 1.2rem;
            font-size: 1.8rem;
            line-height: 2.8rem;
        }

        .info {
            font-size: 0;
        }

        .discount {
            font-size: 1.8rem;
            color: #ff4646;
        }

        .num {
            font-size: 1.8rem;
        }
        
    }
}

.swiper-over {
    padding-left: 2rem;
    padding-right: 2rem;
    margin-left: -2rem;
    margin-right: -2rem;
}

.product--list--option {
    .product__item-box {
        display: flex;

        input[type=radio] {
            margin:0;
        }
    }
    .__best {
        width: 3rem;
        line-height: 1;

        .num {
            font-size: 2rem;
            font-weight: 600;
            color: #cfcfcf;
        }
    }

    .__radio {
        width: 3rem;
        font-size: 0;
    }

    .__checkbox {
        width: 3rem;
        font-size: 0;
        
    }

    > li {
        &:first-child {
            .__best {
                .num {
                    color: #0a0a0a;
                }
            }
        }

        &:nth-child(2) {
            .__best {
                .num {
                    color: #9a9a9a;
                }
            }
        }
    }
}

.shop__best--body {

    .best__paner {
        display: none;

        &.is-active {
            display: block;
        }
    }
}

.banner__recommend {
    padding: 3.8rem 0;
    background-color: #f2f2f2;

    .container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .thumb {
        min-width: 9rem;
    }

    .text-box {
        margin-left: 1rem;
        font-size: 1.8rem;
        color: #0a0a0a;
        word-break: keep-all;


        em {
            color: #00a972;
        }
    }
}

.product__result-box {
    position: relative;
    margin-top: 2.4rem;
    margin-bottom: .2rem;
    z-index: 1;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .result__total {
        padding: 1rem 0;
        font-size: 1.5rem;
        color: #0a0a0a;
    }
    
    .result__filter {
        .btn {
            display: flex;
            align-items: center;
            margin-right: -1.6rem;
            font-size: 1.5rem;
            color: #0a0a0a;

            &::after {
                content: '';
                display: inline-block;
                width: 1.6rem;
                height: 1.6rem;
                margin-left: .8rem;
                background-image: url(../images/ico/ico_arrow_down.svg);
                background-size: cover;
            }
        }
    }

    .result__filter:first-child {
        .btn {
            margin-left: -1.6rem;
        }
    }
}

.filter__selet {
    >ul > li {
        + li {
            margin-top: 1.8rem;
        }

        input[type="radio"] + label {
            display: block;
            padding: 1rem 0;
            font-size: 1.5rem;
        }

        input[type="radio"]:checked + label {
            color: #00a972;
        }
    } 
}

.exhibition--product-box {
    margin-top: -1.8rem;

    .product__items {
        overflow: hidden;
        padding: 1.2rem;
        background-color: #fff;
        border-radius: .8rem;
    }
}

.tabs--event {
    position: relative;
    padding-top: 7.3rem;

    .container {
        position: fixed;
        top: 0;
        width: 100%;
        max-width: 64rem;
        transform: translateY(10rem);
        z-index: 3;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    ul {
        background-color: #fff;
        padding-bottom: 8px;
    }

        .btn {
        // font-size: 1.5rem;
        // color: #636363;
        padding: 2.4rem 0.8rem;
    }
}

.modal__share {
    &.is-active {
		display: block;
	}

    .modal__body {
		margin-top: 3.2rem;
	}

    .modal__container {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    &--item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 1.6rem;
        cursor: pointer;

        &.--copy {
            img {
                width: 2.8rem;
            }
        }
    }

    .thumb {
        overflow: hidden;
        width: 4.8rem;
        height: 4.8rem;
        border-radius: 50%;
        background-color: #f2f2f2;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
    }

    .desc {
        margin: 1.3rem;
        font-size: 1.8rem;
        color: #0a0a0a;
        padding: 0;
    }
}

.coupon {
    &__down {
        &--nolist {
            padding: 5rem 0;

            &::before {
                content: '';
                display: block;
                height: 10rem;
                margin-bottom: .5rem;
                background-image: url(../images/ico/ico_nocoupon.svg);
                background-size: 10rem;
                background-repeat: no-repeat;
                background-position: center;
            }

            .desc {
                font-size: 1.5rem;
                color: #636363;
            }
        }

        &--list {
            overflow: auto;
            // max-height: 50rem; //임시
            // max-height: calc(100vh - 57vh);
            max-height: calc(100vh - 61vh);
            max-height: calc(100vh - 42.6rem);
            margin: -.6rem;
            margin-top: 2rem;
        }
        &--item {
            display: inline-block;
            width: 100%;
            padding: 0 0.6rem;
            margin: 0.6rem 0;

            &.is-disabled {
                .coupon__down-box {
                    border-color: #9a9a9a;
                    box-shadow: none;

                    .title {
                        color: #9a9a9a;
                    }
    
                    .discount {
                        color: #9a9a9a;
                    }
    
                    .date {
                        color: #9a9a9a;
                    }
                    .desc {
                        color: #9a9a9a;
                    }
    
                    .btn {
                        background-color: #f2f2f2 !important;
                        background-image: url(../images/ico/ico_success.svg);
                        cursor: auto;
                    }
                }

                
            }
        }
        &-box {
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 2rem;
            background-color: #fff;
            border: solid 1px transparent;
            border-radius: .8rem;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);

            .text-box {
                flex: 1;
                border-right: 2px dashed rgba(154, 154, 154, .35);
            }
        
            .title {
                font-size: 1.6rem;
                color: #0a0a0a;
            }
        
            .discount {
                margin-top: .8rem;
                color: #0a0a0a;
            }
        
            .num {
                font-size: 2rem;
        
                b {
                    font-weight: 700;
                }
            }
            
            .desc {
                font-size: 1.8rem;
                font-weight: 700;
            }
        
            .date {
                display: block;
                margin-top: .8rem;
                font-size: 1.3rem;
                color: #636363;
            }
        
            .btn-area {
                padding-left: 2rem;
                margin-top: 0;
            }
        
            .btn {
                overflow: hidden;
                width: 4.4rem;
                height: 4.4rem;
                border-radius: 50%;
                background-color: #0a0a0a;
                background-image: url(../images/ico/ico_down.svg);
                background-size: 2.8rem;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }

}

.msg-box__coupon {
    // padding: 0 0 2rem .6rem; //230131
    padding: 0 0 0 .6rem;
    // padding-left: 0.6rem;
    margin-top: 2.6rem;
    background-color: #fff;
    font-size: 1.2rem;
    color: #636363;

    li {
        position: relative;
        + li {
            margin-top: .6rem;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0.6rem;
            left: -0.6rem;
            width: 0.2rem;
            height: 0.2rem;
            background-color: #636363;
            border-radius: 50%;
        }
    }

    b {
        color: #0a0a0a;
    }
}


.product--detail {
    .product__title {
        font-size: 1.8rem;
    }

    .product__del {
        display: block;
        margin-left: 0;
        margin-bottom: 0.4rem;
    }
}

.detail__info {
    .accordion-group-wrap {
        border-color: #cfcfcf;
    }

    .accordion__btn {
        padding: 2rem 0;
        border-color: #cfcfcf;

        &[aria-expanded="true"] {
            font-weight: 400;
        }

        &.link {
            display: block;

            &::after {
                display: none;
            }
        }
    }

    .accordion__link {
        position: relative;
        display: block;
        z-index: 0;
        width: 100%;
        min-height: 5rem;
        padding: 1.6rem 0;
        border-bottom: 1px solid #bbb;
        background-color: #fff;
        font-size: 1.6rem;
        text-align: left;
        cursor: pointer;
        padding: 2rem 0;
        border-color: #cfcfcf;
    }

    .accordion__contents {
        padding: 1.6rem;
        background-color: #f2f2f2;
        font-size: 1.3rem;
        color: #0a0a0a;
        border-bottom: 1px solid #cfcfcf;

        &.is-nopadding {
            padding: 0;
        }
    }
    &--title {
        display: block;
        font-size: 1.3rem;
        color: #0a0a0a;
    }

    &--dl {
        margin: 0;
        border: 1px solid #cfcfcf;
        border-top: 0;
        border-bottom: 0;

        
        > div {
            display: flex;
            justify-content: flex-start;
            // align-items: center;
            

            +  div {
                // margin-top: -1px;
                border-top: 1px solid #ccc;
            }
        }
        
        dt, dd {
            display: flex;
            align-items: center;
            padding: 1.2rem;
            margin: 0;
        }
        dt {
            flex: 1;
            background-color: #f2f2f2;
            word-break: keep-all;
            font-weight: 600;
        }

        dd {
            flex: 2;
            background-color: #fff;
        }
    }

    &--msg {
        margin-top: .6rem;

        li {
            position: relative;
            padding-left: .6rem;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0.9rem;
                overflow: hidden;
                width: 0.2rem;
                height: 0.2rem;
                border-radius: 50%;
                background-color: #0a0a0a;
            }
        }
        &.--delivery {
            margin-top: 1.2rem;
            font-size: 1.2rem;
            color: #636363;

            li {
                position: relative;
                padding-left: .6rem;

                &::before {
                    background-color: #636363;
                }
            }
        }

    }

    &--desc {
        margin-top: .6rem;
        font-size: 1.3rem;
        line-height: 1.9rem;
        color: #0a0a0a;

        + .detail__info--title {
            margin-top: 1.6rem;
        }
    }
}

.review--detail {
    &.review-box li {
        &:first-child {
            padding-top: 2rem
        }

        &:last-child {
            border-bottom: 0;
        }
    }
}

// 결제창 - 가족계정 선택
.user--choice {
    font-size: 0;
    margin: -.2rem;

    > li {
        display: inline-block;
        margin: .2rem;

        .btn {
            
            padding: 0.5rem 1.2rem;
            background: none;
            background-repeat: no-repeat;
            background-size: 1.6rem;
            background-position: left 1.2rem center;
            border: solid 1px #b8b8b8;
            font-size: 1.7rem;
            color: #0a0a0a;

            &__add {
                padding: 0.5rem 1.2rem 0.5rem 3.2rem;
                background-image: url(../images/ico/ico_plus.svg);
            }
        }

        &.is-active {
            .btn {
                padding: 0.5rem 1.2rem 0.5rem 3.2rem;
                background-color: #0a0a0a;
                background-image: url(../images/ico/ico_check_w.svg);
                border-color: #0a0a0a;
                color: #fff;
            }
        }
    }

    &.--gray {
        margin: 0 -0.6rem;
        white-space: nowrap;

        li {
            margin: 0 0.6rem;

            .btn {
                padding: 1.2rem 1.6rem;
                background-color: #e9e9e9;
                border: 0;
                font-size: 1.6rem;
                color: #636363;
            }

            &.is-active {
                .btn {
                    background-color: #0a0a0a;
                    background-image: none;
                    border: 0;
                    color: #fff;
                    font-weight: 600;
                }
            }
        }
    }
}

// 결제창 - 선택된 가족계정
.user--current {
    margin-top: 2rem;
    color: #9a9a9a;

    .user {
        font-size: 1.6rem;
        color: #00a972;
        font-weight: 600;
    }

    .txt {
        font-size: 1.6rem;
    }
}

// 결제창 - 상품명
.detail__pay--title {
    margin-top: 1.2rem;
    font-size: 1.7rem;
    color: #0a0a0a;
    font-weight: 600;
    line-height: 2.5rem;
}

// 결제창 - 수량 및 가격 정보
.detail__pay-box {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .count-box {
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #c1c1c1;
        border-radius: 0.8rem;
        font-size: 0;
    }

    .btn {
        width: 4rem;
        height: 4rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 2rem;

        &__minus {
            background-image: url(../images/ico/order_minus.svg);
        }
        
        &__plus {
            background-image: url(../images/ico/order_plus.svg);
        }
    }

    .count {
        padding: 0;
        display: block;
        min-width: 3.8rem;
        height: 100%;
        font-size: 1.5rem;
        font-weight: 600;
        color: #0a0a0a;
        text-align: center;
        border: 0;
        line-height: inherit;
        width: 5rem;
    }
    
    .price {
        // display: flex;
        // justify-content: flex-end;
        // align-items: center;
        text-align: right;

        del {
            display: block;
            padding-right: 0.2rem;
            font-size: 1.3rem;
            color: #b8b8b8;
        }

        .num {
            display: block;
            // margin-left: .4rem;
            font-size: 1.7rem;
            color: #0a0a0a;

            b {
                
            font-weight: 700;
            }
        }
    }
}

.modal {
    &.--payment {
        .modal__header {
            margin-top: 0;
        }

        .modal__close {
            position: relative;
            width: 3.6rem;
            padding: 1.6rem;
            background-image: none;

            &::after {
                content: '';
                display: block;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
                height: 0.4rem;
                background-color: #cfcfcf;
                border-radius: 0.2rem;

            }
        }
        
        .modal-box {
            margin-top: 1.2rem;
        }

        .btn-area {
            .btn {
                padding: 1.9rem 0;
                border-radius: 0;
                border-right: 0;
                border-left: 0;
                border-bottom: 0;
                font-size: 1.9rem;

                + .btn {
                    margin-left: 0;
                }
            }
        }
    }
}

.detail__pay--total {
    padding-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    border-top: 1px solid #cfcfcf;

    .txt {
        font-size: 1.5rem;
        font-weight: 600;
        color: #0a0a0a;
    }

    .num {
        font-size: 2.1rem;
        color: #ff4646;
        
        b {
            font-weight: 700;
        }
    }
}

.date__choice {
    margin-top: 3.2rem;
    &--title {
        font-size: 1.6rem;
        color: #0a0a0a;
        font-weight: 600;
        margin-bottom: 1.2rem;
    }

    &--list {
        font-size: 0;

        li {
            display: inline-block;
            border: 1px solid #c1c1c1;
        }

        &.is-block {

            li {
                
            display: block;
            width: 100%;

                + li {
                    margin-top: 20px;
                }
            }
        }

        &.--day {
            li {
                width: 25%;

                + li {
                    margin-left: -1px;
                    width: calc(25% + 1px);
                }
            }
        }

        &.--week {
            li {
                width: 50%;

                &:nth-child(2n) {
                    margin-left: -1px;
                    width: calc(50% + 1px);
                }

                &:nth-child(n+3) {
                    margin-top: -1px;
                }
            }
        }

        &.--column-three {
            li {
                width:33%;

                &:nth-child(2n) {
                    margin-left: -1px;
                    width: calc(33% + 1px);
                }
                
                &:nth-child(n+3) {
                    margin-left: -1px;
                    width: calc(33% + 1px);
                }

                &:nth-child(n+4) {
                    margin-top: -1px;
                }
            }
        }
    }

    input[type="radio"] + label.date__choice--label {
        
        display: block;
        font-size: 1.4rem;
        color: #0a0a0a;
        padding: 1.4rem 0;
        text-align: center;
        
        width: calc(100% + 2px);
        margin: -1px;
    }

    input[type="radio"]:checked + label.date__choice--label {
        color: #fff;
        background-color: #0a0a0a;
        border-color: #0a0a0a;
        z-index: 1;
    }

    &--msg {
        margin-top: 3.2rem;

        li {
            position: relative;
            font-size: 1.2rem;
            color: #636366;
            line-height: 1.8rem;
            padding-left: .8rem;

            &::after {
                content: '';
                overflow: hidden;
                position: absolute;
                width: .2rem;
                height: .2rem;
                background-color: #636363;
                border-radius: 50%;
                left: 0;
                top: .8rem;
            }
        }
    }
}

.agree {
    &__scroll-box {
        overflow: hidden;
        // margin-top: 2rem;
        border: 1px solid #bbb;
        border-radius: .6rem;
        font-size: 1.4rem;
        line-height: 2rem;
        color: #949494;

        .scroll {
            overflow-y: auto;
            height: 100%;
            // max-height: 30rem;
            max-height: 11.6rem;
            padding: 1.4rem;
            background-color: #fff;
        }
    }

    &__text-box {
        padding: 0;
        color: #949494;
        font-size: 1.4rem;
        font-weight: 400;
    }

}

.btn__agree--view {
    &::after {
        content: '보기';
        background: none;
        width: auto;
        height: auto;
        transform: none;
        font-size: 1.4rem;
        color: #9a9a9a;
        text-decoration: underline;
        white-space: nowrap;
    }

    &[aria-expanded="true"] {
        &::after {
            content: '닫기';
        }
    }
}

// 카드 추가
.modal__body.pay__card--add {
    max-height: calc(100vh - 161px);
    margin-top: 3.2rem;
    margin-bottom: 0;
    padding-bottom: 0;
    height: 100vh;
    overflow-y: auto;
    background-color: #f2f2f2;

    label {
        font-weight: 500;
    }

    .order__policy {
        margin-top: 4rem;
        margin-left: -2.8rem;
        margin-right: -2.8rem;
    }

    .modal__container {
        background-color: #fff;
    }
}

.modalCardRegist {
    .modal__footer {
        position: fixed;
        bottom: 0;
        width: 100%;
    }
}

.search__shop--main {
    position: relative;
    // padding-top: 8.8rem;
    padding-top: 4.8rem;

    .container {
        display: flex;
        position: fixed;
        top: 0;
        // transform: translateY(14rem);
        transform: translateY(10rem);
        width: 100%;
        // max-width: 64rem;
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
        background-color: #fff;
        z-index: 3;
        transition: .3s;
        box-shadow: 0 .3rem .3rem rgba(0, 0, 0, 0.16);
    }

    .link {
        overflow: hidden;
        width: calc(100% - 10.3rem);
        padding: 1rem 4.6rem 1rem 1.5rem;
        background-color: #f2f2f2;
        background-image: url(../images/ico/ico_input_search.svg);
        background-repeat: no-repeat;
        background-size: 2.8rem;
        background-position: right 1.5rem center;
        border-radius: 1.8rem;
        font-size: 1.4rem;
        color: #9a9a9a;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .btn {
        border: 1px solid #bbb;
        font-size: 1.4rem;
        font-weight: 600;
        max-width: 9.5rem;
        margin-left: .8rem;
        background-image: url(../images/ico/ico_burger.svg);
        background-repeat: no-repeat;
        background-size: 2rem;
        background-position: 1rem 50%;
        padding-left: 3.6rem;
        padding-right: 1rem;
    }
}


.banner--launching + .header + .category-box {

    .container {
        transform: translateY(8.8rem);
    }
}

.banner--launching + .header + .category-box + .search__shop--main {
    .container {
        transform: translateY(14rem);
    }
}

.banner--launching.is-none {
    padding-top: 0; 

    + .header {
        + .category-box {
    
            .container {
                transform: translateY(4.8rem);
            }

            + .search__shop--main {
                .container {
                    transform: translateY(10rem);
                }
            }
        }

        
    }
}


.banner--launching + .header--shop {

    .container {
        transform: translateY(4rem);
    }
    &.is-top {
        .container {
            transform: translateY(0);
        }

        .category-box {
            transform: translateY(4.8rem);
        }

    }
}

.banner--launching + .header--main {
    padding-top: 4.8rem;

    .container {
        top: 0;
        transform: translateY(4rem);
    }
}

.banner-swiepr {
    .swiper-pagination-bullet {
        width: .8rem;
        height: .8rem;
    }
    .swiper-pagination {
        left: auto;
        right: 2rem;
        top: auto;
        bottom: .8rem;
        width: auto;
    
        .swiper-pagination-bullet {
            background-color: #fff;
            opacity: .6;
            margin: 0 .2rem;
        }
    
        .swiper-pagination-bullet-active {
            opacity: 1;
        }
    }
}

.health__tips {
    padding: 2.6rem 2.4rem 2.4rem;
    border-radius: 1.2rem;
    box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.3);
    background-color: #fff;

    .text-box {
        margin-top: 2rem;
    }
    .title {
        // margin-top: 2rem;
        font-size: 2.2rem;
        color: #0a0a0a;
    }

    .title-h2 {
        font-weight: 500;
    }

    .desc {
        margin-top: 1.5rem;
        font-size: 1.7rem;
        color: #636363;
    }
}

.detail__info {
    .accordion__contents {
        .text-box {
            display: block;
        }
    }
}

.magazine {
    &__main {
        .thum {
            // aspect-ratio: 2/1; 사파리 이슈 있을 수 있음
            width: 100%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            background-color: #f7f7f7;

            &::after {
                content: '';
                display: block;
                padding-bottom: 50%;
            }

            img {
                position: absolute;
            }
        }
        
        .title {
            font-size: 2.2rem;
            font-weight: normal;
            line-height: 1.36;
        }

        .desc {
            margin-top: 1.6rem;
            font-size: 1.7rem;
            color: #636363;
        }
    }

    &__list {
        li {
            border-radius: 1.2rem;
            border: 1px solid #cfcfcf;
        }

        li + li {
            margin-top: 1.2rem;
        }

        .list-wrap {
            display: flex;
            align-items: center;
            padding: 2.4rem;
        }

        .thum {
            width: 8rem;
            height: 8rem;
            flex: none;
            margin-right: 1.6rem;
            border-radius: 0.8rem;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .title {
            font-size: 2rem;
            font-weight: normal;
            line-height: 1.4;
            text-overflow: ellipsis;
            overflow: hidden;
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .date {
            display: block;
            margin-top: 1.2rem;
            font-size: 1.3rem;
            color: #9a9a9a;
        }
    }

    &__page {
        &--header {
            margin-bottom: 2.4rem;

            .date {
                display: block;
                margin-bottom: 1.2rem;
                font-size: 1.4rem;
                color: #9a9a9a;
            }

            .title {
                font-size: 2.2rem;
                font-weight: normal;
                line-height: 1.36;
            }

            .desc {
                margin-top: 1.6rem;
                font-size: 1.7rem;
                color: #636363;
            }
        }

        &--body {
            img {
                width: 100% !important;
                height: auto !important;
                float: none !important;
            }
        }
    }
}


.benefit-percent {
    margin: 0 -0.6rem;
    font-size: 0;

    li {
        display: inline-block;
        width: calc(25% - 1.2rem);
        margin: 0 0.6rem;
        text-align: center;
    }

    .percent {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        &::after {
            content: '';
            display: block;
            padding-bottom: 100%;
        }

        &.--yellow {
            background-color: #ffb941;
        }

        &.--purple {
            background-color: #be85ff;
        }

        &.--blue {
            background-color: #6195ff;
        }

        &.--green {
            background-color: #2ccc98;
        }
    }

    .text {
        font-size: 1.4rem;
        color: #fff;

        .num {
            font-size: 2rem;
            font-weight: 600;
        }
    }

    .desc {
        display: block;
        margin-top: 1rem;
        font-size: 1.5rem;
    }
}

#modalPeriodInfo {
    .modal__body {
        height: 100%;
        max-height: calc(100vh - 7rem);
        overflow-y: auto;
    }
}


.tag {

    &__title {
        width: 100%;
        font-size: 2.4rem;
        color: #0a0a0a;
        font-weight: 600;
    }

    &__btn {
        position: relative;
        width: 100%;
        margin-top: 1.6rem;
        padding: 1.4rem 2.2rem;
        font-size: 1.8rem;
        color: #00a972;
        text-align: left;
        border: 3px solid #00a972;
        border-radius: 4rem;
        font-weight: 500;

        &::after {
            position: absolute;
            top: 1.3rem;
            right: 2.2rem;
            display: inline-block;
            content: '';
            width: 1.9rem;
            height: 1.9rem;
            background-image: url(/assets/images/ico/ico_line_arrow.svg);
            background-size: cover;
        }

        &:hover {
            color: #00a972;
        }
    }


    &__list {
        margin-top: 2.2rem;
        padding-left: 2rem;
        padding-right: 2rem;
        margin-left: -2rem;
        margin-right: -2rem;

        .swiper-slide {
            // width: auto !important;

            &.is-active {
                .thumb  {
                    background-color: #fff;
                    border-color: #0a0a0a;
                }
    
                .name {
                    font-weight: bold;
                }
            }    
        }

        &--wrap {
            display: flex;
            flex-wrap: wrap;
            margin: -1rem;
            justify-content: space-between;

            li {
                margin: 1rem .8rem;
            }
        }
    }

    &__item {
        text-align: center;
        cursor: pointer;

        .thumb  {
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            // width: 4.4rem;
            // height: 4.4rem;
            background-color: #f2f2f2;
            border-radius: 50%;
            border: 1px solid transparent;
            margin: auto;
            position: relative;
            max-width: 7rem;

            img {
                // width: 2.8rem;
                width: 70%;
            }

            &::after {
                content: '';
                display: block;
                padding-bottom: 100%;
            }
        }

        .name {
            display: inline-block;
            margin-top: .6rem;
            text-align: center;
            font-size: 1.4rem;
            color: #0a0a0a;
            text-align: center;
        }
    }
}


.category-box.swiper {
    .swiper-slide {
        width: auto;
    }
}

#modalReviewFull {
    height: 100%;

    .modal__body {
        overflow-y: auto;
        height: 100%;
        max-height: calc(100vh - 10rem);
    }
    
    .review-img {
        margin-top: 0;
        margin-left: -2.8rem;
        margin-right: -2.8rem;
    }

    .review__star {
        margin-top: 2rem;
    }

    .review__write {
        margin-top: 0.6rem;
    }

    .text-box {
        display: block;
        max-height: inherit;
        overflow: inherit;
    }
}

#findSelexModal {
    .modal__header + .modal__body {
        margin-top: 0;
        padding-top: 3.2rem;
    }

    .modal__body {
        height: 100%;
        min-height: calc(100vh - 10rem);
        padding-bottom: 18rem;
        overflow-y: auto;
    }

    .title {
        font-size: 1.8rem;
        font-weight: 600;
        text-align: center;
    }

    .find-list {
        display: flex;
        flex-wrap: wrap;
        margin: 2.4rem -1.2rem -1.2rem -1.2rem;

        li {
            width: calc(50% - 1.2rem);
            margin: 0.6rem;

            &.is-active {
                .list-wrap {
                    border-color: #00a972;
                    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.3);;
                }
            }
        }

        &.--function {
            li {
                width: calc(33.33% - 1.2rem);
            }
        }
    }

    .list-wrap {
        display: block;
        width: 100%;
        height: 6rem;
        padding: 1rem 0.2rem;
        font-size: 1.3rem;
        line-height: 1.38;
        border: 1px solid #bbb;
        border-radius: 1.2rem;
        // word-break: break-all;
        word-break: keep-all;
        word-wrap: break-word;
        color: #0a0a0a;

        &:disabled {
            cursor: auto;
            border: 0;
            color: #9a9a9a;
            background-color: #f1f1f1;
        }
    }
}

.section__search--form {

    .tag__list--wrap {
        display: block;
        font-size: 0;

        li {
            display: inline-block;
            width: calc(11.1111% - 1.6rem);
            vertical-align: top;
            transition: all .2s;
        }
    }


    .tag__item {
        .thumb {
            position: relative;
            width: 100%;
            display: block;
            height: auto;

            &::after {
                content: '';
                display: block;
                padding-bottom: 100%;
            }

            img {
                position: absolute;
                width: 60%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}


@media (max-width: 600px) {
    .section__search--form {
        .tag__list--wrap {

            li {
                width: calc(20% - 1.6rem);
            }
        }
    }
}

@media (max-width: 360px) {
    .section__search--form {
        .tag__list--wrap {

            li {
                width: calc(33.333% - 1.6rem);
            }
        }
    }
}

.tabs--sub {
    .category__list {
        .swiper-slide {
            width: auto !important;
        }

    }
}

.tabsSubCategorySwiperChar {
    .btn {
        text-align: center;
        color: #636363;
        font-size: 1.6rem;
        display: block;
        padding: 1.7rem 2rem;
        line-height: 1.4;

        .title {
            display: block;
            color: #636363;
            font-size: 1.8rem;
            font-weight: 500;
        }
    }
    .swiper-wrapper {
        .swiper-slide {
            width: auto !important;

            &.is-active {

                .btn {
                    color: #0a0a0a;

                    .title {
                        color: #00a972;
                    }
                }
            }
        }

    }
}