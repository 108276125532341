
.paging-box {
      display: flex;
    align-items: center;
    justify-content: flex-end;
}

.swiper-pagination-count {
    color: #fff;
}

.swiper-control-box {
    color: #fff;
    // background-color: #555;
}

.swiper-control-wrapper {
        // background: red;
    width: 100%;
    display: block;
}

.siwper-link {
    font-size: 0;
}

.swiper-slide {
    font-size: 0;
}


/* keyVisual */


.swiper-control-box-wrap {
  display: flex;
  position: relative;
  margin-top: 35px;
  height: 50px;
  justify-content: center;
  align-items: center;
  .swiper-button-prev,
  .swiper-button-next {
    position: static;
    margin: 0;
  }
  .swiper-control-box {
    margin-left: 30px;
  }
}
.paging-box {
  &.paging-box-progress1 {
    position: relative;
    width: 555px;
    height: 60px;
    //margin: 0 auto;
    .swiper-pagination-bullet-wrap {
      padding-top: 11px;
    }

    .swiper-pagination-outer {
      position: relative;
      width: 400px;
      margin: 9px auto 0;
    }

    .swiper-pagination-progressbar {
      width: 400px;
      height: 1px;
      top: 4px;
    }

    .swiper-pagination-progressbar-fill {
      height: 1px;
      background-color: #000;
    }

    .swiper-pagination-number {
      font-weight: 400;

      &-active {
        color: #000;
      }
    }
  }
}

.swiper-control-box {
    display: inline-flex;
    align-content: center;

    .swiper-button-pause,
    .swiper-button-play {
        display: inline-flex;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover,
        &:focus {
            border: 1px solid #eee;
        }
    }
    .swiper-button-plus {
        width: 40px;
        height: 40px;
        font-size: 0;
    &:hover,
    &:focus {
        border: 1px solid #eee;
    }
    }
}