
.smbti {
    &__result {

        &--character {
            position: relative;
            display: block;
            padding-bottom: 2.8rem;
            background-color: #fff;
            padding-top: 3.2rem;

            img {
                display: block;
                width: 21.2rem;
                margin: 0 auto;
                position: relative;
                z-index: 1;
            
            }

            &::after {
                content: '';
                position: absolute;
                width: 100%;
                left: 0;
                height: 32rem;
                // background-color: red;
                // background: linear-gradient(to bottom, #2ccc98, #2ccc98 6%, #f2f2f2 71%);
                bottom: -19rem;
                // border-top-left-radius: 45%;
                // border-top-right-radius: 45%;
                background-image: url(../images/bg/bg_personal_character.svg);
                background-size: cover;
            }
        }
    }
}

@media (max-width: 400px) {
    .personal__card .is-inline {
        display: block;
    }
}

@media (max-width: 380px) {
    .personal__card .circle {
        margin: 0 auto;
        width: 13rem;
        height: 13rem;
    }

    .personal__card .thumb img {
        max-height: 13rem;
    }
}

#bubbleChart {
    width: 100%;
    max-width: 56rem;
    background: transparent;
    margin: 0 auto;
    
    path {
        stroke: transparent !important;
        fill-opacity: 1 !important;
    }

    .highcharts-data-label span {
        text-align: center;
    }
}

@media (max-width: 500px) {
    .type-list.is-age li {
        width: calc(100% - 1.6rem);
    }
}

@media (max-width: 400px) {
    .type-list li {
        width: calc(33.333% - 1.6rem);
    }
}

.title-box {
    &.--personal {
        .desc {
            font-size: 1.6rem;
            color: #bbb;

            b {
                font-weight: 400;
                color: #636363;
            }
        }

        .title {
            margin-top: 1.2rem;
            font-size: 2.4rem;
            font-weight: 400;
            color: #0a0a0a;

            b {
                font-weight: 600;
            }
        }

        .subtitle {
            margin-top: 1.6rem;
            font-size: 1.6rem;
            color: #0a0a0a;
        }
    }
}

.prograss {
    &.__personal--top {
        .outer {
            position: relative;
            height: .4rem;
            background-color: #f2f2f2;
            overflow: hidden;
        }

        .inner {
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background-color: #2ccc98;
            transition: .2s ease;
        }
    }
}

.survey {
    &--wrap {
        min-height: calc(100vh - 5.2rem);
    }

    &__count {
        color: #bbb;
        font-size:  1.6reml;
    }

    &__list {
        margin-top: 4.8rem;
        margin-left: -1rem;
        margin-right: -1rem;
        padding-left: 1rem;
        padding-right: 1rem;

        &.is-flex {
            font-size: 0;
            margin-left: -1.6rem;
            margin-right: -1.6rem;

            .survey__item {
                width: calc(50% - 1.2rem);
                display: inline-block;
                margin: 0.6rem;

                // + .survey__item {
                //     margin: 0;
                // }

                &.is-widthfull {
                    width: calc(100% - 1.2rem)
                }
                
                &:last-child {
                    width: calc(100% - 1.2rem)
                }

            }
        }
    }

    &__item {
        + .survey__item {
            margin-top: 1.2rem;
        }

        
        &.--weight {
            .control {
                &::after {
                    content: 'kg';
                    font-size: 2rem;
                    text-align: right;
                    color: #bbb;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                }
            }
        }

        &.--round {
            .control {
                &::after {
                    content: '인치';
                    font-size: 2rem;
                    text-align: right;
                    color: #bbb;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                }
            }
        }

        &.--stature {
            .control {
                &::after {
                    content: 'cm';
                    font-size: 2rem;
                    text-align: right;
                    color: #bbb;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                }
            }
        }


    }

    &--card {
        position: relative;
        z-index: 1;
        height: 100%;

        .control {
            height: 100%;
        }

        input[type="radio"] {

            + label {
                position: relative;
                border-radius: 1.2rem;
                padding-left: 0;
                color: #9a9a9a;
                padding: 2rem;
                background-color: #f2f2f2;
                border: 1px solid transparent;
                height: 100%;
                display: flex;
                align-items: center;

                &::after {
                    content: '';
                    position: absolute;
                    right: 2rem;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 2.8rem;
                    height: 2.8rem;
                    background-color: #bbb;
                    box-shadow: 0 0 0 1px #bbb;
                    border-radius: 50%;
                    border: 7px solid #fff;
                }
            }

            &:checked {
                + label {
                    color: #0a0a0a;
                    background-color: #fff;
                    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.3);
                    border-color: #00a972;

                    &::after {
                        background-color: #00a972;
                        box-shadow: 0 0 0 1px #00a972;
                    }
                }
            }
            
        }

        input[type="checkbox"] {
            position: absolute;
            width: 1px;
            height: 1px;
            overflow: hidden;
            padding: 0;
            margin: -1px;
            border: 0;
            clip: rect(0, 0, 0, 0);

            // width: 10px;
            // height: 10px;
            // position: relative;
            // margin: 0;
            // clip: auto;


            + label {
                position: relative;
                border-radius: 1.2rem;
                padding-left: 0;
                color: #9a9a9a;
                padding: 2rem;
                background-color: #f2f2f2;
                border: 1px solid transparent;
                height: 100%;
                display: flex;
                align-items: center;

                &::before {
                    display: none;
                }

                &::after {
                    content: '';
                    position: absolute;
                    left: auto;
                    right: 2rem;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 3.2rem;
                    height: 3.2rem;
                    background-image: url(/assets/images/ico/ico_per_check.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    border-radius: 0;
                    background-color: transparent;
                }
            }

           

            
        }

        input[type="checkbox"]:checked  + label {
            color: #0a0a0a;
            background-color: #fff;
            box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.3);
            border-color: #00a972;

            // &::before {
            //     display: none;
            // }

            &::after {
                // display: none;
                background-image: url(/assets/images/ico/ico_per_check_p.svg);
            }
        }

        input[type="text"] {
            padding: 1.2rem 0.8rem;
            font-size: 2.4rem;
            font-weight: bold;

            &::placeholder {
                font-weight: 400;
            }
        }
        p {
            width: calc(100% - 4rem);
            line-height: 2.6rem;
            font-size: 1.8rem;
            word-break: keep-all;
        }
    }
}

.view--pc {
    .survey--card input[type="checkbox"] + label {
        &:hover {
            color: #0a0a0a;
            background-color: #fff;
            box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.3);
            border-color: #00a972;

            &::after {
                background-image: url(/assets/images/ico/ico_per_check_p.svg);
            }
        }
    }

    .survey--card input[type="radio"] + label {
        &:hover {
            color: #0a0a0a;
            background-color: #fff;
            box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.3);
            border-color: #00a972;

            &::after {
                background-color: #00a972;
                box-shadow: 0 0 0 1px #00a972;
            }
        }
    }
}

.personal__loading {
    .thumb {
        width: 12rem;
        height: 12rem;
        margin: auto;
        margin-top: 8rem;
    }

    .prograss {
        margin-top: 2rem;

        .outer {
            position: relative;
            height: 1.2rem;
            background-color: #f2f2f2;
            border-top-right-radius: .6rem;
            border-bottom-right-radius: .6rem;
            overflow: hidden;
        }

        .inner {
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background-color: #2ccc98;
            border-top-right-radius: .6rem;
            border-bottom-right-radius: .6rem;
            transition: .1s ease;
        }
    }

    .text {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
        font-size: 1.8rem;
        color: #0a0a0a;
    }
}

.btn-box--fixed.is-flex {
    display: flex;
    justify-content: space-around;

    > .btn {
        flex: 1;
    }
}


.progress__loading {
    position: relative;
    display: flex;
    justify-content: center;
    // margin-top: 15rem;

    .thumb {
        width: 8rem;
        height: 8rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
    }

    canvas {
        transform: rotate(45deg);
    }
}

.text-box.intro__info {
    text-align: center;
    .txt {
        font-size: 2.2rem;
        line-height: 1.36;
        word-break: keep-all;
    }

    .desc {
        margin-top: 2rem;
        font-size: 1.8rem;
        color: #636363;
        line-height: 1.44;
    }
}

.title.is-alone {
    font-size: 2rem;
    color: #0a0a0a;
}

.text-box.is-alone {
    margin-top: 2rem;
    font-size: 1.8rem;
    color: #0a0a0a;
    line-height: 2.8rem;
    
    .block {
        display: block;
        word-break: keep-all;

        + .block {
            margin-top: 2rem;
        }
    }
}

.prograss.__personal--top {
    position: fixed;
    top: 4.8rem;
    width: 100%;
    max-width: 64rem;
    height: .4rem;
    z-index: 3;
}

.personal__card .card-title {
    &.link {
        display: block;
        width: calc(100% - 3rem);
        position: relative;
        
        &::after {
            content: '';
            display: inline-block;
            width: 2.4rem;
            height: 2.4rem;
            background-image: url(/assets/images/ico/ico_next.svg);
            text-align: right;
            position: absolute;
            top: 50%;
            right: -3rem;
            transform: translateY(-50%);
        }
    }

    &.--graph {
        font-size: 2rem;
        b {
            color: #00a972;
            font-weight: 600;
        }
    }
}


@media (max-width: 340px) {
    .survey--card input[type="radio"] + label,
    .survey--card input[type="checkbox"] + label {
        padding: 1.4rem;
    }

    .survey--card input[type="text"] {
        font-size: 1.6rem; 

        &::placeholder {
            font-size: 1.6rem;
        }
    }

    .survey--card p {
        font-size: 1.4rem;
        width: calc(100% - 3rem);
    }

    .survey--card input[type="checkbox"] + label::after {
        right: 1rem;
    }

    .survey__item .control {
        &::after {
            font-size: 1.4rem !important;
        }
    }
}

.examination {
    &__item {
        + .examination__item {
            margin-top: 5rem;
        }
    }
    &-result {
        margin-top: 1rem;
    
        &--detail {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
        }
    
        .current {
            display: inline-block;
            height: 2.4rem;
            // padding: 0 2.6rem;
            // padding: 0 1.8rem;
            border-radius: 1.2rem;
            font-size: 1.2rem;
            color: #fff;
            line-height: 2;
            width: 9rem;
            text-align: center;
    
            &.--greatA{
                background-color: #6195ff;
            }

            &.--greatB {
                background-color: #00a972;
            }
    
            &.--warning {
                background-color: #ff4b4b;
            }
        }
    
        .shame {
            margin-right: .4rem;
            font-size: 1.8rem;
            color: #9a9a9a;
            
            em {
                color: #0a0a0a;
            }
        }
    
        .title {
            display: inline-block;
            min-width: 10rem;
            margin-right: .4rem;
            font-size: 1.8rem;
            color: #0a0a0a;
        }

        &.--info {
            padding: 2.4rem;
            margin-top: 1.6rem;
            background-color: #f2f2f2;
            border-radius: .8rem;

            .title {
                font-size: 1.8rem;
                color: #0a0a0a;
            }

            .desc {
                font-size: 1.4rem;
                color: #636363;
                display: block;
                margin-top: .8rem;
            }

            .list {
                margin-top: 2rem;

                li {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;

                    .current {
                        margin-right: 1.6rem;
                    }

                    .text {
                        width: calc(100% - 10.6rem);
                        font-size: 1.5rem;
                        color: #0a0a0a;
                        line-height: 2.4rem;
                        word-break: keep-all;
                    }

                    + li {
                        margin-top: .8rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 400px) {
    .examination-result .title {
        display: block;
    }

    .examination-result .shame {
        margin-top: .6rem;
        display: block;
    }
}

.title-box.--alone {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .date {
        font-size: 2rem;
        font-weight: 600;
        color: #0a0a0a;
    }

    .title {
        font-size: 2rem;
        font-weight: 600;
        color: #0a0a0a;
    }

    .link {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 1.4rem;
        color: #0a0a0a;

        &::after {
            content: '';
            display: block;
            width: 1.2rem;
            height: 1.2rem;
            background-image: url(/assets/images/ico/ico_arrow_r.svg);
            background-size: cover;
            margin-left: .2rem;
        }
    }
}

.medical-checkup--list {

    > li {
        + li {
            margin-top: 3.2rem;
        }
    }

    .card {
        overflow: hidden;
        border: 1px solid #cfcfcf;
        border-radius: .8rem;

        &__title {
            padding: 1.6rem 2rem;
            background-color: #f2f2f2;
            color: #0a0a0a;
            font-size: 1.6rem;
            line-height: 1.6rem;
        }

        &__desc {
            margin: 0;
            padding: 1.6rem 2rem;
            font-size: 1.6rem;
            line-height: 1.6rem;
        }
    }
}

.personal__card {
    &.date-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 0 .5rem 0 rgba(0, 0, 0, .3);
        
        .date {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 2rem;
            font-weight: 600;
            color: #0a0a0a;

            &::before {
                content: '';
                display: block;
                margin-right: .8rem;
                width: 2.4rem;
                height: 2.4rem;
                background-image: url(../images/ico/ico_input_calendar.svg);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
        }

        .division {
            padding: .5rem 1.6rem;
            border-radius: 2.4rem;
            background-color: #e9e9e9;
            font-size: 1.2rem;
            color: #0a0a0a;
            white-space: nowrap;
        }
    }
}

.order__policy--disc.new {
    > span {
        display: block;
        word-break: keep-all;
        font-size: 1.4rem;

        + span {
            margin-top: 1rem;
        }
    }

    .link-wrap {
        text-align: right;
    }

    .link {
        margin-top: 2rem;
        display: inline-block;
        text-align: right;
        text-decoration: underline;
        color: #0a0a0a;

    }
}