html {
    min-width: 320px;
    overflow-x: auto;
    overflow-y: scroll;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    font-family: var(--body-font-family);
    font-size: 62.5%;
    font-weight: var(--body-font-weight);
    line-height: var(--body-line-height);
    color: var(--body-color);
    text-align: var(--body-text-align);
    background-color: var(--body-bg);
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;

    &::-webkit-scrollbar {
        display: none; /* 크롬, 사파리, 오페라, 엣지 */
    }
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
}

// #test {
//     width: 200px; 
//     height: 200px;
//     background-color:skyblue;
//     overflow-y: scroll;

//     -ms-overflow-style: none; /* 인터넷 익스플로러 */
//     scrollbar-width: none; /* 파이어폭스 */
// }

#test
* { 
    font-family: var(--body-font-family);
}

img {
    width: 100%;
    height: auto;
}

hr {
    margin: 20px 0;
    border-top: 1px solid #ccc;
    border-bottom: 0;
}

a {
    color: inherit;
    text-decoration: none;

    &:hover {
        color: inherit;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    padding: 0;
    margin: -1px;
    border: 0;
    clip: rect(0, 0, 0, 0);
}

// .is-clipped {
//     overflow: hidden !important;
// }

/* SkipNavi */
#skipNav {
    position: relative;
    z-index:9999;

    a {
        position: absolute;
        top: -200px;
        left:0;
        width: 100%;
        line-height: 30px; 
        border: 1px solid #dedede;
        background-color: #333;

        &:focus, 
        &:active {
            top: 0;
        }
    }
}

.wrap {
    
    // padding-bottom: 11rem;
    // margin-left: auto;
    // margin-right: auto;
    width: 100%;
    // overflow: auto;
    position: relative;
}

@media (max-width: 1230px) {

}

.section {
    // padding-left: 20px;
    // padding-right: 20px;
    // margin-left: auto;
    // margin-right: auto;

    + .section {
        margin-top: 2rem;
        // padding-top: 2rem;
    }


    &.is-fullwidth {
        padding: 0;
    }

    // + .section {
    //     margin-top: 40px;
    // }

    &--title-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin-bottom: 12px;
    }

    &--title {
        color: #555;
    }

    .title-h2 {
        font-size: 2.4rem;
        font-weight: 600;
        color: #0a0a0a;

        &.is-regular {
            font-weight: normal;
        }

        &.is-small {
            font-size: 2rem;
        }
    }
}

.container {
    flex-grow: 1;
    position: relative;
    padding-left: 2rem;
    padding-right: 2rem;
    margin: 0 auto;
    width: auto;
    // max-width: 78rem;
}

// .is-clipped {
//     position: fixed;
//     width: 100%;
//     height: 100%;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     overflow: hidden !important;
// }

body.view--pc.is-clipped {
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden !important;
}

.dim {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .7);
    z-index: 5;
    // -webkit-animation: ani-opacity-active .2s both;
    // animation: ani-opacity-active .2s both;
}

.dimmed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .7);
    z-index: 6;

    &--transparent {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: transparent;
        z-index: 9;
    }
}


@-webkit-keyframes ani-opacity-active {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes ani-opacity-active {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes ani-opacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes ani-opacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.dot-list {
    margin-left: .8rem;
    
    li {
        position: relative;
        &::before {
            content: '';
            width: 4px;
            height: 4px;
            background-color: #313131;
            border-radius: 50%;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            left: -.8rem;
        }
        +li {
            margin-top: 8px;
        }
        p {
            margin: 0;
            font-size: 14px;
            font-weight: 400;
            color: #363940;
            line-height: 24px;
        }
        b {
            font-weight: 400;
            color: #1f4799;
        }
    }

    &.dot--top {
        li {
            &::before {
                top: 0;
                transform: translateY(0);
                margin-top: 8px;
            }
        }
    }
}

// PC접근 사이드 네비게이션
.side-nav--pc {
    display: none;
    width: 100%;
    max-width: 64rem;
}

.view--pc {
    background-color: #fff;
    // background-color: #b2b5c0;

    a[href^="tel:"] {
        pointer-events: none;
    }

    .wrap {
        max-width: 64rem;
        background: #fff;
        box-shadow: 0 0 4px rgba(0, 0, 0, .5);
    }

    .container {
        max-width: 64rem;
    }

    .side-nav--pc {
        display: none;
        position: relative;
        // background: rgba(3, 169, 244, .5);
        background: rgba(255, 255, 255, .2);
    }

    &::before {
        display: none;
    }

    .main {
        background-color: #fff;

        &.bg--gray {
            background-color: #f2f2f2;
        }
    }
}


@media (min-width: 1280px) {
    .view--pc {
        .side-nav--pc {
            display: block;
            width: 100%;
            left: 0;

            .container {
                position: fixed;
                display: block;
                width: 100%;
            }
        }

        // &::before {
        //     content: '';
        //     position: fixed;
        //     display: block;
        //     top: 0;
        //     left: 0;
        //     right: 0;
        //     bottom: 0;
        //     background-size: cover;
        //     background-repeat: no-repeat;
        //     background-position: center;
        // }

        // &.--shop {
        //     &::before {
        //         // background-image: url(../images/@dummy/bg_01.jpg);
        //         background-image: url(../images/@dummy/bg_01.svg);
        //     }
        // }

        // &.--mission {
        //     &::before {
        //         // background-image: url(../images/@dummy/bg_02.jpg);
        //         background-image: url(../images/@dummy/bg_02.svg);
        //     }
        // }

        // &.--main {
        //     &::before {
        //         // background-image: url(../images/@dummy/bg_03.jpg);
        //         background-image: url(../images/@dummy/bg_03.svg);
        //     }
        // }
    }
}

@media (max-width: 640px) {
    .view--pc .wrap {
        min-width: 100%;
    }
}

.msg {
    display: block;
    margin-top: 5rem;
    font-size: 1.2rem;
    &-box {
        padding: 2rem;
        background-color: #f2f2f2;

        &.--bottom {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
        }

        &.--above-btn {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 60px;
        }

        &.is-normal {
            padding: 0;
            background-color: #fff;
        }

        .desc {
            margin-top: 1rem;
            font-size: 1.4rem;
            color: #242424;
        }
    }
    
    &__title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 1.1rem;
        font-weight: 500;
        color: #0a0a0a;
        line-height: 1;

        &::before {
            content: '';
            display: inline-block;
            width: 1.2rem;
            height: 1.2rem;
            background-image: url(../images//ico/ico_tip1.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            /* vertical-align: middle; */
            margin-right: 0.2rem;
        }

        &.--large {
            font-size: 1.4rem;
            font-weight: 600;

            &::before {
                display: none;
            }
        }
    }

    &__list {
        margin-top: 1.2rem;
        font-size: 1.2rem;
        color: #636363;
        line-height: 1.5rem;

        li {
            position: relative;
            margin-left: .6rem;
            word-break: keep-all;

            + li {
                margin-top: .8rem;
            }

            &::before {
                content: "";
                position: absolute;
                top: .5rem;
                left: -.4rem;
                width: .2rem;
                height: .2rem;
                background-color: #636363;
                border-radius: 50%;
            }

            .tel {
                color: #2b2b2b;
                text-decoration: underline;
            }

            .black {
                color: #0a0a0a;
            }
        }
    }

    &.msg--succese {
        color: #4194f3;
    }

    &.msg--error {
        color: #ff6161;
    }
}

// 220623, 임시
.main {
    position: relative;
    z-index: 2;
    min-height: calc(100vh - 4.8rem);
    padding-bottom: 6rem;
    // transition: .3s; 왜 넣었을까...
    
}

// 220811, 정리 필요 s
body.view--pc {
    display: block !important;
    
    .flex-box {
        display: flex;
        justify-content: center;
        flex-direction: row-reverse;

    }
}

.more__list {
    > li {
        display: none;
    }
}

// 배너
.banner {
    &--img {
        font-size: 0;
        padding: 0;
        
        .btn {
            padding: 0;
            font-size: 0;
            border: 0;
        }
    }
}

.tabs--search {
    display: none;

    &.is-active {
        display: block;
    }
}

.is-nodeite {
    * {
        background-color: transparent !important;
    }

    p {
        text-align: left !important;
        font-size: 1.4rem !important;
        line-height: 2rem !important;
    }

    s {
        text-decoration: none !important;
    }

    sub, sup {
        font-size: 100% !important;
        line-height: 2rem !important;
        position: relative !important;
        vertical-align: middle !important;
    }

    sub {
        bottom: 0 !important;
    }

    sup {
        top: 0 !important;
    }

    em, p, s, u, strong, sup, sub, span {
        font-family: 'Pretendard', '-apple-system', 'BlinkMacSystemFont', system-ui, sans-serif !important;
    }

    a {
        color: #00a972 !important;
        text-decoration: underline;
    }
}

input[type="number"] {
    &::-webkit-outer-spin-button, 
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &:focus {
        outline: 0;
    }
}

a{
    &:hover {
        color: inherit;
    }
}

input[type=number] {
    -moz-appearance: textfield;
  }

*::selection {
    background-color: hsl(160, 100%, 33%);
    color: #fff;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 50px white inset;
}

// [class^='icon-'],
// [class*=' icon-'] {
// 	/* use !important to prevent issues with browser extensions that change fonts */
// 	// font-family: '#{$icomoon-font-family}' !important;
// 	// speak: never;
// 	font-style: normal;
// 	font-weight: normal;
// 	font-variant: normal;
// 	text-transform: none;
// 	line-height: 1;
//     display: inline-block;

// 	/* Better Font Rendering =========== */
// 	-webkit-font-smoothing: antialiased;
// 	-moz-osx-font-smoothing: grayscale;
// }


.logo {
    display: inline-block;
    overflow: hidden;
    height: 100%;
    padding: .4rem 0;

    img {
        width: auto;
        height: 100%;
    }
}

.is-top-shadow {
    > * {
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            height: 4px;
            width: 100%;
            background-image: url(/assets/images/sd_4px.png);
        }
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-text-fill-color: #0a0a0a;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
}

input:autofill,
input:autofill:hover,
input:autofill:focus,
input:autofill:active {
	-webkit-text-fill-color: #0a0a0a;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
}

//2308 리뉴얼
.renewal {
    .msg-box + .msg-box {
        margin-top: 2rem;
    }

    .msg {
        &-box {
            padding: 0;
            background-color: transparent;
        }

        &__title {
            margin-bottom: 1.2rem;
            font-size: 1.8rem;
            color: #636363;
        }

        &__list {
            margin-top: 0;
            font-size: 1.6rem;
            color: #666;
            line-height: 1.5;

            li {
                &::before {
                    top: 0.8rem;
                    left: -1rem;
                    width: 0.4rem;
                    height: 0.4rem;
                    background-color: #666;
                }
            }

            li + li {
                margin-top: 0.4rem;
            }

            &.--light {
                color: #9a9a9a;
            }
        }
    }
}

.select {
    &__box {
        position: relative;
        margin-right: -1.4rem;
        font-size: 1.6rem;
    }

    &__btn {
        margin: 1.2rem 1.4rem;
        white-space: nowrap;

        &::after {
            content: '';
            display: inline-block;
            width: 1.4rem;
            height: 1.4rem;
            margin-left: 0.5rem;
            background-image: url(../images/ico/ico_arrow_down_gray.svg);
            background-size: cover;
        }
    }

    &__list {
        display: none;
        position: absolute;
        z-index: 2;
        top: 0;
        right: 1.4rem;
        padding: 1.2rem 0;
        text-align: center;
        white-space: nowrap;
        border-radius: 0.5rem;
        background-color: #fff;
        box-shadow: 0px 0px 20px 0px rgba(61, 139, 114, 0.20);

        &.is-visible {
            display: block;
        }

        &--btn {
            padding: 1.2rem 2.4rem;
        }
    }
}