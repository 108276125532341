.gnb {
    &-box {
        position: relative;
        
        
        // left: 0;
        // right: 0;
        width: 100%;
        // padding: 1rem 0;
        // background-color: #fff;
        z-index: 3;
        

        .container {
            position: fixed;
            bottom: 0;
            transform: translateY(7rem);
            transition: transform 0.5s cubic-bezier(0.17, 0.04, 0.03, 0.94);
            background-color: #fff;
            width: 100%;
            padding: 1rem 0;
        }

        &.is-active {
            .container {
                transform: translateY(0);

                &::before {
                content: '';
                position: absolute;
                top: -24px;
                left: 0;
                display: block;
                background-image: url(../images/bg_tab_sd.png);
                background-repeat: repeat-x;
                height: 24px;
                width: 100%;
                transform: rotate(180deg);
            }
            }

            
            // box-shadow: 0 -5px 6px 0 rgba(0, 0, 0, 0.16);

            // &::before,
            // &::after {
            //     content: '';
            //     position: absolute;
            //     width: 6px;
            //     background-color: #f8f8f8;
            //     height: calc(100% + 6px);
            //     bottom: 0;
            // }
            // &::before {
            //     left: -6px;
            // }
            // &::after {
            //     right: -6px;
            // }
        }

        
    }

    &-list {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--item {
            flex: 1;

            svg {
                max-width: 3.2rem;
            }

            &:hover,
            &.is-active {
                .gnb__title {
                    color: #0a0a0a;
                }
            }
        }
    }

    &__link {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-height: 5.1rem;
    }

    &__title {
        display: block;
        margin-top: .5rem;
        font-size: 1.1rem;
        text-align: center;
        color: #636363;
    }
}


// gnb svg style

.gnb-list--item {
    .gnb__ico {
        .fill--color {
			fill: #636363;
			transition:0.1s;
		}
    }

    &:hover,
    &.is-active {
        .fill--color {
            fill: #090909;
        }
    }

    #gnbBasket {
        .gnb--animation {
           transform-box: fill-box;
            transform-origin: bottom;
            transition: 0.3s;
            transform: scaleY(0);
        }
    }

    #gnbSearch {
        .gnb--animation {
            opacity: 0;
            transition:0.3s;
            // transform: rotate(0deg);
            transform-box: fill-box;
            // transform-origin: center;
        }
    }

    #gnbHome {
        .gnb--animation {
            transform-box: fill-box;
            transform-origin: center;
            transform:scale(0);
            transition:0.3s;
            opacity: 0;
        }
    }

    #gnbMisson {
        .gnb--animation {
            transform-box: fill-box;
            transform-origin: center;
            transform:scale(0);
            transition: .3s;
            opacity: 0;
        }
    }


    #gnbData {
        .gnb--animation {
            transform-box: fill-box;
            transform-origin: bottom;
            transition: 0.3s;
            transform: scaleY(0);
        }
    }
    
    #gnbMy {
        .gnb--animation {
            transform-box: fill-box;
            transform-origin: center;
            transition: 0.3s;
            transform: scaleY(0);
        }
    }

    &.is-active {
        #gnbData {
            .gnb--animation {
                transform: scaleY(1);
            }
        }

        #gnbBasket {
            .gnb--animation {
                transform: scaleY(1);
            }
        }

        #gnbSearch {
            .gnb--animation {
                opacity: 1;
                // transform: rotate(180deg);
            }
        }

        #gnbHome {
            .gnb--animation {
                transform:scale(1);
                opacity: 1;
            }
        }

        #gnbMisson {
            .gnb--animation {
                transform:scale(1);
                opacity: 1;
            }
        }

        #gnbMy {
            .gnb--animation {
                transform: scaleY(1);
            }
        }
    }
}

// pc접속시
.view--pc {
    .gnb-box {
        max-width: 640px;
    }
}

// @media (min-width: 1230px) {
//     .view--pc {
//         .gnb-box {
//             transform: translateY(7rem);
//         }
//     }
// }