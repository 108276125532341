.tabs {
    -webkit-overflow-scrolling: touch;
    display: flex;
    align-items: stretch;
    font-size: 1.6rem;
    justify-content: space-between;
    white-space: nowrap;
    position: relative;
        
    &::after {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        right: 0;
        display: block;
        height: 4px;
        width: 100%;
        background-image: url(/assets/images/sd_4px.png);
    }
    ul {
        overflow: auto;
        
        align-items: center;
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        justify-content: space-around;
        align-items: flex-end;
        // border-bottom: 1px solid #d4d4d4;

        

        li {
            flex: 1;
            position: relative;
            background-color: #fff;

            &.is-active {
                .tab__btn {
                    z-index: 1;
                    color: #0a0a0a;

                    &::after {
                        border-bottom: 2px solid #0a0a0a;
                    }
                }
            }
        }
    }

     &.tabs--block {
        .container  > ul {
            justify-content: flex-start;

            > li {
                flex: none;
            } 
        }
    }

    .tab__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        padding: 1.7rem 2rem;
        font-size: 1.6rem;
        color: #636363;
        line-height: 1.4;

        &::after {
            content: '';
            display: block;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            // border-bottom: 1px solid #d4d4d4;
        }

        .title {
            display: block;
            color: #00a972;
            font-size: 1.8rem;
            font-weight: 500;
        }

        .user__character {
            display: flex;
            align-items: center;
            line-height: 2.5rem;

            .title {
                margin-left: 1rem;
            }
        }

    }

    &--sub {
        overflow: auto;
        white-space: nowrap;

        li {
            display: inline-block;
            font-size: 0;

            .btn {
                font-size: 1.5rem;
                color: #636363;
            }

            &.is-active {
                .btn {
                    color: #00a972;
                }
            }

            + li {
                position: relative;

                &::before {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1px;
                    height: 1rem;
                    background-color: #9a9a9a;
                    vertical-align: middle;
                }
            }
        }
    }

    &__panel {
        padding: .7rem 0;
        background-color: #fff;
        z-index: 2;
    }

    &.--rate {
        .tab__btn span {
            font-weight: 600;
        }

        .family {
            color: #00a972;
        }
        .vip {
            color: #6195FF;
        }
        .vvip {
            color: #BE85FF;
        }
    }
}

.tabs {
    position: relative;
    z-index: 3;
    
    &.bg-gray {
        background-color: #f2f2f2;
    }
    
    &__btn {
        flex: 1;
        // padding: 1.5rem 2.8rem;
        padding: 1.3rem 0;
        font-size: 1.9rem;
        // color: #636363;
        color: inherit;
        text-align: center;

        &.is-active {
            color: #00a972;
            border-radius: 0;
            border-bottom: 2px solid #00a972;
        }
    }

    &--top{
        // padding-top: 5.5rem;
        padding-top: 5.5rem;
        background-color: #fff;
        -webkit-overflow-scrolling: touch;

        .container {
            position: fixed;
            top: 4.8rem;
            width: 100%;
            padding: 0;
            display: flex;
            justify-content: space-between;
            align-self: center;
            background-color: #fff;
            transition: .3s;
            z-index: 1;
            // box-shadow: 0 5px 6px 0 rgba(0, 0, 0, .16);
            border-bottom: 1px solid #d4d4d4;

            // &::after {
            //     content: '';
            //     position: absolute;
            //     bottom: -24px;
            //     left: 0;
            //     display: block;
            //     background-image: url(../images/bg_tab_sd.png);
            //     background-repeat: repeat-x;
            //     height: 24px;
            //     width: 100%;
            // }
        }

        &.is-active {
            // .container {
            //     top: 4rem;
                
            // }
            // .tabs__btn {
            //     padding-top: .5rem;
            //     padding-bottom: .5rem;
            //     transition: .3s;
            // }
        }

        &.is-left {
            .container {
                justify-content: flex-start;
            }
        }
    }
}

.tab__swiper {
    .btn.tab__btn {
        padding: 1.6rem 1rem;
    }
}