.code-img {
    margin-top: 2rem;
    font-size: 0;
    text-align: center;

    li {
        display: inline-block;
        width: calc(50% - 1.5rem);
        text-align: center;
    }

    li + li {
        margin-left: 1.5rem;
    }

    .img {
        display: inline-block;
        width: 100%;
        max-width: 25rem;
        min-width: 17rem;
    }
}

@media (max-width: 380px) {
    .code-img {
        li {
            display: block;
            width: 100%;
        }
    
        li + li {
            margin-left: 0;
        }

        .img {
            width: 17rem;
        }
    }
}

.agreement__box {
    height: 100%;
    padding: 2rem;
    margin-top: 2rem;
    background-color: #fff;
    border-radius: .8rem;
    border: 1px solid #bbb;

    .txt {
        overflow: auto;
        max-height: calc(100vh - 28rem);
        font-size: 1.4rem;
        color: #0a0a0a;
        line-height: 1.8;
        word-break: keep-all;
    }

    &.terms {
        .txt {
            max-height: calc(100vh - 25.6rem);
        }
    }
}

.main {
    &.page-error  {
        padding-top: 0;
        padding-bottom: 0;
        height: 100vh;

        .title-box {
            padding-top: 13.2rem;
            text-align: center;

            &::before {
                content: '';
                display: inline-block;
                margin: auto;
                width: 6.6rem;
                height: 6.6rem;
                background-image: url(/assets/images/ico/ico_nodata_black.svg);
                background-size: cover;
            }
        }

        .title {
            margin-top: 2rem;
            font-size: 2.2rem;
            color: #0a0a0a;
            line-height: 1.36;
        }

        .msg {
            margin-top: 2rem;
            font-size: 1.8rem;
            text-align: center;
            color: #636363;
            line-height: 1.44;
        }
    }

    &.page-loading {
        padding-top: 0;
        padding-bottom: 0;
        height: 100vh;
    }
}

.page-loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 7;
    background-color: rgba(0, 0, 0, .7);

    .icon {
        width: 10rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@media (min-width: 1280px){
    .page-loading {
        .icon {
            transform: translate(270%, -50%);
        }
    }
}
