.field {
    &.cscenter--search {
        padding-top: 0;
        padding-bottom: 0;

        .control {
            overflow: hidden;
            padding-left: 0;
            padding-right: 0;
            background-color: #fff;
            border: 0;
            border-bottom: 1px solid #bbb;
            border-radius: 0;
        }

        input {
            height: 4.4rem;
            background-color: transparent;
            border: 0;
            font-size: 1.5rem;
            color: #0a0a0a;

            &::placeholder {
                color: #9a9a9a;
            }
        }

        .btn.btn-search {
            background-size: 2.8rem;
            background-image: url(../images/ico/ico_search.svg);
            width: 2.8rem;
            height: 2.8rem;
        }
        
    }
}

.category-box__swiper.customcenter {
    margin-left: -2rem;
    margin-right: -2rem;
    padding-left: 2rem;
    padding-right: 2rem;

    .swiper-slide {
        text-align: center;
        font-size: 1.8rem;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        width: auto;
        position: relative;

        &.is-active {
            .btn {
                font-weight: 600;
                color: #fff;
                background-color: #0a0a0a;
                border-color: #0a0a0a;
            }
        }

        &:first-child {
            .btn {
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -2px;
                    width: 1px;
                    height: 4.4rem;
                    background-color: #fff;
                }
            }
            
        }
    }

    .btn {
        height: 4.4rem;
        font-size: 1.5rem;
        color: #636363;
        border: 1px solid #bbb;
        border-radius: 2.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.no-search.customcenter {
    background-color: #f2f2f2;
    color: #9a9a9a;
    font-size: 1.5rem;
}


.search__result--title {
    padding-top: 2.8rem;
    // padding-bottom: 2rem;
    font-size: 2.2rem;
}

.board--title-box.faq {
    margin-top: 4rem;
    margin-bottom: 2rem;
    .title {
        font-size: 2rem;
        color: #0a0a0a;
    }
}