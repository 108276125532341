.renewal {
    .section {
        margin-top: 1.6rem
    }

    .title__box {
        &.--flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &.--normal {
            .title {
                font-weight: normal;
            }
        }

        .title {
            flex: 1;
            font-size: 1.8rem;
            font-weight: 700;
            line-height: 1.3;
            word-break: keep-all;
            text-align: left;

            &.--thin {
                font-weight: normal;
            }
        }

        .mission-text {
            font-size: 1.8rem;
        }

        .btn--line--green {
            width: auto;
            padding: 0.6rem 1.2rem;
            font-size: 1.4rem;
            border-radius: 0.4rem;
            background-color: #fff;
        }

        .btn--refresh {
            vertical-align: super;
        }
    }

    .personal__card {
        border-radius: 1rem;
        box-shadow: none;

        &.--shadow {
            box-shadow: 0 0 20px 0 rgba(61, 139, 114, 0.2);
        }

        .title__box {
            margin-bottom: 1.5rem;
        }

        .no-data__text {
            font-size: 1.6rem;
            color: #999;
        }
    }

    .personal__progress {
        &.--walk {
            padding: 0;

            .outer {
                height: 1.6rem;
                border-radius: 1.6rem;
                background-color: #e7e7e7;
            }

            .inner {
                border-radius: 1.6rem;
                background-color: var(--selex--green);
            }

            .point {
                position: absolute;
                top: -0.3rem;

                &:first-child {
                    left: calc(30%);
                }

                &:nth-child(2) {
                    right: 0;
                }
                
                &::before {
                    content: '';
                    display: block;
                    width: 2.2rem;
                    height: 2.2rem;
                    background-color: #fff;
                    border-radius: 50%;
                    border: 0.4rem solid #e7e7e7;
                }

                &.is-active::before {
                    border-color: var(--selex--green);
                }
            }
        }
    }

    .exercise__box {
        display: flex;
        align-items: center;

        .ico {
            width: 2.8rem;
            height: 2.8rem;
            margin-right: 0.5rem;
        }

        .txt {
            font-size: 1.6rem;
            color: var(--selex--green);
        }
    } 

    .ingestion__box {
        display: flex;

        li + li {
            margin-left: 1rem;
        }

        .txt {
            font-size: 1.6rem;

            &::before{
                content: '';
                display: inline-block;
                width: 0.9rem;
                height: 0.9rem;
                margin-right: 0.5rem;
                border-radius: 0.5rem;
            }

            &.--green {
                color: var(--selex--green);
                
                &::before{
                    background-color: #009B5D;
                }
            }

            &.--red {
                color: #A72D2D;

                &::before{
                    background-color: #EE7979;
                }
            }

            &.--yellow {
                color: #A38C14;

                &::before{
                    background-color: #F4D52E;
                }
            }
        }
    }

    .btn__certify {
        font-size: 1.6rem;
        color: var(--selex--green);

        &::before {
            content: '';
            display: inline-block;
            width: 2rem;
            height: 2rem;
            margin-right: 0.2rem;
            background-image: url(../images/ico/ico_d_schedule.svg);
            background-size: cover;
            vertical-align: bottom;
        }
    }

    .history-list {
        li + li {
            margin-top: 2rem;
        }

        &__title-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;
            color: #0a0a0a;

            .title {
                font-size: 1.6rem;
                font-weight: normal;
            }
        }

        &__contents {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            padding: 2rem;
            background-color: #fff;
            border: 1px solid #E0E0E0;
            border-radius: 0.5rem;
            overflow: hidden;

            .thumb {
                overflow: hidden;
                width: 8rem;
                height: 8rem;
                border-radius: 0.5rem;
                background-color: #f2f2f2;
                background-repeat: no-repeat;
                background-size: 4rem;
                background-position: 50%;
            }

            .text-box {
                overflow: hidden;
                flex: 1;
                margin-left: 1.8rem;
                font-size: 2rem;
                color: #0a0a0a;

                .title {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    font-size: 1.8rem;
                    text-align: left;
                }
                
                .desc {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    font-size: 1.8rem;
                    font-weight: 700;
                    text-align: left;
                }

                .nutrition {
                    display: flex;
                    margin-top: 1.2rem;

                    .text + .text {
                        margin-left: 1rem;
                    }

                    .text {
                        display: inline-block;
                        font-size: 1.4rem;

                        &::before {
                            content: '';
                            display: inline-block;
                            width: 1rem;
                            height: 1rem;
                            margin-right: 0.6rem;
                            border-radius: 1rem;
                        }

                        &.--green {
                            color: var(--selex--green);

                            &::before {
                                background-color: var(--selex--green);
                            }
                        }

                        &.--red {
                            color: #A72D2D;

                            &::before {
                                background-color: #EE7979;
                            }
                        }

                        &.--yellow {
                            color: #A38C14;

                            &::before {
                                background-color: #F4D52E;
                            }
                        }
                    }
                }
            }
        }
    }

    .calendar-wrap {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;
        width: 100%;

        &.is-visible {
            display: block;
        }
    }
}
//renewal 영역 끝

.personal__progress--box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text {
        font-size: 1.3rem;
        color: #999;
    }
}

.container__card {
    padding: 1.8rem 1rem 1rem 1rem;
    border-radius: 1.4rem;

    &--title {
        margin-bottom: 1.8rem;
        font-size: 1.8rem;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.45);
        text-align: center;
    }

    .personal__card {
        padding: 2rem 2rem 1.5rem 2rem;
    }

    .personal__card + .personal__card {
        margin-top: 1rem;
    }
}

.bnr-btn__area {
    li + li {
        margin-top: 1.6rem;
    }

    .personal__card {
        padding: 1rem 2rem;

        .title__box {
            margin-bottom: 0;
        }
    }

    .btn--line--gray {
        width: auto;
        padding: 1rem 1.4rem;
    }

    .thumb {
        width: 8rem;
        height: 8rem;
        margin-left: 1rem;
    }
}

.filter {
    &__tabs {
        padding:0 2rem;
        font-size: 0;
        overflow: auto;
        white-space: nowrap;

        li + li {
            margin-left: 0.4rem;
        }

        li {
            display: inline-block;

            &.is-active {
                .filter__btn {
                    color: #009B5D;
                    font-weight: 700;
                    border-color: var(--selex--green);
                    background-color: #FAFFFD;
                }
            }
        }
    }

    &__btn {
        display: inline-block;
        padding: 0.6rem 2rem;
        font-size: 1.6rem;
        text-align: center;
        border: 1px solid #e0e0e0;
        border-radius: 2rem;
        background-color: #fff;

        /* &.is-active {
            color: #009B5D;
            font-weight: 700;
            border-color: var(--selex--green);
            background-color: #FAFFFD;
        } */
    }
}

#dailyKcal {
    .personal__card {
        padding: 1.8rem 1.6rem;
    }

    .title {
        padding-top: 0.2rem;
        font-size: 1.6rem;
    }

    .tooltip-wrap {
        display: block;
        text-align: center;
    }

    .tooltip__btn {
        position: absolute;
        top: 0;
        right: 0;
    }

    .kcal__area {
        display: flex;
        margin-top: 1.4rem;
    }

    .kcal__box {
        width: 50%;
        text-align: center;

        &.--green {
            .kcal {
                color: var(--selex--green);
            }  
        }

        &.--yellow {
            .kcal {
                color: #F6A916;
            }  
        }

        .kcal {
            font-size: 2.2rem;
            font-weight: normal;

            strong {
                font-weight: 700;
            }
        }

        .desc {
            display: inline-block;
            margin-top: 0.6rem;
            font-size: 1.4rem;
            color: #999;
        }
    }

    .kcal__box + .kcal__box {
        border-left: 1px solid #ccc;
    }

    .tooltip-box {
        width: calc(100% + 3.3rem);

        &.is-bottom {
            &::before {
                right: 1.2rem;
            }

            &::after {
                right: 1.2rem;
            }
        }
    }
}

#dailyExercise {
    .container__card {
        background: linear-gradient(180deg, #81F2CD 0%, #D6F2E9 100%);
    }
    
    .personal__progress {
        &.--walk {
            width: calc(100% - 10rem);
        }
    } 
}

#dailyMeal {
    .container__card {
        background: linear-gradient(180deg, #FFDF70 0%, #FFEFB5 100%);
    }
}

.cert__list {
    &--header {
        padding-bottom: 9.5rem;

        &-wrap {
            position: fixed;
            z-index: 3;
            width: 100%;
            max-width: 64rem;
            padding-top: 1.6rem;
            padding-bottom: 1rem;
            background-color: #fff;
        }

        .title__box {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .btn__date {
            flex: 1;
            font-size: 1.8rem;
            font-weight: 700;
            line-height: 1.3;
            text-align: left;
            word-break: keep-all;

            &::after {
                content: '';
                display: inline-block;
                width: 1.4rem;
                height: 1.4rem;
                margin-left: 0.4rem;
                background-image: url(../images/ico/ico_arrow_down_gray.svg);
                background-size: cover;
                vertical-align: inherit;
            }
        }

        .btn__certify {
            font-size: 1.6rem;
            color: var(--selex--green);
    
            &::before {
                content: '';
                display: inline-block;
                width: 2rem;
                height: 2rem;
                margin-right: 0.2rem;
                background-image: url(../images/ico/ico_d_schedule.svg);
                background-size: cover;
                vertical-align: bottom;
            }
        }
    }

    &--walk {
        .personal__progress {
            &.--walk {
                .inner {
                    background-color: #ffb941;
                }
                .text-box {
                    .point::before {
                        top: -3.6rem;
                    }
                }
            }
        } 
    }

    &--meal {
        .my-mission__history {
            .title {
                font-size: 2.6rem;
                font-weight: normal;
            }

            .disc {
                color: #999;
            }
        }

        .progress {
            &__list {
                li + li {
                    margin-top: 2.8rem;
                }

                .is-green {
                    .subjuect {
                        color: var(--selex--green);

                        &::before {
                            background-color: var(--selex--green);
                        }
                    }

                    .progress-inner {
                        background-color: var(--selex--green);
                    }
                }

                .is-red {
                    .subjuect {
                        color: #A72D2D;

                        &::before {
                            background-color: #EE7979;
                        }
                    }

                    .progress-inner {
                        background-color: #EE7979;
                    }
                }

                .is-yellow {
                    .subjuect {
                        color: #A38C14;

                        &::before {
                            background-color: #F1CB00;
                        }
                    }

                    .progress-inner {
                        background-color: #F1CB00;
                    }
                }
            }

            &__text {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 1rem;

                .subjuect {
                    font-size: 1.6rem;

                    &::before {
                        content: '';
                        display: inline-block;
                        width: 1rem;
                        height: 1rem;
                        margin-right: 0.6rem;
                        border-radius: 5rem;
                    }
                }

                .desc {
                    font-size: 1.6rem;
                    color: #aaa;
                }
            }

            &-outer {
                width: 100%;
                height: 1.6rem;
                position: relative;
                border-radius: 1.2rem;
                background-color: #E7E7E7;
                overflow: hidden;
            }

            &-inner {
                height: inherit;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 1.2rem;
            }
        }
    }

    &--exercise {
        .tooltip__btn::after {
            background-image: url(../images/ico/ico_tip2.svg);
        }
    }
}

.health {
    &__diary {
        &--inquiry {
            .title__box {
                .title {
                    font-size: 1.6rem;
                }
            }

            .select {
                &__box {
                    font-size: 1.4rem;
                }

                &__btn {
                    &::after {
                        width: 1.2rem;
                        height: 1.2rem;
                        vertical-align: middle;
                    }
                }

                &__list {
                    padding: 1rem 0;

                    &--btn {
                        padding: 1rem 2rem;
                        color: #000;
                    }
                }
            }
        }

        &--list + &--list {
            margin-top: 6rem;
        }

        .title__box {
            padding-bottom: 1.6rem;
            margin-bottom: 2rem;
            border-bottom: 1px solid #E7E7E7;
        }
        
        .kcal-box {
            margin-top: 0.6rem;

            span {
               display: inline-block;
               font-size: 1.6rem; 
            }    

            .consume {
                margin-right: 0.4rem;
                color: var(--selex--green);
            }   

            .intake {
                color: #F6A916;

                &::before {
                   content: '·';
                   display: inline-block;
                   margin-right: 0.6rem;
                   font-size: 1.6rem;
                   color: #000;
                }
            }
        }

        .is-nodata {
            padding: 10rem 0;
        }
    }
}

.point {
    &__list {
        li + li {
            margin-top: 2rem;
        }

        &--wrap {
            display: flex;
            // justify-content: flex-start;
            flex-wrap: wrap;
            align-items: center;
        }

        .ico {
            position: relative;
            width: 5rem;
            height: 5rem;
            margin-right: 1rem;
            border-radius: 5rem;
            overflow: hidden;
            background-color: #f7f7f7;

            img {
                width: 3rem;
                height: 3rem;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .text {
            flex: 1;
            overflow: hidden;

            span {
                display: block;
            }

            .time {
                margin-bottom: 0.5rem;
                font-size: 1.6rem;
                color: #999;
            }

            .title {
                font-size: 1.8rem;
                word-break: break-all;
                /* text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden; */
            }
        }

        .point {
            display: block;
            margin-left: 1rem;
            font-size: 1.8rem;
            color: var(--selex--green);
        }
    }
}